import {
  selectCrossSectionEnabledForTomographicView,
  selectSheetElevation,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { setCrossSectionEnabled } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { ViewDiv } from "@faro-lotv/app-component-toolbox";
import { matrix4ToAlignmentTransform } from "../alignment-modes-commons/alignment-transform";
import { CrossSectionToggleButton } from "../alignment-modes-commons/cross-section-toggle-button";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";

/**
 * @returns cad tomographic view toggle cross-section vs full CAD
 */
export function SheetToCadCrossSectionToggle(): JSX.Element {
  const dispatch = useAppDispatch();

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForTomographicView,
  );

  const sheetElevation = useAppSelector(selectSheetElevation);
  const sheet = useSheetSelectedForAlignment("sheetToCad");
  const sheetMatrix = useAppSelector(selectIElementWorldMatrix4(sheet.id));
  const sheetTransform = matrix4ToAlignmentTransform(sheetMatrix);
  const elevation = sheetElevation ?? sheetTransform.position[1];

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);
  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CrossSectionToggleButton
        isEnabled={isCrossSectionEnabled}
        elevation={elevation}
        onToggleCrossSections={() => {
          dispatch(setCrossSectionEnabled(!isCrossSectionEnabled));
        }}
      />
    </ViewDiv>
  );
}
