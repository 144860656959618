import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import {
  GUID,
  IElementCamView,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import {
  selectBestViewForElement,
  selectIElement,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { createSelector } from "@reduxjs/toolkit";
import { Quaternion, Vector3 } from "three";

export enum CameraViewType {
  /** Camera view with perspective projection */
  perspective = "perspective",

  /** Camera view with orthographic projection */
  orthographic = "orthographic",
}

/** Data describing the camera view. Unlike the IElementCamView, this object is in three.js coordinates. */
export type CameraView = {
  /** Camera type */
  type: CameraViewType;

  /** Position of the camera */
  position: Vector3;

  /** Rotation of the camera */
  rotation: Quaternion;

  /** FOV in degrees for a perspective camera, ortho vertical size for ortho camera */
  zoomLevel: IElementCamView["zoomLevel"];
};

/**
 * @returns the best CameraView in three js coordinates for a given element
 * @param elementId the ID of the element to get the best view for
 */
export function selectBestCameraViewForElementInThreeSpace(elementId?: GUID) {
  return (state: RootState): CameraView | undefined => {
    const element = selectIElement(elementId)(state);
    const savedViewForElement = selectBestViewForElement(element)(state);

    if (!savedViewForElement) return undefined;

    return selectCameraViewInThreeSpace(savedViewForElement)(state);
  };
}

/**
 * @returns the CameraView in three js coordinates for a given IElementCamView
 * @param state current app state
 * @param camView the IElementCamView to convert the CameraView for
 */
export const selectCameraViewInThreeSpace = curryAppSelector(
  createSelector(
    [
      (state: RootState, camView: IElementCamView) =>
        selectIElementWorldTransform(camView.id)(state),
      (state: RootState, camView: IElementCamView) => camView,
    ],
    (worldTransform, camView) => {
      const { position, quaternion } = worldTransform;

      return {
        type:
          camView.typeHint === IElementTypeHint.orthographic
            ? CameraViewType.orthographic
            : CameraViewType.perspective,
        position: new Vector3().fromArray(position),
        rotation: new Quaternion().fromArray(quaternion),
        zoomLevel: camView.zoomLevel,
      };
    },
  ),
);
