import { WaypointPosition } from "@/components/r3f/renderers/waypoint-label-render";
import {
  offsetPlaceholders,
  OffsetPlaceholdersReturn,
} from "@/utils/offset-placeholders";
import { useClipFrustum } from "@faro-lotv/app-component-toolbox";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { Plane, Vector3 } from "three";

type UseVisiblePlaceholdersProps = {
  /** All available placeholders */
  placeholders: IElementImg360[];

  /** The positions of the placeholders */
  positions: Vector3[];

  /** The clipping planes to clip the placeholders by. If undefined, all placeholders are returned */
  clippingPlanes?: Plane[];
};

export type VisiblePlaceholders = {
  /** The placeholders that are considered visible */
  visiblePlaceholders: IElementImg360[];

  /** The positions of the visible placeholders */
  visiblePositions: Vector3[];
};

/**
 * @returns The visible placeholders and their positions clipped by a clipping plane
 */
export function useVisiblePlaceholders({
  placeholders,
  positions,
  clippingPlanes,
}: UseVisiblePlaceholdersProps): VisiblePlaceholders {
  const clipFrustum = useClipFrustum(clippingPlanes);

  return useMemo<VisiblePlaceholders>(() => {
    // If no clipping planes are provided, then all placeholders are visible
    if (!clipFrustum || placeholders.length !== positions.length) {
      return {
        visiblePlaceholders: placeholders,
        visiblePositions: positions,
      };
    }

    // Only placeholders inside the clipping frustum are visible
    const visiblePlaceholders: IElementImg360[] = [];
    const visiblePositions: Vector3[] = [];
    for (let i = 0; i < placeholders.length; i++) {
      const placeholder = placeholders[i];
      if (clipFrustum.containsPoint(positions[i])) {
        visiblePlaceholders.push(placeholder);
        visiblePositions.push(positions[i]);
      }
    }
    return { visiblePlaceholders, visiblePositions };
  }, [clipFrustum, placeholders, positions]);
}

/**
 * @returns the placeholder positions shifted using the offsetPlaceholders function
 * @param visiblePositions the positions to shift
 */
export function useOffsetPlaceholders(
  visiblePositions: Vector3[],
): OffsetPlaceholdersReturn {
  return useMemo(
    () => offsetPlaceholders(visiblePositions),
    [visiblePositions],
  );
}

/**
 * @returns the placeholder elements and positions and their final render positions wrapped in a WaypointPosition object
 * @param visiblePlaceholders the visible placeholders
 * @param visiblePositions the positions of visible placeholders
 * @param panoYOffset optional offset to apply to all final render positions
 */
export function useWaypoints(
  visiblePlaceholders: IElementImg360[],
  visiblePositions: Vector3[],
  panoYOffset?: number,
): WaypointPosition[] {
  return useMemo(() => {
    if (visiblePlaceholders.length !== visiblePositions.length) {
      return new Array<WaypointPosition>();
    }
    return visiblePlaceholders.map((pano, index) => {
      let renderPosition = visiblePositions[index];

      if (panoYOffset !== undefined) {
        renderPosition = renderPosition.clone();
        renderPosition.y += panoYOffset;
      }

      return { pano, renderPosition };
    });
  }, [visiblePlaceholders, visiblePositions, panoYOffset]);
}
