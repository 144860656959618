import { useCached3DObject } from "@/object-cache";
import { AlignmentViewLayout } from "@/store/modes/alignment-ui-types";
import {
  selectAlignmentStepForCloudToSheetAlignment,
  selectLayoutForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import { CloudToSheetAlignmentStep } from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import { isIElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { CloudToSheetElevationScene } from "./cloud-to-sheet-elevation-scene";
import { CloudToSheetOverlayScene } from "./cloud-to-sheet-overlay-scene";
import { CloudToSheetSplitScreenScene } from "./cloud-to-sheet-split-screen-scene";
import { useCloudToSheetAlignmentElements } from "./use-cloud-to-sheet-alignment-elements";

/** @returns The overlay for the cloud-to-sheet alignment mode */
export function CloudToSheetAlignmentModeScene(): JSX.Element {
  const step = useAppSelector(selectAlignmentStepForCloudToSheetAlignment);
  const alignmentViewLayout = useAppSelector(
    selectLayoutForCloudToSheetAlignment,
  );

  const alignmentElements = useCloudToSheetAlignmentElements();
  const cloudObject = useCached3DObject(alignmentElements.cloudPointStream);

  const sheet = selectChildDepthFirst(
    alignmentElements.sectionArea,
    isIElementGenericImgSheet,
  )(store.getState());
  assert(sheet, "Sheet not found for selected area.");

  const sheetObject = useCached3DObject(sheet);

  switch (step) {
    case CloudToSheetAlignmentStep.setElevation:
      return <CloudToSheetElevationScene cloudObject={cloudObject} />;
    case CloudToSheetAlignmentStep.pairPoints:
      if (alignmentViewLayout === AlignmentViewLayout.splitScreen) {
        return (
          <CloudToSheetSplitScreenScene
            cloudObject={cloudObject}
            sheetObject={sheetObject}
          />
        );
      }
      return (
        <CloudToSheetOverlayScene
          cloudObject={cloudObject}
          sheetObject={sheetObject}
        />
      );
  }
}
