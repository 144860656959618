import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useObjectVisibility } from "@/hooks/use-object-visibility";
import { useRealtimeRaycasting } from "@/hooks/use-real-time-raycasting";
import { PointCloudObject } from "@/object-cache";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  ExplorationControls,
  FilteredRenderPass,
  GridPlane,
  TransformControls,
  UniformLights,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { reproportionCamera } from "@faro-lotv/lotv";
import { useTheme } from "@mui/material";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Group, PerspectiveCamera, Quaternion, Vector2, Vector3 } from "three";
import { FLOOR_OVERLAP, NEAR_PLANE_DISTANCE } from "./model-elevation-scene";

const ROT_QUATERNION = new Quaternion(-Math.SQRT1_2, 0, 0, Math.SQRT1_2);

type CloudElevationSceneProps = {
  /** The element to set elevation */
  pointCloud: PointCloudObject;

  /** the initial elevation value */
  cloudElevation: number;

  /** callback function to update elevation */
  updateCloudElevation(elevation: number): void;

  /** the camera that is used to render the model */
  camera: PerspectiveCamera;
};

/** @returns the scene for set elevation in cloud object*/
export function CloudElevationScene({
  pointCloud,
  cloudElevation,
  updateCloudElevation,
  camera,
}: CloudElevationSceneProps): JSX.Element {
  const theme = useTheme();
  useRealtimeRaycasting(pointCloud, false);

  const cloudBox = useObjectBoundingBox(pointCloud, pointCloud.iElement.id);
  assert(cloudBox, "Cloud bounding box must exist");
  const { extents, cloudCenter } = useMemo(() => {
    const bboxSize = cloudBox.getSize(new Vector3());
    const extents = new Vector2(
      bboxSize.x * FLOOR_OVERLAP,
      bboxSize.z * FLOOR_OVERLAP,
    );
    const cloudCenter = cloudBox.getCenter(new Vector3());
    return { extents, cloudCenter };
  }, [cloudBox]);

  const [initPosition] = useState(() => {
    const center = cloudCenter.clone();
    center.y = cloudElevation;

    return center;
  });
  const size = useThree((s) => s.size);

  useEffect(() => {
    const diagonal = cloudBox.getSize(new Vector3()).length();
    camera.position.set(
      cloudCenter.x - diagonal,
      cloudCenter.y + 0.1 * diagonal,
      cloudCenter.z - 0.5 * diagonal,
    );

    camera.updateMatrixWorld(true);
    const cameraFarFactor = 50;
    camera.far = diagonal * cameraFarFactor;
    camera.near = NEAR_PLANE_DISTANCE;
    reproportionCamera(camera, size.width / size.height);
  }, [cloudBox, camera, cloudCenter, size]);

  useObjectVisibility(pointCloud, true);

  // plane elevation value obtained from TransformControls widget.
  // It is in the same CS and units as a CAD model (W.C.S, meters)
  // note that this value is updated on any movement of the plane in the widget
  const [planeElevation, setPlaneElevation] = useState<number>(0);

  const planeGroup = useRef<Group | null>(null);

  const pickPlaneHeight = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      const { point } = event.intersections[0];

      // event.delta is a distance between pointerDown and pointerUp in pixels.
      // if it's 2 or less pixels it was a pick of new elevation plane
      // if it's more, then it's considered as OrbitControl movement and plane elevation will be not updated
      if (planeGroup.current && event.delta <= 2) {
        planeGroup.current.position.setY(point.y);
        setPlaneElevation(point.y);

        // update value in Redux store at picking plane
        updateCloudElevation(point.y);
      }
    },
    [updateCloudElevation],
  );

  const [isElevationArrowControlInUse, setElevationArrowControlInUse] =
    useState<boolean>(true);

  const onElevationArrowControlPointerDown = useCallback((e?: Event) => {
    setElevationArrowControlInUse(false);
    e?.stopPropagation();
  }, []);

  // update the plane elevation value if sheet elevation is changed
  useEffect(() => {
    setPlaneElevation(cloudElevation);
    planeGroup.current?.position.setY(cloudElevation);
  }, [cloudElevation]);

  // update the plane elevation value after first rendering
  useNonExhaustiveEffect(() => {
    if (planeGroup.current) {
      setPlaneElevation(planeGroup.current.position.y);
      updateCloudElevation(planeGroup.current.position.y);
    }
  }, []);

  const onElevationArrowControlPointerUp = useCallback(
    (e?: Event) => {
      // update value in Redux store at ButtonUp event (while current value updates managed by useState)
      updateCloudElevation(planeElevation);

      setElevationArrowControlInUse(true);
      e?.stopPropagation();
    },
    [planeElevation, updateCloudElevation],
  );

  return (
    <>
      <UniformLights />
      <PointCloudRenderer pointCloud={pointCloud} onClick={pickPlaneHeight} />

      <group
        position={initPosition}
        quaternion={ROT_QUATERNION}
        ref={planeGroup}
      >
        <GridPlane
          size={extents}
          backgroundColor={theme.palette.gridBackground}
          backgroundOpacity={0.8}
          lineColor={theme.palette.gridLines}
          lineOpacity={0.5}
          borderColor={theme.palette.gridLines}
          borderWidth={0.1}
        />
      </group>
      <ExplorationControls
        enabled={isElevationArrowControlInUse}
        target={cloudCenter}
        disableInertia
        hidePivot
      />
      {planeGroup.current && (
        <group>
          <TransformControls
            object={planeGroup.current}
            camera={camera}
            showX={false}
            showZ={false}
            onMouseDown={onElevationArrowControlPointerDown}
            onMouseUp={onElevationArrowControlPointerUp}
            onTransformChanged={(position: Vector3) =>
              setPlaneElevation(position.y)
            }
          />
        </group>
      )}
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={pointCloud} />
        <FilteredRenderPass
          filter={(o) => !isObjPointCloudPoint(o)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}
