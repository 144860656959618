import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import {
  GUID,
  IElement,
  IElementGenericImgSheet,
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementGenericImgSheet,
  isIElementImg360,
  isIElementPanoInOdometryPath,
  isIElementWithTypeAndHint,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  isSingleScanSection,
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
} from "@faro-lotv/project-source";
import { createSelector } from "@reduxjs/toolkit";
import {
  CurrentAreaData,
  selectClosestPanoSectionFromTimeSeries,
} from "../mode-selectors";

export type TagsManagementScene = {
  /** The list of scans for each dataset/data session */
  scans: Record<GUID, IElementSection[]>;
  /** The list of rooms in the area */
  rooms: IElementSection[];
  /** The list of sheet in the area */
  sheets: IElementGenericImgSheet[];
};

/**
 * @returns The sheets and panos that should be visible in the current area
 * @param state The state of the store
 * @param areaData The data of the current area
 */
export const selectTagsManagementScene = curryAppSelector(
  createSelector(
    [
      (state: RootState) => state,
      (state: RootState, areaData: CurrentAreaData | undefined) => areaData,
    ],
    (state: RootState, areaData: CurrentAreaData | undefined) => {
      const scene: TagsManagementScene = { scans: {}, rooms: [], sheets: [] };
      if (!areaData?.area) return scene;

      for (const dataSession of [
        ...areaData.dataSessions2d,
        ...areaData.dataSessions5d,
      ]) {
        const scans = selectChildrenDepthFirst(
          dataSession,
          isIElementScan,
        )(state).filter((scan) => {
          const pano = selectChildDepthFirst(scan, isIElementImg360, 1)(state);
          return !!pano && !isIElementPanoInOdometryPath(pano);
        });

        if (scans.length > 0) {
          scene.scans[dataSession.id] = scans;
        }
      }

      const activeArea = areaData.area;
      const rooms = areaData.roomsTimeSeries.map((timeSeries) =>
        selectAncestor(
          selectClosestPanoSectionFromTimeSeries(activeArea, timeSeries)(state),
          (e) =>
            isIElementWithTypeAndHint(
              e,
              IElementType.section,
              IElementTypeHint.room,
            ),
        )(state),
      );

      scene.rooms = rooms.filter(isValid);
      scene.sheets = selectChildrenDepthFirst(
        areaData.area,
        isIElementGenericImgSheet,
      )(state);

      return scene;
    },
  ),
);

/**
 * @returns true if the input iElement is a valid scan type
 * @param iElement The element to check
 */
function isIElementScan(iElement: IElement): iElement is IElementSection {
  return (
    isIElementWithTypeAndHint(
      iElement,
      IElementType.section,
      IElementTypeHint.structuredE57,
    ) || isSingleScanSection(iElement)
  );
}
