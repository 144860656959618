import { useSheetBoundingBox } from "@/modes/alignment-modes-commons/align-to-cad-utils";
import { CadModelObject, PointCloudObject } from "@/object-cache";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { Box3 } from "three";
import { use3DmodelsBoundingBox } from "./use-object-bounding-box";

/**
 *
 * @param pointCloud The point coud
 * @param cad The CAD model
 * @param sheets the list of visible sheets/layers
 * @returns The bounding box containing all the argument objects
 */
export function useClippableObjectsBox(
  pointCloud: PointCloudObject | null,
  cad: CadModelObject | null | Error,
  sheets: IElementGenericImgSheet[],
): Box3 {
  const modelsBox = use3DmodelsBoundingBox(pointCloud, cad);
  const sheetBBox = useSheetBoundingBox(sheets);

  return useMemo(
    () => modelsBox.clone().union(sheetBBox),
    [modelsBox, sheetBBox],
  );
}
