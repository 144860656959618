import { neutral } from "@faro-lotv/flat-ui";
import { Box, Paper, Typography } from "@mui/material";
import { Html, HtmlProps } from "@react-three/drei/web/Html";
import {
  MouseEventHandler,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Vector3 } from "three";
import { LabelsScreenPositionsComputer } from "../utils/labels-screen-positions-computer";
import {
  INACTIVE_MEASURES_OPACITY,
  MEASURE_ANIMATION_LENGTH,
} from "./measurements/measure-constants";

export type TextLabelProps = {
  /** True to show this label, will animate opacity on change */
  visible: boolean;

  /** Index of the segment used for the position computation in case of measure label */
  index: number;

  /** The 3D position that the label should have in the scene */
  position: Vector3 | undefined;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Optional symbol that shows before the text string (Eg. X, Y, Z, ...) */
  symbol?: string;

  /** Support class to optimize the actual position of the labels so they do not overlap */
  labelsScreenPositionsComputer?: LabelsScreenPositionsComputer;

  /** True if this label is active (selected) */
  active: boolean;

  /** Callback when this label is clicked */
  onClick?: MouseEventHandler<HTMLDivElement>;

  /** Callback for PointerDown event on the label */
  onPointerDown?: MouseEventHandler<HTMLDivElement>;

  /** Callback for ContextMenu event on the label */
  onContextMenu?: MouseEventHandler<HTMLDivElement>;

  /** Callback when mouse is entering the label element */
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;

  /** Callback when mouse is leaving the label element */
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;

  /** True if this label should be transparent at INACTIVE_MEASURES_OPACITY, false if it should be opaque. */
  transparent: boolean;

  /** The pointerEvents allowed on the label */
  pointerEvents: HtmlProps["pointerEvents"];

  /** Optional placement of the label related to the position */
  placement?: "top" | "bottom";

  /** Optional background color of the label*/
  backgroundColor?: string;

  /** Optional label padding (default: 0.625) */
  padding?: number;

  /** Optional font size (default: "0.75em") */
  fontSize?: string;

  /** Optional cursor used when the text label is active (default is "text") */
  activeCursor?: string;
} & PropsWithChildren;

/**
 * @returns a Html label that displays the given text and moves in the 3D scene along with a given 3D position.
 */
export function TextLabel({
  index,
  visible,
  position,
  parentRef,
  symbol = "",
  labelsScreenPositionsComputer,
  active,
  activeCursor = "text",
  onClick,
  onPointerDown,
  onContextMenu,
  onMouseEnter,
  onMouseLeave,
  transparent,
  pointerEvents,
  placement,
  backgroundColor = neutral[999],
  padding = 0.625,
  fontSize = "0.75em",
  children,
}: TextLabelProps): JSX.Element | null {
  // Taking a ref to the HTML label to know at runtime any moment its width and height,
  // needed for the label collision resolution algorithm.
  const ref = useRef<HTMLDivElement>(null);

  // Whenever something changes, the screen size of the measure label is updated to
  // the labels position computer.
  useEffect(() => {
    if (ref.current) {
      labelsScreenPositionsComputer?.setLabelSize(
        index,
        ref.current.clientWidth,
        ref.current.clientHeight,
      );
    }
  });

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (!visible) setOpacity(0);
    else if (transparent) setOpacity(INACTIVE_MEASURES_OPACITY);
    else setOpacity(1);
  }, [transparent, visible]);

  const transform = useMemo(() => {
    switch (placement) {
      case "top":
        return "translateY(-100%)";
      case "bottom":
        return "translateY(100%)";
    }
  }, [placement]);

  if (!position) {
    return null;
  }
  return (
    <Html
      position={position}
      portal={parentRef}
      style={{
        pointerEvents: visible ? pointerEvents : "none",
        display: "block",
        transform,
      }}
      calculatePosition={
        labelsScreenPositionsComputer
          ? () => labelsScreenPositionsComputer.position(index)
          : undefined
      }
      zIndexRange={[0, 0]}
      ref={ref}
    >
      {/* Main Body */}
      <Paper
        elevation={0}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onPointerDown={onPointerDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          padding,
          userSelect: active ? "text" : "none",
          cursor: active ? activeCursor : "default",
          opacity,
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          transform: "translate(-50%, -50%)",
          pointerEvents: visible ? pointerEvents : "none",
          backgroundColor: { backgroundColor },
          outline: `${neutral[0]}33 solid 1px`,
        }}
      >
        <Typography
          noWrap
          sx={{
            fontSize,
            fontWeight: "bold",
            color: neutral[0],
          }}
        >
          {symbol && (
            <Box
              component="span"
              sx={{ opacity: 0.5 }}
            >{`${symbol}\u00A0`}</Box>
          )}
          {children}
        </Typography>
      </Paper>
    </Html>
  );
}
