import { useAnnotationTagOptions } from "@/components/ui/annotations/use-annotation-tag-options";
import { TagsManagementDialog } from "@/components/ui/tags/tags-management-dialog";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectTags } from "@/store/tags/tags-selectors";
import { Tag, UNTAGGED } from "@/store/tags/tags-slice";
import { selectShowSpinner } from "@/store/ui/ui-selectors";
import { setShowSpinner } from "@/store/ui/ui-slice";
import {
  ContextualToolbar,
  cyan,
  FaroButton,
  FaroMenu,
  FaroTooltip,
  FilterMenuContent,
  Option,
  PlusIcon,
  TagsAddIcon,
  TagsDeleteIcon,
  ToolButton,
  TranslateVar,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { Stack } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { useTagsManagementContext } from "./tags-management-context";
import { useAddTagsToIElements } from "./use-add-tags-to-ielements";
import { useRemoveAllTagsFromIElements } from "./use-remove-all-tags";

/**
 *
 * @returns The HTML overlay elements of the TagsManagement mode
 */
export function TagsManagementOverlay(): JSX.Element | null {
  const { selectedIds, clearSelection } = useTagsManagementContext();

  const removeAllTags = useRemoveAllTagsFromIElements(selectedIds);
  const dispatch = useAppDispatch();

  if (selectedIds.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        height: "100%",
        p: 1,
      }}
      justifyContent="end"
      alignItems="center"
    >
      <ContextualToolbar
        label={
          <>
            <TranslateVar name="numberOfElements">
              {selectedIds.length}
            </TranslateVar>{" "}
            {`${selectedIds.length > 1 ? "elements" : "element"} selected`}
          </>
        }
        clearSelection={clearSelection}
      >
        <AddTagsButton selectedIds={selectedIds} />
        <FaroTooltip title="Remove tags" placement="top">
          <ToolButton
            aria-label="delete-tag"
            onClick={async () => {
              dispatch(setShowSpinner(true));
              await removeAllTags();
              dispatch(setShowSpinner(false));
            }}
          >
            <TagsDeleteIcon />
          </ToolButton>
        </FaroTooltip>
      </ContextualToolbar>
    </Stack>
  );
}

type AddTagsButtonProps = {
  selectedIds: GUID[];
};

/** @returns A button to assign the tags to the selected scans */
function AddTagsButton({ selectedIds }: AddTagsButtonProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTagsManagementOpen, setIsTagsManagementOpen] = useState(false);

  const projectTags = useAppSelector(selectTags);
  const tags = useMemo(
    () => projectTags.filter((t) => t.name !== UNTAGGED.name),
    [projectTags],
  );

  const [selectedTagsOptions, setSelectedTagsOptions] = useState<Option[]>([]);
  const { tagsOptions } = useAnnotationTagOptions({
    tags,
    onDeleteButtonClick: (tag) => {
      // Remove the deleted tag from the selected tags
      setSelectedTagsOptions((prev: Option[]) =>
        prev.filter((t) => t.key !== tag.id),
      );
    },
  });

  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  const selectedTags = useMemo<Tag[]>(
    () =>
      selectedTagsOptions.map((option) => ({
        id: option.key,
        name: option.value,
      })),
    [selectedTagsOptions],
  );
  const addTags = useAddTagsToIElements(selectedTags, selectedIds);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setSelectedTagsOptions([]);
  }, []);

  const showSpinner = useAppSelector(selectShowSpinner);
  const dispatch = useAppDispatch();

  return (
    <>
      <FaroTooltip title="Add tags" placement="top">
        <ToolButton
          ref={setButtonRef}
          aria-label="add-tag"
          onClick={() => setIsMenuOpen(true)}
          selected={isMenuOpen}
        >
          <TagsAddIcon />
        </ToolButton>
      </FaroTooltip>
      <FaroMenu
        anchorEl={buttonRef}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={isMenuOpen}
        onClose={closeMenu}
        dark
        sx={{ transform: "translate(0px, -8px)" }}
      >
        <FilterMenuContent
          headingLabel="Add tags"
          options={tagsOptions}
          selectedOptions={selectedTagsOptions}
          onFilterChange={setSelectedTagsOptions}
          onResetFilter={() => setSelectedTagsOptions([])}
          dark
          keepHeightOnSearch
        >
          <FaroButton
            variant="ghost"
            disabled={showSpinner}
            dark
            sx={{
              color: cyan[400],
              textDecoration: "underline",
              justifyContent: "flex-start",
            }}
            icon={<PlusIcon />}
            onClick={() => setIsTagsManagementOpen(true)}
          >
            Manage tags
          </FaroButton>
        </FilterMenuContent>
        <Stack direction="row" sx={{ width: "100%" }} justifyContent="flex-end">
          <FaroButton
            dark
            onClick={async () => {
              dispatch(setShowSpinner(true));
              await addTags();
              dispatch(setShowSpinner(false));
              closeMenu();
            }}
            isLoading={showSpinner}
            size="m"
            disabled={selectedTagsOptions.length === 0}
          >
            Add
          </FaroButton>
        </Stack>
      </FaroMenu>
      <TagsManagementDialog
        open={isTagsManagementOpen}
        onClose={() => setIsTagsManagementOpen(false)}
      />
    </>
  );
}
