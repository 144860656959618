import { EnsureWriteAccess } from "@/permissions/ensure-project-access";
import { selectIsProjectEmpty } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { redirectToDataManagementUrl } from "@/utils/redirects";
import { Banner, BannerProps } from "@faro-lotv/flat-ui";
import { selectDashboardUrl, selectProjectId } from "@faro-lotv/project-source";
import {
  CaptureTreeEntityType,
  RegistrationRevision,
  RegistrationState,
  RevisionStatus,
  RevisionType,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { CreateAreaMainBody } from "./create-area-main-body";
import { CrateAreaTitle } from "./create-area-title";
import { InformationBox } from "./information-box";
import { LimitedAccess } from "./limited-access";

/** @returns The overlay elements of this mode */
export function CreateAreaModeOverlay(): JSX.Element | null {
  /** Style used to fit the entire page */
  const entirePageStyle = useMemo<CSSProperties>(
    () => ({
      position: "relative",
      // Set the size of the element to the entire page, minus the header bar
      width: "100%",
      height: "100%",
      overflow: "auto",
    }),
    [],
  );

  const banner = useBannerForDraftRevision();

  return (
    <Stack sx={entirePageStyle}>
      {banner && <Banner {...banner} sx={{ m: 0 }} />}
      <CreateAreaModeBody />
    </Stack>
  );
}

/**
 * @returns a component containing the entire body of the CreateArea mode Overlay
 */
function CreateAreaModeBody(): JSX.Element {
  const theme = useTheme();

  /** Style shared by the body of the overlay */
  const commonStyle = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: `${theme.palette.gray100}80`,
      width: "100%",
      py: 1.5,
      px: { xs: 2, md: 5 },
    }),
    [theme.palette.gray100],
  );

  return (
    <Stack alignSelf="center" width={{ xs: "90%", md: "80%" }}>
      <CrateAreaTitle
        sx={{
          mt: { xs: "1.5rem", md: "60px" },
        }}
      />
      <InformationBox sx={{ ...commonStyle, mt: { xs: "1rem", md: "24px" } }} />
      <EnsureWriteAccess
        fallback={<LimitedAccess sx={{ ...commonStyle, mt: "0.625rem" }} />}
      >
        <CreateAreaMainBody sx={{ ...commonStyle, mt: "24px" }} isFirstSheet />
      </EnsureWriteAccess>
    </Stack>
  );
}

/**
 * @returns True if the provided revision is an open Draft Revision.
 * @param revision The revision to check
 */
function isOpenDraftRevision(revision: RegistrationRevision): boolean {
  return (
    revision.revisionType === RevisionType.draft &&
    revision.state !== RegistrationState.merged &&
    revision.state !== RegistrationState.canceled
  );
}

/**
 * @returns The banner to show if the project is empty, the user has editing permission and there's an open draft
 * in the staging area.
 */
function useBannerForDraftRevision(): BannerProps | undefined {
  const isProjectEmpty = useAppSelector(selectIsProjectEmpty);
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const projectId = useAppSelector(selectProjectId);
  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const { projectApiClient } = useApiClientContext();
  const [banner, setBanner] = useState<BannerProps | undefined>();
  useEffect(() => {
    setBanner(undefined);

    async function checkForOpenDraft(): Promise<void> {
      const revisions = await projectApiClient.getAllRegistrationRevisions();
      const openDraftRevision = revisions.find(isOpenDraftRevision);
      const openDraftEntities = openDraftRevision?.id
        ? await projectApiClient.getCaptureTreeForRegistrationRevision(
            openDraftRevision.id,
          )
        : [];
      const hasDraftChanges = openDraftEntities.some(
        (entity) =>
          (entity.type !== CaptureTreeEntityType.root ||
            entity.status !== RevisionStatus.added) &&
          entity.status !== RevisionStatus.initialized,
      );

      if (
        projectId &&
        isProjectEmpty &&
        hasWritePermission &&
        hasDraftChanges
      ) {
        setBanner({
          variant: "info",
          title: "Draft in progress",
          children: "This project has pending changes.",
          action: {
            label: "View draft",
            action: () => redirectToDataManagementUrl(projectId, dashboardUrl),
          },
        });
      }
    }

    checkForOpenDraft();
  }, [
    dashboardUrl,
    hasWritePermission,
    isProjectEmpty,
    projectApiClient,
    projectId,
  ]);

  return banner;
}
