import { updateProject } from "@/components/common/project-provider/update-project";
import { ElementIconType } from "@/components/ui/icons";
import { setShowSpinner } from "@/store/ui/ui-slice";
import {
  ContextMenuAction,
  ContextMenuActionHandlerArgs,
  ContextMenuActionType,
} from "../action-types";

// ContextMenuAction to reset cloud alignments in capture tree
// Will be disabled when the cloud is already in its original published position
export const RESET_CLOUD_POSITION_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.resetCloudPosition,
  label: "Reset to original position",
  icon: ElementIconType.GeoReferenceIcon,
  handler: resetCloudPosition,
  disabledMessageForNode: (iElement) => {
    if (iElement.pose === null) {
      return "This cloud is already at its original position";
    }
  },

  tooltipMessage: () =>
    "Restores the point cloud to its original published position",
};

/**
 * Reset cloud's position
 */
async function resetCloudPosition({
  apiClients,
  dispatch,
  errorHandlers,
}: ContextMenuActionHandlerArgs): Promise<void> {
  dispatch(setShowSpinner(true));
  try {
    const response = await apiClients.projectApiClient.resetCloudAlignments();
    dispatch(
      updateProject({
        projectApi: apiClients.projectApiClient,
        iElementQuery: {
          ancestorIds: response.modifiedIElements.map((element) => element.id),
        },
      }),
    );
  } catch (error) {
    errorHandlers.handleErrorWithToast({
      title:
        "Failed to restore the point cloud to its original published position",
      error,
    });
  }

  dispatch(setShowSpinner(false));
}
