import { useUnitOfMeasureContext } from "@/components/common/unit-of-measure-context";
import {
  useWaypointAltitudeRange,
  useWaypointDateRange,
} from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import { selectWaypointsColoring } from "@/store/view-options/view-options-selectors";
import { WaypointsColoringOptions } from "@/store/view-options/view-options-slice";
import {
  WAYPOINTS_ALTITUDE_GRADIENT,
  WAYPOINTS_DATE_GRADIENT,
} from "@/utils/waypoints-color-gradient";
import { humanReadableDistance } from "@faro-lotv/app-component-toolbox";
import {
  BuildingElevationIcon,
  ColorBar,
  ColorString,
  convertToDateString,
  FaroTooltip,
  neutral,
  TimeDistanceIcon,
  TranslateVar,
} from "@faro-lotv/flat-ui";
import { Stack, SxProps } from "@mui/system";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useState } from "react";

type AppColorBarProps = {
  /** The style applied to the stack containing the color bar */
  sx?: SxProps;
};

/** @returns The vertical color bar used to colorize the waypoints on the sheet */
export function AppColorBar({ sx }: AppColorBarProps): JSX.Element | null {
  const waypointsAltitudeRange = useWaypointAltitudeRange();
  const waypointsDateRange = useWaypointDateRange();

  const coloring = useAppSelector(selectWaypointsColoring);

  const elevation =
    coloring === WaypointsColoringOptions.byElevation &&
    !!waypointsAltitudeRange;
  const captureDate =
    coloring === WaypointsColoringOptions.byCaptureDate && !!waypointsDateRange;

  const { unitOfMeasure } = useUnitOfMeasureContext();

  const valueToElevation = useCallback(
    (value: number | undefined): ReactNode => {
      if (!value || !waypointsAltitudeRange) return "";
      const elevation =
        waypointsAltitudeRange.lowest +
        value *
          (waypointsAltitudeRange.highest - waypointsAltitudeRange.lowest);
      return (
        <>
          Elevation:{" "}
          <TranslateVar name="elevation">
            {humanReadableDistance(elevation, unitOfMeasure)}
          </TranslateVar>
        </>
      );
    },
    [waypointsAltitudeRange, unitOfMeasure],
  );

  const valueToDate = useCallback(
    (value: number | undefined): ReactNode => {
      if (!value || !waypointsDateRange) return "";
      const date = DateTime.fromMillis(
        waypointsDateRange.lowest +
          value * (waypointsDateRange.highest - waypointsDateRange.lowest),
      ).toISODate();
      const dateString = date ? convertToDateString(date) : "";
      return (
        <>
          Capture date:{" "}
          <TranslateVar name="captureDate">{dateString}</TranslateVar>
        </>
      );
    },
    [waypointsDateRange],
  );

  const tooltipFnc = elevation ? valueToElevation : valueToDate;

  const [colorbarPoint, setColorbarPoint] = useState<
    ColorAndValue | undefined
  >();

  const onColorbarHovered = useCallback(
    (color: ColorString | undefined, normalizedValue: number) => {
      if (color) {
        setColorbarPoint({ color, value: normalizedValue });
      }
    },
    [],
  );

  if (
    coloring === WaypointsColoringOptions.default ||
    (!elevation && !captureDate)
  ) {
    return null;
  }

  return (
    <Stack gap={0.5} sx={sx}>
      {elevation && (
        <FaroTooltip title="Colorize by elevation" placement="left">
          <BuildingElevationIcon
            sx={{ width: "16px", height: "16px", color: neutral[100] }}
          />
        </FaroTooltip>
      )}
      {captureDate && (
        <FaroTooltip title="Colorize by capture date" placement="left">
          <TimeDistanceIcon
            sx={{ width: "16px", height: "16px", color: neutral[100] }}
          />
        </FaroTooltip>
      )}
      <FaroTooltip
        // The line below is needed to keep the colorbar using the full height
        sx={{ height: "100%" }}
        icon={
          <Stack
            sx={{
              p: 1,
              borderRadius: 2,
              outline: `1px solid ${neutral[0]}33`,
              backgroundColor: colorbarPoint?.color,
            }}
          />
        }
        title={tooltipFnc(colorbarPoint?.value)}
        placement="left"
      >
        <ColorBar
          direction="vertical"
          colors={
            elevation ? WAYPOINTS_ALTITUDE_GRADIENT : WAYPOINTS_DATE_GRADIENT
          }
          sx={{ height: "100%" }}
          onPointerMove={onColorbarHovered}
          onPointerEnter={onColorbarHovered}
        />
      </FaroTooltip>
    </Stack>
  );
}

type ColorAndValue = {
  color: ColorString;
  value: number;
};
