import { useSceneEvents } from "@/components/common/scene-events-context";
import {
  CameraAnimation,
  CameraAnimationProps,
} from "@/components/r3f/animations/camera-animation";
import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { PanoramaRenderer } from "@/components/r3f/renderers/panorama-renderer";
import { PinMarkerRenderer } from "@/components/r3f/renderers/pin-marker/pin-marker-renderer";
import { useSceneContextMenuEventHandlers } from "@/hooks/use-scene-context-menu-event-handlers";
import { PanoObject } from "@/object-cache";
import { Measurement } from "@/store/measurement-tool-slice";
import { selectPanoAnnotationSection } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectFilteredElementsWithTags } from "@/store/tags/tags-selectors";
import { PickingToolsRef } from "@/tools/picking-tools";
import { usePanoAnnotationsAdjustedPose } from "@/utils/camera-transform";
import {
  Img360PanoRef,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementDepthMap,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  isIElementGenericAnnotation,
  isIElementMarkup,
} from "@faro-lotv/ielement-types";
import {
  EquirectangularDepthImage,
  LodPano,
  assert,
  decodeCdiBuffer,
} from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { useEffect, useMemo, useRef, useState } from "react";
import { Matrix4, Plane, Quaternion, Vector3 } from "three";
import { WalkPlaceholders } from "./walk-placeholders";
import {
  selectDepthMapForImg360,
  selectIsPanoWithDepth,
} from "./walk-scene-selectors";
import { WalkSceneActiveElement } from "./walk-types";

export type WalkPanoramaProps = PickingToolsRef & {
  /* The panorama object to render */
  pano: PanoObject;

  /** List of all the placeholders to show */
  placeholders: IElementImg360[];

  /** If true other panorama's placeholders will be rendered  */
  shouldShowPlaceholders: boolean;

  /** If true, the panorama's annotations will be rendered  */
  shouldShowAnnotations: boolean;

  /** List of all the annotations for this area */
  annotations: IElementGenericAnnotation[];

  /** True if the active tool is using picking */
  isPickingToolActive: boolean;

  /** List of all the measurements in the store for the current selection */
  measurements: Measurement[];

  /** The floor image where the placeholders are placed */
  sheetForElevation?: IElementGenericImgSheet;

  /* True to show, will animate opacity */
  visible: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Define to true to register this as a secondary view for split screen rendering */
  isSecondaryView?: boolean;

  /** Callback executed when the active element should be changed */
  onTargetElementChanged(element: WalkSceneActiveElement): void;

  /** Callback called when the active waypoint target element inside the walk scene has changed. */
  onWayPointChanged(target: WayPointTarget): void;
};

async function loadDepths(
  pano: LodPano,
  depthMap: IElementDepthMap,
  signal: AbortSignal,
): Promise<void> {
  const ret = await fetch(depthMap.uri, { signal });
  if (!ret.ok) return;
  const b = await ret.blob();
  const data = await b.arrayBuffer();
  const depthData = depthMap.name.endsWith("cdi.br")
    ? decodeCdiBuffer(new DataView(data))
    : new Float32Array(data);
  const equirectImage = new EquirectangularDepthImage(
    depthMap.pixelWidth,
    depthData,
  );
  // Increase the offset to make the normal more stable while moving the mouse over the pano.
  // By experiments with multiple datasets, 9px is chosen as a good value results stable normal.
  equirectImage.normalComputeTrianglePointsOffset = 9;
  if (!signal.aborted) {
    pano.tiledPano.setDepths(equirectImage);
  }
}

type PanoWidthDepthProps = PickingToolsRef &
  Pick<WalkPanoramaProps, "pano" | "sheetForElevation" | "isSecondaryView">;

/** @returns a component that renders a LOD panorama image and loads and manages its depth information if present.  */
function WalkPanoWithDepth({
  pano,
  sheetForElevation,
  isSecondaryView,
  onModelClicked,
  onModelHovered,
  onModelZoomed,
}: PanoWidthDepthProps): JSX.Element {
  const ref = useRef<Img360PanoRef>();
  const depthMap = useAppSelector(selectDepthMapForImg360(pano.iElement));

  useEffect(() => {
    if (!ref.current) return;
    if (!depthMap) return;

    const controller = new AbortController();
    const connection =
      ref.current === pano
        ? undefined
        : ref.current.depthsChanged.pipe(pano.depthsChanged);
    if (ref.current.depths === undefined) {
      loadDepths(ref.current, depthMap, controller.signal).catch((error) => {
        console.warn(error);
      });
    }
    return () => {
      controller.abort();
      connection?.dispose();
    };
  }, [ref, depthMap, pano]);

  return (
    <PanoramaRenderer
      pano={pano}
      sheetForElevation={sheetForElevation}
      ref={ref}
      isSecondaryView={isSecondaryView}
      onClick={(ev) => onModelClicked(ev, pano.iElement.id)}
      onPointerMove={(ev) => onModelHovered(ev, pano.iElement.id)}
      onWheel={(ev) => onModelZoomed(ev, pano.iElement.id)}
    />
  );
}

/**
 * @returns the rendering logic for panorama images inside a WalkScene
 */
export function WalkPanorama({
  pano,
  placeholders,
  measurements,
  shouldShowPlaceholders,
  shouldShowAnnotations,
  annotations,
  isPickingToolActive,
  sheetForElevation,
  visible,
  isSecondaryView,
  clippingPlanes,
  onTargetElementChanged,
  onWayPointChanged,
  ...toolEvents
}: WalkPanoramaProps): JSX.Element | null {
  // Compute annotations section and adjusted pose
  const panoAdjustedPose = usePanoAnnotationsAdjustedPose(pano.iElement);
  const panoSection = useAppSelector(
    selectPanoAnnotationSection(pano.iElement),
  );

  useSceneContextMenuEventHandlers({ panoObject: pano });

  const isPanoWithDepth = useAppSelector(selectIsPanoWithDepth(pano.iElement));
  assert(
    !isPanoWithDepth || pano.iElement.isRotationAccurate,
    "isRotationAccurate must be true if pano has depth info",
  );

  const camera = useThree((s) => s.camera);
  // Holobuilder markups/annotations could be closer than expected
  // and we want to avoid to clip them
  useEffect(() => {
    const { near } = camera;
    camera.near = 0.01;
    camera.updateProjectionMatrix();
    return () => {
      camera.near = near;
      camera.updateProjectionMatrix();
    };
  }, [camera]);

  // Collect annotations of the current panorama
  const generalAnnotations = useAppSelector(
    selectChildrenDepthFirst(panoSection, isIElementGenericAnnotation),
    isEqual,
  );
  const filteredGeneralAnnotations = useAppSelector(
    selectFilteredElementsWithTags(generalAnnotations, (el) =>
      selectChildDepthFirst(el, isIElementMarkup),
    ),
    isEqual,
  );

  // Remove general annotations from area annotations, so that there wont be duplicate rendering of same annotations
  const areaAnnotations = useMemo(
    () =>
      annotations.filter(
        (annotation) =>
          !filteredGeneralAnnotations.some((a) => a.id === annotation.id),
      ),
    [annotations, filteredGeneralAnnotations],
  );

  const [cameraAnimation, setCameraAnimation] =
    useState<CameraAnimationProps>();

  const { lookAt, setCameraView } = useSceneEvents();
  useTypedEvent(lookAt, (position: Vector3) => {
    const quaternion = new Quaternion().setFromRotationMatrix(
      new Matrix4().lookAt(camera.position, position, camera.up),
    );
    setCameraAnimation({
      quaternion,
    });
  });
  useTypedEvent(setCameraView, (cameraView) => {
    setCameraAnimation({
      quaternion: cameraView.rotation,
      fov: cameraView.zoomLevel,
    });
  });

  if (!visible) {
    return null;
  }

  return (
    <>
      {cameraAnimation && (
        <CameraAnimation
          {...cameraAnimation}
          onAnimationFinished={() => setCameraAnimation(undefined)}
        />
      )}
      <WalkPanoWithDepth
        pano={pano}
        sheetForElevation={sheetForElevation}
        isSecondaryView={isSecondaryView}
        {...toolEvents}
      />

      {shouldShowPlaceholders && (
        <WalkPlaceholders
          placeholders={placeholders}
          sheetForElevation={sheetForElevation}
          clippingPlanes={clippingPlanes}
          onPlaceholderClick={(targetElement, position) => {
            onTargetElementChanged(targetElement);

            onWayPointChanged({
              targetElement,
              position,
              quaternion: camera.quaternion,
            });
          }}
          shouldFadeOff
        />
      )}

      {shouldShowAnnotations && (
        <>
          {/* The annotations which are local to the panorama and they should not be collapsed */}
          <AnnotationsRenderer
            annotations={filteredGeneralAnnotations}
            worldTransform={panoAdjustedPose}
            clippingPlanes={clippingPlanes}
            preventCollapse
            onTargetElementChanged={onTargetElementChanged}
          />
          {pano.iElement.isRotationAccurate && (
            // Annotation that are created in the nearby area of the panorama, which needs to be collapsed if they are far away
            // Visible only if the pano has a correct rotation
            <AnnotationsRenderer
              annotations={areaAnnotations}
              worldTransform={panoAdjustedPose}
              clippingPlanes={clippingPlanes}
              onTargetElementChanged={onTargetElementChanged}
            />
          )}
        </>
      )}

      {pano.iElement.isRotationAccurate && (
        <>
          <MeasurementsRenderer
            measurements={measurements}
            isPickingToolActive={isPickingToolActive}
            clippingPlanes={clippingPlanes}
          />

          <PinMarkerRenderer />
        </>
      )}
    </>
  );
}
