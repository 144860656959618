import { TREE_NODE_HEIGHT } from "@/components/ui/tree/tree-node";
import { TreeWrapper } from "@/components/ui/tree/tree-wrapper";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
} from "@faro-lotv/service-wires";
import { isEqual } from "es-toolkit";
import { useEffect, useMemo, useRef } from "react";
import { Tree, TreeApi } from "react-arborist";
import { selectSelectedEntityIds } from "../../store/data-preparation-ui/data-preparation-ui-selectors";
import { setSelectedEntityIds } from "../../store/data-preparation-ui/data-preparation-ui-slice";
import { handleAddConnection } from "../handle-add-connection";
import { ScanTreeNode } from "./scan-tree-node";

type ScanTreeProps = {
  /** The entities to show in the tree. */
  entities: CaptureTreeEntityRevision[];
};

/** @returns A tree of scans in clusters. */
export function ScanTree({ entities }: ScanTreeProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { getState } = useAppStore();

  const selectedEntityIds = useAppSelector(selectSelectedEntityIds);

  // Map for a quick children lookup by id
  const entityChildrenMap = useEntityChildrenMap(entities);

  // The children of the root entity
  const rootEntities = useMemo(() => {
    const rootEntityId = entities.find(
      (e) => e.type === CaptureTreeEntityType.root,
    )?.id;

    if (!rootEntityId) return [];

    return entityChildrenMap.get(rootEntityId);
  }, [entities, entityChildrenMap]);

  const treeRef = useRef<TreeApi<CaptureTreeEntityRevision>>();

  // Sync the selection state with the tree
  useEffect(() => {
    const tree = treeRef.current;
    if (!tree) return;

    // Prevent infinite loop due to state sync
    if (isEqual(new Set(selectedEntityIds), tree.selectedIds)) return;

    tree.setSelection({
      ids: selectedEntityIds,
      anchor: selectedEntityIds.at(0) ?? null,
      mostRecent: selectedEntityIds.at(-1) ?? null,
    });
    tree.focus(selectedEntityIds.at(-1) ?? null);
  }, [selectedEntityIds]);

  return (
    <TreeWrapper>
      <Tree
        ref={treeRef}
        data={rootEntities}
        width="100%"
        disableDrag
        disableDrop
        rowHeight={TREE_NODE_HEIGHT}
        indent={24}
        onSelect={(nodes) => {
          const selectedIds = nodes.map((node) => node.data.id);
          const lastScanId = selectedIds.at(-1);

          if (
            handleAddConnection(
              dispatch,
              getState(),
              selectedEntityIds,
              lastScanId,
            )
          ) {
            return;
          }

          dispatch(setSelectedEntityIds(selectedIds));
        }}
        childrenAccessor={(node) => entityChildrenMap.get(node.id) ?? null}
      >
        {ScanTreeNode}
      </Tree>
    </TreeWrapper>
  );
}

/**
 * @returns a map for quick children lookup of entities
 * @param entities the entities to create the map for
 */
export function useEntityChildrenMap(
  entities: CaptureTreeEntityRevision[],
): Map<GUID, CaptureTreeEntityRevision[]> {
  return useMemo(() => {
    const map = new Map<GUID, CaptureTreeEntityRevision[]>();

    for (const entity of entities) {
      if (!entity.parentId) continue;

      let childrenOfParent = map.get(entity.parentId);

      if (!childrenOfParent) {
        childrenOfParent = [];
        map.set(entity.parentId, childrenOfParent);
      }

      childrenOfParent.push(entity);
    }

    return map;
  }, [entities]);
}
