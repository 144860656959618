import {
  FaroTooltip,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { MouseEvent, useCallback } from "react";

export enum Projection {
  /** 2 dimensional Projection*/
  twoDimensional = "2D",

  /** 3 dimensional projection */
  threeDimensional = "3D",
}

type ProjectionSwitchProps = {
  /** The currently active projection. */
  projection: Projection;

  /** A callback to execute when the user changes the projection. */
  onProjectionChange(projection: Projection): void;
};

/** Size to match an icon button */
const BUTTON_SIZE = 46;

/** @returns A toolbar to change the projection method. */
export function ProjectionSwitch({
  projection,
  onProjectionChange,
}: ProjectionSwitchProps): JSX.Element {
  const changeProjection = useCallback(
    (_event: MouseEvent, value: Projection | null) => {
      if (value) {
        onProjectionChange(value);
      }
    },
    [onProjectionChange],
  );

  return (
    <Toolbar>
      <ToolGroup
        value={projection}
        orientation="vertical"
        exclusive
        onChange={changeProjection}
      >
        <FaroTooltip title="2D projection" placement="right">
          <ToolButton
            aria-label="point clouds in 2d projection"
            value={Projection.twoDimensional}
            selected={projection === Projection.twoDimensional}
            sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }}
          >
            2D
          </ToolButton>
        </FaroTooltip>
        <FaroTooltip title="3D projection" placement="right">
          <ToolButton
            aria-label="point clouds in 3d projection"
            value={Projection.threeDimensional}
            selected={projection === Projection.threeDimensional}
            sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }}
          >
            3D
          </ToolButton>
        </FaroTooltip>
      </ToolGroup>
    </Toolbar>
  );
}
