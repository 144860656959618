import { FaroSlider, FaroSliderProps, FaroText } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { HelpPopover, HelpPopoverProps } from "../help-popover";

type ViewOptionsSliderProps = { label: ReactNode } & HelpPopoverProps &
  FaroSliderProps &
  PropsWithChildren;

/** @returns A slider option for the view option menu */
export function ViewOptionsSlider({
  label,
  title,
  description,
  onClick,
  marks,
  step,
  min,
  max,
  valueLabelDisplay,
  valueLabelFormat,
  value,
  onChange,
  children,
}: ViewOptionsSliderProps): JSX.Element {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ px: "2px" }}>
        <FaroText variant="heading14" dark>
          {label}
        </FaroText>

        <HelpPopover
          title={title}
          description={description}
          onClick={onClick}
        />
      </Stack>
      <FaroSlider
        dark
        marks={marks}
        step={step}
        min={min}
        max={max}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        onChange={onChange}
        value={value}
      />
      {children}
    </Stack>
  );
}
