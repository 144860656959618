import { setWorkspaceIntegrations } from "@/store/integrations/integrations-slice";
import { setProjectUsers } from "@/store/project-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { initSubscriptions } from "@/store/subscriptions/subscriptions-slice";
import { setCompanyRole } from "@/store/user-slice";
import { GUID } from "@faro-lotv/foundation";
import {
  setCompanyId,
  setDashboardUrl,
  setProjectAccessLevel,
  setProjectName,
} from "@faro-lotv/project-source";
import {
  parseGenericUserInfoFromUserInfo,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

/**
 * Updates the project metadata from the CoreAPI:
 * * feature-slice with the user's permissions specific to the loaded project
 * * company id for this project
 *
 * @param projectId the currently loaded project
 * @returns true when the loading finished and all the metadata is available
 */
export function useUpdateProjectMetadata(projectId: GUID): boolean {
  const dispatch = useAppDispatch();

  const { coreApiClient } = useApiClientContext();

  const [isReady, setIsReady] = useState(false);

  // Update feature slice with the user's permissions for the loaded project
  useEffect(() => {
    async function updateMetaData(): Promise<void> {
      if (projectId) {
        const contextPromise = coreApiClient.getProjectContext(projectId);
        const [context, members, project, workspaces, users, usersTokens] =
          await Promise.all([
            contextPromise,
            contextPromise.then((context) =>
              coreApiClient
                .getProjectMembers(context.company.id, projectId)
                // The response is not a valid ProjectMembersPayload
                // Since the endpoint is mostly used for fetching the profile images of the members,
                // we can safely ignore the error and return undefined
                .catch(() => undefined),
            ),
            coreApiClient.getProjectInfo(projectId),
            // getUserWorkspace, getProjectUsers and getUsersTokens
            // fails for not logged in user
            // just return nothing or undefined if they fail
            coreApiClient
              .getUserWorkspaces()
              .catch(() => ({ data: { workspaces: [] } })),
            coreApiClient
              .getProjectUsers(projectId)
              .catch(() => ({ data: { users: [] } })),
            coreApiClient.getUsersTokens().catch(() => undefined),
          ]);

        const workspace = workspaces.data.workspaces.find((workspace) =>
          workspace.url.includes(context.company.id),
        );

        dispatch(initSubscriptions(context.roles));
        dispatch(setWorkspaceIntegrations(usersTokens));
        dispatch(setCompanyId(context.company.id));
        dispatch(setCompanyRole(context.company.role));
        dispatch(setProjectName(project.name));
        dispatch(setProjectAccessLevel(project.accessLevel));
        dispatch(setDashboardUrl(workspace?.url));
        dispatch(
          setProjectUsers(
            users.data.users
              .map(parseGenericUserInfoFromUserInfo)
              // Add the profileImageUrl to the users if it's missing using the members data
              .map((user) => {
                if (!user.profileImageUrl) {
                  const member = members?.data.projectMembers.find(
                    (member) => member.identity === user.identity,
                  );
                  user.profileImageUrl = member?.thumbnailUrl;
                }
                return user;
              }),
          ),
        );

        setIsReady(true);
      }
    }

    updateMetaData();
  }, [coreApiClient, dispatch, projectId]);

  return isReady;
}
