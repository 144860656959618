import { RootState } from "@/store/store";
import { UNTAGGED } from "@/store/tags/tags-slice";
import {
  GUID,
  LARGEST_MILLISECONDS,
  SMALLEST_MILLISECONDS,
  compareDateTimes,
} from "@faro-lotv/foundation";
import {
  AnnotationStatus,
  IElementMarkup,
  isIElementMarkup,
  isIElementMarkupAssigneeId,
  isIElementMarkupDueTime,
  isIElementMarkupState,
} from "@faro-lotv/ielement-types";
import { IElementsRecord } from "@faro-lotv/project-source";
import { GenericUserInfo, isAnnotationStatus } from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { DateTime, Interval } from "luxon";
import { UNASSIGNED_ID } from "./annotation-filters/assignee-filter";
import { Dates } from "./annotation-filters/date-filter";
import { AnnotationSortingOptions } from "./sort-menu-button";

/** The options used to filter and sort the list of markups */
export type AnnotationFilteringOptions = {
  /** The list of assignees selected by the user */
  assignee: GUID[];
  /** The list of tags selected by the user */
  tags: GUID[];
  /** The list of statuses selected by the user */
  status: AnnotationStatus[];
  /** The date range selected by the user */
  date: Dates | undefined;
  /** The sorting algorithm selected by the user */
  sorting: AnnotationSortingOptions | undefined;
  /** The text typed by the user in the search bar */
  searchText: string;
};

/**
 * @returns true if the input date is in the provided range, ignoring the time
 * @param date The input date to check
 * @param startDate The date representing the lower limit of the interval, if provided
 * @param endDate The date representing the upper limit of the interval, if provided
 */
function isDateInInterval(
  date: DateTime,
  startDate: DateTime | undefined = DateTime.fromMillis(SMALLEST_MILLISECONDS),
  endDate: DateTime | undefined = DateTime.fromMillis(LARGEST_MILLISECONDS),
): boolean {
  /** Luxon interval is half-open [) https://moment.github.io/luxon/api-docs/index.html#interval */
  return Interval.fromDateTimes(startDate, endDate).contains(date);
}

/** Markup with all the data necessary to filter and sort it */
type MarkupMetadata = {
  /** The markup iElement */
  markup: IElementMarkup;
  /** The name of the assignee, if provided */
  assigneeName: string | undefined;
  /** The state of the annotation, if provided */
  status: string | null | undefined;
  /** The due date of the annotation, if provided */
  dueDate: string | undefined;
};

/**
 * @returns A list of filtered and sorted markup elements, based on the values defined in the options.
 *  The elements not filtered out are the ones that respect all the options.
 * @param state The state of the app
 * @param options The options used to filter and sort the complete list
 */
export const selectFilteredAndSortedMarkups: (
  state: RootState,
  options: AnnotationFilteringOptions,
) => GUID[] = createSelector(
  [
    (state: RootState) => state.iElements.iElements,
    (state: RootState) => state.project.users,
    (state: RootState, options: AnnotationFilteringOptions) => options,
  ],
  (
    iElements: IElementsRecord,
    users: GenericUserInfo[],
    options: AnnotationFilteringOptions,
  ): GUID[] => {
    const markups: MarkupMetadata[] = [];
    for (const element of Object.values(iElements)) {
      if (!element || !isIElementMarkup(element)) continue;

      if (!matchesTextSearch(options.searchText, element.name)) continue;

      // Check if the markup contains one of the tags in the filter
      if (!matchTagFilter(options.tags, element)) {
        continue;
      }

      // Filter out a markup that does not have any child if a filter is selected
      if (!element.childrenIds) {
        if (noFilterSelected(options)) {
          markups.push({
            markup: element,
            assigneeName: undefined,
            status: undefined,
            dueDate: undefined,
          });
        }
        continue;
      }

      const { assigneeId, status, dueDate } = getMarkupFilterFields(
        element,
        iElements,
      );
      // Filter by assignee
      if (!matchAssigneeFilter(options.assignee, assigneeId)) {
        continue;
      }
      // Filter by status
      if (!matchStatusFilter(options.status, status)) {
        continue;
      }
      // Filter by date
      if (!matchDateFilter(options.date, dueDate)) {
        continue;
      }

      // Collect all the markups that were not filtered out
      markups.push({
        markup: element,
        assigneeName: users.find((user) => user.id === assigneeId)?.name,
        status,
        dueDate,
      });
    }

    // Sort the markups
    if (options.sorting) {
      markups.sort(SORTING_ALGORITHMS[options.sorting]);
    }

    return markups.map((m) => m.markup.id);
  },
);

/**
 * @returns true if the input string contains the search text
 * @param searchText The text used to filter the names
 * @param name The name of the annotation
 */
function matchesTextSearch(searchText: string, name: string): boolean {
  return (
    searchText.length === 0 ||
    name.toLowerCase().includes(searchText.toLowerCase())
  );
}

/**
 * @returns True if the user did not filter for any specific markup property
 * @param options The list of filtering options
 */
function noFilterSelected(options: AnnotationFilteringOptions): boolean {
  return (
    options.assignee.length === 0 &&
    (options.status.length === 0 ||
      options.status.includes(AnnotationStatus.Unclassified)) &&
    (!options.date || options.date === Dates.NoDate)
  );
}

type MarkupFilterFields = {
  /** The id of the assignee */
  assigneeId: string | undefined;
  /** The status of the markup */
  status: string | null | undefined;
  /** The dued date of the markup */
  dueDate: string | undefined;
};

/**
 * @returns The fields used to filter the markup
 * @param element The markup for which we want to extract the fields
 * @param iElements The list of iElements in the projects
 */
function getMarkupFilterFields(
  element: IElementMarkup,
  iElements: IElementsRecord,
): MarkupFilterFields {
  let assigneeId: string | undefined;
  let status: string | null | undefined;
  let dueDate: string | undefined;
  for (const childId of element.childrenIds ?? []) {
    const child = iElements[childId];
    if (!child) continue;

    // Get the assignee
    if (isIElementMarkupAssigneeId(child)) {
      assigneeId = child.values?.[0];
    }
    // Get the status
    else if (isIElementMarkupState(child)) {
      status = child.value;
    }
    // Get the due date
    else if (isIElementMarkupDueTime(child)) {
      dueDate = child.value;
    }
  }

  return {
    assigneeId,
    status,
    dueDate,
  };
}

/**
 * @returns True if the element tags matches the tags list
 * @param tags The list of tags used to filter
 * @param element The element to check
 */
function matchTagFilter(tags: GUID[], element: IElementMarkup): boolean {
  // Check if the markup contains one of the tags in the filter
  if (tags.length > 0) {
    const hasTags = element.labels && element.labels.length > 0;
    if (!tags.includes(UNTAGGED.id) && !hasTags) {
      return false;
    } else if (
      hasTags &&
      !element.labels?.find((label) => tags.includes(label.id))
    ) {
      return false;
    }
  }
  return true;
}

/**
 * @returns True if the element assignee matches the assignees list
 * @param assignee The list of assignees used to filter
 * @param assigneeId The id of the assignee of the markup
 */
function matchAssigneeFilter(
  assignee: GUID[],
  assigneeId: GUID | undefined,
): boolean {
  if (assignee.length > 0) {
    if (assigneeId && !assignee.includes(assigneeId)) {
      return false;
    } else if (!assigneeId && !assignee.includes(UNASSIGNED_ID)) {
      return false;
    }
  }
  return true;
}

/**
 * @returns True if the element status matches the statuses list
 * @param statuses The list of statuses used to filter
 * @param status The status of the markup
 */
function matchStatusFilter(
  statuses: AnnotationStatus[],
  status: string | null | undefined,
): boolean {
  if (statuses.length > 0) {
    if (status && isAnnotationStatus(status) && !statuses.includes(status)) {
      return false;
    } else if (!status && !statuses.includes(AnnotationStatus.Unclassified)) {
      return false;
    }
  }
  return true;
}

/**
 * @returns True if the element due date matches the dates filter list
 * @param dateFilter The type of date filter
 * @param dueDateString The string representing the due date of the markup
 */
function matchDateFilter(
  dateFilter: Dates | undefined,
  dueDateString: string | null | undefined,
): boolean {
  if (dateFilter) {
    if (dueDateString) {
      // we only care about the "day" as seen in user's local time zone, not necessary in reporter's time zone
      const dueDate = DateTime.fromISO(dueDateString);
      switch (dateFilter) {
        case Dates.BeforeToday: {
          if (
            !isDateInInterval(dueDate, undefined, DateTime.now().startOf("day"))
          ) {
            return false;
          }
          break;
        }
        case Dates.Today: {
          if (
            !isDateInInterval(
              dueDate,
              DateTime.now().startOf("day"),
              DateTime.now().endOf("day"),
            )
          ) {
            return false;
          }
          break;
        }
        case Dates.Tomorrow: {
          if (
            !isDateInInterval(
              dueDate,
              DateTime.now().plus({ day: 1 }).startOf("day"),
              DateTime.now().plus({ day: 1 }).endOf("day"),
            )
          ) {
            return false;
          }
          break;
        }
        case Dates.Next7Days: {
          const inSevenDays = DateTime.now().plus({ day: 7 }).endOf("day");
          if (
            !isDateInInterval(
              dueDate,
              DateTime.now().startOf("day"),
              inSevenDays,
            )
          ) {
            return false;
          }
          break;
        }
        case Dates.Next30Days: {
          const inThirtyDays = DateTime.now().plus({ day: 30 }).endOf("day");
          if (
            !isDateInInterval(
              dueDate,
              DateTime.now().startOf("day"),
              inThirtyDays,
            )
          ) {
            return false;
          }
          break;
        }
        case Dates.Next90Days: {
          const inNinetyDays = DateTime.now().plus({ day: 90 }).endOf("day");
          if (
            !isDateInInterval(
              dueDate,
              DateTime.now().startOf("day"),
              inNinetyDays,
            )
          ) {
            return false;
          }
          break;
        }
        case Dates.NoDate: {
          return false;
        }
      }
    } else if (dateFilter !== Dates.NoDate) {
      return false;
    }
  }
  return true;
}

const SORTING_ALGORITHMS: Record<
  AnnotationSortingOptions,
  (a: MarkupMetadata, b: MarkupMetadata) => number
> = {
  [AnnotationSortingOptions.creationDateDown]: (a, b) =>
    compareDateTimes(
      new Date(a.markup.createdAt),
      new Date(b.markup.createdAt),
    ),
  [AnnotationSortingOptions.creationDateUp]: (a, b) =>
    compareDateTimes(
      new Date(b.markup.createdAt),
      new Date(a.markup.createdAt),
    ),
  [AnnotationSortingOptions.dueDateDown]: (a, b) => {
    const aDate = a.dueDate
      ? new Date(a.dueDate).getTime()
      : Number.POSITIVE_INFINITY;
    const bDate = b.dueDate
      ? new Date(b.dueDate).getTime()
      : Number.POSITIVE_INFINITY;
    return aDate - bDate;
  },
  [AnnotationSortingOptions.dueDateUp]: (a, b) => {
    const aDate = a.dueDate
      ? new Date(a.dueDate).getTime()
      : Number.POSITIVE_INFINITY;
    const bDate = b.dueDate
      ? new Date(b.dueDate).getTime()
      : Number.POSITIVE_INFINITY;
    return bDate - aDate;
  },
  [AnnotationSortingOptions.titleDown]: (a, b) =>
    a.markup.name.localeCompare(b.markup.name),
  [AnnotationSortingOptions.titleUp]: (a, b) =>
    b.markup.name.localeCompare(a.markup.name),
  [AnnotationSortingOptions.assignee]: (a, b) => {
    const aAssignee = a.assigneeName ?? "";
    const bAssignee = b.assigneeName ?? "";
    return aAssignee.localeCompare(bAssignee);
  },
  [AnnotationSortingOptions.status]: (a, b) => {
    const aStatus =
      a.status && isAnnotationStatus(a.status)
        ? a.status
        : AnnotationStatus.Unclassified;
    const bStatus =
      b.status && isAnnotationStatus(b.status)
        ? b.status
        : AnnotationStatus.Unclassified;
    return StatusOptionsPriority[aStatus] - StatusOptionsPriority[bStatus];
  },
};

const StatusOptionsPriority: Record<AnnotationStatus, number> = {
  [AnnotationStatus.Unclassified]: 0,
  [AnnotationStatus.Open]: 1,
  [AnnotationStatus.InProgress]: 2,
  [AnnotationStatus.ToReview]: 3,
  [AnnotationStatus.Resolved]: 4,
};
