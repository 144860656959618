import { EventType } from "@/analytics/analytics-events";
import { ElementIconType } from "@/components/ui/icons";
import { RootState } from "@/store/store";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElement,
  IElementGenericPointCloud,
  IElementPointCloudE57,
  IElementPointCloudLaz,
  isIElementBimImportGroup,
  isIElementBimModelSection,
  isIElementGenericPointCloud,
  isIElementModel3d,
  isIElementPointCloudE57Flash,
  isIElementPointCloudLaz,
} from "@faro-lotv/ielement-types";
import {
  ContextMenuAction,
  ContextMenuActionType,
  NestedMenuHandlerFnArgs,
} from "../action-types";
import { selectDownloadablePointCloud } from "../utils";

const nestedMenuProps = {
  title: "select data to download",
  options: [
    {
      label: "Download SLAM and Flash Scans",
      tagline: "E57 file - Structured",
      onClick: ({ menuItemId, state }: NestedMenuHandlerFnArgs) =>
        handlePointCloudDownload<IElementPointCloudE57>(
          menuItemId,
          state,
          isIElementPointCloudE57Flash,
        ),
    },
    {
      label: "Download SLAM point cloud",
      tagline: "LAZ file - Unstructured",
      onClick: ({ menuItemId, state }: NestedMenuHandlerFnArgs) =>
        handlePointCloudDownload<IElementPointCloudLaz>(
          menuItemId,
          state,
          isIElementPointCloudLaz,
        ),
    },
  ],
  shouldShowNestedMenu(elementID: GUID, state: RootState) {
    const iElement = selectIElement(elementID)(state);

    // Nested menu should only be shown when the data session contains an e57 flash point cloud.
    return !!selectChildDepthFirst(
      iElement,
      isIElementPointCloudE57Flash,
    )(state);
  },
};

export const DOWNLOAD_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.download,
  label: "Download point cloud",
  icon: ElementIconType.DownloadIcon,
  nestedMenuProps,
  handler: ({ elementID, state }) =>
    Promise.resolve(
      handlePointCloudDownload<IElementGenericPointCloud>(
        elementID,
        state,
        isIElementGenericPointCloud,
      ),
    ),
};

/**
 * This function handle downloads of original CAD file uploaded by user to the project
 *
 * @param cadModelStreamId ID of the 3D Model from whom CAD should be downloaded.
 * @param state State of the application.
 */
export function handleCadDownload(
  cadModelStreamId: string,
  state: RootState,
): void {
  const cadModelElement = selectIElement(cadModelStreamId)(state);

  const bimModelSection = selectAncestor(
    cadModelElement,
    isIElementBimModelSection,
  )(state);

  if (bimModelSection) {
    const bimModelGroup = selectChildDepthFirst(
      bimModelSection,
      isIElementBimImportGroup,
    )(state);

    if (bimModelGroup) {
      const bimModel = selectChildDepthFirst(
        bimModelGroup,
        isIElementModel3d,
      )(state);

      if (bimModel && isIElementModel3d(bimModel)) {
        downloadFile(bimModel.uri, bimModel.fileName);
      }
    }
  }
}

/**
 * @param elementID ID of the element from whom the point cloud should be downloaded.
 * @param state State of the application.
 * @param predicate Selection typeguard for the point cloud
 */
function handlePointCloudDownload<Type extends IElementGenericPointCloud>(
  elementID: GUID,
  state: RootState,
  predicate: (el: IElement) => el is Type,
): void {
  Analytics.track(EventType.downloadPointCloud);
  // Get the pointcloud section element.
  const iElement = selectIElement(elementID)(state);

  // Get the child element of the section, that is a generic pointcloud and contains the url and the file name.
  const pointCloud = selectDownloadablePointCloud(iElement, predicate)(state);

  if (!pointCloud) return;

  // Download the file and assign the file name.
  downloadFile(pointCloud.uri, pointCloud.fileName);
}

/**
 * This function downloads a file given a url and sets the name of the downloaded file.
 *
 * @param url Link to the file to download
 * @param filename Name to set the downloaded file to.
 */
function downloadFile(url: string, filename?: string | null): void {
  const anchor = document.createElement("a");

  // Setting the filename in this way will only work for same-origin files.
  if (filename) {
    anchor.download = filename;
  }

  // Set the anchor's link to the file url.
  anchor.href = url;

  // Click the anchor/link element to start the download of the file.
  anchor.click();
}
