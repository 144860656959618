import { EventType } from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { addNewTags } from "@/store/tags/tags-slice";
import {
  ActionTextField,
  FaroButton,
  FaroDialog,
  FaroDialogProps,
  FaroText,
  NO_TRANSLATE_CLASS,
  TextField,
  neutral,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { generateGUID } from "@faro-lotv/foundation";
import {
  MutationAddAllowedLabel,
  createMutationAddAllowedLabel,
} from "@faro-lotv/service-wires";
import { Divider, List, ListItem, Skeleton, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useCurrentProjectApiClient } from "../../common/project-provider/project-loading-context";
import { selectSortedAllowedTags, validateNewTagName } from "./tag-utils";
import { useDeleteTag } from "./use-delete-tag";
import { useLongTask } from "./use-long-task";
import { useUpdateTag } from "./use-update-tag";

export type TagsManagementDialogProps = Pick<
  FaroDialogProps,
  "open" | "onClose"
>;

/** @returns a dialog to manage allowed tags in the current project */
export function TagsManagementDialog({
  open,
  onClose,
}: TagsManagementDialogProps): JSX.Element {
  const { handleErrorWithToast } = useErrorHandlers();

  const client = useCurrentProjectApiClient();
  const dispatch = useAppDispatch();

  const tags = useAppSelector(selectSortedAllowedTags);

  const [newTagName, setNewTagName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const { startLongTask, stopLongTask } = useLongTask(setIsProcessing);

  /** Adds a new tag to the project allowed tags */
  const addNewTag = useCallback(() => {
    Analytics.track(EventType.createNewTag);

    startLongTask(
      `The creation of tag "${newTagName}" is taking longer than expected. Please wait...`,
    );

    const newTag: MutationAddAllowedLabel["label"] = {
      id: generateGUID(),
      createdAt: new Date().toISOString(),
      name: newTagName,
      resourceId: client.projectId,
    };

    client
      .applyMutations([createMutationAddAllowedLabel(newTag)])
      .then(() => {
        dispatch(addNewTags([newTag]));
        setNewTagName("");
      })
      .catch((error) => {
        handleErrorWithToast({
          title: "Unable to create new tag",
          error,
        });
      })
      .finally(() => {
        stopLongTask();
      });
  }, [
    startLongTask,
    newTagName,
    client,
    dispatch,
    handleErrorWithToast,
    stopLongTask,
  ]);

  const updateTagName = useUpdateTag(setIsProcessing);
  const deleteTag = useDeleteTag(setIsProcessing);

  return (
    <FaroDialog
      open={open}
      onClose={onClose}
      title="Tag Management"
      disabled={isProcessing}
      showXButton
      dark
    >
      <Stack gap={2} direction="column" sx={{ overflow: "auto" }}>
        <FaroText variant="bodyM" color="inherit">
          Add tag to your project to better organize the data.
        </FaroText>
        <AddTagField
          newTagName={newTagName}
          setNewTagName={setNewTagName}
          isProcessing={isProcessing}
          addNewTag={addNewTag}
        />
        <Divider sx={{ borderColor: neutral[800] }} />
        <Stack sx={{ overflow: "auto" }}>
          <List
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            {isProcessing && (
              <ListItem
                key="processing"
                sx={{ height: "36px", minHeight: "36px", py: 0 }}
              >
                <Skeleton
                  sx={{ width: "100%", height: "100%", bgcolor: neutral[800] }}
                />
              </ListItem>
            )}
            {tags.map((tag) => (
              <ListItem
                key={tag.id}
                sx={{ py: 0 }}
                className={NO_TRANSLATE_CLASS}
              >
                <ActionTextField
                  inputText={tag.name}
                  dark
                  fullWidth
                  validate={(newName) =>
                    validateNewTagName(tag.id, newName, tags)
                  }
                  onConfirmButtonClick={(newName) => {
                    // After the user has confirmed they want to edit the tag, the event is tracked
                    Analytics.track(EventType.editTag);
                    updateTagName(tag, newName);
                  }}
                  onDeleteButtonClick={() => deleteTag(tag)}
                />
              </ListItem>
            ))}
          </List>
        </Stack>
      </Stack>
    </FaroDialog>
  );
}

type AddTagFieldProps = {
  /** True if the app is creating a new tag in the backend */
  isProcessing: boolean;

  /** Name for the new tag to create */
  newTagName: string;

  /** Callback to update the name of the new tag to create */
  setNewTagName(name: string): void;

  /** Callback to trigger the creation of a new tag */
  addNewTag(): void;
};

function AddTagField({
  newTagName,
  setNewTagName,
  isProcessing,
  addNewTag,
}: AddTagFieldProps): JSX.Element {
  return (
    <TextField
      label="New tag"
      dark
      fullWidth
      placeholder="Insert name"
      text={newTagName}
      onTextChanged={setNewTagName}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          addNewTag();
        }
      }}
      InputProps={{
        endAdornment: (
          <AddTagButton
            isEnabled={!isProcessing && newTagName.length > 0}
            onClick={addNewTag}
          />
        ),
      }}
      sx={{
        padding: 0,
        height: "2.5rem",
        // Use && to have more priority over other mui classes
        "&& >input": {
          py: 0,
          height: "inherit",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        // Make the button the same height of the TextField
        "& >button": {
          height: "inherit",
          // Border only on the right
          borderRadius: "0 4px 4px 0",
        },
      }}
    />
  );
}

type AddTagButtonProps = {
  /** True if this button should be enabled */
  isEnabled: boolean;

  /** Callback called when the user click the button */
  onClick(): void;
};

/** @returns a custom button with a spinner when the app is processing */
function AddTagButton({ isEnabled, onClick }: AddTagButtonProps): JSX.Element {
  return (
    <FaroButton
      variant="primary"
      size="m"
      dark
      disabled={!isEnabled}
      onClick={onClick}
    >
      Add
    </FaroButton>
  );
}
