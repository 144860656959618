import { CustomCamera } from "@/modes/mode";
import { selectSheetForElement } from "@/modes/mode-selectors";
import { selectActiveElement } from "@/store/selections-selectors";
import { store } from "@/store/store";
import { perspCameraDistance } from "@/utils/persp-camera-distance";
import { selectIElementWorldPosition } from "@faro-lotv/app-component-toolbox";
import { SupportedCamera } from "@faro-lotv/lotv";
import { Camera, OrthographicCamera } from "three";

/** A custom orthographic camera */
export const CustomOrthoCamera: CustomCamera = {
  camera() {
    const o = new OrthographicCamera();
    /**
     * If the manual property is not set or is false,
     * R3F automatically changes the camera when resizing the canvas
     */
    Object.assign(o, { manual: true });
    return o;
  },

  restoreGlobalCamera: (custom: Camera, global: SupportedCamera) => {
    if (
      !(custom instanceof OrthographicCamera) ||
      global instanceof OrthographicCamera
    ) {
      // If this happen it means the transition was interrupted before the
      // mode started, so the camera was still the global one
      return;
    }

    const state = store.getState();

    const activeElement = selectActiveElement(state);
    const activeSheet = selectSheetForElement(activeElement)(state);
    if (!activeSheet) {
      return;
    }
    const position = selectIElementWorldPosition(activeSheet.id)(
      store.getState(),
    );

    // Set the frustum of the default camera to line up with our orthographic camera,
    // at the position of the sheet.
    // This avoids "jumps" when the camera is switched.
    const currHeight = custom.top - custom.bottom;
    const distance = perspCameraDistance(currHeight, global.getEffectiveFOV());
    const sheetAltitude = position[1];
    global.position.copy(custom.position);
    global.position.y = sheetAltitude + distance;
    global.quaternion.copy(custom.quaternion);
  },
};
