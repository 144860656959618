import { blue, ColorsWithRatio, neutral } from "@faro-lotv/flat-ui";

/** Color used for the elements with invalid height */
export const INVALID_VALUE_COLOR = neutral[900];

/** Color gradient used for the waypoints colorization by altitude */
export const WAYPOINTS_ALTITUDE_GRADIENT: ColorsWithRatio = [
  {
    color: blue[500],
    ratio: 0.0,
  },
  {
    color: "#8E306D",
    ratio: 0.5,
  },
  {
    color: "#E1080B",
    ratio: 1.0,
  },
];

/** Color gradient used for the waypoints colorization by capture date */
export const WAYPOINTS_DATE_GRADIENT: ColorsWithRatio = [
  {
    color: blue[500],
    ratio: 0.0,
  },
  {
    color: "#189393",
    ratio: 0.5,
  },
  {
    color: "#12B059",
    ratio: 1.0,
  },
];
