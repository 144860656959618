import { CustomOrthoCamera } from "@/components/r3f/cameras/custom-ortho-camera";
import { changeMode } from "@/store/mode-slice";
import { Mode } from "../mode";
import { TagsManagementOverlay } from "./tags-management-overlay";
import { TagsManagementPanel } from "./tags-management-panel";
import { TagsManagementScene } from "./tags-management-scene";
import { TagsManagementTransition } from "./tags-management-transition";

export const tagsManagementMode: Mode = {
  name: "tagsManagement",
  ModeScene: TagsManagementScene,
  ModeTransition: TagsManagementTransition,
  ModePanel: TagsManagementPanel,
  ModeOverlay: TagsManagementOverlay,
  customCamera: CustomOrthoCamera,
  exclusive: {
    title: "Tags management",
    onBack({ dispatch }) {
      dispatch(changeMode("sheet"));
      return Promise.resolve();
    },
  },
};
