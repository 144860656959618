import { BackgroundTask } from "@/utils/background-tasks";
import { FaroText, neutral, NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";
import {
  SceneWorkerErrorCode,
  validateSceneConversionResult,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { CopyButton } from "../../copy-button";

/**
 * @returns error details for a task, undefined if not available
 * @param task The task to get error details for
 */
export function taskErrorDetails(
  task: BackgroundTask,
): JSX.Element | undefined {
  if (
    task.errorCode === SceneWorkerErrorCode.partialScanProcessingFailure &&
    validateSceneConversionResult(task.metadata?.result) &&
    task.metadata.result.failedScans
  ) {
    return (
      <>
        <FaroText variant="labelS" sx={{ textTransform: "uppercase" }}>
          Failed scans
        </FaroText>
        <CopyableList
          items={task.metadata.result.failedScans.map((scan) => scan.scanName)}
        />
      </>
    );
  }
}

type CopyableListProps = {
  /** The items to show in the list */
  items: string[];
};

/** @returns a list of items with a button to copy them to the clipboard */
function CopyableList({ items }: CopyableListProps): JSX.Element {
  return (
    <Stack className={NO_TRANSLATE_CLASS}>
      {items.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          sx={{
            color: neutral[600],

            ".MuiIconButton-root": {
              opacity: 0,
              transition: "opacity 0.1s",
            },

            ":hover": {
              ".MuiIconButton-root": {
                opacity: 1,
              },
            },
          }}
        >
          <FaroText variant="bodyS" color="inherit">
            {item}
          </FaroText>
          <CopyButton text={item} />
        </Stack>
      ))}
    </Stack>
  );
}
