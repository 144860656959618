import {
  EventType,
  SelectPointCloudFileEventProperties,
} from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { RelativeCrop } from "@/modes/create-area-mode/image-crop";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  computeRotatedImageDimensions,
  parseImageFromFile,
  resizeImage,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID, assert, getFileExtension } from "@faro-lotv/foundation";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MAX_UPLOAD_CLOUD_FILE_SIZE_GB,
  MAX_UPLOAD_MODEL_FILE_SIZE_GB,
  PointCloudUploadDialog,
  SupportedPCFileExtensions,
  pointCloudTypeFromFileName,
} from "./point-cloud-upload-utils";
import { UploadElementType, useUploadElement } from "./use-upload-element";

type SetUploadFileCallback = (file: File, onFileUploaded?: () => void) => void;

type UploadAreaImageFileCallback = (
  /** image file name */
  name: string,

  /** area id */
  areaId: GUID,

  /** image rotation angle in degrees */
  rotationAngle: number,

  /** image cropping parameters in % from original image */
  crop?: RelativeCrop,

  ...commonArgs: Parameters<SetUploadFileCallback>
) => void;

type ElementFileUploadContext = {
  /** Method allowing to set/change the currently selected Cad file */
  setCadFile: SetUploadFileCallback;

  /** Method allowing to set/change the currently selected PointCloud file */
  setPointCloudFile: SetUploadFileCallback;

  /** Method allowing to set/change the currently selected AreaImage file */
  uploadAreaImageFile: UploadAreaImageFileCallback;
};

/**
 * * Helper function to rotate image before saving to backend
 *
 * @param file image file
 * @param angle angle to rotate image in degrees
 * @returns rotated image
 */
// eslint-disable-next-line func-style -- FIXME
const rotateImage = async (file: File, angle: number): Promise<File> => {
  // Step 1: Create an image object from the file
  const img = await parseImageFromFile(file);

  const rotatedDimensions = computeRotatedImageDimensions(
    { width: img.width, height: img.height },
    angle,
  );

  // Step 2: Set up a canvas and rotate the image
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  assert(
    ctx && canvas,
    "Unable to create an offscreen 2d context to render the pdf image",
  );

  canvas.width = rotatedDimensions.width;
  canvas.height = rotatedDimensions.height;

  ctx.translate(rotatedDimensions.width / 2, rotatedDimensions.height / 2);
  ctx.rotate((angle * Math.PI) / 180);
  ctx.drawImage(img, -img.width / 2, -img.height / 2);

  // Step 3: Convert the canvas back to a file (Blob or File)
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      assert(blob, "Unable to convert rotated image to blob");
      const rotatedFile = new File([blob], file.name, { type: file.type });
      resolve(rotatedFile);
    }, file.type);
  });
};

/**
 * Helper function to crop image before saving to backend
 *
 * @param file image file
 * @param crop crop image parameters in %
 * @returns cropped image
 */
async function cropImage(file: File, crop: RelativeCrop): Promise<File> {
  // Create an image object from the file
  const img = await parseImageFromFile(file);

  // Create a canvas to draw the cropped image
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  assert(
    ctx && canvas,
    "Unable to create an offscreen 2d context to render the pdf image",
  );

  // get cropping parameters in pixels
  const cropWidth = 0.01 * crop.width * img.width;
  const cropHeight = 0.01 * crop.height * img.height;

  // Set canvas size to the cropped area
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    img,
    0.01 * crop.x * img.width,
    0.01 * crop.y * img.height,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight,
  );

  // Convert the canvas back to a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      assert(blob, "Unable to convert rotated image to blob");
      const rotatedFile = new File([blob], file.name, { type: file.type });
      resolve(rotatedFile);
    }, file.type);
  });
}

const ElementFileUploadContext = createContext<
  ElementFileUploadContext | undefined
>(undefined);

/**
 * @returns A context for storing the selected file by the user
 *
 * While such storage logic would be preferred to be done inside the Redux store, this can't be done for object that
 * can't be serialized (like File objects)
 */
export function ElementFileUploadContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const { handleErrorWithToast } = useErrorHandlers();

  const uploadCad = useUploadElement(UploadElementType.cad);
  const uploadAreaImage = useUploadElement(UploadElementType.areaImage);
  const [pointCloudFile, setPointCloudFile] = useState<File | undefined>();
  const [onPointCloudUploaded, setOnPointCloudUploaded] =
    useState<() => void>();

  const activeArea = useAppSelector(selectActiveArea);

  const handleCadFile = useCallback<SetUploadFileCallback>(
    (file, onFileUploaded) => {
      const fileSizeGB = file.size / 1024 ** 3;
      if (fileSizeGB > MAX_UPLOAD_MODEL_FILE_SIZE_GB) {
        handleErrorWithToast({
          title: `Input file larger than ${MAX_UPLOAD_MODEL_FILE_SIZE_GB}GB. Please try simplifying the model or uploading a subset of the model.`,
          error: "",
        });
        return;
      }

      uploadCad({
        file,
        name: file.name || "",
        createdAt: new Date(file.lastModified),
        onFileUploaded,
      });
    },
    [uploadCad, handleErrorWithToast],
  );

  const hasUnlimitedUpload = useAppSelector(
    selectHasFeature(Features.UnlimitedUpload),
  );

  /** Checking if the passed file object is a valid Point Cloud file (based on the extension) */
  const handlePointCloudFile = useCallback<SetUploadFileCallback>(
    (file, onFileUploaded) => {
      Analytics.track<SelectPointCloudFileEventProperties>(
        EventType.uploadPointCloud,
        {
          fileSize: file.size,
          extension: getFileExtension(file.name) ?? "",
        },
      );

      try {
        pointCloudTypeFromFileName(file.name);

        const fileSizeGB = file.size / 1024 ** 3;
        if (!hasUnlimitedUpload && fileSizeGB > MAX_UPLOAD_CLOUD_FILE_SIZE_GB) {
          handleErrorWithToast({
            title: `File must be less than ${MAX_UPLOAD_CLOUD_FILE_SIZE_GB}GB`,
            error: "",
          });
          return;
        }

        setOnPointCloudUploaded(onFileUploaded);
        setPointCloudFile(file);
      } catch {
        setOnPointCloudUploaded(undefined);
        setPointCloudFile(undefined);

        const supportedExtensions = new Intl.ListFormat("en-US").format(
          Object.values(SupportedPCFileExtensions),
        );
        handleErrorWithToast({
          title: "Failed to import file",
          error: `The file extension is not supported. Only ${supportedExtensions} files are supported.`,
        });
      }
    },
    [hasUnlimitedUpload, handleErrorWithToast],
  );

  const handleAreaImageFile = useCallback<UploadAreaImageFileCallback>(
    async (name, areaId, rotationAngle, crop, file, onFileUploaded) => {
      try {
        const rotatedImage =
          rotationAngle === 0 ? file : await rotateImage(file, rotationAngle);

        const croppedImage = crop
          ? await cropImage(rotatedImage, crop)
          : rotatedImage;

        const thumbnailFile = await resizeImage(croppedImage);

        uploadAreaImage({
          file: croppedImage,
          additionalFiles: [thumbnailFile],
          areaId,
          name,
          createdAt: new Date(file.lastModified),
          onFileUploaded,
        });
      } catch {
        handleErrorWithToast({
          title: "Upload of the sheet failed. Please, try again",
          error: "",
        });
      }
    },
    [handleErrorWithToast, uploadAreaImage],
  );

  const value = useMemo<ElementFileUploadContext>(
    () => ({
      setCadFile: handleCadFile,
      setPointCloudFile: handlePointCloudFile,
      uploadAreaImageFile: handleAreaImageFile,
    }),
    [handleAreaImageFile, handleCadFile, handlePointCloudFile],
  );

  // TODO: Offer better UX to upload point clouds without an area selected
  // https://faro01.atlassian.net/browse/SWEB-6017
  useEffect(() => {
    if (pointCloudFile && !activeArea) {
      handleErrorWithToast({
        title: "No Area selected",
        error: "Please first select an area to upload the point cloud to.",
      });

      setPointCloudFile(undefined);
    }
  }, [activeArea, handleErrorWithToast, pointCloudFile]);

  return (
    <ElementFileUploadContext.Provider value={value}>
      {children}
      {pointCloudFile && activeArea && (
        <PointCloudUploadDialog
          area={activeArea}
          file={pointCloudFile}
          setPointCloudFile={setPointCloudFile}
          onFileUploaded={onPointCloudUploaded}
        />
      )}
    </ElementFileUploadContext.Provider>
  );
}

/**
 * @returns The ElementFileUploadContext context
 */
export function useElementFileUploadContext(): ElementFileUploadContext {
  const context = useContext(ElementFileUploadContext);

  if (!context) {
    throw Error(
      "useElementFileUploadContext() must be used within an ElementFileUploadContext",
    );
  }

  return context;
}
