import { Theme } from "@mui/material";

export enum ProgressMenuLayer {
  /** The base layer */
  base = 0,

  /** Layer for menu dropdowns */
  menu = 1,
}

/**
 * @returns a theme function to calculate the z-index layer for elements in the progress menu in an SxProp.
 * @param layer the progress menu layer to calculate the z-index for
 */
export function calculateProgressMenuZIndex(layer: number) {
  return (theme: Theme) => theme.zIndex.drawer + layer;
}
