import {
  FaroChipFilter,
  FaroMenu,
  FilterMenuContent,
  FilterMenuContentProps,
} from "@faro-lotv/flat-ui";
import { Box } from "@mui/system";
import { ReactNode, useCallback, useState } from "react";

type FilterProps = {
  label: ReactNode;
} & Omit<FilterMenuContentProps, "selectedOptions" | "onResetFilter"> &
  Required<Pick<FilterMenuContentProps, "selectedOptions">>;

/** @returns A chip that can be clicked to open a sub menu */
export function ChipFilterWithSearch({
  label,
  selectedOptions,
  onFilterChange,
  ...rest
}: FilterProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onResetFilter = useCallback(() => {
    onFilterChange?.([], false);
  }, [onFilterChange]);

  return (
    <Box ref={setAnchorEl} component="div">
      <FaroChipFilter
        label={label}
        count={selectedOptions.length > 0 ? selectedOptions.length : undefined}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      />
      {anchorEl && (
        <FaroMenu
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
        >
          <FilterMenuContent
            selectedOptions={selectedOptions}
            onFilterChange={onFilterChange}
            onResetFilter={onResetFilter}
            {...rest}
          />
        </FaroMenu>
      )}
    </Box>
  );
}
