import {
  selectControlPointsAlignmentAnchorPositions,
  selectControlPointsSheetElevation,
  selectElementToAlignWithControlPointsAlignment,
} from "@/store/modes/control-points-alignment-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";
import { areControlPointsParametersValid } from "./control-points-alignment-set-points-panel";

interface ControlPointsAlignmentProgressBarProps {
  /** A callback issued when the user wants to apply the alignment */
  apply(): void;
}

/**
 * This defines all the steps to align layer using control points
 */
enum ControlPointsAlignmentStep {
  alignAreaOrLayer = "alignAreaOrLayer",
}

/**
 * @returns The bar that is shown in the top of a control points alignment mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function ControlPointsAlignmentProgressBar({
  apply,
}: ControlPointsAlignmentProgressBarProps): JSX.Element {
  const alignmentAnchorPositions = useAppSelector(
    selectControlPointsAlignmentAnchorPositions,
  );

  const elementToAlignId = useAppSelector(
    selectElementToAlignWithControlPointsAlignment,
  );
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));

  const sheetElevation = useAppSelector(selectControlPointsSheetElevation);

  if (!elementToAlign) {
    throw new Error("Element to align not assigned");
  }

  /** true if the alignment parameters are valid and we can apply it. */
  const areValidParams = useMemo(
    () =>
      areControlPointsParametersValid(alignmentAnchorPositions, sheetElevation),
    [alignmentAnchorPositions, sheetElevation],
  );

  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: ControlPointsAlignmentStep.alignAreaOrLayer,
        label: isIElementAreaSection(elementToAlign)
          ? "Align area"
          : "Align layer",
        allowNext: () => areValidParams,
      },

      // TODO: Add onBeforeNext when second step will be implemented
    ],
    [areValidParams, elementToAlign],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      hideStepNumbers
      onLastStepButtonClicked={apply}
    />
  );
}
