import { useAppSelector } from "@/store/store-hooks";
import { getSheetSize } from "@faro-lotv/app-component-toolbox";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { memberWithPrivateData } from "@faro-lotv/lotv";
import { getElementTransformation } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { Box3, Matrix4, Matrix4Tuple, Vector3, Vector3Tuple } from "three";

/**
 * Get the 4 corners of one sheet.
 *
 * @param sheet to compute the corners for
 * @param worldMatrixTuple the conversion matrix from sheet to world coordinates system
 * @returns the 4 corners for the sheet in world coordinates (including any rotation of the sheet)
 */
function getSheetCorner(
  sheet: IElementGenericImgSheet,
  worldMatrixTuple: Matrix4Tuple,
): Vector3Tuple[] {
  const { width, height } = getSheetSize(sheet);

  const worldMatrix = new Matrix4().fromArray(worldMatrixTuple);

  return [
    new Vector3(0, 0, 0),
    new Vector3(width, 0, 0),
    new Vector3(0, height, 0),
    new Vector3(width, height, 0),
  ].map((v) => v.applyMatrix4(worldMatrix).toArray());
}

/**
 * @param sheets list of sheets to compute the corners for
 * @returns the list of 4 interesting corners for each sheet
 */
export function useSheetCorners(
  sheets: IElementGenericImgSheet[],
): Vector3Tuple[] {
  const elementsState = useAppSelector((state) => state.iElements);

  return useMemo(() => {
    if (!sheets.length) return [];

    return sheets.reduce((corners: Vector3Tuple[], sheet) => {
      const { worldMatrix } = getElementTransformation(elementsState, sheet.id);
      corners.push(...getSheetCorner(sheet, worldMatrix));
      return corners;
    }, []);
  }, [elementsState, sheets]);
}

/**
 *
 * @param sheetCorners The four corners of a sheet
 * @param box The bounding box to add the corners to
 * @returns The argument box with the added corners
 */
export const sheetCorners2Box = memberWithPrivateData(() => {
  const point = new Vector3();

  return (sheetCorners: Vector3Tuple[], box = new Box3()): Box3 => {
    sheetCorners.forEach((p) => box.expandByPoint(point.set(p[0], p[1], p[2])));
    return box;
  };
});
