import {
  ChangeRegistrationCameraProjectionProperties,
  EventType,
} from "@/analytics/analytics-events";
import { CameraViewType } from "@/utils/cam-view";
import {
  FaroTooltip,
  OrthographicCameraIcon,
  PerspectiveCameraIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { MouseEvent, useCallback } from "react";

type CameraSwitchProps = {
  /** The currently active camera in 3D projection. */
  cameraOptions3D: CameraViewType;

  /** A callback to execute when the user changes the camera in 3D. */
  onCameraChange(camera: CameraViewType): void;
};

/** @returns A toolbar to change the camera in 3D projection */
export function CameraSwitch({
  cameraOptions3D,
  onCameraChange,
}: CameraSwitchProps): JSX.Element {
  const changeCamera = useCallback(
    (_event: MouseEvent, value: CameraViewType | null) => {
      if (value) {
        Analytics.track<ChangeRegistrationCameraProjectionProperties>(
          EventType.changeRegistrationCameraProjection,
          {
            projection: value,
          },
        );
        onCameraChange(value);
      }
    },
    [onCameraChange],
  );

  return (
    <Toolbar>
      <ToolGroup
        value={cameraOptions3D}
        orientation="vertical"
        exclusive
        onChange={changeCamera}
      >
        <FaroTooltip title="Perspective Camera" placement="right">
          <ToolButton
            value={CameraViewType.perspective}
            selected={cameraOptions3D === CameraViewType.perspective}
          >
            <PerspectiveCameraIcon />
          </ToolButton>
        </FaroTooltip>
        <FaroTooltip title="Orthographic Camera" placement="right">
          <ToolButton
            value={CameraViewType.orthographic}
            selected={cameraOptions3D === CameraViewType.orthographic}
          >
            <OrthographicCameraIcon />
          </ToolButton>
        </FaroTooltip>
      </ToolGroup>
    </Toolbar>
  );
}
