import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  CameraCenterData,
  centerOrthoCamera,
} from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { PointCloudObject, SheetObject } from "@/object-cache";
import { isFromSheetObject } from "@/object-cache-type-guard";
import {
  selectCrossSectionEnabledForCloudToSheetAlignment,
  selectElevationForCloudToSheetAlignment,
  selectIncrementalTransformForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import { setIncrementalTransformForCloudToSheetAlignment } from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipeline,
  FilteredRenderPass,
  Map2DControls,
  quaternionToVector4Tuple,
  TwoPointAlignment,
  UniformLights,
  useReproportionCamera,
  View,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Object3D, Points, Quaternion, Vector3 } from "three";
import {
  useCameraInCurrentScene,
  useCenterCameraOnBoundingBoxIn2D,
  useClippingPlanesAtCloudElevation,
  useCloudForAlignmentView,
  useNewOrthographicCamera,
  useRotationAroundGravity,
  useSheetBoundingBox,
} from "../alignment-modes-commons/align-to-cad-utils";
import {
  AlignmentTransform,
  alignmentTransformToMatrix4,
} from "../alignment-modes-commons/alignment-transform";
import { useOverlayElements } from "../overlay-elements-context";

type CloudToSheetOverlaySceneProps = {
  /** The reference sheet object */
  sheetObject: SheetObject;

  /** The aligned cloud object */
  cloudObject: PointCloudObject;
};

/** @returns the overlay scene for align cloud-to-sheet in 2d */
export function CloudToSheetOverlayScene({
  sheetObject,
  cloudObject,
}: CloudToSheetOverlaySceneProps): JSX.Element {
  const dispatch = useAppDispatch();

  const { singleScreen } = useOverlayElements();

  const background = useThree((s) => s.scene.background);

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const incrementalTransform = useAppSelector(
    selectIncrementalTransformForCloudToSheetAlignment,
  );

  const [transform] = useState<AlignmentTransform>(
    incrementalTransform ?? {
      position: [0, 0, 0],
      quaternion: [0, 0, 0, 1],
      scale: [1, 1, 1],
    },
  );

  const onTransformChanged = useCallback(
    (position: Vector3, quaternion: Quaternion, scale: Vector3) => {
      const transform: AlignmentTransform = {
        scale: scale.toArray(),
        position: position.toArray(),
        quaternion: quaternionToVector4Tuple(quaternion),
      };

      dispatch(setIncrementalTransformForCloudToSheetAlignment(transform));
    },
    [dispatch],
  );

  const camera = useNewOrthographicCamera();
  useCameraInCurrentScene(camera);

  const sheetElements = useMemo(() => [sheetObject.iElement], [sheetObject]);
  const sheetBox = useSheetBoundingBox(sheetElements);
  const cloudBox = useObjectBoundingBox(cloudObject, cloudObject.iElement.id);
  assert(
    cloudBox,
    "unable to compute the combined cloud and sheet bounding box",
  );

  const boundingBox = useMemo(
    () =>
      cloudBox
        .clone()
        .applyMatrix4(alignmentTransformToMatrix4(transform))
        .union(sheetBox),
    [cloudBox, sheetBox, transform],
  );

  const size = useThree((s) => s.size);
  const centeringData = useCenterCameraOnBoundingBoxIn2D(
    boundingBox,
    size.width / size.height,
  );
  const cameraRotation = useRotationAroundGravity(sheetObject.iElement.id);

  const [initialCenteringData] = useState<CameraCenterData>({
    position: centeringData.position,
    frustum: centeringData.frustum,
    quaternion: cameraRotation,
  });

  useEffect(() => {
    centerOrthoCamera(camera, initialCenteringData);
  }, [camera, initialCenteringData]);

  useReproportionCamera(camera);

  const sheetElevation = useAppSelector(
    selectElevationForCloudToSheetAlignment,
  );

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForCloudToSheetAlignment,
  );

  const cloudClippingPlanes = useClippingPlanesAtCloudElevation(
    sheetElevation,
    isCrossSectionEnabled,
  );

  useCloudForAlignmentView(cloudObject, cloudClippingPlanes);

  const isOverlayObject = useCallback(
    (o: Object3D) => !isFromSheetObject(o) && !(o instanceof Points),
    [],
  );

  return (
    <View
      camera={camera}
      trackingElement={singleScreen}
      background={background}
      hasSeparateScene
    >
      <UniformLights />

      <TwoPointAlignment
        onPointerDown={() => setControlsEnabled(false)}
        onPointerUp={() => setControlsEnabled(true)}
        onTransformChanged={onTransformChanged}
        isScaleEnabled={false}
        disableDataSquash
        {...transform}
      >
        <PointCloudRenderer pointCloud={cloudObject} />
      </TwoPointAlignment>
      <SheetRenderer sheet={sheetObject} />
      <EffectPipeline>
        <FilteredRenderPass filter={(o) => isFromSheetObject(o)} />
        <FilteredRenderPass
          filter={(o) => isObjPointCloudPoint(o)}
          clear={false}
          clearDepth={false}
        />
        <FilteredRenderPass
          filter={(o) => isOverlayObject(o)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipeline>
      <Map2DControls
        camera={camera}
        referencePlaneHeight={sheetElevation}
        enabled={controlsEnabled}
        isPrimaryControl={false}
      />
    </View>
  );
}
