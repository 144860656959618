import {
  DeleteMeasurementEventProperties,
  EventType,
  ToggleUnitOfMeasureActionSource,
} from "@/analytics/analytics-events";
import { useToggleUnitOfMeasure } from "@/components/common/unit-of-measure-context";
import { selectActiveMeasurement } from "@/store/measurement-tool-selector";
import {
  ComponentsToDisplay,
  Measurement,
  removeMeasurement,
  setActiveMeasurement,
  setMeasurementComponentsToDisplay,
} from "@/store/measurement-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  selectObjectVisibility,
  selectVisibilityDistance,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { MeasuresSorter } from "@/tools/multi-point-measures/measures-sorter";
import {
  UPDATE_CAMERA_MONITOR_PRIORITY,
  parseVector3,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useFrame, useThree } from "@react-three/fiber";
import { useCallback, useMemo, useState } from "react";
import { Material, Object3D, Plane, Vector3 } from "three";
import { AnnotationVisibility } from "../annotations/annotation-utils";
import { isInsideClippingPlanes } from "../annotations/annotations-renderer";
import { useAddMeasurementToProject } from "./add-measurement-to-project";
import { MultiPointMeasureRenderer } from "./multi-point-measure-renderer";

type MeasurementsRendererProps = {
  /** The list of measurements to render */
  measurements: Measurement[];

  /** Check if there's a picking tool active */
  isPickingToolActive?: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** When a collapsed measure is clicked, this callback is issued to move the camera towards the measure */
  onCollapsedMeasurementClicked?(point: Vector3): void;

  /** Whether depth testing should be used to render the measurement */
  depthTest?: Material["depthTest"];

  /** The render order to use for the measurement */
  renderOrder?: Object3D["renderOrder"];
};

/** @returns The renderer of the measurements stored in the app store */
export function MeasurementsRenderer({
  measurements: allMeasurements,
  isPickingToolActive = false,
  clippingPlanes,
  onCollapsedMeasurementClicked,
  depthTest,
  renderOrder,
}: MeasurementsRendererProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const { unitOfMeasure, toggleUnitOfMeasure } = useToggleUnitOfMeasure(
    true,
    ToggleUnitOfMeasureActionSource.measureToolbar,
  );

  const activeMeasurement = useAppSelector(selectActiveMeasurement);
  // Computing the measurements inside the clipping planes
  const measurements = useMemo(() => {
    const filtered = allMeasurements.filter(
      (m) => !isPickingToolActive || m.id === activeMeasurement?.id,
    );
    if (clippingPlanes?.length !== 6) {
      return filtered;
    }
    const ret = new Array<Measurement>();
    for (const measurement of filtered) {
      const position = measurement.points
        .reduce((prev, next) => prev.add(parseVector3(next)), new Vector3())
        .divideScalar(measurement.points.length);
      if (isInsideClippingPlanes(position, clippingPlanes)) {
        ret.push(measurement);
      }
    }
    return ret;
  }, [
    activeMeasurement?.id,
    allMeasurements,
    isPickingToolActive,
    clippingPlanes,
  ]);

  const measurementsPoints = useMemo(
    () =>
      measurements.map((m) => m.points.map((p) => new Vector3().fromArray(p))),
    [measurements],
  );

  const deleteActiveMeasurement = useCallback(() => {
    if (activeMeasurement) {
      Analytics.track<DeleteMeasurementEventProperties>(
        EventType.deleteMeasurement,
        {
          via: "action bar",
        },
      );

      dispatch(
        removeMeasurement({
          elementID: activeMeasurement.parentId,
          measurementID: activeMeasurement.id,
        }),
      );
    }
  }, [activeMeasurement, dispatch]);

  const changeMeasurementComponentsToDisplay = useCallback(
    (newComponentsToDisplay: ComponentsToDisplay) => {
      if (activeMeasurement) {
        // update currentComponentsToDisplay
        dispatch(
          setMeasurementComponentsToDisplay({
            elementID: activeMeasurement.parentId,
            measurementID: activeMeasurement.id,
            componentsToDisplay: newComponentsToDisplay,
          }),
        );
      }
    },
    [activeMeasurement, dispatch],
  );

  const disableToggleMeasurmentComponents = useMemo(
    () => activeMeasurement && activeMeasurement.points.length !== 2,
    [activeMeasurement],
  );

  const camera = useThree((s) => s.camera);

  const sorter = useMemo(() => new MeasuresSorter(camera), [camera]);

  // While picking the other measurements should not be interactable
  const activeTool = useAppSelector(selectActiveTool);
  const disablePointerEvents = activeTool === ToolName.measurement;

  const [measuresVisibility, setMeasuresVisibility] = useState(
    new Array<AnnotationVisibility>(),
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);

  useFrame(({ camera }, delta) => {
    sorter.update(
      camera,
      measurements,
      activeMeasurement?.id,
      delta,
      visibilityDistance,
      visibilityDistance,
    );
    if (sorter.dirty()) {
      // Updating React states inside useFrame leads to downgraded performances.
      // Therefore, the 'measuresVisibility' state is updated only once every
      // 'sorter.secsBeforeUpdate' seconds.
      setMeasuresVisibility(sorter.measuresVisibility.slice());
      sorter.resetDirty();
    }
  }, UPDATE_CAMERA_MONITOR_PRIORITY);

  const [activeLabelIndex, setActiveLabelIndex] = useState<
    number | undefined
  >();
  const [isNewLabelSelected, setIsNewLabelSelected] = useState(true);

  const onMeasurementClicked = useCallback(
    (measurement: Measurement, labelIndex: number | undefined) => {
      const isSameLabel =
        activeLabelIndex === labelIndex &&
        activeMeasurement?.id === measurement.id;

      setIsNewLabelSelected(!isSameLabel);
      setActiveLabelIndex(isSameLabel ? undefined : labelIndex);
      dispatch(setActiveMeasurement(isSameLabel ? undefined : measurement));

      if (!isSameLabel) {
        Analytics.track(EventType.selectMeasurement);
      }
    },
    [activeLabelIndex, activeMeasurement?.id, dispatch],
  );

  const saveMeasurementToAnAnnotation = useAddMeasurementToProject();

  const shouldMeasurementsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.measurements),
  );
  if (!shouldMeasurementsBeVisible) {
    return null;
  }

  return (
    <>
      {measurements.map((measurement, index) => (
        <MultiPointMeasureRenderer
          key={measurement.id}
          points={measurementsPoints[index]}
          disablePointerEvents={disablePointerEvents}
          onClick={(_, labelIndex) =>
            onMeasurementClicked(measurement, labelIndex)
          }
          onDeleteActiveMeasurement={deleteActiveMeasurement}
          onChangeMeasurementComponentsToDisplay={
            disableToggleMeasurmentComponents
              ? undefined
              : changeMeasurementComponentsToDisplay
          }
          otherLive={disablePointerEvents}
          active={
            isNewLabelSelected && activeMeasurement?.id === measurement.id
          }
          visibility={measuresVisibility[index]}
          onCollapsedMeasurementClicked={(point) => {
            dispatch(setActiveMeasurement(measurement));
            onCollapsedMeasurementClicked?.(point);
          }}
          unitOfMeasure={unitOfMeasure}
          onCreateAnnotation={() => saveMeasurementToAnAnnotation(measurement)}
          onToggleUnitOfMeasure={toggleUnitOfMeasure}
          isClosed={measurement.metadata.isLoop}
          depthTest={depthTest}
          renderOrder={renderOrder}
          componentsToDisplay={measurement.componentsToDisplay}
        />
      ))}
    </>
  );
}
