import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch } from "@/store/store-hooks";
import { setTagName, Tag } from "@/store/tags/tags-slice";
import {
  createMutationSetLabelName,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { useUpdateTaggedElements } from "./tag-utils";
import { useLongTask } from "./use-long-task";

type UpdateTagCallback = (tag: Tag, tagName: string) => void;

/**
 * @returns A callback to update the name of a tag in the project allowed tags and update the elements that have thats tag.
 * @param onUpdateStatusChanged A callback executed when the execution state of the update changes
 */
export function useUpdateTag(
  onUpdateStatusChanged: (isRunning: boolean) => void,
): UpdateTagCallback {
  const { projectApiClient } = useApiClientContext();
  const { handleErrorWithDialog } = useErrorHandlers();
  const updateTaggedElements = useUpdateTaggedElements();
  const dispatch = useAppDispatch();

  const { startLongTask, stopLongTask } = useLongTask(onUpdateStatusChanged);

  return useCallback(
    (tag: Tag, tagName: string) => {
      startLongTask(
        "Updating tag... It's taking a bit longer than usual. Please wait",
      );
      const mutationSetLabelName = createMutationSetLabelName(tag.id, tagName);

      projectApiClient
        .applyMutations([mutationSetLabelName])
        .then(() => {
          dispatch(setTagName({ id: tag.id, name: tagName }));
        })
        .then(() => updateTaggedElements(tag))
        .catch((error) =>
          handleErrorWithDialog({
            title: "Error: failed to update tag",
            error,
          }),
        )
        .finally(() => stopLongTask());
    },

    [
      projectApiClient,
      dispatch,
      handleErrorWithDialog,
      startLongTask,
      stopLongTask,
      updateTaggedElements,
    ],
  );
}
