import {
  ChangeMapOpacityEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { ModeNames } from "@/modes/mode";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  CadSmallIcon,
  MapLargeIcon,
  PointCloudIcon,
  SliderHandlerIcon,
  blue,
  neutral,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Box, Slider, SliderThumb, Stack } from "@mui/material";
import { floor } from "es-toolkit/compat";
import {
  PointerEventHandler,
  PropsWithChildren,
  useCallback,
  useRef,
} from "react";
import {
  ObjectsOpacity,
  useTransparencySettingsContext,
} from "./transparency-settings-context";

/** @returns true if the maps/sheets opacity should be ignored */
export function useIgnoreMapOpacity(): boolean {
  const mode: ModeNames = useAppSelector(selectModeName);
  return mode !== "overview";
}

/** @returns A component with sliders to manipulate transparency settings */
export function TransparencySliders(): JSX.Element {
  const { objectsOpacity, setOpacities } = useTransparencySettingsContext(
    (state) => state,
  );

  const ignoreMapOpacity = useIgnoreMapOpacity();
  /** Update the opacity value of an object when the slider value changes */
  const updateOpacity = useCallback(
    (value: number | number[], key: keyof ObjectsOpacity): void => {
      const val = typeof value === "number" ? value : value[0];
      setOpacities({
        ...objectsOpacity,
        [key]: val,
      });
    },
    [objectsOpacity, setOpacities],
  );

  return (
    <Stack direction="row" gap={1} padding="0.4em">
      {/* Map slider */}
      {!ignoreMapOpacity && (
        <TransparencySlider
          icon={<MapLargeIcon sx={{ color: neutral[0], width: 16 }} />}
          opacity={objectsOpacity.mapOpacity}
          onChange={(value) => updateOpacity(value, "mapOpacity")}
          onDragStop={(timeSpent) =>
            Analytics.track<ChangeMapOpacityEventProperties>(
              EventType.changeMapOpacity,
              {
                timeSpent,
                newValue: objectsOpacity.mapOpacity,
              },
            )
          }
        />
      )}

      {/* CAD slider */}
      <TransparencySlider
        icon={<CadSmallIcon sx={{ color: neutral[0], width: 16 }} />}
        opacity={objectsOpacity.cadOpacity}
        onChange={(value) => updateOpacity(value, "cadOpacity")}
        onDragStop={(timeSpent) =>
          Analytics.track<ChangeMapOpacityEventProperties>(
            EventType.changeCadOpacity,
            {
              timeSpent,
              newValue: objectsOpacity.cadOpacity,
            },
          )
        }
      />

      {/* PointCloud slider */}
      <TransparencySlider
        icon={<PointCloudIcon sx={{ color: neutral[0], width: 16 }} />}
        opacity={objectsOpacity.pcOpacity}
        onChange={(value) => updateOpacity(value, "pcOpacity")}
        onDragStop={(timeSpent) =>
          Analytics.track<ChangeMapOpacityEventProperties>(
            EventType.changePointcloudOpacity,
            {
              timeSpent,
              newValue: objectsOpacity.pcOpacity,
            },
          )
        }
      />
    </Stack>
  );
}

/** @returns a component that provides a checkers pattern to the background of its children */
function CheckersBackground({ children }: PropsWithChildren): JSX.Element {
  return (
    <Stack
      direction="column"
      sx={{
        height: "100%",
        width: "1rem",
        position: "relative",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Box
        component="div"
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          background: `repeating-conic-gradient(${neutral[0]} 0% 25%, ${blue[500]} 0% 50%) 50% / 10px 10px`,

          "&:after": {
            content: '""',
            display: "block",
            height: "100%",
            opacity: 0.9,
            width: "100%",
            borderRadius: "10px",
            background: `linear-gradient(${blue[500]}, ${neutral[0]})`,
          },
        }}
      />
      {children}
    </Stack>
  );
}

type SliderHandlerThumbProps = Parameters<typeof SliderThumb> & {
  /** Method to call when the pointer is pressed down */
  onPointerDown?: PointerEventHandler<HTMLSpanElement>;

  /** Method to call when the pointer is released */
  onPointerUp?: PointerEventHandler<HTMLSpanElement>;
};

/**
 * @returns Slider thumb that replaces the MUI handle with the SliderHandlerIcon
 */
function SliderHandlerThumb({
  children,
  onPointerDown,
  onPointerUp,
  ...sliderThumbProps
}: PropsWithChildren<SliderHandlerThumbProps>): JSX.Element {
  return (
    <SliderThumb
      {...sliderThumbProps}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      {/* Attaching the children from the MUI Slider for adding click handlers */}
      {children}

      <SliderHandlerIcon
        sx={{ width: "1rem", height: "1rem", color: neutral[950] }}
      />
    </SliderThumb>
  );
}

type TransparencySliderProps = {
  /** Icon to display below the slider */
  icon: JSX.Element;

  /** Value that the slider should have */
  opacity: number;

  /** A callback function when the slider value changes */
  onChange(value: number | number[]): void;

  /**
   * Method to call after the user stops dragging the slider thumb
   *
   * @param timeSpent How long the user dragged the slider before releasing the mouse button, in seconds
   */
  onDragStop?(timeSpent: number): void;
};

function TransparencySlider({
  icon,
  opacity,
  onChange,
  onDragStop,
}: TransparencySliderProps): JSX.Element {
  const draggingStartTime = useRef<number>();

  const onPointerDown = useCallback(() => {
    draggingStartTime.current = Date.now();
  }, []);

  const onPointerUp = useCallback(() => {
    if (onDragStop && draggingStartTime.current !== undefined) {
      const timeSpent = (Date.now() - draggingStartTime.current) / 1000;
      onDragStop(floor(timeSpent, 2));
    }
  }, [onDragStop]);

  return (
    <Stack direction="column" alignItems="center">
      {icon}

      <CheckersBackground>
        <Slider
          sx={{
            color: blue[300],
            background: `linear-gradient(${blue[500]} 0%, rgba(0,0,0,0) 100%)`,
            height: "100%",
            padding: 0,
            width: "100%",
            borderRadius: "6px",
            "& .MuiSlider-rail": {
              display: "none",
            },
            "& .MuiSlider-thumbColorPrimary": {
              color: "rgba(0,0,0,0)",
            },
          }}
          aria-label="Model opacity"
          value={opacity}
          track={false}
          min={0}
          max={1}
          step={0.01}
          orientation="vertical"
          size="small"
          onChange={(_, value) => onChange(value)}
          componentsProps={{
            thumb: {
              style: {
                boxShadow: "none",
              },
            },
          }}
          slots={{
            thumb: SliderHandlerThumb,
          }}
          slotProps={{
            thumb: { onPointerDown, onPointerUp },
          }}
        />
      </CheckersBackground>
    </Stack>
  );
}
