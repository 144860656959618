import { EventType } from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch } from "@/store/store-hooks";
import { removeTag, Tag } from "@/store/tags/tags-slice";
import { useDialog } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  createMutationRemoveAllowedLabel,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { DeleteElementDialogContent } from "../delete-element-dialog-content";
import { useUpdateTaggedElements } from "./tag-utils";
import { useLongTask } from "./use-long-task";

type DeleteTagCallback = (tag: Tag) => void;

/**
 * @returns A callback to delete a tag from the project
 * @param onDeletionStateChanged A callback executed when the execution state of the deletion changes
 */
export function useDeleteTag(
  onDeletionStateChanged: (isRunning: boolean) => void,
): DeleteTagCallback {
  const { projectApiClient } = useApiClientContext();
  const { createDialog } = useDialog();
  const { handleErrorWithDialog } = useErrorHandlers();
  const updateTaggedElements = useUpdateTaggedElements();
  const dispatch = useAppDispatch();

  const { startLongTask, stopLongTask } = useLongTask(onDeletionStateChanged);

  return useCallback(
    async (tag: Tag) => {
      // Create a delete confirmation dialog
      const hasConfirmed = await createDialog({
        title: "Delete tag?",
        confirmText: "Delete",
        content: <DeleteElementDialogContent dark name={tag.name} />,
        variant: "danger",
        dark: true,
      });

      if (!hasConfirmed) return;

      // After the user has confirmed that they want to delete the tag, track the event
      Analytics.track(EventType.deleteTag);

      startLongTask(
        `The deletion of tag "${tag.name}" is taking longer than expected. Please wait...`,
      );

      try {
        // Delete the label in the backend
        const mutation = createMutationRemoveAllowedLabel(tag.id);
        await projectApiClient.applyMutations([mutation]);

        // Update the tagged elements in the store
        updateTaggedElements(tag);

        // Remove the tag from the store
        dispatch(removeTag(tag));
      } catch (error) {
        handleErrorWithDialog({
          title: "Error: failed to delete tag",
          error,
        });
      }

      stopLongTask();
    },
    [
      createDialog,
      startLongTask,
      stopLongTask,
      projectApiClient,
      updateTaggedElements,
      dispatch,
      handleErrorWithDialog,
    ],
  );
}
