import { useEffect } from "react";
import { DefaultModeInitialState, ModeTransitionProps } from "../mode";
import { useCurrentArea } from "../mode-data-context";
import { useTagsManagementContext } from "./tags-management-context";

/** @returns The transition to enter the tags management mode */
export function TagsManagementTransition({
  onCompleted,
}: ModeTransitionProps<DefaultModeInitialState>): null {
  const { area } = useCurrentArea();

  // When transitioning to the TagsManagement mode, synchronize the active area
  // of the mode with the one in the app store
  const { activeAreaId, setActiveAreaId, clearSelection } =
    useTagsManagementContext();
  useEffect(() => {
    setActiveAreaId(area?.id);
  }, [area, setActiveAreaId]);

  useEffect(() => {
    if (activeAreaId === area?.id) {
      clearSelection();
      onCompleted();
    }
  }, [activeAreaId, area?.id, onCompleted, clearSelection]);

  return null;
}
