import { ElementIconType } from "@/components/ui/icons";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { selectCadAlignmentErrorMessage } from "@/store/cad/cad-slice";
import {
  isIElementModel3dStream,
  isIElementOverviewImage,
} from "@faro-lotv/ielement-types";
import {
  disableAlignAreaMessage,
  selectDisableCaptureTreeAlignment,
  selectFromCaptureTree,
} from "../../cad-model-tree/use-disable-capture-tree-alignment";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const OPEN_ALIGN_WIZARD_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.openAlignmentWizard,
  label: "Align",
  icon: ElementIconType.AlignIcon,
  handler: ({ elementID, dispatch }) =>
    Promise.resolve(
      openAlignmentWizard({ elementIdToAlign: elementID, dispatch }),
    ),
  disabledMessageForNode: (element, state) => {
    if (selectDisableCaptureTreeAlignment(element)(state)) {
      return disableAlignAreaMessage;
    }

    if (isIElementModel3dStream(element)) {
      return selectCadAlignmentErrorMessage(state);
    }

    if (isIElementOverviewImage(element)) {
      return "Overview map can't be aligned";
    }
  },
  tooltipMessage: (element, state) => {
    if (selectFromCaptureTree(element)(state)) {
      return "Manual alignments will not be preserved when syncing the project back to SCENE.";
    }
  },
};
