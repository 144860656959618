import { selectModeName } from "@/store/mode-selectors";
import { RootState } from "@/store/store";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { neutral, NoTranslate } from "@faro-lotv/flat-ui";
import { convertToDateTimeString } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  isIElementImg360,
  isIElementOdometryPath,
  isIElementSection,
  isIElementVideoRecording,
  pickClosestInTime,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIsChildOfRooms,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import {
  TimestampDropdown,
  TimestampDropdownOptionLabel,
} from "./timestamp-dropdown/timestamp-dropdown";

type DataSessionsDropDownProps = {
  /** The list of datasets of interest for the current mode */
  datasets: IElement[];

  /** The current active element */
  element?: IElement;

  /** The element containing the time reference for the current active element  */
  referenceElement?: IElement;

  /** Called when the user selects a new data session from the drop down */
  onDataSessionChanged(id: GUID): void;
};

/**
 * @returns a dropdown to select the available odometric paths
 */
export function DataSessionsDropDown({
  datasets,
  element,
  referenceElement,
  onDataSessionChanged,
}: DataSessionsDropDownProps): JSX.Element | null {
  const store = useAppStore();
  const activeMode = useAppSelector(selectModeName);

  const selectedElement = useMemo(
    () =>
      referenceElement
        ? pickClosestInTime(referenceElement, datasets)
        : undefined,
    [datasets, referenceElement],
  );

  const panoElement = useMemo(() => {
    if (element && isIElementImg360(element)) {
      return element;
    }
  }, [element]);

  // The pano info should be shown only when the viewer is showing a pano in walk or split mode
  const shouldShowPanoInfo = useMemo(
    () => !!panoElement && (activeMode === "walk" || activeMode === "split"),
    [panoElement, activeMode],
  );
  const isRoomPano = useAppSelector(
    selectIsChildOfRooms(panoElement?.id ?? ""),
  );

  const labelFormatter = useCallback(
    (iElement: IElement) => {
      const videoRecording = selectAncestor(
        iElement,
        isIElementVideoRecording,
      )(store.getState());

      const referenceElement = videoRecording ?? iElement;

      return (
        <NoTranslate>
          {convertToDateTimeString(referenceElement.createdAt)} -{" "}
          {referenceElement.name}
        </NoTranslate>
      );
    },
    [store],
  );

  // Formatter for the pano label (name + timestamp)
  const panoLabelFormatter = useCallback(
    (iElement: IElement) => {
      const referenceElement = selectPanoReferenceElement(iElement)(
        store.getState(),
      );

      return (
        <NoTranslate>
          {referenceElement.name}{" "}
          {convertToDateTimeString(referenceElement.createdAt)}
        </NoTranslate>
      );
    },
    [store],
  );

  // Check if datasets contains referenceElement since it could be a room and we don't
  // want to show it
  if (datasets.length === 0 || !selectedElement) {
    return null;
  }

  // If the active element is a pano from a room, show only its name and timestamp.
  // Otherwise, show both the name of the dataset the pano is from and the name of the pano itself.
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: neutral[999],
        zIndex: 0,
        alignItems: "center",
        padding: "5px 12px",
        gap: 0.75,
        borderRadius: "4px",
      }}
    >
      <TimestampDropdown
        value={selectedElement.id}
        options={datasets}
        onChange={(e) => onDataSessionChanged(e.target.value)}
        labelFormatter={isRoomPano ? panoLabelFormatter : labelFormatter}
      />
      {shouldShowPanoInfo && !isRoomPano && panoElement && (
        <TimestampDropdownOptionLabel
          iElement={panoElement}
          labelFormatter={panoLabelFormatter}
          sx={{
            color: neutral[400],
            maxWidth: activeMode === "split" ? "150px" : "180px",
          }}
        />
      )}
    </Stack>
  );
}

/**
 * Function to select the pano reference element to show the info in the dropdown
 * For odometry paths, the reference element are the panos themselves
 * Otherwise, the reference element is the section containing the panos
 *
 * @param iElement the current element
 * @returns The pano reference element
 */
function selectPanoReferenceElement(iElement: IElement) {
  return (state: RootState): IElement => {
    const section = selectAncestor(iElement, isIElementSection)(state);

    if (!!section && isIElementOdometryPath(section)) {
      return iElement;
    }

    return section ?? iElement;
  };
}
