import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import { AlignmentViewLayout } from "@/store/modes/alignment-ui-types";
import {
  selectCloudAndCadAlignReference,
  selectCloudForCadAlignment,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentLayout,
  selectCloudToCadAlignmentModelElevation,
  selectCloudToCadAlignmentStep,
  selectIncrementalCloudTransform,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  AlignmentReference,
  CloudToCadAlignmentStep,
  resetCloudToCadAlignment,
  setCloudToCadAlignmentLayout,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { setClippingBoxEnabled } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { alignAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { useBoxControlsContext } from "@/utils/box-controls-context";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  useNonExhaustiveEffect,
  ViewDiv,
} from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { isIElementBimModelSection } from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIElement,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import {
  createMutationSetElementMetaData,
  createMutationSetElementPosition,
  createMutationSetElementRotation,
  createMutationSetElementScale,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/system";
import { useCallback } from "react";
import {
  alignmentTransformToMatrix4,
  IDENTITY,
  matrix4ToAlignmentTransform,
} from "../alignment-modes-commons/alignment-transform";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { CloudToCadAlignmentProgressBar } from "./cloud-to-cad-alignment-progress-bar";
import { CloudToCadAlignmentSplitScreen } from "./cloud-to-cad-alignment-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function CloudToCadAlignmentModeOverlay(): JSX.Element {
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const activeCloudID = useAppSelector(selectCloudForCadAlignment);
  assert(activeCloudID, "point cloud for alignment not defined");

  const activeCad = useAppSelector(selectActiveCadModel);
  assert(activeCad, "Cad model for alignment not defined");

  const reference = useAppSelector(selectCloudAndCadAlignReference);

  const { clippingPlanes, setClippingPlanes } = useBoxControlsContext();

  // store original clippingPlanes when entering to sheet-to-cad alignment mode and restoring original value back at return
  useNonExhaustiveEffect(() => {
    const originalClippingPlanes = clippingPlanes;
    setClippingPlanes(undefined);

    return () => {
      setClippingPlanes(originalClippingPlanes);
      dispatch(setClippingBoxEnabled(false));
    };
  }, []);

  const applyMutation = useCallback(async () => {
    dispatch(setShowSpinner(true));

    const cloudElevation = selectCloudToCadAlignmentCloudElevation(
      store.getState(),
    );
    const modelElevation = selectCloudToCadAlignmentModelElevation(
      store.getState(),
    );

    const initialElementWorldMatrix = selectIElementWorldMatrix4(
      reference === AlignmentReference.bimModel ? activeCloudID : activeCad.id,
    )(store.getState());

    const initialElementWorldTransform = matrix4ToAlignmentTransform(
      initialElementWorldMatrix,
    );

    if (reference === AlignmentReference.bimModel) {
      // use new cloutobim end point to apply alignment to point cloud
      const transformToApply = alignmentTransformToMatrix4(
        selectIncrementalCloudTransform(store.getState()) ?? IDENTITY,
      ).multiply(initialElementWorldMatrix);

      const alignmentTransform = matrix4ToAlignmentTransform(transformToApply);
      alignmentTransform.position[1] =
        initialElementWorldTransform.position[1] +
        modelElevation -
        cloudElevation;

      const activeCloud = selectIElement(activeCloudID)(store.getState());
      assert(activeCloud, "Cloud for alignment not defined");

      const cloudWorldTransform = selectIElementProjectApiLocalPose(
        activeCloud,
        alignmentTransformToMatrix4(alignmentTransform),
      )(store.getState());

      try {
        const response = await client.applyCloudToBimAlignment({
          pointCloudId: activeCloudID,
          bimModelId: activeCad.id,
          pointCloudPose: {
            isWorldPose: true,
            pos: cloudWorldTransform.pos,
            rot: cloudWorldTransform.rot,
            scale: cloudWorldTransform.scale,
          },
          cloudToBimElevation: modelElevation,
        });

        dispatch(
          updateProject({
            projectApi: client,
            iElementQuery: {
              // Refresh the area node to get new transform
              ancestorIds: response.modifiedIElements.map(
                (element) => element.id,
              ),
            },
          }),
        );

        dispatch(
          alignAnalysis({
            parentCloudId: activeCloudID,
            alignMatrix: alignmentTransformToMatrix4(alignmentTransform)
              .multiply(initialElementWorldMatrix.invert())
              .toArray(),
          }),
        );

        openToast({
          title: "Alignment Completed",
          variant: "success",
        });
        dispatch(setShowSpinner(false));
        dispatch(resetCloudToCadAlignment());
      } catch (error) {
        handleErrorWithToast({
          title: "Failed to save new alignment",
          error,
        });
        dispatch(setShowSpinner(false));
        return;
      }
      dispatch(changeMode("overview"));
      return;
    }

    // CAD to cloud alignment
    const parentSection = selectAncestor(
      selectIElement(activeCad.id)(store.getState()),
      isIElementBimModelSection,
    )(store.getState());
    assert(parentSection, "Dataset section for alignment not found");

    const sectionWorldMatrix = selectIElementWorldMatrix4(parentSection.id)(
      store.getState(),
    );

    const transformToApply = alignmentTransformToMatrix4(
      selectIncrementalCloudTransform(store.getState()) ?? IDENTITY,
    ).multiply(sectionWorldMatrix);

    const alignmentTransform = matrix4ToAlignmentTransform(transformToApply);
    alignmentTransform.position[1] =
      initialElementWorldTransform.position[1] +
      cloudElevation -
      modelElevation;

    const mutationTransform = selectIElementProjectApiLocalPose(
      parentSection,
      alignmentTransformToMatrix4(alignmentTransform),
    )(store.getState());

    const mutations = [
      createMutationSetElementPosition(parentSection.id, mutationTransform.pos),
      createMutationSetElementRotation(parentSection.id, mutationTransform.rot),
      createMutationSetElementScale(parentSection.id, {
        x: 1,
        y: 1,
        z: 1,
      }),

      createMutationSetElementMetaData(activeCad.id, [
        {
          key: "alignToCloudId",
          value: activeCloudID,
          skipIfPresent: false,
        },
        {
          key: "cadToCloudElevation",
          value: cloudElevation,
          skipIfPresent: false,
        },
      ]),
    ];

    try {
      await client.applyMutations(mutations);

      // Fetch the changed section area sub-tree and update the local copy of the project
      // that new alignment will be used without reloading whole project
      dispatch(
        updateProject({
          projectApi: client,
          iElementQuery: {
            // Refresh the area node to get new transform
            ancestorIds: [parentSection.id],
          },
        }),
      );

      // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
      dispatch(resetCloudToCadAlignment());

      dispatch(setShowSpinner(false));
      openToast({
        title: "Alignment Completed",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      dispatch(setShowSpinner(false));

      return;
    }
    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));
  }, [
    dispatch,
    reference,
    activeCloudID,
    activeCad.id,
    client,
    handleErrorWithToast,
    openToast,
  ]);

  const step = useAppSelector(selectCloudToCadAlignmentStep);
  const alignmentLayout = useAppSelector(selectCloudToCadAlignmentLayout);

  const changeAlignmentScreenLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setCloudToCadAlignmentLayout(value)),
    [dispatch],
  );

  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <CloudToCadAlignmentProgressBar apply={applyMutation} />

      {(step === CloudToCadAlignmentStep.setElevations ||
        alignmentLayout === AlignmentViewLayout.splitScreen) && (
        <CloudToCadAlignmentSplitScreen />
      )}
      {step === CloudToCadAlignmentStep.alignIn2d &&
        alignmentLayout === AlignmentViewLayout.overlay && (
          <CloudToCloudAlignmentOverlay />
        )}

      {step === CloudToCadAlignmentStep.alignIn2d && (
        <AlignmentViewLayoutToggle
          alignmentLayout={alignmentLayout}
          changeAlignmentScreenLayout={changeAlignmentScreenLayout}
        />
      )}
    </Stack>
  );
}

/** @returns The sheet to cloud alignment elevation sub-view */
function CloudToCloudAlignmentOverlay(): JSX.Element {
  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          border: 3,
          borderColor: neutral[100],
          height: "100%",
        }}
      />
    </ViewDiv>
  );
}
