import { AlignmentTransform } from "@/modes/alignment-modes-commons/alignment-transform";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/modes/alignment-modes-commons/compute-split-screen-alignment";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "@/store/modes/alignment-ui-types";
import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";
import { CloudToSheetAlignmentStep } from "./cloud-to-sheet-alignment-mode-slice";

/**
 * @returns the current cloud-to-sheet alignment step
 */
export function selectAlignmentStepForCloudToSheetAlignment({
  cloudToSheetAlignmentMode,
}: RootState): CloudToSheetAlignmentStep {
  return cloudToSheetAlignmentMode.step;
}

/**
 * @param state  Current state
 * @returns ID of reference element (sheet) current session of cloud-to-sheet alignment
 */
export function selectReferenceElementIdForCloudToSheetAlignment(
  state: RootState,
): GUID | undefined {
  return state.cloudToSheetAlignmentMode.referenceElementId;
}

/**
 * @param state  Current state
 * @returns ID of aligned element (cloud) current session of cloud-to-sheet alignment
 */
export function selectAlignedElementIdForCloudToSheetAlignment(
  state: RootState,
): GUID | undefined {
  return state.cloudToSheetAlignmentMode.alignedElementId;
}

/**
 * @returns the current cloud-to-sheet alignment sheet elevation
 */
export function selectElevationForCloudToSheetAlignment({
  cloudToSheetAlignmentMode,
}: RootState): number {
  return cloudToSheetAlignmentMode.elevation;
}

/**
 * @returns the current cloud-to-sheet alignment isClippingBoxEnabled
 */
export function selectClippingBoxEnabledForCloudToSheetAlignment({
  cloudToSheetAlignmentMode,
}: RootState): boolean {
  return cloudToSheetAlignmentMode.isClippingBoxEnabled;
}

/**
 * @returns the current flag isCrossSectionEnabled for cloud-to-sheet alignment
 */
export function selectCrossSectionEnabledForCloudToSheetAlignment({
  cloudToSheetAlignmentMode,
}: RootState): boolean {
  return cloudToSheetAlignmentMode.isCrossSectionEnabled;
}

/**
 * @param state  Current state
 * @returns  anchor positions in split screen for cloud-to-sheet alignment
 */
export function selectAnchorPositionsForCloudToSheetAlignment(
  state: RootState,
): TwoPointsPairsAlignmentAnchorPositions {
  return state.cloudToSheetAlignmentMode.alignmentAnchorPositions;
}

/**
 * @param state  Current state
 * @returns incremental cloud-to-sheet transform in CS of previous pose or undefined if position/scale was not modified
 */
export function selectIncrementalTransformForCloudToSheetAlignment(
  state: RootState,
): AlignmentTransform | undefined {
  return state.cloudToSheetAlignmentMode.incrementalTransform;
}

/**
 * @returns the current cloud-to-sheet alignment screen layout
 */
export function selectLayoutForCloudToSheetAlignment({
  cloudToSheetAlignmentMode,
}: RootState): AlignmentViewLayout {
  return cloudToSheetAlignmentMode.alignmentLayout;
}

/**
 * @returns the current alignment screen split direction
 */
export function selectSplitDirectionForCloudToSheetAlignment({
  cloudToSheetAlignmentMode,
}: RootState): SplitDirection {
  return cloudToSheetAlignmentMode.splitDirection;
}
