import { EventType } from "@/analytics/analytics-events";
import { selectAreaSections } from "@/components/ui/area-navigation/area-navigation-utils";
import { DeleteElementDialogContent } from "@/components/ui/delete-element-dialog-content";
import { ElementIconType } from "@/components/ui/icons";
import { selectAllSheets, selectSheetForElement } from "@/modes/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { TranslateVar } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementBimModelSection,
  isIElementGenericImgSheet,
  isIElementOverviewImage,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  removeIElement,
  selectAncestor,
  selectIElement,
} from "@faro-lotv/project-source";
import { createMutationDeleteElement } from "@faro-lotv/service-wires";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";
import { isElementInUse } from "../utils";

export const DELETE_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.delete,
  label: "Delete",
  icon: ElementIconType.DeleteIcon,
  disabledMessageForNode: (element, state) => {
    if (isIElementOverviewImage(element)) {
      return "Overview map can't be deleted";
    }

    if (isIElementGenericImgSheet(element)) {
      const parentArea = selectAncestor(element, isIElementAreaSection)(state);
      if (!parentArea) {
        return "Area doesn't have any layer to remove";
      }
      const sheets = selectAllSheets(parentArea)(state);

      if (sheets.length <= 1) {
        return "At least one layer must remain";
      }
    }
  },
  handler: async ({
    elementID,
    state,
    dispatch,
    apiClients,
    createDialog,
    errorHandlers,
  }) => {
    const el = selectIElement(elementID)(state);
    if (el && isIElementSectionDataSession(el)) {
      Analytics.track(EventType.deletePointCloud);
    }

    assert(
      el,
      "Delete action is not supposed to be called for an undefined element",
    );

    assert(
      !el.parentId || !isIElementBimModelSection(el),
      "Delete action is not supposed to be called for a 3D Model element",
    );

    const deleteMutation = createMutationDeleteElement(elementID, true);

    // Create a delete confirmation dialog
    const hasConfirmed = await createDialog({
      title: "Delete Element?",
      confirmText: "Delete",
      content: (
        <DeleteElementDialogContent
          name={<TranslateVar name="elementName">{el.name}</TranslateVar>}
        />
      ),
      variant: "danger",
    });

    if (!hasConfirmed) return;

    // Prevent crash when removing the element from overview mode.
    // TODO: create generic way of exiting mode when require data for the mode is removed. https://faro01.atlassian.net/browse/SWEB-1334
    if (isElementInUse(el, state)) {
      const sheet = selectSheetForElement(el)(state);

      // Check if the current element is a parent of the current sheet
      const parentElement = selectAncestor(
        sheet,
        (ancestor) => el.id === ancestor.id,
      )(state);
      // If the current element is an area, change the active area
      if (parentElement && isIElementAreaSection(parentElement)) {
        const areas = selectAreaSections(state);
        const activeArea = areas.find((a) => a.id !== parentElement.id);
        dispatch(setActiveElement(activeArea?.id));
        dispatch(changeMode("start"));
      } else {
        assert(sheet, "A sheet must be available in the project");
        dispatch(setActiveElement(sheet.id));
        dispatch(changeMode("sheet"));
      }
    }

    // Delete the element from the project.
    apiClients.projectApiClient
      .applyMutations([deleteMutation])
      .then(() => {
        // TODO: Delete the time series containing the point cloud if it has not any remaining child
        // Ticket: https://faro01.atlassian.net/browse/SWEB-1737
        // Update store after removing the element.
        dispatch(removeIElement(elementID));
      })
      .catch((error) => {
        errorHandlers.handleErrorWithToast({
          title: `Failed to remove "${el.name}"`,
          error,
        });
      });
  },
};
