import { PointCloudFormat } from "@faro-lotv/service-wires";

/**
 * @param format to check
 * @param density of the exported point cloud defined as min distance between points in millimeters
 * @returns the max exportable volume in that format or undefined for no limit
 */
export function computeExportLimit(
  format: PointCloudFormat,
  density: number,
): number | undefined {
  return format.volumeLimit
    ? Math.floor(format.volumeLimit) * Math.pow(density, 2)
    : undefined;
}

/**
 * @param format for the pc export
 * @param density of the pc export (min distance between points in millimeters)
 * @param exportVolume to export
 * @returns true if the volume can be exported with the selected format and density
 */
export function canExport(
  format: PointCloudFormat,
  density: number | undefined,
  exportVolume: number,
): boolean {
  const limit = computeExportLimit(format, density ?? DEFAULT_DENSITY_VALUE);
  return !limit || limit >= exportVolume;
}

/**
 * The default point density value, in millimeters. This is only used for the export limit calculation,
 * and for an error message saying that the export limit has been exceeded. There is no corresponding
 * default value in the backend.
 */
export const DEFAULT_DENSITY_VALUE = 1;
