import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useUiOverlayContext } from "@/components/common/ui-overlay-provider";
import { createAnnotationFields } from "@/components/ui/annotations/annotation-fields";
import { isExternalAnnotationData } from "@/components/ui/annotations/annotation-props";
import { createAttachments } from "@/components/ui/annotations/attachment-mutations";
import {
  CreateAnnotationForm,
  CreateAnnotationFormProps,
} from "@/components/ui/annotations/create-annotation-form";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useCurrentArea } from "@/modes/mode-data-context";
import { Measurement, removeMeasurement } from "@/store/measurement-tool-slice";
import { RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { deactivateTool, setAnnotationExpansion } from "@/store/ui/ui-slice";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  setObjectVisibility,
  ViewObjectTypes,
} from "@/store/view-options/view-options-slice";
import { selectMutationAddCamViewData } from "@/tools/annotation-camview-utils";
import { selectAddPolygonMutationData } from "@/tools/annotation-mutation-utils";
import { cameraToAnnotationCameraParams } from "@/tools/annotation-picker/use-create-single-point-annotation";
import { useUpdateVisibilityDistance } from "@/utils/use-update-visibility-distance";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import {
  IElementMeasurePolygon,
  IElementSection,
  IElementTypeHint,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import {
  IElementWithPose,
  selectAdvancedMarkupTemplateIds,
  selectIElementWorldPosition,
  selectRootIElement,
} from "@faro-lotv/project-source";
import {
  createMutationAddCamView,
  createMutationAddLabel,
  createMutationAddMarkup,
  createMutationAddMeasurePolygon,
  MutationAddMeasurePolygon,
} from "@faro-lotv/service-wires";
import { Dialog } from "@mui/material";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Matrix4, Vector3 } from "three";
import { MeasurementValuesField } from "../annotations/annotation-renderers/measurement-values-field";

type SetAnnotationToCreate = (annotation: Measurement | undefined) => void;

/**
 * This hook mounts and encapsulates all the logic to add a measurement to the project
 * as an annotation.
 *
 * @returns A function to save a measurement as a project annotation
 */
export function useAddMeasurementToProject(): SetAnnotationToCreate {
  // Define all variables needed to add the measurement to the project
  const appStore = useAppStore();
  const dispatch = useAppDispatch();

  const { handleErrorWithToast } = useErrorHandlers();

  const { area } = useCurrentArea();
  const currentUser = useAppSelector(selectCurrentUser);
  const projectApi = useCurrentProjectApiClient();
  const [annotationToCreate, setAnnotationToCreate] = useState<Measurement>();
  const root = useAppSelector(selectRootIElement);
  const camera = useThree((s) => s.camera);
  const updateVisibilityDistance = useUpdateVisibilityDistance();

  const saveMeasurement = useCallback<CreateAnnotationFormProps["onSave"]>(
    (data): Promise<void> => {
      async function createMeasurement(): Promise<void> {
        assert(
          !isExternalAnnotationData(data),
          "It's only possible to create a Sphere XG annotation from a measurement",
        );
        const {
          title,
          newAttachments,
          assignee,
          description,
          dueDate,
          status,
          tags,
        } = data;
        if (!root || !currentUser) return;
        if (!annotationToCreate) return;

        const appState = appStore.getState();

        assert(area, "Expected an area to be available");

        const mutation = createMeasurementMutation(
          appState,
          annotationToCreate,
          area,
        );
        if (!mutation) return;
        const { addMeasureMutation, parentId, worldMatrixInverse } = mutation;

        const templateIds = selectAdvancedMarkupTemplateIds(appState);
        assert(
          templateIds,
          "Expected project to have an advanced markup template",
        );

        const markupId = generateGUID();
        const markupFields = createAnnotationFields({
          assignee,
          status,
          dueDate,
          ...templateIds,
          markupId,
          currentUserId: currentUser.id,
          rootId: root.id,
        });

        const addMarkupMutation = createMutationAddMarkup({
          id: markupId,
          templateId: templateIds.templateId,
          rootId: root.id,
          name: title,
          description: description ?? "",
          annotationId: addMeasureMutation.newElement.id,
          markupFields,
        });

        const attachmentMutations = createAttachments(
          root.id,
          markupId,
          newAttachments,
        );

        const tagsMutations =
          tags?.map((tag) => createMutationAddLabel(markupId, tag.id)) ?? [];

        const extras: IElementWithPose[] = addMeasureMutation.group
          ? [addMeasureMutation.group]
          : [];
        extras.push(addMeasureMutation.newElement);
        const camViewMutationData = selectMutationAddCamViewData(
          cameraToAnnotationCameraParams(camera),
          addMeasureMutation.newElement.id,
          root.id,
          worldMatrixInverse,
          extras,
        )(appState);

        const camViewMutation = createMutationAddCamView(camViewMutationData);

        const results = await projectApi.applyMutations([
          addMeasureMutation,
          addMarkupMutation,
          ...attachmentMutations,
          ...tagsMutations,
          camViewMutation,
        ]);

        if (results.some((r) => r.status !== "success")) {
          return;
        }

        setAnnotationToCreate(undefined);

        // Update the IElement tree
        await dispatch(
          updateProject({
            projectApi,
            iElementQuery: {
              // We only need to fetch the subtree starting from the targetIElement
              ancestorIds: [parentId],
            },
          }),
        );

        // Remove the measurement from the store since it's in the project now
        dispatch(
          removeMeasurement({
            elementID: annotationToCreate.parentId,
            measurementID: annotationToCreate.id,
          }),
        );

        // Auto expand the new annotation
        dispatch(setAnnotationExpansion({ id: markupId, expanded: true }));

        // Ensure the new generated annotation is visible
        const markupPosition = selectIElementWorldPosition(markupId)(
          appStore.getState(),
        );
        updateVisibilityDistance(
          camera,
          new Vector3().fromArray(markupPosition),
        );
        dispatch(
          setObjectVisibility({
            type: ViewObjectTypes.annotations,
            visibility: true,
          }),
        );

        // Disable possible active tools to make sure the new created measurement is visible
        dispatch(deactivateTool());
      }
      return createMeasurement().catch((error) => {
        handleErrorWithToast({
          title: "Could not create annotation",
          error,
        });
      });
    },
    [
      annotationToCreate,
      appStore,
      area,
      camera,
      currentUser,
      dispatch,
      handleErrorWithToast,
      projectApi,
      root,
      updateVisibilityDistance,
    ],
  );

  const onClose = useCallback(() => setAnnotationToCreate(undefined), []);
  useCreateMeasurementForm(annotationToCreate, onClose, saveMeasurement);

  return setAnnotationToCreate;
}

type MutationCreationResult = {
  addMeasureMutation: MutationAddMeasurePolygon;
  parentId: GUID;
  worldMatrixInverse: Matrix4;
};

/**
 * Compute the mutation and all the necessary parameters to add the measurement polygon to the project
 *
 * @param appState The app state
 * @param annotationToCreate The measurement to store in the project
 * @param area The current area of the project
 * @returns The mutation to add the measurement to project and id of the parent element
 */
function createMeasurementMutation(
  appState: RootState,
  annotationToCreate: Measurement,
  area: IElementSection,
): MutationCreationResult | undefined {
  const polygonMutationData = selectAddPolygonMutationData(
    annotationToCreate,
    area,
  )(appState);
  if (!polygonMutationData) return;

  const { mutationData, worldMatrixInverse } = polygonMutationData;

  // Create the actual mutation
  const addMeasureMutation = createMutationAddMeasurePolygon({
    ...mutationData,
    name: annotationToCreate.metadata.name,
    isClosed: annotationToCreate.metadata.isLoop,
    typeHint: isIElementGenericImgSheet(mutationData.targetElement)
      ? IElementTypeHint.mapMeasurement
      : IElementTypeHint.spaceMeasurement,
  });

  return {
    addMeasureMutation,
    parentId: mutationData.sectionId,
    worldMatrixInverse,
  };
}

function useCreateMeasurementForm(
  measurement: Measurement | undefined,
  onClose: CreateAnnotationFormProps["onClose"],
  onSave: CreateAnnotationFormProps["onSave"],
): void {
  const annotationToCreate:
    | Pick<IElementMeasurePolygon, "points" | "isClosed">
    | undefined = useMemo(
    () =>
      measurement
        ? {
            points: measurement.points.map((p) => ({
              // TODO: Remove usage of null from the polygon point states - https://faro01.atlassian.net/browse/SWEB-4163
              state: null,
              x: p[0],
              y: p[1],
              z: p[2],
            })),
            isClosed: measurement.metadata.isLoop,
          }
        : undefined,
    [measurement],
  );

  const { setContent } = useUiOverlayContext();

  useEffect(() => {
    if (!annotationToCreate) return;

    setContent(
      <Dialog
        open
        PaperProps={{
          sx: { p: 1, background: "transparent" },
          elevation: 0,
        }}
      >
        <CreateAnnotationForm onClose={onClose} onSave={onSave}>
          <MeasurementValuesField measurement={annotationToCreate} />
        </CreateAnnotationForm>
      </Dialog>,
    );

    return () => setContent(undefined);
  }, [annotationToCreate, onClose, onSave, setContent]);
}
