import { EventType } from "@/analytics/analytics-events";
import { Mode, ModeTransitionProps } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { selectElementToAlignWithControlPointsAlignment } from "@/store/modes/control-points-alignment-mode-selectors";
import {
  resetControlPointsAlignment,
  setControlPointsSheetElevation,
} from "@/store/modes/control-points-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { Analytics } from "@faro-lotv/foreign-observers";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useEffect, useMemo } from "react";
import {
  AlignmentTransform,
  matrix4ToAlignmentTransform,
} from "../alignment-modes-commons/alignment-transform";
import { ControlPointsAlignmentModeOverlay } from "./control-points-alignment-mode-overlay";
import { ControlPointsAlignmentModeScene } from "./control-points-alignment-mode-scene";

export const controlPointsAlignmentMode: Mode = {
  name: "controlPointsAlignment",
  ModeScene: ControlPointsAlignmentModeScene,
  ModeOverlay: ControlPointsAlignmentModeOverlay,
  hasCustomCanvasStyle: true,
  ModeTransition: ControlPointsAlignmentModeTransition,
  exclusive: {
    title: "Align using control points",
    onBack({ dispatch, store }) {
      const storeState = store.getState();
      const elementToAlignId =
        selectElementToAlignWithControlPointsAlignment(storeState);
      const alignedElement = selectIElement(elementToAlignId)(storeState);

      dispatch(changeMode("start"));
      dispatch(resetControlPointsAlignment());

      Analytics.track(
        alignedElement && isIElementAreaSection(alignedElement)
          ? EventType.exitControlPointsAlignmentArea
          : EventType.exitControlPointsAlignmentLayer,
      );

      return Promise.resolve();
    },
  },
};

function ControlPointsAlignmentModeTransition({
  onCompleted,
}: ModeTransitionProps): null {
  const elementToAlignId = useAppSelector(
    selectElementToAlignWithControlPointsAlignment,
  );

  const alignedLayerWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(elementToAlignId),
  );

  const alignedLayerTransform = useMemo(
    (): AlignmentTransform =>
      matrix4ToAlignmentTransform(alignedLayerWorldMatrix),
    [alignedLayerWorldMatrix],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setControlPointsSheetElevation(alignedLayerTransform.position[1]));
    onCompleted();
  }, [alignedLayerTransform.position, dispatch, onCompleted]);

  return null;
}
