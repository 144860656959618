import { EventType } from "@/analytics/analytics-events";
import {
  getBcfIntegrationTypeForIElementType,
  getExternalAnnotationProviderName,
} from "@/components/ui/annotations/external-annotation-utils";
import { DeleteElementDialogContent } from "@/components/ui/delete-element-dialog-content";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { useDialog } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  ExternalMarkupIElement,
  IElementMarkup,
  isIElementExternalMarkup,
  isIElementGenericAnnotation,
} from "@faro-lotv/ielement-types";
import { removeIElement, selectAncestor } from "@faro-lotv/project-source";
import {
  createMutationDeleteElement,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";

export type UseDeleteAnnotationReturn = {
  /** Function to call when the annotation needs to be removed */
  removeAnnotation(): Promise<void>;

  /** True while the mutation is in progress */
  isRemovingAnnotation: boolean;
};

/**
 * Return a function to remove an annotation and a flag to know when the project is mutating
 *
 * @param markup the markup whose annotation must be removed
 * @returns the function to call to remove the annotation and a flag to check for project mutations
 */
export function useDeleteAnnotation(
  markup: IElementMarkup | ExternalMarkupIElement,
): UseDeleteAnnotationReturn {
  const { projectApiClient } = useApiClientContext();
  const { createDialog } = useDialog();
  const [isRemovingAnnotation, setIsRemoving] = useState(false);
  const { handleErrorWithToast } = useErrorHandlers();
  const dispatch = useAppDispatch();
  const externalAnnotationProviderName = isIElementExternalMarkup(markup)
    ? getExternalAnnotationProviderName(
        getBcfIntegrationTypeForIElementType(markup.type),
      )
    : undefined;
  const externalAnnotationWarningMessage = `The corresponding annotation saved on ${externalAnnotationProviderName} will not be deleted.`;

  // At app startup, 'annotationToDelete' may also be undefined because not all
  // markups' parents are loaded.
  const annotationToDelete = useAppSelector(
    selectAncestor(markup, isIElementGenericAnnotation),
  );

  const removeAnnotation = useCallback(async () => {
    const shouldRemove = await createDialog({
      title: "Delete Element?",
      confirmText: "Delete",
      content: (
        <DeleteElementDialogContent
          name="the annotation"
          warningMessage={
            externalAnnotationProviderName
              ? externalAnnotationWarningMessage
              : undefined
          }
          dark
        />
      ),
      variant: "danger",
      dark: true,
    });

    if (!shouldRemove) {
      Analytics.track(EventType.cancelAnnotationDeletion);
      return;
    }

    Analytics.track(EventType.confirmAnnotationDeletion);

    // Preemptive hide the annotation
    setIsRemoving(true);
    try {
      assert(
        annotationToDelete,
        "The annotation cannot be removed because its parent was not loaded",
      );
      await projectApiClient.applyMutations([
        createMutationDeleteElement(annotationToDelete.id),
      ]);
      dispatch(removeIElement(annotationToDelete.id));
    } catch (error) {
      handleErrorWithToast({
        title: "Removal Failed",
        error,
      });
      // Show again if removal failed
      setIsRemoving(false);
    }
  }, [
    createDialog,
    externalAnnotationProviderName,
    externalAnnotationWarningMessage,
    annotationToDelete,
    projectApiClient,
    dispatch,
    handleErrorWithToast,
  ]);

  return {
    isRemovingAnnotation,
    removeAnnotation,
  };
}
