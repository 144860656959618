import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useSceneEvents } from "@/components/common/scene-events-context";
import { ElementIcon, ElementIconType } from "@/components/ui/icons";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectCanReadCAD } from "@/store/subscriptions/subscriptions-selectors";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  FaroIconButton,
  useCheckForOverflow,
  useDialog,
} from "@faro-lotv/flat-ui";
import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { TreeNode, TreeNodeProps } from "../tree-node";
import { TreePopUp } from "../tree-popup";
import {
  ContextMenuCadModelTree,
  deleteCad,
} from "./cad-model-tree-context-menu";
import { CadModelTreeNodeData } from "./cad-model-tree-data";
import {
  highlightModelTreeNode,
  isNodeVisible,
  setNodeVisibility,
  unhighlightCadObjects,
} from "./cad-model-visibility";

/**
 * @returns A node in the CAD model tree
 */
export function CadModelTreeNode({
  node,
  style,
}: TreeNodeProps<CadModelTreeNodeData>): JSX.Element {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();
  const { getState } = useAppStore();
  const dispatch = useAppDispatch();
  const projectApi = useCurrentProjectApiClient();
  const { createDialog } = useDialog();
  const { handleErrorWithDialog } = useErrorHandlers();

  // If the user has permission to edit the project or not
  const hasWritePermission = useAppSelector(selectHasWritePermission);
  const hasValidCadLicense = useAppSelector(selectCanReadCAD);

  const boxRef = useRef<HTMLDivElement>();

  const toggleContextMenu = useCallback(
    (open: boolean) => {
      if (isPopupOpen && open) {
        setIsPopupOpen(false);
      }
    },
    [isPopupOpen],
  );
  const highlightedCadObjects = useRef<number[]>([]);
  const cadSceneEvents = useSceneEvents();
  const visibilityIconActive = useRef(false);

  const highlightNodeIn3DScene = useCallback(() => {
    setIsPopupOpen(true);
    // After clicking on the eye icon, the entry event is triggered again,
    // and we don't want to highlight the node at the moment.
    if (visibilityIconActive.current) {
      return;
    }
    unhighlightCadObjects(
      node.data.cadModelData,
      highlightedCadObjects.current,
    );
    highlightedCadObjects.current.length = 0;
    highlightModelTreeNode(node.data, highlightedCadObjects.current);
    cadSceneEvents.invalidateCadScene.emit();
  }, [node, cadSceneEvents]);

  const unhighlightNodeIn3DScene = useCallback(() => {
    setIsPopupOpen(false);
    if (highlightedCadObjects.current.length > 0) {
      unhighlightCadObjects(
        node.data.cadModelData,
        highlightedCadObjects.current,
      );
      highlightedCadObjects.current.length = 0;
      cadSceneEvents.invalidateCadScene.emit();
    }
    // reset the flag after move out of the node
    visibilityIconActive.current = false;
  }, [node.data, cadSceneEvents]);

  const changeNodeVisibility = useCallback(() => {
    setNodeVisibility(node.data, !node.data.visibility);
    if (highlightedCadObjects.current.length > 0) {
      unhighlightCadObjects(
        node.data.cadModelData,
        highlightedCadObjects.current,
      );
      highlightedCadObjects.current.length = 0;
    }
    cadSceneEvents.invalidateCadScene.emit();
    // Mark the flag to prevent the entry event from highlighting the CAD again
    visibilityIconActive.current = true;
  }, [node.data, cadSceneEvents.invalidateCadScene]);

  const deleteCadCallback = useCallback(async () => {
    if (node.data.idIElementModel3dStream) {
      try {
        await deleteCad(
          node.data.idIElementModel3dStream,
          getState(),
          dispatch,
          projectApi,
          createDialog,
        );
      } catch (error) {
        handleErrorWithDialog({
          title: "Error: failed to delete the 3D Model",
          error,
        });
      }
    }
  }, [
    createDialog,
    dispatch,
    getState,
    handleErrorWithDialog,
    node.data.idIElementModel3dStream,
    projectApi,
  ]);

  const getNodeIcon = useCallback(
    (data: CadModelTreeNodeData) => {
      if (!hasValidCadLicense) return ElementIconType.DeleteIcon;

      return isNodeVisible(data)
        ? ElementIconType.Visible
        : ElementIconType.NonVisible;
    },
    [hasValidCadLicense],
  );

  return (
    <Box
      ref={boxRef}
      component="div"
      style={{ ...style, height: "100%" }}
      onMouseEnter={highlightNodeIn3DScene}
      onMouseLeave={unhighlightNodeIn3DScene}
    >
      <TreePopUp
        parentRef={boxRef}
        open={isPopupOpen}
        title={node.data.name}
        isTooltipEnabled={hasOverflown}
      >
        <TreeNode<CadModelTreeNodeData>
          node={node}
          shouldExpandNodeOnClick={false}
        >
          <Stack
            direction="row"
            flexGrow={1}
            alignItems="center"
            overflow="hidden"
          >
            <Typography
              ml={1}
              flexGrow={1}
              fontSize="0.875em"
              noWrap
              onMouseEnter={(e) => checkForOverflow(e.currentTarget)}
            >
              {node.data.name}
            </Typography>
          </Stack>
          <FaroIconButton
            onClick={
              hasValidCadLicense ? changeNodeVisibility : deleteCadCallback
            }
            disabled={!hasWritePermission && !hasValidCadLicense}
            margin={0.5}
            size="s"
            aria-label="toggle model visibility"
            // Get both the image color and its hover color from the container
            color="inherit"
            hoverColor="inherit"
            // Change the button color to forward the container's color to the image
            sx={{ color: "inherit" }}
          >
            <ElementIcon icon={getNodeIcon(node.data)} />
          </FaroIconButton>
          {hasValidCadLicense && (
            <ContextMenuCadModelTree
              onToggle={toggleContextMenu}
              idIElementModel3dStream={node.data.idIElementModel3dStream}
              modelMessages={node.data.modelMessages}
            />
          )}
        </TreeNode>
      </TreePopUp>
    </Box>
  );
}
