import { RootState } from "@/store/store";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  convertIElementToThreeTransform,
  parseQuaternion,
  parseVector3,
  quaternionToVector4Tuple,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { IElement, IPose } from "@faro-lotv/ielement-types";
import {
  Matrix4,
  Quaternion,
  Vector3,
  Vector3Tuple,
  Vector4Tuple,
} from "three";

/** Type of the action used for loading the WebShare projects */
export const loadWebShareProjectsAction = "alignment/fetchWebShareProjects";

/**
 * Type to store the modified transform during the use of the tool
 */
export type AlignmentTransform = {
  position: Vector3Tuple;
  quaternion: Vector4Tuple;
  scale: Vector3Tuple;
};

/**
 * Identity transform
 *
 * Used also as default initial transformation to apply to a Webshare PointCloud to be used in the
 * alignment tool.
 *
 * As Webshare PointClouds are defined in a Z-UP coordinate system we need to rotate them
 * to an Y-UP coordinate system to then work properly in a Sphere project where the rest of
 * the data comes from the HoloBuilder ProjectAPI that assumes a Y-UP coordinate system instead
 */
export const IDENTITY: AlignmentTransform = {
  position: [0, 0, 0],
  quaternion: [0, 0, 0, 1],
  scale: [1, 1, 1],
};

/**
 * Converts an AlignmentTransform to a Matrix4
 *
 * @param alignmentTransform The transform to convert
 * @returns A Matrix4 describing the alignmentTransform
 */
export function alignmentTransformToMatrix4(
  alignmentTransform: AlignmentTransform,
): Matrix4 {
  return new Matrix4().compose(
    parseVector3(alignmentTransform.position),
    parseQuaternion(alignmentTransform.quaternion),
    parseVector3(alignmentTransform.scale),
  );
}

/**
 * Converts an Matrix4 to a AlignmentTransform
 *
 * @param matrix4 The transform matrix to convert
 * @returns An AlignmentTransform describing the matrix4 transform
 */
export function matrix4ToAlignmentTransform(
  matrix4: Matrix4,
): AlignmentTransform {
  const translation = new Vector3();
  const quaternion = new Quaternion();
  const scale = new Vector3();

  matrix4.decompose(translation, quaternion, scale);

  return {
    position: translation.toArray(),
    quaternion: quaternionToVector4Tuple(quaternion),
    scale: scale.toArray(),
  };
}

/**
 * Convert an IElement IPose to an AlignmentTransform
 *
 * @param pose the pose to convert
 * @returns An AlignmentTransform describing that pose
 */
export function iElementTransformToAlignmentTransform(
  pose: IPose | null,
): AlignmentTransform {
  return convertIElementToThreeTransform(pose);
}

/**
 * Convert a transform to the coordinate system of a parent element.
 *
 * @param parentWorld The world transform of the parent element.
 * @param childWorld The world transform of the child element.
 * @returns The local transform of the child element, in the space of the parent.
 */
export function worldToLocal(
  parentWorld: Matrix4,
  childWorld: Matrix4,
): Matrix4 {
  return childWorld.clone().premultiply(parentWorld.clone().invert());
}

/**
 * Calculate the new local transform of the element to change after alignment.
 *
 * @param alignElement The IElement that was being aligned by the user.
 * @param worldNewAlignElement The new transform of the element aligned by the user, in world space.
 * @param persistenceElement The element where the new transform should be persisted. Must be an ancestor of `alignedElement`.
 * @param state Root state of the application.
 * @returns The new local transform of the element to apply, right-handed y-up.
 */
export function calculateAlignmentTransform(
  alignElement: IElement,
  worldNewAlignElement: Matrix4,
  persistenceElement: IElement,
  state: RootState,
): AlignmentTransform {
  assert(
    persistenceElement.parentId,
    `The element ${persistenceElement.id} to persist the transform to must have a parent`,
  );

  // Get the world transform of all involved elements
  // This is the _persisted_ transform, before the alignment
  const worldOldAlignElement = selectIElementWorldMatrix4(alignElement.id)(
    state,
  );
  const worldOldPersistenceElement = selectIElementWorldMatrix4(
    persistenceElement.id,
  )(state);
  const worldPersistenceElementParent = selectIElementWorldMatrix4(
    persistenceElement.parentId,
  )(state);
  // Determine how the align element was positioned in the persistence element before alignment
  const localOldAlignElement = worldToLocal(
    worldOldPersistenceElement,
    worldOldAlignElement,
  );
  // Determine how the persistence element has to be positioned to align the align element
  const worldNewPersistenceElement = worldNewAlignElement.multiply(
    localOldAlignElement.invert(),
  );
  // Get the local transform of the aligned persistence element, as required by the Project API
  const { pos, rot, scale } = selectIElementProjectApiLocalPose(
    persistenceElement,
    worldToLocal(worldPersistenceElementParent, worldNewPersistenceElement),
  )(state);
  return {
    position: [pos.x, pos.y, pos.z],
    quaternion: [rot.x, rot.y, rot.z, rot.w],
    scale: [scale.x, scale.y, scale.z],
  };
}
