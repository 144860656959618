import { useLoadProjectArea } from "@/components/common/project-provider/project-loading-context";
import { useCurrentAreaIfAvailable } from "@/modes/mode-data-context";
import { useAppStore } from "@/store/store-hooks";
import {
  GUID,
  IElement,
  IElementAreaSection,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { OpenMap } from "react-arborist/dist/module/state/open-slice";

type OpenAreasLoaderProps = {
  /** The map tracking which elements of tree have been expanded */
  openState: OpenMap;
};

/** @returns A non-visual component that loads the subtree of the expanded elements in a tree */
export function OpenAreasLoader({
  openState,
}: OpenAreasLoaderProps): JSX.Element {
  const store = useAppStore();

  // Exclude the current area to avoid making the volume queries twice
  const areaData = useCurrentAreaIfAvailable();
  const areas = useMemo(
    () =>
      Object.keys(openState)
        .map((e) => selectIElement(e)(store.getState()))
        .filter(isValidArea)
        .filter((a) => a.id !== areaData?.area?.id),
    [areaData?.area?.id, openState, store],
  );

  return (
    <>
      {areas.map(({ id }) => (
        <OpenAreaLoader key={id} id={id} />
      ))}
    </>
  );
}

/**
 * @returns True if the input element is a valid area section
 * @param element The IElement to check
 */
function isValidArea(
  element: IElement | undefined,
): element is IElementAreaSection {
  return !!element && isIElementAreaSection(element);
}

type OpenAreaLoaderProps = {
  /** The id of the iElement whose subtree should be loaded */
  id: GUID;
};

/**
 * @returns A non-visual component that loads the sub-tree of the iElement with the input id
 */
function OpenAreaLoader({ id }: OpenAreaLoaderProps): null {
  useLoadProjectArea(id);
  return null;
}
