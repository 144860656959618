import {
  blue,
  Checkmark2Icon,
  FaroChip,
  FaroRadio,
  FaroRadioGroup,
  FaroText,
  neutral,
  red,
  TranslateVar,
} from "@faro-lotv/flat-ui";
import { assert, validateEnumValue } from "@faro-lotv/foundation";
import { FloorplanGenerationMeasurementUnit } from "@faro-lotv/service-wires";
import { Link } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { PropsWithChildren, useState } from "react";

type GenerateFloorplanDialogProps = TokenInfoProps & {
  /** The initial state of the measurement unit. */
  initialMeasurementUnit: FloorplanGenerationMeasurementUnit;

  /** Callback when the user selects a different measurement unit. */
  onMeasurementUnitChanged(newUnit: FloorplanGenerationMeasurementUnit): void;
};

/** @returns Dialog contents for when the user has sufficient token funds for a floor plan generation. */
export function GenerateFloorPlanDialog({
  availableTokens,
  requiredTokens,
  initialMeasurementUnit,
  onMeasurementUnitChanged,
}: GenerateFloorplanDialogProps): JSX.Element {
  const [measurementUnit, setMeasurementUnit] = useState(
    initialMeasurementUnit,
  );

  return (
    <Stack gap={2}>
      <FaroText variant="bodyM">
        Use your available tokens to generate a floor plan. The floor plan
        generation will take 1-3 business days.
      </FaroText>

      <Box component="div">
        <FaroRadioGroup
          label="Units"
          value={measurementUnit}
          onChange={(ev) => {
            const newUnit = ev.target.value;
            assert(
              validateEnumValue(newUnit, FloorplanGenerationMeasurementUnit),
              "invalid measurement unit",
            );
            setMeasurementUnit(newUnit);
            onMeasurementUnitChanged(newUnit);
          }}
        >
          <FaroRadio
            value={FloorplanGenerationMeasurementUnit.metric}
            label="Metric"
          />
          <FaroRadio
            value={FloorplanGenerationMeasurementUnit.imperial}
            label="Imperial"
          />
        </FaroRadioGroup>
      </Box>

      <ActionCostInfo
        availableTokens={availableTokens}
        requiredTokens={requiredTokens}
      />
    </Stack>
  );
}

type RequestMoreTokensDialogProps = TokenInfoProps & {
  /** Whether the current user is allowed to buy tokens */
  isAllowedToBuyTokens: boolean;

  /** The URL to the subscriptions page where more tokens can be bought */
  subscriptionsUrl: string;
};

/** @returns Dialog contents for when the user has insufficient token funds for a floor plan generation */
export function RequestMoreTokensDialog({
  availableTokens,
  requiredTokens,
  isAllowedToBuyTokens,
  subscriptionsUrl,
}: RequestMoreTokensDialogProps): JSX.Element {
  return (
    <Stack gap={2}>
      <ActionCostInfo
        availableTokens={availableTokens}
        requiredTokens={requiredTokens}
      />

      {isAllowedToBuyTokens ? (
        <FaroText variant="bodyM">
          Manage your tokens in the{" "}
          <Link
            component="a"
            href={subscriptionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            subscription
          </Link>{" "}
          page.
        </FaroText>
      ) : (
        <FaroText variant="bodyM">
          To request more tokens you need to contact your workspace admin.
        </FaroText>
      )}
    </Stack>
  );
}

type FloorPlanGenerationStartedDialogProps = {
  /** The tokens remaining after the action has been performed. */
  remainingTokens: number;
};

/** @returns Dialog contents for when the generation has been started successfully. */
export function FloorPlanGenerationStartedDialog({
  remainingTokens,
}: FloorPlanGenerationStartedDialogProps): JSX.Element {
  return (
    <Stack gap={4} alignItems="center" textAlign="center">
      <FaroText variant="heading24">
        Thank you,
        <br /> your request has been sent.
      </FaroText>

      <BigCheckmark />

      <FaroText variant="bodyM">
        The floor plan will be ready within 1-3 business days.
        <br /> You'll receive an email when it's available for download.
      </FaroText>

      <TokenInfoBox availableTokens={remainingTokens} requiredTokens={0}>
        <FaroText variant="bodyM">Remaining tokens</FaroText>{" "}
      </TokenInfoBox>
    </Stack>
  );
}

/** @returns a big checkmark in a box to indicate success */
function BigCheckmark(): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        width: 84,
        height: 84,
        color: blue[500],
        background: neutral[100],
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Checkmark2Icon sx={{ width: 64, height: 64 }} />
    </Box>
  );
}

/** @returns A small info box informing the user about the cost of an action. */
function ActionCostInfo({
  availableTokens,
  requiredTokens,
}: TokenInfoProps): JSX.Element {
  return (
    <TokenInfoBox
      availableTokens={availableTokens}
      requiredTokens={requiredTokens}
    >
      <FaroText variant="bodyM">
        This action requires{" "}
        <b>
          <TranslateVar name="requiredTokens">{requiredTokens}</TranslateVar>{" "}
          Tokens.
        </b>
      </FaroText>
    </TokenInfoBox>
  );
}

type TokenInfoProps = {
  /** The token balance available to the user */
  availableTokens: number;

  /** The token balance required to perform the action */
  requiredTokens: number;
};

/** @returns a box to display text with the current available amount of tokens in a chip. */
function TokenInfoBox({
  availableTokens,
  requiredTokens,
  children,
}: PropsWithChildren<TokenInfoProps>): JSX.Element {
  const hasEnoughTokens = availableTokens >= requiredTokens;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background: neutral[100],
        border: `1px solid ${neutral[200]}`,
        borderRadius: 1,
        px: 0.75,
        py: 1.5,
        width: "100%",
      }}
    >
      {children}

      <FaroChip
        variant="outlined"
        label={
          <>
            <TranslateVar name="availableTokens">
              {availableTokens}
            </TranslateVar>{" "}
            tokens available
          </>
        }
        color={hasEnoughTokens ? blue[500] : red[500]}
        backgroundColor={hasEnoughTokens ? blue[50] : red[50]}
        borderColor={hasEnoughTokens ? blue[100] : red[100]}
      />
    </Stack>
  );
}
