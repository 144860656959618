import { RootState } from "@/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** State specific for placing a pin marker */
type PinMarkerState = {
  /**
   * The pin marker position in world space in meter. If no pin is added, the position is undefined.
   */
  position?: Vector3Tuple;

  /**
   * flag to indicate the pin is newly added
   * It is set to true when setting a pin marker and used to open the toolbar when a new pin is set.
   * It will be set to false when user click the pin to close the toolbar as well as when user switch to another mode.
   */
  isNewPin: boolean;
};

const initialState: PinMarkerState = { isNewPin: false };

const pinMarkerSlice = createSlice({
  initialState,
  name: "pinMarker",
  reducers: {
    setPinMarker(state, action: PayloadAction<Vector3Tuple | undefined>) {
      state.position = action.payload;
      state.isNewPin = true;
    },
    setIsNewPin(state, action: PayloadAction<boolean>) {
      state.isNewPin = action.payload;
    },
  },
});

export const { setPinMarker, setIsNewPin } = pinMarkerSlice.actions;

export const pinMarkerReducer = pinMarkerSlice.reducer;

/**
 * @returns pin marker position or undefined if not added
 */
export function selectPinMarker({
  pinMarker,
}: RootState): Vector3Tuple | undefined {
  return pinMarker.position;
}

/**
 * @returns if the pin is newly set
 */
export function selectIsNewPin({ pinMarker }: RootState): boolean {
  return pinMarker.isNewPin;
}
