import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import {
  FrontViewIcon,
  SideViewIcon,
  TopViewIcon,
} from "@/registration-tools/icons/manual-alignment-icons";
import {
  FaroTooltip,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { MouseEvent, useCallback } from "react";

type PerspectiveSwitchProps = {
  /** The currently active perspective. */
  perspective: Perspective;

  /** A callback to execute when the user changes the perspective. */
  onPerspectiveChange(perspective: Perspective): void;
};

/** @returns A toolbar to change the 2D perspective. */
export function PerspectiveSwitch({
  perspective,
  onPerspectiveChange,
}: PerspectiveSwitchProps): JSX.Element {
  const changePerspective = useCallback(
    (_event: MouseEvent, value: Perspective | null) => {
      if (value) {
        onPerspectiveChange(value);
      }
    },
    [onPerspectiveChange],
  );

  return (
    <Toolbar>
      <ToolGroup
        value={perspective}
        orientation="vertical"
        exclusive
        onChange={changePerspective}
      >
        <FaroTooltip title="Top view" placement="right">
          <ToolButton
            aria-label="point clouds top view"
            value={Perspective.topView}
            selected={perspective === Perspective.topView}
          >
            <TopViewIcon />
          </ToolButton>
        </FaroTooltip>
        <FaroTooltip title="Front view" placement="right">
          <ToolButton
            aria-label="point clouds front view"
            value={Perspective.frontView}
            selected={perspective === Perspective.frontView}
          >
            <FrontViewIcon />
          </ToolButton>
        </FaroTooltip>
        <FaroTooltip title="Side view" placement="right">
          <ToolButton
            aria-label="point clouds side view"
            value={Perspective.sideView}
            selected={perspective === Perspective.sideView}
          >
            <SideViewIcon />
          </ToolButton>
        </FaroTooltip>
      </ToolGroup>
    </Toolbar>
  );
}
