import {
  alignmentTransformToMatrix4,
  iElementTransformToAlignmentTransform,
} from "@/modes/alignment-modes-commons/alignment-transform";
import { selectBackgroundTasks } from "@/store/background-tasks/background-tasks-selector";
import { RootState } from "@/store/store";
import {
  C2CRegistrationTask,
  isC2CRegistrationTask,
} from "@/utils/background-tasks";
import {
  IElementGenericPointCloudStream,
  IPose,
} from "@faro-lotv/ielement-types";
import { selectIElementWorldTransform } from "@faro-lotv/project-source";
import { BackendJobId } from "@faro-lotv/service-wires";
import { chunk } from "es-toolkit";
import { Matrix4, Matrix4Tuple } from "three";

/**
 * @param jobId The ID of the job to register the point cloud.
 * @returns The RegistrationTask corresponding to the jobId, or `undefined`
 */
export function selectRegistrationTask(jobId: BackendJobId | undefined) {
  return (state: RootState): C2CRegistrationTask | undefined => {
    if (!jobId) {
      return;
    }
    const registrationTasks = selectBackgroundTasks(isC2CRegistrationTask)(
      state,
    );
    return registrationTasks.find(
      (task) => task.metadata !== undefined && task.metadata.jobId === jobId,
    );
  };
}

/**
 * Computes the transformation from model coordinate system to reference coordinate system formatted
 *  for direct passing to registration backend
 *
 * @param refWorldTransformArray the coordinate system of the reference point cloud
 * @param modelWorldTransformArray the coordinate system of the model point cloud
 * @returns the transformation from the model coordinate system to the reference coordinate system
 */
export function computeInitialTransformationFormatted(
  refWorldTransformArray: Matrix4Tuple,
  modelWorldTransformArray: Matrix4Tuple,
): number[][] {
  // Need to compute the correct transformation here
  // Reg Worker needs right-handed row major 4x4 matrix in form of a 2d array
  const refWorldTransform = new Matrix4().fromArray(refWorldTransformArray);
  const modelWorldTransform = new Matrix4().fromArray(modelWorldTransformArray);

  const modelToRefTransform = refWorldTransform
    .invert()
    .multiply(modelWorldTransform);

  return chunk(modelToRefTransform.transpose().toArray(), 4);
}

/**
 * Converts the transform received from the registration backend to world space (via the reference point cloud)
 *
 * @param refWorldTransform the world transform of the reference pointcloud
 * @param backendTransform the received backend transformation (relative model transformation)
 * @returns the world transform of the model pointcloud
 */
export function convertBackendTransformToWorldSpace(
  refWorldTransform: Matrix4,
  backendTransform: Matrix4,
): Matrix4 {
  // Convert the transform received to world space (via the reference point cloud)
  return (
    new Matrix4()
      .copy(refWorldTransform)
      // backend transformation is from the model cloud coordinate system
      // to the reference cloud coordinate system, hence we should revert it
      .multiply(backendTransform)
  );
}

/**
 * Calculates the world transform matrix for a given registration pose received from the registration backend with respect
 * to a reference point cloud.
 *
 * @param rawPose - The raw pose received (left-handed).
 * @param refPointCloud - The reference point cloud.
 * @param state - The application state.
 * @returns The calculated world transform matrix.
 */
export function getModelWorldTransform(
  rawPose: IPose,
  refPointCloud: IElementGenericPointCloudStream,
  state: RootState,
): Matrix4 {
  // convert the IPose into transform (also change from left to right handed coordinate system)
  const backendTransform = alignmentTransformToMatrix4(
    iElementTransformToAlignmentTransform(rawPose),
  );
  const refWorldTransform = selectIElementWorldTransform(refPointCloud.id)(
    state,
  );
  return convertBackendTransformToWorldSpace(
    new Matrix4().fromArray(refWorldTransform.worldMatrix),
    backendTransform,
  );
}
