import { RootState } from "@/store/store";
import { generateGUID, GUID } from "@faro-lotv/foundation";
import { IElementType, IElementTypeHint } from "@faro-lotv/ielement-types";
import {
  IElementWithPose,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import { CreateMutationAddCamView } from "@faro-lotv/service-wires";
import { Matrix4 } from "three";

/**
 * Type to encapsulate information on the camera view from which the annotation
 * was taken
 */
export type AnnotationCameraParameters = {
  /** The camera's global pose matrix */
  cameraPose: Matrix4;
  /** True if the camera is perspective, false if it is orthographic */
  perspective: boolean;
  /** FOV in degrees for perspective camera, frustum vertical size for orthographic */
  zoomLevel: number;
};

/**
 * @returns Data to perform the mutation that creates the camera view from which the annotation was taken
 * @param cameraParams The camera parameters
 * @param parentId The parent ID of the camera view
 * @param rootId The root ID of the project
 * @param worldMatrixInverse Transform from world coordinates to markup polygon local coordinates
 * @param extraElements Extra elements to consider when computing the project API pose
 */
export function selectMutationAddCamViewData(
  cameraParams: AnnotationCameraParameters,
  parentId: GUID,
  rootId: GUID,
  worldMatrixInverse: Matrix4,
  extraElements: IElementWithPose[],
) {
  return (appState: RootState): CreateMutationAddCamView => {
    const pose = new Matrix4().multiplyMatrices(
      worldMatrixInverse,
      cameraParams.cameraPose,
    );

    const iElement: IElementWithPose = {
      id: generateGUID(),
      parentId,
      type: IElementType.camView,
      typeHint: cameraParams.perspective ? null : IElementTypeHint.orthographic,
    };

    const projectApiPose = selectIElementProjectApiLocalPose(iElement, pose, [
      ...extraElements,
      iElement,
    ])(appState);

    return {
      ...iElement,
      parentId,
      name: "Camera View",
      rootId,
      orthographic: !cameraParams.perspective,
      zoomLevel: cameraParams.zoomLevel,
      pose: {
        pos: projectApiPose.pos,
        rot: projectApiPose.rot,
        scale: projectApiPose.scale,
        isWorldRot: false,
        gps: null,
      },
    };
  };
}
