import { ArrowDownIcon, FaroText, neutral } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useCallback } from "react";
import { THRESHOLD_SETS } from "./registration-thresholds";
import { useThresholdSetContext } from "./threshold-set-context";

/**
 * @returns A selector for the threshold set used in the registration report
 * to determine the registration quality.
 */
export function ThresholdSetSwitch(): JSX.Element | null {
  const { thresholdSet, setThresholdSet, showThresholdSetSwitch } =
    useThresholdSetContext();

  const updateThresholdSet = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      const newThresholdSet = THRESHOLD_SETS.find(
        (set) => set.name === newName,
      );
      assert(newThresholdSet, `Invalid threshold set ${newName}`);

      setThresholdSet(newThresholdSet);
    },
    [setThresholdSet],
  );

  if (!showThresholdSetSwitch) return null;

  return (
    <Accordion
      // Styled to match the design of the `RegistrationCard` component
      sx={{
        width: "100%",
        background: neutral[50],
        boxShadow: "none",
        borderRadius: 0.5,
        border: "solid rgba(0, 0, 0, 0.12) 1px",
        overflow: "hidden",
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDownIcon />}
        aria-controls="panel-content"
      >
        <FaroText variant="heading16">
          How to interpret the values of this report?
        </FaroText>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          <FaroText variant="bodyM">
            These quality details are evaluated based on the threshold set
            below. Threshold values decide if your registration results are
            considered as good, medium or poor. Select the threshold set below
            according to the capture device you used.
          </FaroText>
          <RadioGroup value={thresholdSet.name} onChange={updateThresholdSet}>
            {THRESHOLD_SETS.map((thresholdSet) => (
              <FormControlLabel
                key={thresholdSet.name}
                value={thresholdSet.name}
                control={<Radio />}
                label={
                  <Stack>
                    <FaroText variant="bodyM">{thresholdSet.name}</FaroText>
                    <FaroText variant="helpText">
                      {thresholdSet.description}
                    </FaroText>
                  </Stack>
                }
              />
            ))}
          </RadioGroup>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
