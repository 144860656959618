import { Vector3 } from "three";

export type OffsetPlaceholdersReturn = {
  /** The offset applied to all placeholders */
  placeholdersOffset: Vector3 | undefined;

  /** The shifted placeholder positions */
  shiftedPlaceholders: Vector3[];
};

/**
 * shift given placeholder positions by its first position
 *
 * @param placeholders the given placeholder positions
 * @returns the placeholder position offset and the shifted placeholder positions
 */
export function offsetPlaceholders(
  placeholders: Vector3[],
): OffsetPlaceholdersReturn {
  const placeholdersOffset =
    placeholders.length > 0 ? placeholders[0] : undefined;

  const shiftedPlaceholders = placeholders.map((p) =>
    p.clone().sub(placeholders[0]),
  );
  return { placeholdersOffset, shiftedPlaceholders };
}
