import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectTags } from "@/store/tags/tags-selectors";
import { Tag, UNTAGGED } from "@/store/tags/tags-slice";
import { GUID, IElement, isValid } from "@faro-lotv/ielement-types";
import { addIElements, selectAllIElements } from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "es-toolkit";
import { useCallback } from "react";

/**
 * @returns True if the input element is valid iElement and contains the tag we are removing
 * @param e The iElement to check
 * @param tag The tag that should be removed
 */
export function isTaggedIElement(
  e: IElement | undefined,
  tag: Tag,
): e is IElement {
  return isValid(e) && !!e.labels?.find((label) => label.id === tag.id);
}

/**
 * @returns A callback that will re-fetch all the previously fetch iElements that have the given tag and update the store
 * @param tag The tag of the elements to fetch
 */
export function useUpdateTaggedElements(): (tag: Tag) => void {
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();

  const elements = useAppSelector(selectAllIElements, isEqual);

  return useCallback(
    (tag) => {
      // Collect all the elements that had the tag
      const iElementIds = Object.values(elements)
        .filter((element) => isTaggedIElement(element, tag))
        .map((e) => e.id);

      // Re-fetch from the backend all the iElements that had the tag
      projectApiClient
        .getAllIElements({ ids: iElementIds })
        .then((elements) => dispatch(addIElements(elements)))
        .catch(() => []);
    },
    [dispatch, elements, projectApiClient],
  );
}

/** @returns only the user allowed tags sorted by name */
export const selectSortedAllowedTags = createSelector([selectTags], (tags) =>
  tags
    .filter((tag) => tag !== UNTAGGED)
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" }),
    ),
);

/**
 * Validates the new tag name.
 * The tag name cannot be empty and must be unique among the existing tags.
 *
 * @param tagId The id of the tag that is being edited
 * @param newTagName The new name of the tag
 * @param tagList The list of all the tags
 * @returns The error message if the new tag name is invalid
 */
export function validateNewTagName(
  tagId: GUID,
  newTagName: string,
  tagList: Tag[],
): string | undefined {
  const trimmedNewTagName = newTagName.trim();

  if (trimmedNewTagName.length === 0) {
    return "Field cannot be empty";
  } else if (trimmedNewTagName === UNTAGGED.name) {
    return "It's a system tag. Try another one";
  } else if (
    tagList.some((tag) => tag.name === trimmedNewTagName && tag.id !== tagId)
  ) {
    return "Tag name already exists";
  }
}
