import { CameraAnimation } from "@/components/r3f/animations/camera-animation";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import {
  PointCloudProps,
  PointCloudRenderer,
} from "@/components/r3f/renderers/pointcloud-renderer";
import { obbToPlanes, useClippingPlanes } from "@/hooks/use-clipping-planes";
import { useCurrentAreaClippingBox } from "@/hooks/use-object-bounding-box";
import { useViewIfNeeded } from "@/hooks/use-view-if-needed";
import { useCached3DObjectIfExists } from "@/object-cache";
import { isPointCloudObject } from "@/object-cache-type-guard";
import { getCameraAnimationTime } from "@/utils/camera-animation-time";
import { UniformLights } from "@faro-lotv/app-component-toolbox";
import { isIElementModel3dStream } from "@faro-lotv/ielement-types";
import { isCadModel } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useLayoutEffect } from "react";
import { Quaternion, Vector3 } from "three";
import { WalkModePipeline } from "../static/walk-mode-pipeline";
import { WalkSceneActiveElement } from "../walk-types";

type Models3dAnimationProps = {
  /** Target position of the animation */
  targetCameraPosition: Vector3;
  /** Target rotation of the animation */
  targetCameraQuaternion?: Quaternion;
  /** Current scene's main element */
  mainElement: WalkSceneActiveElement;
  /** /** Current scene's overlay element */
  overlayElement: WalkSceneActiveElement | undefined;
  /** Callback issued when the transition is finished */
  onCompleted(): void;
  /** Whether to turn on the lights */
  lightsOn?: boolean;
  /** Define as true to mark this scene as a secondary view for resource sharing */
  isSecondaryView: boolean;
};

/**
 * @returns a camera animation moving through the 3d scene of the walk mode, rendering
 * the point cloud and/or the CAD model with the correct rendering pipeline.
 */
export function Models3dAnimation({
  targetCameraPosition,
  targetCameraQuaternion,
  mainElement,
  overlayElement,
  onCompleted,
  lightsOn = true,
  isSecondaryView,
}: Models3dAnimationProps): JSX.Element {
  const cadElement =
    overlayElement && isIElementModel3dStream(overlayElement)
      ? overlayElement
      : undefined;

  /** Get the current 3D object (do not report loading failure for the IElementModel3dStream)*/
  const displayLoadingErrorCurrentElement =
    !isIElementModel3dStream(mainElement);
  const currentObject = useCached3DObjectIfExists(
    mainElement,
    displayLoadingErrorCurrentElement,
  );

  const displayLoadingErrorOverlayElement =
    !!cadElement && !isIElementModel3dStream(cadElement);
  const cadObject = useCached3DObjectIfExists(
    cadElement,
    displayLoadingErrorOverlayElement,
  );

  const camera = useThree((s) => s.camera);

  const pointCloud = isPointCloudObject(currentObject) ? currentObject : null;

  const cad = isCadModel(currentObject) ? currentObject : cadObject;

  const { clippingPlanes, setClippingPlanes } = useClippingPlanes();

  const areaBox = useCurrentAreaClippingBox();

  // Here we use a layout effect to ensure that, if an area box is active,
  // the clipping planes are updated before anything is rendered. With an
  // useEffect instead, the unclipped 3d models flicker for an instant.
  useLayoutEffect(() => {
    if (areaBox) {
      setClippingPlanes(obbToPlanes(areaBox));
    }
  }, [areaBox, setClippingPlanes]);

  return (
    <>
      {lightsOn && <UniformLights />}
      {pointCloud && (
        <PointCloudRendererView
          pointCloud={pointCloud}
          raycastEnabled={false}
          isSecondaryView={isSecondaryView}
          clippingPlanes={clippingPlanes}
        />
      )}
      {cad && <CadRenderer cadModel={cad} clippingPlanes={clippingPlanes} />}
      <WalkModePipeline
        pointCloud={pointCloud}
        cadModel={cad}
        enabled
        renderOnDemand
      />
      <CameraAnimation
        position={targetCameraPosition}
        quaternion={targetCameraQuaternion}
        duration={getCameraAnimationTime(camera, targetCameraPosition)}
        onAnimationFinished={onCompleted}
      />
    </>
  );
}

function PointCloudRendererView({
  pointCloud,
  raycastEnabled,
  isSecondaryView,
  clippingPlanes,
}: Pick<PointCloudProps, "pointCloud" | "raycastEnabled" | "clippingPlanes"> &
  Pick<Models3dAnimationProps, "isSecondaryView">): JSX.Element | null {
  const view = useViewIfNeeded(pointCloud, isSecondaryView);
  if (!view) return null;
  return (
    <PointCloudRenderer
      pointCloud={view}
      raycastEnabled={raycastEnabled}
      clippingPlanes={clippingPlanes}
    />
  );
}
