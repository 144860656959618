import { useDeleteArea } from "@/hooks/use-delete-area";
import { selectDefaultModeFor } from "@/modes";
import { ModeNames } from "@/modes/mode";
import {
  useCurrentScene,
  useIsCurrentAreaLoading,
} from "@/modes/mode-data-context";
import { selectIsElementViewablePointCloudStream } from "@/modes/mode-selectors";
import {
  AreaContentsKey,
  ENTIRE_PROJECT_KEY,
} from "@/store/area-contents-slice";
import { changeMode } from "@/store/mode-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { setActiveArea, setActiveElement } from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectCurrentUser,
  selectHasWritePermission,
} from "@/store/user-selectors";
import {
  CircularProgress,
  DeleteIcon,
  DividerOption,
  Dropdown,
  FaroButton,
  FaroMenu,
  FaroMenuItem,
  FaroText,
  NoTranslate,
  PlusIcon,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementAreaSection,
  isIElementPointCloudStream,
} from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { isEqual } from "es-toolkit";
import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { AreaNavigationTree } from "./area-navigation/area-navigation-tree";
import { selectAreaSections } from "./area-navigation/area-navigation-utils";
import { ToolTipForDisabledAreaTool } from "./canvas-toolbar";
import { selectTreeNodeDisabledReason } from "./tree/capture-tree/capture-tree-node-selectors";

/**
 * @returns the content of the project overview panel. It will contain the area selector and the list of elements in the area
 */
export function AreaNavigationPanel(): JSX.Element {
  const dispatch = useAppDispatch();

  const activeArea = useAppSelector(selectActiveArea);
  const isLoading = useIsCurrentAreaLoading();

  const areas = useAppSelector(selectAreaSections);
  const areaOptions = useAppSelector(selectAreaOptions(areas), isEqual);

  const changeActiveArea = useCallback(
    (areaId: AreaContentsKey) => {
      dispatch(setActiveArea(areaId));

      // TODO: Define what is the supposed behavior when changing the area (keep same activeElement if possible or not)
      // Currently to be safe, the active element is reset to the area
      if (areaId !== ENTIRE_PROJECT_KEY) {
        dispatch(setActiveElement(areaId));
      }

      // Find the best mode to use for the area
      const modeToUse = areaOptions
        .filter(isAreaOption)
        .find((area) => area.key === areaId)?.mode;

      if (modeToUse?.targetMode) {
        dispatch(changeMode(modeToUse.targetMode));
      }
    },
    [areaOptions, dispatch],
  );

  return (
    <Stack gap={2} sx={{ height: "100%" }}>
      <Dropdown
        label={<AreaSelectorLabel />}
        options={areaOptions}
        value={activeArea?.id ?? ENTIRE_PROJECT_KEY}
        onChange={(ev) => changeActiveArea(ev.target.value)}
        shouldCapitalize={false}
      />
      {isLoading ? <CircularProgress size={20} /> : <AreaDataList />}
    </Stack>
  );
}

/**
 * @returns a label that will be displayed on top of the dropdown to select the active area.
 * It will contain a title and a button to open the create area tool.
 */
function AreaSelectorLabel(): JSX.Element {
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "baseline" }}
    >
      <FaroText variant="labelM">Areas</FaroText>
      <ManageAreasMenu />
    </Stack>
  );
}

/** @returns a menu to open the area management menu */
function ManageAreasMenu(): JSX.Element {
  const dispatch = useAppDispatch();
  const { main, referenceElement } = useCurrentScene();

  const currentUser = useAppSelector(selectCurrentUser);
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const pointCloudId = useAppSelector(
    selectPointCloudId(main, referenceElement),
  );

  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Tooltip to show for the button
  // It will show a different message depending on the reason why the button is disabled
  const creationDisabledTooltip = useMemo(() => {
    if (!currentUser || !hasWritePermission) {
      return <ToolTipForDisabledAreaTool toolName="Area" />;
    }

    if (!pointCloudId) {
      return "To proceed, please select a 3D capture containing a point cloud first";
    }
  }, [pointCloudId, currentUser, hasWritePermission]);

  const goToCreateAreaMode = useCallback<MouseEventHandler>(() => {
    // Set the active element to the pointcloud to use in the create area workflow
    dispatch(setActiveElement(pointCloudId));

    // Change mode to go to the create area tool
    dispatch(changeMode("clippingbox"));
  }, [dispatch, pointCloudId]);

  const activeArea = useAppSelector(selectActiveArea);
  const deletionDisabledTooltip = useMemo(() => {
    if (!currentUser || !hasWritePermission) {
      return <ToolTipForDisabledAreaTool toolName="Area deletion" />;
    }

    if (!activeArea) {
      return "Entire project cannot be deleted";
    }
  }, [activeArea, currentUser, hasWritePermission]);

  const deleteArea = useDeleteArea();

  return (
    <>
      <FaroButton
        ref={setButtonRef}
        variant="ghost"
        size="xs"
        onClick={() => setIsMenuOpen(true)}
      >
        Manage
      </FaroButton>

      <FaroMenu
        open={isMenuOpen}
        anchorEl={buttonRef}
        onClose={() => setIsMenuOpen(false)}
        sx={{ maxWidth: "250px" }}
      >
        <FaroMenuItem
          label="Create areas"
          Icon={PlusIcon}
          disabled={!!creationDisabledTooltip}
          secondaryText={creationDisabledTooltip}
          onClick={goToCreateAreaMode}
        />
        <FaroMenuItem
          label="Delete selected area"
          Icon={DeleteIcon}
          disabled={!!deletionDisabledTooltip}
          secondaryText={deletionDisabledTooltip}
          onClick={() => {
            if (activeArea) deleteArea(activeArea);
            setIsMenuOpen(false);
          }}
        />
      </FaroMenu>
    </>
  );
}

/**
 * @returns a list of elements related to the current area.
 */
function AreaDataList(): JSX.Element {
  return (
    <Stack gap={2} sx={{ height: "100%" }}>
      <AreaNavigationTree />
    </Stack>
  );
}

type AreaOption = {
  /** The unique key of the option */
  key: AreaContentsKey;
  /** The value of the option */
  value: AreaContentsKey;
  /** The label to display in the dropdown */
  label: ReactNode;
  /** True if the option is disabled */
  isDisabled: boolean;
  /** The mode to use when selecting this area */
  mode?: { targetMode: ModeNames; element: IElement };
};

type AreaOptionOrDivider = AreaOption | DividerOption;

/**
 * @returns whether an AreaOptionOrDivider is a AreaOption
 * @param option the option to check
 */
export function isAreaOption(
  option: AreaOptionOrDivider,
): option is AreaOption {
  return "key" in option && "value" in option && "label" in option;
}

/** Option to select the "entire project" */
const ENTIRE_PROJECT_OPTION = {
  key: ENTIRE_PROJECT_KEY,
  value: ENTIRE_PROJECT_KEY,
  label: "Entire project",
  isDisabled: false,
};

function selectAreaOptions(areaOptions: IElementAreaSection[]) {
  return (state: RootState): AreaOptionOrDivider[] => [
    ENTIRE_PROJECT_OPTION,
    {
      isDivider: true,
    },
    ...areaOptions.map((area) => {
      const disabledReason = selectTreeNodeDisabledReason(area.id)(state);
      const modeToUse = selectDefaultModeFor(area)(state);

      return {
        key: area.id,
        value: area.id,
        label: <NoTranslate>{area.name}</NoTranslate>,
        isDisabled: !!disabledReason,
        mode: modeToUse,
      };
    }),
  ];
}

/**
 * @param main The main element of the current scene.
 * @param reference The reference dataset of the current scene.
 * @returns the id of the point cloud to use for area creation.
 * Returns the main element, if a point cloud is displayed in the current scene.
 * Otherwise a fitting point cloud will be searched for in the currently active reference data set.
 */
function selectPointCloudId(
  main: IElement | undefined,
  reference: IElement | undefined,
) {
  return (state: RootState): GUID | undefined => {
    // Check if the main element is a pointcloud
    if (main && isIElementPointCloudStream(main)) {
      return main.id;
    }

    // Find the point cloud stream in the reference data set
    return selectChildDepthFirst(reference, (el) =>
      selectIsElementViewablePointCloudStream(el)(state),
    )(state)?.id;
  };
}
