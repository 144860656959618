import { useAppSelector } from "@/store/store-hooks";
import { HelpBanner } from "@faro-lotv/flat-ui";
import {
  selectIsAnyEditModeEnabled,
  selectSelectedEntityIds,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";

/** @returns a help banner to explain the data preparation page */
export function DataPreparationHelpBanner(): JSX.Element | null {
  const helpMessage = useHelpMessage();

  if (!helpMessage) return null;

  return (
    <HelpBanner
      sx={{
        display: "inline-block",
        margin: 1,
      }}
    >
      {helpMessage}
    </HelpBanner>
  );
}

/** @returns The help message to show to the user, or `undefined` if no help should be shown. */
function useHelpMessage(): string | undefined {
  const isEditing = useAppSelector(selectIsAnyEditModeEnabled);
  const isEntitySelected = useAppSelector(selectSelectedEntityIds).length > 0;

  if (!isEditing) return undefined;

  return isEntitySelected
    ? "Confirm & run a refinement after aligning all scans"
    : "Select a scan or cluster to move from the list";
}
