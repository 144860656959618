import {
  PropOptional,
  validateEnumValue,
  validateOfType,
} from "@faro-lotv/foundation";
import type { RegistrationEditMode } from "../store/data-preparation-ui/data-preparation-ui-slice";

/**
 * The deep link data as encoded in the URL search parameters.
 *
 * The main goal is to keep the URL as short as possible.
 *
 * **IMPORTANT**: When updating the data format, please also update the documentation on confluence.
 *
 * @see https://faro01.atlassian.net/wiki/x/EYHlCwE
 */
export type EncodedDeepLinkData = {
  edit?: EncodedEditMode;
};

/**
 * @param data The data to check.
 * @returns Whether the data is a valid RawDeepLinkData object.
 */
export function isEncodedDeepLinkData(
  data: Record<string, string>,
): data is EncodedDeepLinkData {
  return validateOfType(
    data,
    "edit",
    (value) => validateEnumValue(value, EncodedEditMode),
    PropOptional,
  );
}

/** Encoded variant of {@link RegistrationEditMode} */
export enum EncodedEditMode {
  editScans = "s",
  addConnection = "a",
  deleteConnection = "d",
}
