import { UploadedFile } from "@/components/common/file-upload-context/use-file-uploader";
import { ErrorHandlers } from "@/errors/components/error-handling-context";
import { ProjectIntegrationProviderName } from "@/store/integrations/integrations-selectors";
import { Tag } from "@/store/tags/tags-slice";
import { FileDetails } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  BcfServicesIntegrationType,
  PostTopic,
  TopicCustomField,
} from "@faro-lotv/service-wires";
import { DateTime } from "luxon";

/** The default text to suggest/display as the title of a new external annotation */
export const EXTERNAL_ANNOTATION_DEFAULT_TITLE = "Title";

/** The text to display as tooltip when the user haven't filled all mandatory fields */
export const FILL_MANDATORY_FIELDS_TOOLTIP =
  "The fields name ending with a star (*) are mandatory.";

/**
 * This is the hyperlink text that will be displayed in external annotations third-party provider that supports it.
 * The associated link should open the corresponding annotation in Sphere XG.
 */
export const SPHERE_XG_ANNOTATION_LINK_TEXT = "Open in FARO Sphere XG";

/** The detailed information about an Annotation */
export type AnnotationProps = {
  /** The annotation title */
  title: string;

  /** The annotation description */
  description?: string;

  /** The user assigned to this Annotation */
  assignee?: string;

  /** The current status of this Annotation */
  status?: string;

  /** The due date for the Annotation */
  dueDate?: Date;

  /** The labels marking the annotation */
  tags?: Tag[];
};

type AnnotationPropsHandlers = {
  /** callback function called when the title has changed */
  onTitleChange?(title: string): void;

  /** callback function called when the value of the assignee has changed */
  onAssigneeChange?(assignee: string): void;

  /** callback function called when the value of the description has changed */
  onDescriptionChange?(description: string): void;

  /** callback function called when the value of the status has changed */
  onStatusChange?(status: string): void;

  /** callback function called when the value of the due date has changed */
  onDueDateChange?(dueDate: Date): void;
};

/** The type of a new attachment for the annotation */
export type NewAttachment = UploadedFile & FileDetails;

/** Defines the props required for the creation of a Sphere XG annotation */
export type SphereXGAnnotationData = AnnotationProps & {
  /** The list of new attachments for this annotation */
  newAttachments: NewAttachment[];
};

/**
 * Defines the fields that are common to all the external annotations. For fields description, see the BCF API documentation at:
 * https://github.com/buildingSMART/BCF-API/?tab=readme-ov-file#322-post-topic-service
 */
export type CommonExternalAnnotationData = Omit<AnnotationProps, "tags"> & {
  /** The external annotation type that should be created */
  externalAnnotationType: BcfServicesIntegrationType;

  /** The priority of a topic */
  priority?: string;

  /** The type of a topic */
  topicType?: string;

  /** Stage this topic is part of */
  stage?: string;
};

type CommonExternalAnnotationDataHandlers = AnnotationPropsHandlers & {
  /** callback function called when the value of the stage has changed */
  onStageChange?(stage: string): void;

  /** callback function called when the value of the priority has changed */
  onPriorityChange?(priority: string): void;

  /** callback function called when the value of the type has changed */
  onTopicTypeChange?(topicType: string): void;
};

export type CommonExternalAnnotationCreationFormProps =
  CommonExternalAnnotationData &
    CommonExternalAnnotationDataHandlers & {
      /** Indicates if some changes to annotation's values should be allowed */
      allowEdition: boolean;

      /** callback function called when an error occurs */
      onError?: ErrorHandlers["handleErrorWithToast"];

      /** function that indicates if all the required fields values have been filled */
      setAreMandatoryFieldsFilled?(valid: boolean): void;
    };

/**
 * Defines the fields contained in the companion fields of Autodesk Construction Cloud (ACC).
 */
export type AccCompanionFields = {
  /** The selected people that should watch the issue */
  selectedWatchers?: Tag[];

  /** The root cause of the issue */
  rootCause?: string;

  /** The start date of the issue */
  startDate?: Date;

  /**
   * The "published" status of the issue. If `false`, the issue will be set as draft
   * and will not be visible to other users until it is published.
   */
  published?: boolean;
};

/**
 * Defines the fields contained in the companion fields of Procore RFI.
 */
export type ProcoreRfiCompanionFields = {
  /** The RFI manager */
  rfiManager?: string;
};

export type AccComponentFieldsHandlers = {
  /** callback function called when the value of the root cause has changed */
  onRootCauseChange?(rootCause: string): void;

  /** callback function called when the value of the start date has changed */
  onStartDateChange?(startDate: Date): void;

  /** callback function called when the selected watchers have changed */
  onSelectedWatchersChange?(selectedWatchers: Tag[]): void;
};

/**
 * Defines the data required for the creation of an ACC annotation.
 */
export type AccAnnotationData = Omit<CommonExternalAnnotationData, "stage"> & {
  accCompanionFields: AccCompanionFields;
};

/**
 * Defines the data required for the creation of a Procore RFI.
 */
export type ProcoreRfiAnnotationData = Omit<
  CommonExternalAnnotationData,
  "stage"
> & {
  procoreRfiCompanionFields: ProcoreRfiCompanionFields;
};

/**
 * Enum representing the IDs of ACC companion fields.
 */
export enum AccCompanionFieldId {
  watchers = "watchers",
  startDate = "startDate",
  rootCause = "rootCause",
  published = "published",
}

/**
 * @returns Builds the companion fields of an ACC annotation for a PostTopic object
 */
function generateAccCompanionFields({
  selectedWatchers,
  startDate,
  rootCause,
  published,
}: AccCompanionFields): TopicCustomField[] | undefined {
  const companionFields: TopicCustomField[] = [];
  if (selectedWatchers && selectedWatchers.length > 0) {
    companionFields.push({
      id: AccCompanionFieldId.watchers,
      values: selectedWatchers.map((watcher) => watcher.id),
    });
  }
  if (startDate) {
    companionFields.push({
      id: AccCompanionFieldId.startDate,
      values: [DateTime.fromJSDate(startDate).toISODate() ?? ""],
    });
  }
  if (rootCause) {
    companionFields.push({
      id: AccCompanionFieldId.rootCause,
      values: [rootCause],
    });
  }
  if (published) {
    companionFields.push({
      id: AccCompanionFieldId.published,
      values: [published.toString()],
    });
  }

  return companionFields.length > 0 ? companionFields : undefined;
}

/**
 * Enum representing the IDs of Procore RFI companion fields.
 */
export enum ProcoreRfiCompanionFieldId {
  rfiManager = "rfi_manager",
}

/**
 * @returns Builds the companion fields of a Procore RFI annotation for a PostTopic object
 */
function generateProcoreRfiCompanionFields({
  rfiManager,
}: ProcoreRfiCompanionFields): TopicCustomField[] | undefined {
  const companionFields: TopicCustomField[] = [];
  if (rfiManager) {
    companionFields.push({
      id: ProcoreRfiCompanionFieldId.rfiManager,
      values: [rfiManager],
    });
  }
  return companionFields.length > 0 ? companionFields : undefined;
}

/**
 * Defines the data required for the creation of an external annotation
 */
export type ExternalAnnotationData =
  | CommonExternalAnnotationData
  | AccAnnotationData
  | ProcoreRfiAnnotationData;

/**
 * Type guard function to check if the given data is of type `ExternalAnnotationData`.
 *
 * @param data - The data to check, which can be either `SphereXGAnnotationData` or `ExternalAnnotationData`.
 * @returns A boolean indicating whether the data is of type `ExternalAnnotationData`.
 */
export function isExternalAnnotationData(
  data: SphereXGAnnotationData | ExternalAnnotationData,
): data is ExternalAnnotationData {
  return "externalAnnotationType" in data;
}

/**
 * Type guard function to check if the given data is of type `AccAnnotationData`.
 *
 * @param data - The data to check
 * @returns A boolean indicating whether the data is of type `AccAnnotationData`.
 */
export function isAccAnnotationData(
  data: ExternalAnnotationData,
): data is AccAnnotationData {
  return "accCompanionFields" in data;
}

/**
 * Type guard function to check if the given data is of type `ProcoreRfiAnnotationData`.
 *
 * @param data - The data to check
 * @returns A boolean indicating whether the data is of type `ProcoreRfiAnnotationData`.
 */
export function isProcoreRfiAnnotationData(
  data: ExternalAnnotationData,
): data is ProcoreRfiAnnotationData {
  return "procoreRfiCompanionFields" in data;
}

/** Defines a type containing data required for the creation of an annotation */
export type AnnotationCreationData =
  | SphereXGAnnotationData
  | ExternalAnnotationData;

/**
 * Returns the appropriate deep link separator based on the provided project integration provider name.
 *
 * @param providerName - The name of the project integration provider.
 * @returns The deep link separator string for the specified provider.
 * @throws Will throw an error if the provider name is unknown.
 */
function getDeepLinkSeparator(
  providerName: ProjectIntegrationProviderName,
): string {
  switch (providerName) {
    case ProjectIntegrationProviderName.autodeskAcc:
    case ProjectIntegrationProviderName.autodeskBim360:
      return "\n\n";
    case ProjectIntegrationProviderName.procore:
      return "<br/><br/>";
    default:
      assert(false, `Unknown provider name: ${providerName}`);
  }
}

/**
 * Removes the deep link within annotation's description.
 *
 * @param description - The description string that may contain a deep link.
 * @param providerName - The name of the project integration provider.
 * @returns The description string without the deep link.
 * @throws Will throw an error if the provider name is unknown.
 */
export function removeDeepLinkFromDescription(
  description: string,
  providerName: ProjectIntegrationProviderName,
): string {
  // We want to allow users to add text before and after the deep link, that's why we are using regular expressions to remove it.
  switch (providerName) {
    case ProjectIntegrationProviderName.autodeskAcc:
    case ProjectIntegrationProviderName.autodeskBim360: {
      const pattern = new RegExp(
        `${SPHERE_XG_ANNOTATION_LINK_TEXT}\\shttps?://[^\\s]*`,
      );
      const linksFound = description.match(pattern);
      return linksFound ? description.replace(linksFound[0], "") : description;
    }
    case ProjectIntegrationProviderName.procore: {
      // Procore is not returning the HTML elements in the description, so we can't use them as separator
      // Only the visible text is returned, so we will simply remove the hyperlink text at the end of the description
      const pattern = new RegExp(`${SPHERE_XG_ANNOTATION_LINK_TEXT}`);
      const linksFound = description.match(pattern);
      return linksFound ? description.replace(linksFound[0], "") : description;
    }
    default:
      assert(false, `Unknown provider name: ${providerName}`);
  }
}

/**
 * Formats a deep link URL into an HTML link string.
 *
 * @param deepLinkUrl - The URL to be formatted as a deep link.
 * @param providerName - The name of the provider in which the link will be displayed.
 * @returns A string containing an HTML link with the provided URL.
 * @throws An error if the provider name is unknown.
 */
function formatDeepLink(
  deepLinkUrl: string,
  providerName: ProjectIntegrationProviderName,
): string {
  switch (providerName) {
    case ProjectIntegrationProviderName.autodeskAcc:
    case ProjectIntegrationProviderName.autodeskBim360:
      return `${SPHERE_XG_ANNOTATION_LINK_TEXT} ${deepLinkUrl}`;
    case ProjectIntegrationProviderName.procore:
      return `<a href=${deepLinkUrl} target="_blank">${SPHERE_XG_ANNOTATION_LINK_TEXT}</a>`;
    default:
      assert(false, `Unknown provider name: ${providerName}`);
  }
}

type CreatePostTopicData = {
  /** The external annotation data to store in a PostTopic */
  data: ExternalAnnotationData;
  /** The id of the annotation */
  id: string;
  /** The deepLink to the corresponding Sphere XG annotation */
  deepLinkUrl: string;
  /** The provider name */
  providerName: ProjectIntegrationProviderName;
};

/**
 * Formats a description string by appending a deep link URL and a separator based on the provider name.
 *
 * @param description - The description text to be formatted. Can be undefined.
 * @param deepLinkUrl - The URL to be appended as a deep link.
 * @param providerName - The name of the project integration provider, used to determine the separator and format.
 * @returns The formatted description string with the deep link URL appended.
 */
function getFormattedDescription(
  description: string | undefined,
  deepLinkUrl: string,
  providerName: ProjectIntegrationProviderName,
): string {
  return `${description}${getDeepLinkSeparator(providerName)}${formatDeepLink(deepLinkUrl, providerName)}`;
}

/**
 *
 * @returns a PostTopic object containing the data needed for the creation of the external annotation
 */
export function createPostTopic({
  data,
  id,
  deepLinkUrl,
  providerName,
}: CreatePostTopicData): PostTopic {
  const commonPostTopicData = {
    title: data.title,
    guid: id,
    priority: data.priority ? { id: data.priority } : undefined,
    due_date: data.dueDate
      ? DateTime.fromJSDate(data.dueDate).toISODate() ?? undefined
      : undefined,
    assigned_to: data.assignee ? { id: data.assignee } : undefined,
    topic_status: data.status ? { id: data.status } : undefined,
    description: getFormattedDescription(
      data.description,
      deepLinkUrl,
      providerName,
    ),
    topic_type: data.topicType ? { id: data.topicType } : undefined,
  };

  if (isAccAnnotationData(data)) {
    return {
      ...commonPostTopicData,
      companion_fields: generateAccCompanionFields(data.accCompanionFields),
    };
  }

  if (isProcoreRfiAnnotationData(data)) {
    return {
      ...commonPostTopicData,
      companion_fields: generateProcoreRfiCompanionFields(
        data.procoreRfiCompanionFields,
      ),
    };
  }

  return {
    ...commonPostTopicData,
    stage: data.stage ? { id: data.stage } : undefined,
  };
}
