import { Mode, ModeTransitionProps } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import {
  resetCloudToSheetAlignment,
  setElevationForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectIElementWorldPosition } from "@faro-lotv/project-source";
import { useEffect } from "react";
import { CloudToSheetAlignmentModeOverlay } from "./cloud-to-sheet-alignment-mode-overlay";
import { CloudToSheetAlignmentModeScene } from "./cloud-to-sheet-alignment-mode-scene";
import { useCloudToSheetAlignmentElements } from "./use-cloud-to-sheet-alignment-elements";

export const cloudToSheetAlignmentMode: Mode = {
  name: "cloudToSheetAlignment",
  ModeScene: CloudToSheetAlignmentModeScene,
  ModeOverlay: CloudToSheetAlignmentModeOverlay,
  ModeTransition: CloudToSheetAlignmentModeTransition,
  hasCustomCanvasStyle: true,
  exclusive: {
    title: "Align point cloud to sheet",
    onBack({ dispatch }) {
      dispatch(resetCloudToSheetAlignment());
      dispatch(changeMode("start"));
      return Promise.resolve();
    },
  },
};

function CloudToSheetAlignmentModeTransition({
  onCompleted,
}: ModeTransitionProps): null {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const alignmentElements = useCloudToSheetAlignmentElements();

  useEffect(() => {
    // get current elevation of the area as initial elevation value for alignment
    const areaWorldPosition = selectIElementWorldPosition(
      alignmentElements.sectionArea.id,
    )(store.getState());
    dispatch(setElevationForCloudToSheetAlignment(areaWorldPosition[1]));
    onCompleted();
  }, [alignmentElements.sectionArea.id, dispatch, onCompleted, store]);

  return null;
}
