import { EventType } from "@/analytics/analytics-events";
import { useAppSelector } from "@/store/store-hooks";
import { InfoIcon } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Popover,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { isEqual } from "es-toolkit";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CloudActivityMenuButton } from "./progress-overview-button";
import { selectProcesses } from "./progress-overview-selectors";
import {
  ProgressMenuLayer,
  calculateProgressMenuZIndex,
} from "./progress-overview-z-index";
import { ProgressOverviewTab } from "./tabs/progress-overview-tab";

/** Available tabs in the ProgressOverview menu */
type MenuTabValues = "import" | "export";

/** @returns a menu to track the upload and processing of PointClouds */
export function ProgressOverviewMenu(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);
  const [hasNewTasks, setHasNewTasks] = useState(false);
  const [tabValue, setTabValue] = useState<MenuTabValues>("import");

  const { imports, exports, registrations } = useAppSelector(
    selectProcesses,
    isEqual,
  );
  const processes = useMemo(
    () => [...imports, ...exports, ...registrations],
    [imports, exports, registrations],
  );

  // When the list of uploads changes show the badges to notify the user
  // there's new info to check
  useEffect(() => {
    if (!isOpen) {
      setHasNewTasks(processes.length > 0);
    }
    // We don't want this effect to be triggered when "isOpen" changes
    // but only when the list of tracked uploads changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes]);

  const handleClick = useCallback<MouseEventHandler>(
    (ev) => {
      if (!isOpen) {
        Analytics.track(EventType.openProgressOverview);
        setIsOpen(true);
        setHasNewTasks(false);
        ev.stopPropagation();
      }
    },
    [isOpen],
  );

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: MenuTabValues) => {
      setTabValue(newValue);
    },
    [],
  );

  const tabSx = { px: 3, py: 0, paddingTop: 2, overflow: "scroll" };

  return (
    <>
      <CloudActivityMenuButton
        ref={button}
        onClick={handleClick}
        processes={processes}
        showBadge={hasNewTasks}
        disabled={processes.length === 0}
      />
      <Popover
        open={isOpen}
        anchorEl={button.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          zIndex: calculateProgressMenuZIndex(ProgressMenuLayer.base),
        }}
      >
        <Stack
          component="div"
          sx={{
            width: "400px",
            maxHeight: "550px",
            borderRadius: "5px",
            p: 0,
            my: 4,
            overflow: "hidden",
          }}
        >
          <Typography fontSize={16} fontWeight={600} mx={4}>
            Cloud activity
          </Typography>
          <TabContext value={tabValue}>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{ mx: 4 }}>
              <Tab label="Import" value="import" aria-label="import tab" />
              <Tab
                label="Registration"
                value="registration"
                aria-label="registration tab"
              />
              <Tab label="Export" value="export" aria-label="export tab" />
            </Tabs>
            <Divider />

            <TabPanel value="import" sx={tabSx}>
              <ProgressOverviewTab processes={imports} />
            </TabPanel>

            <TabPanel value="registration" sx={tabSx}>
              <ProgressOverviewTab processes={registrations} />
            </TabPanel>

            <TabPanel value="export" sx={tabSx}>
              <ProgressOverviewTab processes={exports} />
            </TabPanel>
          </TabContext>

          <Divider />
          <Stack direction="row" pt={2} spacing={0.5} mx={4}>
            <InfoIcon sx={{ color: "yellow600", height: "0.7em", pt: 0.3 }} />
            <Box component="div" fontSize="0.75em" color="gray700">
              Progress will be lost only if you close the browser while
              uploading or exporting your point cloud.
            </Box>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
