import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
  useOrthoCameraRotationFromSheet,
} from "@/hooks/use-center-camera-on-placeholders";
import { SheetObject, useCached3DObject } from "@/object-cache";
import { isFromSheetObject } from "@/object-cache-type-guard";
import {
  selectAlignedLayerForLayerToLayerAlignment,
  selectAnchorPositionsForLayerToLayerAlignment,
  selectReferenceForLayerToLayerAlignment,
} from "@/store/modes/layer-to-layer-alignment-mode-selectors";
import {
  setLayerToLayerIncrementalTransform,
  setMovingElementAnchor1ForLayerToLayerAlignment,
  setMovingElementAnchor2ForLayerToLayerAlignment,
  setReferenceElementAnchor1ForLayerToLayerAlignment,
  setReferenceElementAnchor2ForLayerToLayerAlignment,
} from "@/store/modes/layer-to-layer-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  AnchorPairPlacement,
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  FilteredRenderPass,
  useNonExhaustiveEffect,
  View,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElementGenericImgSheet,
  IElementImg360,
  isIElementGenericImgSheet,
} from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrthographicCamera, Vector3Tuple } from "three";
import { useNewOrthographicCamera } from "../alignment-modes-commons/align-to-cad-utils";
import { computeSplitScreenAlignment } from "../alignment-modes-commons/compute-split-screen-alignment";
import { useOverlayElements } from "../overlay-elements-context";

/** @returns The overlay for the layer-to-layer alignment mode */
export function LayerToLayerAlignmentModeScene(): JSX.Element {
  const alignedLayerId = useAppSelector(
    selectAlignedLayerForLayerToLayerAlignment,
  );
  const referenceLayerId = useAppSelector(
    selectReferenceForLayerToLayerAlignment,
  );

  const alignedLayer = useAppSelector(selectIElement(alignedLayerId));
  const referenceLayer = useAppSelector(selectIElement(referenceLayerId));
  assert(
    isIElementGenericImgSheet(alignedLayer) &&
      isIElementGenericImgSheet(referenceLayer),
    "LayerToLayer Alignment Mode requires both elements: aligned and reference",
  );

  return (
    <LayerToLayerSplitScreen
      alignedLayer={alignedLayer}
      referenceLayer={referenceLayer}
    />
  );
}

type LayerToLayerSplitScreenProps = {
  /** Sheet to be aligned to the reference layer */
  alignedLayer: IElementGenericImgSheet;

  /** Reference layer for alignment */
  referenceLayer: IElementGenericImgSheet;
};

/** @returns the split-screen scene for layer-to-layer alignment in 2d */
function LayerToLayerSplitScreen({
  alignedLayer,
  referenceLayer,
}: LayerToLayerSplitScreenProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [controlsEnabled, setControlsEnabled] = useState(true);

  // first camera is for top or left screen, second is for bottom or right screen
  const firstCamera = useNewOrthographicCamera();
  const secondCamera = useNewOrthographicCamera();

  const { firstScreen, secondScreen } = useOverlayElements();

  const sheet1 = useCached3DObject(alignedLayer);
  const sheet2 = useCached3DObject(referenceLayer);

  const sheetElementsAligned = useMemo(() => [alignedLayer], [alignedLayer]);
  const alignedLayerCameraRotation = useOrthoCameraRotationFromSheet(
    alignedLayer.id,
  );
  const sheet1CenteringData = useCenterCameraOnPlaceholders({
    sheetElements: sheetElementsAligned,
    cameraRotation: alignedLayerCameraRotation,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio: firstScreen
      ? firstScreen.clientWidth / firstScreen.clientHeight
      : 1,
  });

  const sheetElementsReference = useMemo(
    () => [referenceLayer],
    [referenceLayer],
  );
  const referenceLayerCameraRotation = useOrthoCameraRotationFromSheet(
    referenceLayer.id,
  );
  const sheet2CenteringData = useCenterCameraOnPlaceholders({
    sheetElements: sheetElementsReference,
    cameraRotation: referenceLayerCameraRotation,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio: secondScreen
      ? secondScreen.clientWidth / secondScreen.clientHeight
      : 1,
  });

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(firstCamera, sheet1CenteringData);
    centerOrthoCamera(secondCamera, sheet2CenteringData);
  }, []);

  const alignmentAnchorPositions = useAppSelector(
    selectAnchorPositionsForLayerToLayerAlignment,
  );

  // compute split screen alignment when there is change in anchor positions
  useEffect(() => {
    const alignmentTransform = computeSplitScreenAlignment(
      alignmentAnchorPositions,
      true,
    );

    if (alignmentTransform) {
      dispatch(setLayerToLayerIncrementalTransform(alignmentTransform));
    }
  }, [alignmentAnchorPositions, dispatch]);

  const changeModelAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor1ForLayerToLayerAlignment(position));
    },
    [dispatch],
  );
  const changeModelAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setReferenceElementAnchor2ForLayerToLayerAlignment(position));
    },
    [dispatch],
  );
  const changeSheetAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setMovingElementAnchor1ForLayerToLayerAlignment(position));
    },
    [dispatch],
  );
  const changeSheetAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(setMovingElementAnchor2ForLayerToLayerAlignment(position));
    },
    [dispatch],
  );

  return (
    <>
      <LayerView
        sheet={sheet1}
        camera={firstCamera}
        screen={firstScreen}
        controlsEnabled={controlsEnabled}
        setControlsEnabled={setControlsEnabled}
        anchor1Position={alignmentAnchorPositions.movingElementAnchor1}
        anchor2Position={alignmentAnchorPositions.movingElementAnchor2}
        changeAnchor1Position={changeSheetAnchor1Position}
        changeAnchor2Position={changeSheetAnchor2Position}
      />

      <LayerView
        sheet={sheet2}
        camera={secondCamera}
        screen={secondScreen}
        controlsEnabled={controlsEnabled}
        setControlsEnabled={setControlsEnabled}
        anchor1Position={alignmentAnchorPositions.referenceElementAnchor1}
        anchor2Position={alignmentAnchorPositions.referenceElementAnchor2}
        changeAnchor1Position={changeModelAnchor1Position}
        changeAnchor2Position={changeModelAnchor2Position}
      />
    </>
  );
}

type LayerViewProps = {
  /** Sheet object in cache */
  sheet: SheetObject;

  /** camera */
  camera: OrthographicCamera;

  /** screen */
  screen: HTMLDivElement | null;

  /** if false controls in view are disabled to avoid interference with setting anchor points  */
  controlsEnabled: boolean;

  /** enable/disable controls in view interference with setting anchor points  */
  setControlsEnabled: React.Dispatch<React.SetStateAction<boolean>>;

  /** position of first anchor point */
  anchor1Position?: Vector3Tuple | undefined;

  /** position of second anchor point */
  anchor2Position?: Vector3Tuple | undefined;

  /** method to set position of first anchor point */
  changeAnchor1Position(position: Vector3Tuple): void;

  /** method to set position of second anchor point */
  changeAnchor2Position(position: Vector3Tuple): void;
};

/** @returns the split-screen scene for layer-to-layer alignment in 2d */
function LayerView({
  sheet,
  camera,
  screen,
  controlsEnabled,
  setControlsEnabled,
  anchor1Position,
  anchor2Position,
  changeAnchor1Position,
  changeAnchor2Position,
}: LayerViewProps): JSX.Element {
  const background = useThree((s) => s.scene.background);
  return (
    <View
      camera={camera}
      trackingElement={screen}
      background={background}
      hasSeparateScene
    >
      <AnchorPairPlacement
        onPointerDown={() => setControlsEnabled(false)}
        onPointerUp={() => setControlsEnabled(true)}
        anchor1Position={anchor1Position}
        anchor2Position={anchor2Position}
        changeAnchor1Position={changeAnchor1Position}
        changeAnchor2Position={changeAnchor2Position}
      >
        <SheetRenderer sheet={sheet} />
      </AnchorPairPlacement>
      <EffectPipeline>
        <FilteredRenderPass filter={(o) => isFromSheetObject(o)} />
        <DesaturatePass />
        <FilteredRenderPass
          filter={(o) => !isFromSheetObject(o)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipeline>
      <SheetModeControls
        camera={camera}
        referencePlaneHeight={0}
        enabled={controlsEnabled}
      />
    </View>
  );
}
