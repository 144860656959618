import {
  CreateAnnotationShareLinkEventProperties,
  EditAnnotationEventProperties,
  EventType,
  GotoAnnotationProperties,
  OpenAnnotationAttachmentEventProperties,
  OpenAnnotationDetailsContextMenuEventProperties,
} from "@/analytics/analytics-events";
import { MeasurementValuesField } from "@/components/r3f/renderers/annotations/annotation-renderers/measurement-values-field";
import { HandleAnnotationViewerVariantReturn } from "@/components/r3f/renderers/annotations/annotation-renderers/use-handle-annotation-details-variant";
import { EditAnnotation } from "@/components/ui/annotations/edit-annotation";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAnnotationAssignees } from "@/hooks/use-annotation-assignees";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { useGoToAnnotation } from "@/hooks/use-go-to-annotation";
import { useShareAnnotationOrAnalysis } from "@/hooks/use-share-annotation-or-analysis";
import {
  selectProjectIdForIntegrationType,
  selectWorkspaceIntegrations,
} from "@/store/integrations/integrations-selectors";
import {
  selectProjectUser,
  selectProjectUsers,
} from "@/store/project-selector";
import { selectMarkupAttachments } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  useDashboardProjectIntegrationsUrl,
  useDashboardWorkspaceIntegrationsUrl,
} from "@/utils/redirects";
import { getAnnotationStatusDisplayName } from "@faro-lotv/app-component-toolbox";
import {
  AnnotationViewer,
  ExternalAnnotationsField,
  FileDetails,
  neutral,
  UrlLink,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAttachment,
  isIElementExternalMarkup,
  isIElementMarkup,
  isIElementMarkupDueTime,
  isIElementMarkupState,
  isIElementMeasurePolygon,
  isIElementUrlLink,
  MarkupIElement,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectCompanyId,
  selectProjectId,
} from "@faro-lotv/project-source";
import {
  ApiValidationError,
  BcfServicesIntegrationType,
  CollaborationUser,
  GenericUserInfo,
  GetTopicResponse,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Box, Dialog, Stack, SxProps, Theme } from "@mui/material";
import { isEqual } from "es-toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { removeDeepLinkFromDescription } from "./annotation-props";
import { ExternalAnnotationProviderLogo } from "./external-annotation-provider-logo";
import {
  getBcfIntegrationTypeForIElementType,
  getExternalAnnotationProviderName,
  getExternalAnnotationTypeName,
  getWorkspaceIntegrationProviderId,
} from "./external-annotation-utils";

interface MarkupAnnotationUIProps
  extends Omit<
    HandleAnnotationViewerVariantReturn,
    "onAnnotationViewerHovered"
  > {
  /** The details about a specific annotation */
  markup: MarkupIElement;

  /** Optional icon to render next to the UI. Used for single point annotations */
  icon?: JSX.Element;

  /** Pixel size to use for the icon */
  iconSize?: number;

  /** Optional style to apply to the Markup's UI */
  sx?: SxProps<Theme>;

  /** Callback executed when the copy to clipboard button is pressed */
  onCopyToClipboard?(): void;

  /** Callback executed when the user requests to delete the annotation. */
  onDelete(): void;
}

/** @returns the ui to interact with an annotation */
export function MarkupAnnotationUI({
  markup,
  icon,
  iconSize,
  sx,
  AnnotationViewerVariant,
  onAnnotationViewerExpanded,
  onAnnotationViewerClosed,
  onCopyToClipboard,
  onDelete,
}: MarkupAnnotationUIProps): JSX.Element | null {
  const [externalMarkupData, setExternalMarkupData] =
    useState<GetTopicResponse>();

  const [errorMessage, setErrorMessage] = useState<string>();

  const externalMarkup = useAppSelector(
    selectChildDepthFirst(markup, isIElementExternalMarkup),
  );

  const { bcfServicesApiClient } = useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();

  const integrationType = externalMarkup
    ? getBcfIntegrationTypeForIElementType(externalMarkup.type)
    : undefined;

  const integrationProjectId = useAppSelector(
    integrationType
      ? selectProjectIdForIntegrationType(integrationType)
      : () => undefined,
  );

  const workspaceIntegrations = useAppSelector(selectWorkspaceIntegrations);
  const companyId = useAppSelector(selectCompanyId);
  const dashboardWorkspaceIntegrationsUrl =
    useDashboardWorkspaceIntegrationsUrl(companyId);
  const projectId = useAppSelector(selectProjectId);
  const dashboardProjectIntegrationsUrl = useDashboardProjectIntegrationsUrl(
    companyId,
    projectId,
  );

  useEffect(() => {
    async function fetchExternalMarkupData(): Promise<void> {
      setExternalMarkupData(undefined);
      try {
        assert(integrationType, "Integration type is not defined");
        assert(
          externalMarkup?.externalIssueId,
          "External issue ID is not defined",
        );
        assert(integrationProjectId, "Integration project ID is not defined");
        const topic = await bcfServicesApiClient.getTopic(
          integrationType,
          integrationProjectId,
          externalMarkup.externalIssueId,
        );
        setExternalMarkupData(topic);
      } catch (error) {
        if (error instanceof ApiValidationError) {
          handleErrorWithToast({
            title: "Error fetching external annotation data",
            error,
          });
        } else {
          // We are ignoring the error thrown by the BCF Services API client because it is too generic
          // and don't guide the user to solve the problem. We are showing a generic error message instead.
          // This is a temporary solution until the BCF Services API client is improved. Here is the issue
          // opened to track this improvement: https://faro01.atlassian.net/browse/CADBIM-898
          setErrorMessage(
            "Your session with " +
              `${integrationType ? getExternalAnnotationProviderName(integrationType) : "external annotation provider"} ` +
              "might have expired. To continue, please refresh the connection through the workspace " +
              `[integration settings](${dashboardWorkspaceIntegrationsUrl}) and refresh this page once done.`,
          );
        }
      }
    }

    if (externalMarkup && integrationType) {
      const workspaceIntegrationEnabled = workspaceIntegrations.includes(
        getWorkspaceIntegrationProviderId(integrationType),
      );
      const projectIntegrationEnabled = integrationProjectId !== undefined;
      if (workspaceIntegrationEnabled && projectIntegrationEnabled) {
        fetchExternalMarkupData();
      } else if (workspaceIntegrationEnabled === false) {
        setErrorMessage(
          `Please establish the connection to ${getExternalAnnotationProviderName(integrationType)} through the workspace ` +
            `[integration settings](${dashboardWorkspaceIntegrationsUrl}) and refresh this page once done.`,
        );
      } else {
        setErrorMessage(
          `Please connect to one ${getExternalAnnotationProviderName(integrationType)} project through the Sphere XG project ` +
            `[integration settings](${dashboardProjectIntegrationsUrl}) and refresh this page once done.`,
        );
      }
    }
  }, [
    bcfServicesApiClient,
    dashboardWorkspaceIntegrationsUrl,
    dashboardProjectIntegrationsUrl,
    externalMarkup,
    handleErrorWithToast,
    integrationProjectId,
    integrationType,
    workspaceIntegrations,
  ]);

  const sphereMarkup = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkup),
  );

  // Check if the markup has an attachment
  const hasAttachment = useAppSelector(
    (state) => selectMarkupAttachments(markup)(state).length > 0,
  );

  // Collect the info related to this annotation
  const dueDate = useAppSelector(
    selectChildDepthFirst(sphereMarkup, isIElementMarkupDueTime),
  );
  const state = useAppSelector(
    selectChildDepthFirst(sphereMarkup, isIElementMarkupState),
  );
  const mainAssignee = useAnnotationAssignees(sphereMarkup).at(0);
  const projectUsers = useAppSelector(selectProjectUsers);

  // The creator of the annotation
  const projectUser = useAppSelector(
    selectProjectUser(sphereMarkup?.createdBy),
  );
  const currentUser = useAppSelector(selectCurrentUser);
  const createdBy =
    currentUser?.id === sphereMarkup?.createdBy ? currentUser : projectUser;

  // For now use the first assignee
  const assigneeId = useMemo(
    () =>
      mainAssignee
        ? projectUsers.find((user) => user.id === mainAssignee.id)?.id
        : undefined,
    [mainAssignee, projectUsers],
  );

  const { canWriteAnnotations } = useAnnotationPermissions();
  const [isEditing, setIsEditing] = useState(false);

  const shareAnnotation = useShareAnnotationOrAnalysis(markup.id);

  const measurement = useAppSelector(
    selectAncestor(sphereMarkup, isIElementMeasurePolygon),
  );
  const isMeasurement = !!measurement;

  const attachmentsElements = useAppSelector(
    selectChildrenDepthFirst(sphereMarkup, isIElementAttachment),
    isEqual,
  );

  const attachments = useMemo<FileDetails[]>(
    () =>
      attachmentsElements.map((el) => ({
        id: el.id,
        fileName: el.name,
        fileSize: el.fileSize,
        date: el.createdAt,
        urlOrFile: el.uri,
        thumbnailUrl: el.thumbnailUri,
      })),
    [attachmentsElements],
  );

  const trackContextMenuOpening = useCallback(() => {
    Analytics.track<OpenAnnotationDetailsContextMenuEventProperties>(
      EventType.openAnnotationDetailsContextMenu,
      {
        via: AnnotationViewerVariant,
      },
    );
  }, [AnnotationViewerVariant]);

  const linkElements = useAppSelector(
    selectChildrenDepthFirst(sphereMarkup, isIElementUrlLink),
    isEqual,
  );
  const links = useMemo<UrlLink[] | undefined>(() => {
    if (linkElements.length === 0) return;
    return linkElements.map((el) => ({
      label: el.descr ?? el.name,
      href: el.uri,
    }));
  }, [linkElements]);

  const tags = useMemo(
    () => markup.labels?.map((l) => ({ id: l.id, label: l.name })),
    [markup.labels],
  );

  const isFull = AnnotationViewerVariant === "full";

  const onIconClicked = useCallback(() => {
    if (isFull) {
      onAnnotationViewerClosed();
    } else {
      onAnnotationViewerExpanded();
    }
  }, [isFull, onAnnotationViewerClosed, onAnnotationViewerExpanded]);

  const goToAnnotation = useGoToAnnotation(markup);

  const shouldRenderDetails =
    AnnotationViewerVariant !== "hidden" &&
    AnnotationViewerVariant !== "collapsed";

  const finalTitle = externalMarkup
    ? getExternalAnnotationTitle(
        externalMarkup.externalIssueId,
        externalMarkupData,
        errorMessage !== undefined,
      )
    : markup.name;
  const finalAssignee = externalMarkupData
    ? getGenericUserInfo(externalMarkupData.assigned_to)
    : mainAssignee;
  const finalCreatedBy = externalMarkupData
    ? getGenericUserInfo(externalMarkupData.creation_author)
    : createdBy;
  const externalMarkupAnnotationDueDate = externalMarkupData?.due_date
    ? new Date(externalMarkupData.due_date)
    : undefined;
  const sphereDueDate = dueDate ? new Date(dueDate.value) : undefined;
  const finalDueDate = externalMarkup
    ? externalMarkupAnnotationDueDate
    : sphereDueDate;
  const finalDescription = externalMarkup
    ? getExternalAnnotationDescription(
        externalMarkupData,
        integrationType,
        errorMessage,
      )
    : markup.descr;
  const finalStatus = externalMarkup
    ? externalMarkupData?.topic_status?.name
    : getAnnotationStatusDisplayName(state?.value);
  const finalHasAttachment = externalMarkup ? false : hasAttachment;
  const finalAttachments = externalMarkup ? undefined : attachments;
  const finalLinks = externalMarkup ? undefined : links;
  const finalTags = externalMarkup
    ? externalMarkupData?.labels.map((l) => ({ id: l.id, label: l.name }))
    : tags;
  const finalIsMeasurement = externalMarkup ? false : isMeasurement;

  return (
    <>
      <Stack
        direction="row"
        alignItems="top"
        sx={{
          width: "max-content",
          ...sx,
        }}
      >
        <Box
          component="div"
          sx={{
            width: iconSize,
            height: iconSize,
            visibility: icon ? "visible" : "collapse",
            cursor: "pointer",
          }}
          onClick={onIconClicked}
        >
          {icon}
        </Box>
        {shouldRenderDetails && (
          <AnnotationViewer
            onDescriptionError={(error) =>
              handleErrorWithToast({ title: "Error in description", error })
            }
            onExpandButtonClick={() => {
              Analytics.track(EventType.expandAnnotationDetails);
              onAnnotationViewerExpanded();
            }}
            onCloseButtonClick={() => {
              Analytics.track(EventType.closeAnnotationDetails);
              onAnnotationViewerClosed();
            }}
            onGoToButtonClick={() => {
              Analytics.track<GotoAnnotationProperties>(
                EventType.gotoAnnotation,
                { via: "3d scene" },
              );
              goToAnnotation();
            }}
            onShareButtonClick={() => {
              Analytics.track<CreateAnnotationShareLinkEventProperties>(
                EventType.createAnnotationShareLink,
                {
                  via: AnnotationViewerVariant,
                },
              );

              shareAnnotation();
            }}
            onAttachmentOpened={(fileType: string) =>
              Analytics.track<OpenAnnotationAttachmentEventProperties>(
                EventType.openAnnotationAttachment,
                {
                  fileType,
                },
              )
            }
            // External markups are not supported yet
            canEdit={canWriteAnnotations && !externalMarkup}
            canDelete={canWriteAnnotations}
            onContextMenuOpen={trackContextMenuOpening}
            onEdit={() => {
              Analytics.track<EditAnnotationEventProperties>(
                EventType.editAnnotation,
                {
                  via: "3d scene",
                },
              );

              setIsEditing(true);
            }}
            onDelete={onDelete}
            variant={AnnotationViewerVariant}
            title={finalTitle}
            assignee={finalAssignee}
            createdBy={finalCreatedBy}
            dueDate={finalDueDate}
            description={finalDescription}
            status={finalStatus}
            externalAnnotationViewerProps={
              externalMarkup && integrationType
                ? {
                    annotationTypeName:
                      getExternalAnnotationTypeName(integrationType),
                    providerName:
                      getExternalAnnotationProviderName(integrationType),
                    link: externalMarkupData
                      ? externalMarkupData.origin_user_interface_url
                      : undefined,
                    logo: (
                      <ExternalAnnotationProviderLogo
                        annotationType={integrationType}
                        sx={{
                          color: neutral[0],
                        }}
                      />
                    ),
                    fields: [
                      ...(errorMessage === undefined && externalMarkupData
                        ? getExternalAnnotationTypeFields(
                            integrationType,
                            externalMarkupData,
                          )
                        : []),
                      {
                        name: "External ID",
                        value: externalMarkup.externalIssueId,
                      },
                    ],
                    errorOccurred: errorMessage !== undefined,
                  }
                : undefined
            }
            hasAttachment={finalHasAttachment}
            isMeasurement={finalIsMeasurement}
            onCopyToClipboard={onCopyToClipboard}
            files={finalAttachments}
            links={finalLinks}
            tags={finalTags}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </AnnotationViewer>
        )}
      </Stack>
      {isEditing && sphereMarkup !== undefined && (
        <Dialog
          open
          PaperProps={{
            sx: {
              padding: 0,
              backgroundColor: "transparent",
              overflow: "hidden",
              boxShadow: "none",
            },
          }}
        >
          <EditAnnotation
            iElement={sphereMarkup}
            title={sphereMarkup.name}
            description={sphereMarkup.descr ?? undefined}
            assignee={assigneeId}
            status={state?.value ?? undefined}
            dueDate={dueDate?.value ? new Date(dueDate.value) : undefined}
            onClose={() => setIsEditing(false)}
          >
            {measurement && (
              <MeasurementValuesField measurement={measurement} />
            )}
          </EditAnnotation>
        </Dialog>
      )}
    </>
  );
}

/**
 * Retrieves the external annotation type fields based on the integration type and external annotation data.
 *
 * @param integrationType The type of BCF services integration.
 * @param externalAnnotationData The external annotation data containing topic information.
 * @returns An array of external annotation fields.
 *
 * If the integration type is not supported, an assertion error is thrown.
 */
function getExternalAnnotationTypeFields(
  integrationType: BcfServicesIntegrationType,
  externalAnnotationData: GetTopicResponse,
): ExternalAnnotationsField[] {
  switch (integrationType) {
    case BcfServicesIntegrationType.autodeskAccIssues:
      return [
        ...(externalAnnotationData.topic_type
          ? [{ name: "Type", value: externalAnnotationData.topic_type.name }]
          : []),
      ];
    case BcfServicesIntegrationType.autodeskAccRfis:
      return [
        ...(externalAnnotationData.priority
          ? [{ name: "Priority", value: externalAnnotationData.priority.name }]
          : []),
      ];
    case BcfServicesIntegrationType.procoreObservations:
      return [
        ...(externalAnnotationData.topic_type
          ? [{ name: "Type", value: externalAnnotationData.topic_type.name }]
          : []),
        ...(externalAnnotationData.priority
          ? [{ name: "Priority", value: externalAnnotationData.priority.name }]
          : []),
      ];
    case BcfServicesIntegrationType.procoreRfis:
      return [
        ...(externalAnnotationData.stage
          ? [{ name: "Stage", value: externalAnnotationData.stage.name }]
          : []),
      ];
    default:
      assert(false, `Integration type: ${integrationType} is not supported`);
  }
}

/**
 * Converts a CollaborationUser type to GenericUserInfo type
 *
 * @param user The user to convert
 * @returns The corresponding GenericUserInfo or undefined if the user is null or undefined
 */
function getGenericUserInfo(
  user: CollaborationUser | null | undefined,
): GenericUserInfo | undefined {
  if (!user) {
    return;
  }
  return {
    identity: user.id,
    email: user.email ?? "",
    name: user.name ?? undefined,
  };
}

/**
 * Retrieves the title of the external annotation based on the fetched data.
 *
 * @param id The ID of the external annotation.
 * @param fetchedData The fetched data of the external annotation.
 * @param errorOccurred Indicates if an error occurred while fetching the data.
 * @returns The title of the external annotation.
 */
function getExternalAnnotationTitle(
  id: string,
  fetchedData: GetTopicResponse | undefined,
  errorOccurred: boolean,
): string {
  if (errorOccurred) {
    return "Connection error";
  }
  return fetchedData?.title ?? `Loading annotation ${id}`;
}

/**
 * Retrieves the description of the external annotation based on the fetched data.
 *
 * @param fetchedData The fetched data of the external annotation.
 * @param integrationType The type of BCF services integration.
 * @param errorMessage If an error occurred, it will contain the details about the error.
 * @returns The description of the external annotation.
 */
function getExternalAnnotationDescription(
  fetchedData: GetTopicResponse | undefined,
  integrationType: BcfServicesIntegrationType | undefined,
  errorMessage: string | undefined,
): string {
  if (errorMessage) {
    return errorMessage;
  }
  return fetchedData && integrationType
    ? removeDeepLinkFromDescription(
        fetchedData.description ?? "",
        getExternalAnnotationProviderName(integrationType),
      )
    : "";
}
