import { HandlerRenderer } from "@/components/r3f/renderers/measurements/measure-handler";
import { TwoPointMeasureSegmentBase } from "@/components/r3f/renderers/measurements/two-point-segment-renderer";
import { useSvg } from "@faro-lotv/app-component-toolbox";
import { LineHandler } from "@faro-lotv/flat-ui";
import { Vector3 } from "three";

/** Props for the user defined line */
type ReferenceLineProps = {
  /** Starting point */
  start?: Vector3;

  /** End point */
  end?: Vector3;
};

/** @returns a simple line drawn by the user on a floor plan */
export function ReferenceLine({
  start,
  end,
}: ReferenceLineProps): JSX.Element | null {
  const handlerTexture = useSvg(LineHandler);
  const handlersSize = 10;

  return (
    <>
      {start && (
        <HandlerRenderer
          position={start}
          size={handlersSize}
          color="white"
          texture={handlerTexture}
        />
      )}
      {end && (
        <HandlerRenderer
          position={end}
          size={handlersSize}
          color="white"
          texture={handlerTexture}
        />
      )}
      {start && end && (
        <TwoPointMeasureSegmentBase main visible start={start} end={end} />
      )}
    </>
  );
}
