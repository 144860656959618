import {
  EventType,
  SetPointCloudRenderingProfileProperties,
} from "@/analytics/analytics-events";
import {
  PointCloudRenderingProfile,
  useRenderingSettings,
} from "@/components/common/rendering-settings-context";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import { ViewOptionsSlider } from "./view-options-slider";

const ALL_PROFILES = [
  PointCloudRenderingProfile.SmoothSilk,
  PointCloudRenderingProfile.Smooth,
  PointCloudRenderingProfile.Sharp,
  PointCloudRenderingProfile.Sharpest,
  PointCloudRenderingProfile.Original,
];

/** @returns a slider to select one out of four point cloud rendering profiles */
export function PointCloudProfilesSlider(): JSX.Element {
  const { pointCloudRenderingProfile, setPointCloudRenderingProfile } =
    useRenderingSettings();
  const hasOriginalProfile = useAppSelector(
    selectHasFeature(Features.OriginalPcRendering),
  );

  const availableProfiles = useMemo(() => {
    if (hasOriginalProfile) return ALL_PROFILES;
    return ALL_PROFILES.filter(
      (p) => p !== PointCloudRenderingProfile.Original,
    );
  }, [hasOriginalProfile]);

  const marks = useMemo(
    () => availableProfiles.map((value, index) => ({ value: index + 1 })),
    [availableProfiles],
  );

  const currentValue =
    availableProfiles.indexOf(pointCloudRenderingProfile) + 1;

  return (
    <ViewOptionsSlider
      label="Point cloud rendering"
      title="Point cloud rendering options"
      description={<ProfilesHelpText />}
      marks={marks}
      step={null}
      min={1}
      max={availableProfiles.length}
      valueLabelDisplay="auto"
      valueLabelFormat={(x) => availableProfiles[x - 1]}
      onChange={(_, value: number | number[]) => {
        if (typeof value === "number") {
          const selectedProfile = availableProfiles[value - 1];
          setPointCloudRenderingProfile(selectedProfile);
          Analytics.track<SetPointCloudRenderingProfileProperties>(
            EventType.setPointCloudRenderingProfile,
            { value: selectedProfile },
          );
        }
      }}
      value={currentValue}
    >
      <Stack direction="row" justifyContent="space-between">
        <FaroText dark variant="placeholder">
          Smooth
        </FaroText>
        <FaroText dark variant="placeholder">
          {hasOriginalProfile ? "Original" : "Sharpest"}
        </FaroText>
      </Stack>
    </ViewOptionsSlider>
  );
}

/** @returns a text panel with four help messages, one for each point cloud rendering profile. */
function ProfilesHelpText(): JSX.Element {
  const hasOriginalProfile = useAppSelector(
    selectHasFeature(Features.OriginalPcRendering),
  );

  // The interplay between <Stack> and <Box> components below is needed to ensure
  // that the text is divided into four correctly spaced paragraphs, and at the
  // same time the first word only of each paragraph is bold.
  return (
    <Stack spacing=".5em">
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Smooth:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Optimized for smooth and flowing fly-through experience. Details may
          appear blurred and flattened out, especially on SLAM data. Good for
          sparse point clouds.
        </FaroText>
      </Box>
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Balanced:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Good fly-through experience, details appear sharper especially when
          more points are loaded.
        </FaroText>
      </Box>
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Sharp:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Navigation is fairly smooth, details are enhanced via outlining. Good
          especially for SLAM data, and for obtaining a "dollhouse view" of the
          point cloud.
        </FaroText>
      </Box>
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Sharpest:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Maximum sharpness of detail, noisy fly-through experience. Good for
          detailed annotation/measurement when the camera is not moving.
        </FaroText>
      </Box>
      {hasOriginalProfile && (
        <Box component="div">
          <FaroText dark variant="bodyS" fontWeight="Bold">
            Original:{" "}
          </FaroText>
          <FaroText dark variant="bodyS">
            Show the point cloud original data without any special effect
          </FaroText>
        </Box>
      )}
    </Stack>
  );
}
