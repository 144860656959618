import { EventType } from "@/analytics/analytics-events";
import { ModeNames } from "@/modes/mode";
import {
  useCurrentAreaIfAvailable,
  useCurrentSceneIfAvailable,
} from "@/modes/mode-data-context";
import { selectModeName } from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { selectOverviewSceneFilter } from "@/store/modes/overview-mode-slice";
import { setWalkSceneFilter } from "@/store/modes/walk-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectCanReadCAD,
  selectCanReadPointCloud,
} from "@/store/subscriptions/subscriptions-selectors";
import { SceneFilter } from "@/types/scene-filter";
import {
  DollhouseIcon,
  DollhouseWarningIcon,
  LayoutSheetSmallIcon,
  RunningIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";
import { PointCloudAddOnRequiredMessage } from "./permission-messages";

/**
 * @returns A mode toggle between overview, sheet, and walk mode.
 */
export function ModeSwitch(): JSX.Element {
  const activeMode = useAppSelector(selectModeName);
  const dispatch = useAppDispatch();

  const overviewToggleState = useOverviewToggleState();

  const currentScene = useCurrentSceneIfAvailable();
  const isWalkModeAvailable =
    !!currentScene?.paths.length ||
    !!currentScene?.panos.length ||
    overviewToggleState.isEnabled;

  const overviewSceneFilter = useAppSelector(selectOverviewSceneFilter);

  return (
    <Toolbar>
      <ToolGroup
        value={activeMode}
        onChange={(_, modeName: ModeNames | null) => {
          // modeName can be null when the user clicks on the already selected button
          if (!modeName) return;

          updateAnalyticsEvent(modeName);

          if (modeName === "overview" && !overviewToggleState.isEnabled) return;
          if (
            modeName === "walk" &&
            (!isWalkModeAvailable || activeMode === "split")
          ) {
            return;
          }
          if (activeMode !== modeName) {
            dispatch(changeMode(modeName));
          }
        }}
        orientation="vertical"
        exclusive
      >
        <Tooltip
          title={<Typography>{overviewToggleState.tooltipContent}</Typography>}
          placement="right"
          disableInteractive={!overviewToggleState.isTooltipInteractive}
        >
          <ToolButton
            value="overview"
            selected={activeMode === "overview"}
            disabled={!overviewToggleState.isEnabled}
            sx={overviewToggleState.sx}
          >
            {overviewToggleState.icon}
          </ToolButton>
        </Tooltip>

        <Tooltip title={<Typography>2D overview</Typography>} placement="right">
          <ToolButton
            onClick={() => {
              Analytics.track(EventType.openSheetView);
            }}
            value="sheet"
            selected={activeMode === "sheet"}
          >
            <LayoutSheetSmallIcon />
          </ToolButton>
        </Tooltip>

        <Tooltip title={<Typography>Walk</Typography>} placement="right">
          <ToolButton
            value="walk"
            selected={activeMode === "walk" || activeMode === "split"}
            disabled={!isWalkModeAvailable}
            onClick={() => {
              if (activeMode === "overview") {
                // when transitioning from overview to walk, without a pano
                // as active element, the scene filter is transferred from
                // overview to walk mode.
                dispatch(setWalkSceneFilter(overviewSceneFilter));
              } else if (currentScene) {
                const pano =
                  currentScene.panos.at(0)?.id ?? currentScene.paths.at(0)?.id;
                if (pano) {
                  // Set the pano as the active element so that it's selected in the tree side panel.
                  dispatch(setActiveElement(pano));
                } else {
                  assert(
                    overviewToggleState.hasPointCloud ||
                      overviewToggleState.hasCad,
                    "Walk mode enabled but no valid pano, cad or point cloud exist",
                  );
                  dispatch(
                    setWalkSceneFilter(
                      overviewToggleState.hasPointCloud
                        ? SceneFilter.PointCloud
                        : SceneFilter.Cad,
                    ),
                  );
                }
              }
            }}
          >
            <RunningIcon
              sx={{
                opacity: isWalkModeAvailable ? 1 : 0.5,
              }}
            />
          </ToolButton>
        </Tooltip>
      </ToolGroup>
    </Toolbar>
  );
}

/**
 * Update the analytics event based on certain mode change
 *
 * @param modeName name of the currently clicked mode
 */
function updateAnalyticsEvent(modeName: ModeNames): void {
  if (modeName === "overview") {
    Analytics.track(EventType.openModel3dView);
  } else if (modeName === "walk") {
    // Sending event to analytics when the user clicks on walk mode,
    // along with the type of the data (panorama/pointcloud) the user is looking at
    Analytics.track(EventType.inspectCaptureLocation);
  }
}

/** Current state for the 3d overview button */
type OverviewToggleState = {
  /** True if the button is enabled */
  isEnabled: boolean;

  /** True, when the tooltip is interactive */
  isTooltipInteractive: boolean;

  /** Content of the button tooltip */
  tooltipContent: ReactNode;

  /** Icon to use for the button */
  icon: ReactNode;

  /** Extra style to apply to the button */
  sx?: SxProps<Theme>;

  /** Check if the current selection has a valid point cloud */
  hasPointCloud: boolean;

  /** Check if the current selection has a valid cad */
  hasCad: boolean;
};

/** @returns the current state of the Overview Button based on the app state */
function useOverviewToggleState(): OverviewToggleState {
  const canReadCad = useAppSelector(selectCanReadCAD);
  const canReadPointCloud = useAppSelector(selectCanReadPointCloud);

  const currentScene = useCurrentSceneIfAvailable();
  const currentArea = useCurrentAreaIfAvailable();

  const hasCad = !!currentScene?.cad;
  const hasPointCloud =
    !!currentArea?.dataSessions3d.length ||
    !!currentArea?.dataSessions5d.length;
  const isEnabled =
    (hasCad && canReadCad) || (hasPointCloud && canReadPointCloud);

  const pcPermissionError = hasPointCloud && !canReadPointCloud;

  return {
    isEnabled,
    isTooltipInteractive: pcPermissionError,
    tooltipContent: pcPermissionError ? (
      <PointCloudAddOnRequiredMessage />
    ) : (
      "3D overview"
    ),
    icon: pcPermissionError ? (
      <DollhouseWarningIcon />
    ) : (
      <DollhouseIcon sx={{ opacity: isEnabled ? 1 : 0.5 }} />
    ),
    sx: pcPermissionError ? { opacity: 1 } : undefined,
    hasPointCloud,
    hasCad,
  };
}
