import { useLoadIElements } from "@/registration-tools/common/use-load-ielements";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementGenericPointCloudStream,
  IElementType,
  isValid,
} from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { isEqual } from "es-toolkit";
import { selectPointCloudStreamForScanEntity } from "../store/revision-selectors";

/**
 *
 * @param scanEntities The scans in the revision to load the point cloud streams for.
 * @returns The point cloud streams corresponding to the given revision entities.
 *   If the data is still loading, `undefined` is returned.
 *   Note that some scans may not yet have a processed point cloud stream available.
 */
export function useLoadRevisionPointCloudStreams(
  scanEntities: RevisionScanEntity[],
): IElementGenericPointCloudStream[] | undefined {
  const isLoading = useLoadIElements([
    {
      // Load the point clouds streams from the Project API
      // They are not filtered by the scan entities, as that can cause performance issues in the queries
      // So currently, it can happen that we load more point clouds than are actually needed
      types: [IElementType.pointCloudStream],
    },
  ]);

  const pointCloudStreams = useAppSelector(
    (state) =>
      scanEntities
        .map((entity) => selectPointCloudStreamForScanEntity(entity)(state))
        .filter(isValid),
    isEqual,
  );

  if (isLoading) return;

  return pointCloudStreams;
}
