import {
  FaroText,
  NO_TRANSLATE_CLASS,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { IElementAreaSection } from "@faro-lotv/ielement-types";
import { Box, Stack } from "@mui/system";

type ExistingAreasListProps = {
  /** The existing areas to show in the list */
  existingAreas: IElementAreaSection[];
};

/**
 * @returns a list to show and manage the existing areas in the project.
 * @param existingAreas The existing areas to show in the list.
 */
export function ExistingAreasList({
  existingAreas,
}: ExistingAreasListProps): JSX.Element {
  return (
    <Stack>
      <FaroText variant="labelM" sx={{ pb: 1 }}>
        Existing Areas
      </FaroText>
      <Stack>
        {existingAreas.map((area) => (
          <Box component="div" key={area.id} p={1}>
            <TruncatedFaroText variant="bodyM" className={NO_TRANSLATE_CLASS}>
              {area.name}
            </TruncatedFaroText>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}
