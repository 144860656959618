import { ModeNames } from "@/modes/mode";
import { useCurrentAreaIfAvailable } from "@/modes/mode-data-context";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementGenericImgSheet,
  isIElementOverviewImage,
} from "@faro-lotv/ielement-types";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StoreApi, createStore, useStore } from "zustand";

/**
 * A simple global object shared between sliders in the UI in overview and walk
 * mode, and the rendering pipelines in overview and walk modes.
 * Since using React states or the store has very bad performance, the slider
 * updates directly this object when its value changes. These values are directly
 * loaded to the FBO composing shader at each frame.
 */
export type ObjectsOpacity = {
  /** Opacity to use for overview maps/floor plans, from zero to one */
  mapOpacity: number;

  /** Opacity to use for the Cad models, from zero to one */
  cadOpacity: number;

  /** Opacity to use for Point Clouds, from zero to one */
  pcOpacity: number;
};

/** The default opacity value for scene elements */
const DEFAULT_OPACITIES: ObjectsOpacity = {
  mapOpacity: 1.0,
  cadOpacity: 1.0,
  pcOpacity: 1.0,
};

export type TransparencySettingsContext = {
  /** An object that contains the transparency settings. */
  objectsOpacity: ObjectsOpacity;

  /**
   * Resets the context data to the default values that express full opacity everywhere.
   * Called when the user exits a mode, in order to prevent settings specific of a mode
   * to bleed out to another mode.
   * NOTE: it resets to default values cad and cloud opacity but not layers (layers opacity will preserve current value)
   */
  resetCadAndCloudOpacities(): void;

  /**
   * Change the values of the objects opacities
   *
   * @param opacities new objects opacities
   */
  setOpacities(opacities: ObjectsOpacity): void;

  /**
   * @returns if the opacity values match the default values
   * @param opacities to check
   * @param ignoreMapOpacity true if the slider for layers/maps can be ignored
   */
  areDefaultOpacities(
    opacities: ObjectsOpacity,
    ignoreMapOpacity: boolean,
  ): boolean;
};

/** The actual context for the transparency settings */
const Context = createContext<StoreApi<TransparencySettingsContext> | null>(
  null,
);

type TransparencySettingsProviderProps = PropsWithChildren<{
  /** the list of visible sheets/layers */
  activeSheets: IElementGenericImgSheet[] | undefined;
}>;

/** @returns The provider for the TransparencySettings context */
export function TransparencySettingsProvider({
  activeSheets,
  children,
}: TransparencySettingsProviderProps): JSX.Element | null {
  const activeMode = useAppSelector(selectModeName);
  const previousMode = useRef<ModeNames | undefined>();

  const isThereNonOverviewMapVisible = useMemo(
    () => activeSheets?.some((sheet) => !isIElementOverviewImage(sheet)),
    [activeSheets],
  );

  const [store] = useState<StoreApi<TransparencySettingsContext>>(() =>
    createStore((set) => ({
      objectsOpacity: {
        ...DEFAULT_OPACITIES,
        mapOpacity: isThereNonOverviewMapVisible ? 1 : 0,
      },

      resetCadAndCloudOpacities: () => {
        const curOpacity = store.getState().objectsOpacity;
        set({
          objectsOpacity: {
            ...DEFAULT_OPACITIES,
            mapOpacity: curOpacity.mapOpacity,
          },
        });
      },

      setOpacities: (objectsOpacity) => set({ objectsOpacity }),

      areDefaultOpacities: (objectsOpacity, ignoreMapOpacity) =>
        objectsOpacity.cadOpacity === DEFAULT_OPACITIES.cadOpacity &&
        (objectsOpacity.mapOpacity === DEFAULT_OPACITIES.mapOpacity ||
          ignoreMapOpacity) &&
        objectsOpacity.pcOpacity === DEFAULT_OPACITIES.pcOpacity,
    })),
  );

  /**
   * @param forceLayerOpacityReset - if true (at switch of view modes) we want to force re-evaluation of opacities
   * to zero even if only overview map is selected. But in case when we switch view modes we want allow reset of opacity
   * to zero if only overview map is visible
   */
  const adjustOpacityUsingCurrentLayersSelection = useCallback(
    (forceLayerOpacityReset: boolean) => {
      const curOpacity = store.getState().objectsOpacity;

      // If the sheet opacity is at one of the extremes (either max or min),
      // we should adjust the visibility when the list of visible/active layers changes.
      // However, if the user has set the sheet opacity to a value within the middle range
      // of the slider, we should preserve the current opacity "as is."
      if (curOpacity.mapOpacity <= 0 || curOpacity.mapOpacity >= 1) {
        let opacityToApply = isThereNonOverviewMapVisible
          ? 1
          : curOpacity.mapOpacity;
        if (forceLayerOpacityReset) {
          opacityToApply = isThereNonOverviewMapVisible ? 1 : 0;
        }

        store.setState({
          objectsOpacity: {
            // We preserve cad and cloud opacity while switching layers
            cadOpacity: curOpacity.cadOpacity,
            pcOpacity: curOpacity.pcOpacity,
            // When user enable floor plan visibility, we want to force visibility.
            // But when user leave only overview map visible, we want to preserve current visibility
            mapOpacity: opacityToApply,
          },
        });
      }
    },
    [isThereNonOverviewMapVisible, store],
  );

  // Adjust opacity when activeSheets selection changed
  useEffect(() => {
    adjustOpacityUsingCurrentLayersSelection(false);
  }, [activeSheets, adjustOpacityUsingCurrentLayersSelection]);

  // Adjust opacity when activeMode changed
  useEffect(() => {
    if (activeMode !== previousMode.current) {
      adjustOpacityUsingCurrentLayersSelection(true);
      previousMode.current = activeMode;
    }
  }, [activeMode, adjustOpacityUsingCurrentLayersSelection]);

  // Reset the values in the store when the area changes
  const area = useCurrentAreaIfAvailable();
  const [referenceArea, setReferenceArea] = useState(area?.area?.id);
  useEffect(() => {
    if (area?.area?.id === referenceArea) return;
    store.setState({
      objectsOpacity: {
        ...DEFAULT_OPACITIES,
        mapOpacity: isThereNonOverviewMapVisible ? 1 : 0,
      },
    });
    setReferenceArea(area?.area?.id);
  }, [area, isThereNonOverviewMapVisible, referenceArea, store]);

  return <Context.Provider value={store}>{children}</Context.Provider>;
}

export function useTransparencySettingsContext(): TransparencySettingsContext;
export function useTransparencySettingsContext<T>(
  selector: (state: TransparencySettingsContext) => T,
): T;
/**
 * @returns The result of the selector after parsing the state
 * @param selector The function used to parse the state. If no selector is defined, the whole context is returned
 */
export function useTransparencySettingsContext(
  selector = (state: TransparencySettingsContext) => state,
): TransparencySettingsContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error("TransparencySettingsContext is not initialized.");
  }
  return useStore(context, selector);
}
