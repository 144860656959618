import { removeBackgroundTask } from "@/store/background-tasks/background-tasks-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  BackgroundTask,
  getErrorCodeAndTaskName,
} from "@/utils/background-tasks";
import {
  FaroButton,
  FaroText,
  FontWeights,
  neutral,
  NoTranslate,
  red,
} from "@faro-lotv/flat-ui";
import { selectIElement } from "@faro-lotv/project-source";
import {
  ProgressApiSupportedTaskTypes,
  taskErrorToUserMessage,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { ElementIconType } from "../../icons/element-icon-type";
import { CardAction, GenericCardLayout } from "../layouts/generic-card-layout";
import { taskErrorDetails } from "./task-error-details";

type ErrorCardProps<Task extends BackgroundTask = BackgroundTask> = {
  /** Id of the task to show an error card for */
  task: Task;
};

/**
 * @returns Dismissible card showing an error and the cause, if available
 */
export function ErrorCard({ task }: ErrorCardProps): JSX.Element {
  const dispatch = useAppDispatch();
  const element = useAppSelector(selectIElement(task.iElementId ?? ""));

  const dismissErrorTask = useCallback((): void => {
    dispatch(removeBackgroundTask(task.id));
  }, [dispatch, task.id]);

  const { taskName, errorCode } = getErrorCodeAndTaskName(task, element?.name);

  const elementName = useMemo(() => {
    if (element?.name) return <NoTranslate>{element.name}</NoTranslate>;
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return "SCENE conversion";
    }
  }, [task, element]);

  const errorMessage = useMemo(() => {
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return (
        <ErrorMessageWithDetails
          message={taskErrorToUserMessage(taskName, errorCode)}
          hint="Save again the project in SCENE and press the Sync button to start the processing."
          errorDetails={taskErrorDetails(task)}
        />
      );
    }
    return (
      <FaroText
        variant="bodyS"
        color={red[500]}
        fontWeight={FontWeights.SemiBold}
      >
        {taskErrorToUserMessage(taskName, errorCode)}
      </FaroText>
    );
  }, [errorCode, task, taskName]);

  const menu = useMemo<CardAction[]>(() => {
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) return [];
    return [
      {
        name: "Clear",
        callback: dismissErrorTask,
      },
    ];
  }, [dismissErrorTask, task.type]);

  return (
    <GenericCardLayout
      name={elementName}
      icon={
        task.type === ProgressApiSupportedTaskTypes.sceneConversion
          ? ElementIconType.FolderIcon
          : undefined
      }
      menu={menu}
      startTime={task.createdAt}
      sx={{ gap: 1.25 }}
    >
      {errorMessage}
    </GenericCardLayout>
  );
}

type ErrorMessageWithDetailsProps = {
  /** The main error message top show */
  message: ReactNode;

  /** A resolution hint to provide more details to the user */
  hint?: ReactNode;

  /** More details about the error, that are initially collapsed */
  errorDetails?: ReactNode;
};

function ErrorMessageWithDetails({
  message,
  hint,
  errorDetails,
}: ErrorMessageWithDetailsProps): JSX.Element {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <FaroText variant="labelM" color={red[500]}>
          {message}
        </FaroText>
        {errorDetails && (
          <FaroButton
            variant="ghost"
            size="xs"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? "Hide" : "Show"} Details
          </FaroButton>
        )}
      </Stack>

      {hint && (
        <FaroText variant="bodyS" color={neutral[600]}>
          {hint}
        </FaroText>
      )}

      {showDetails && errorDetails}
    </>
  );
}
