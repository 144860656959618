import { GUID } from "@faro-lotv/foundation";

/** A connection between two scans. */
export type ScanConnection = {
  /** The ID of the source scan. */
  sourceId: GUID;
  /** The ID of the target scan. */
  targetId: GUID;
};

/**
 * @param connection1 The first connection
 * @param connection2 The second connection
 * @returns `true` if the connections connect the same scans (in any direction), else `false`.
 */
export function areConnectionsEqual(
  connection1: ScanConnection,
  connection2: ScanConnection,
): boolean {
  return (
    (connection1.sourceId === connection2.sourceId &&
      connection1.targetId === connection2.targetId) ||
    (connection1.sourceId === connection2.targetId &&
      connection1.targetId === connection2.sourceId)
  );
}
