import {
  selectAlignedElementIdForCloudToSheetAlignment,
  selectReferenceElementIdForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectChildDepthFirst } from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElementAreaSection,
  IElementGenericPointCloudStream,
  IElementSectionDataSession,
  isIElementAreaSection,
  isIElementGenericPointCloudStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";

/** elements used in cloud-to-sheet alignment */
export type CloudToSheetAlignmentElements = {
  /** area to use as reference in cloud-to-sheet alignment  */
  sectionArea: IElementAreaSection;

  /** cloud DataSession to use in cloud-to-sheet alignment  */
  cloudDataSession: IElementSectionDataSession;

  /** cloud PointStream to use in cloud-to-sheet alignment  */
  cloudPointStream: IElementGenericPointCloudStream;
};

/**
 * @returns elements used in cloud-to-sheet alignment
 */
export function useCloudToSheetAlignmentElements(): CloudToSheetAlignmentElements {
  const alignmentReferenceAreaID = useAppSelector(
    selectReferenceElementIdForCloudToSheetAlignment,
  );
  const sectionArea = useAppSelector(selectIElement(alignmentReferenceAreaID));
  assert(
    sectionArea && isIElementAreaSection(sectionArea),
    "invalid reference element for alignment",
  );

  const cloudId = useAppSelector(
    selectAlignedElementIdForCloudToSheetAlignment,
  );
  const cloudDataSession = useAppSelector(selectIElement(cloudId));
  assert(
    cloudDataSession && isIElementSectionDataSession(cloudDataSession),
    "invalid aligned element for alignment",
  );

  const cloudPointStream = useAppSelector(
    selectChildDepthFirst(cloudDataSession, isIElementGenericPointCloudStream),
  );
  assert(
    cloudPointStream && isIElementAreaSection(sectionArea),
    "Cloud-to-sheet alignment mode requires a valid cloud",
  );

  return { sectionArea, cloudDataSession, cloudPointStream };
}
