import {
  useLocalStorage,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { NewFeaturePopup, NewFeaturePopupProps } from "./new-feature-popup";

type FeatureProps = NewFeaturePopupProps & {
  /** The key used to store in the localStorage if the user sees the new feature for the first time */
  localStorage: string;
};

type NewFeatureContext = {
  /** Add a new feature popup to the queue */
  enqueuePopup(props: FeatureProps): void;

  /** Remove a new feature popup from the queue */
  removePopup(localStorage: string): void;
};

export const NewFeatureContext = createContext<NewFeatureContext | undefined>(
  undefined,
);

/** @returns a context that determines based on user input and ui state what data each mode should render */
export function NewFeatureProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [queue] = useState<FeatureProps[]>([]);
  const enqueuePopup = useCallback(
    (props: FeatureProps) => {
      const index = queue.findIndex(
        (p) => p.localStorage === props.localStorage,
      );
      if (index < 0) {
        // Push the new popup in the queue
        queue.push(props);
      } else {
        // Updated the properties, e.g. the anchor element could have changed
        queue[index] = props;
      }
      // Update the current visible popup
      setPopup(queue.at(0));
    },
    [queue],
  );

  const removePopup = useCallback(
    (localStorage: string) => {
      const index = queue.findIndex(
        (props) => props.localStorage === localStorage,
      );
      if (index > -1) {
        // Remove the popup from the queue
        queue.splice(index, 1);
      }
      setPopup(queue.at(0));
    },
    [queue],
  );

  const [popup, setPopup] = useState<FeatureProps>();

  const value = useMemo(
    () => ({ enqueuePopup, removePopup }),
    [enqueuePopup, removePopup],
  );

  return (
    <NewFeatureContext.Provider value={value}>
      {children}
      {popup && <NewFeaturePopup key={popup.localStorage} {...popup} />}
    </NewFeatureContext.Provider>
  );
}

/**
 * Show a popup for a new feature
 *
 * @param props The properties needed for correctly showing the popup
 * @returns True if the feature is new and visible
 */
export function useNewFeaturePopup(
  props: Omit<FeatureProps, "onClose"> & {
    /** Check if the popup should be visible to the user */
    isVisible: boolean;
  },
): boolean {
  const context = useContext(NewFeatureContext);
  assert(
    context,
    "useNewFeaturePopup need to be used inside a NewFeatureProvider",
  );

  const [isNewFeature, setIsNewFeature] = useLocalStorage(
    props.localStorage,
    true,
  );

  // Using non exhaustive effect because we don't want to trigger it when title or
  // description change, just when the visibility does
  useNonExhaustiveEffect(() => {
    if (!props.isVisible || !props.anchorEl || !isNewFeature) return;
    context.enqueuePopup({
      ...props,
      onClose: () => setIsNewFeature(false),
    });
    return () => {
      context.removePopup(props.localStorage);
    };
  }, [context, isNewFeature, props.isVisible, props.anchorEl, setIsNewFeature]);

  return isNewFeature;
}
