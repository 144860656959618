import { EventType } from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppSelector } from "@/store/store-hooks";
import { redirectToViewer } from "@/utils/redirects";
import { FaroButton, useToast, WarningIcon } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID } from "@faro-lotv/foundation";
import {
  getProjectRawDataPage,
  selectDashboardUrl,
} from "@faro-lotv/project-source";
import {
  isMergeConflictError,
  RegistrationRevision,
  RegistrationState,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";

type PublishButtonProps = {
  /** The revision to publish. */
  revision: RegistrationRevision;
  /** Whether the button should be disabled. */
  disabledDueToError: boolean;
  /** Whether the button should show a warning on the left side. */
  showWithWarning: boolean;
};

/** @returns A button to publish a revision, i.e. merge it into the main revision. */
export function PublishButton({
  revision,
  disabledDueToError,
  showWithWarning,
}: PublishButtonProps): JSX.Element {
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const { handleErrorWithToast } = useErrorHandlers();
  const { projectApiClient } = useApiClientContext();

  const openMergeConflictToast = useOpenMergeConflictToast(
    projectApiClient.projectId,
  );

  // Only registered revisions can be published
  const isDisabled =
    revision.state !== RegistrationState.registered || disabledDueToError;

  const publishRevision = useCallback(async () => {
    Analytics.track(EventType.publishRegistration);

    setIsPublishLoading(true);

    try {
      await projectApiClient.applyRegistrationRevisionToMain(revision.id);
      redirectToViewer(projectApiClient.projectId);
    } catch (error) {
      if (isMergeConflictError(error)) {
        openMergeConflictToast();
        return;
      }

      handleErrorWithToast({
        error,
        title: "Failed to publish data",
      });
    }

    setIsPublishLoading(false);
  }, [
    projectApiClient,
    revision.id,
    handleErrorWithToast,
    openMergeConflictToast,
  ]);

  return (
    <FaroButton
      variant="primary"
      disabled={isDisabled}
      isLoading={isPublishLoading}
      onClick={publishRevision}
      icon={showWithWarning ? <WarningIcon /> : null}
    >
      Publish
    </FaroButton>
  );
}

/**
 * @returns a method to open a toast informing the user about how to resolve a merge conflict for the data-preparation workflow
 * @param projectId the current project id
 */
function useOpenMergeConflictToast(projectId: GUID): () => void {
  const { openToast } = useToast();
  const dashboardUrl = useAppSelector(selectDashboardUrl);

  return useCallback(() => {
    const action = dashboardUrl
      ? {
          label: "Restart",
          onClicked: () => {
            window.location.assign(
              getProjectRawDataPage(dashboardUrl, projectId),
            );
          },
        }
      : undefined;

    openToast({
      variant: "error",
      title: "Conflicting changes",
      message:
        "Your project has been changed by another user. Restart the registration workflow to incorporate the latest changes.",
      action,
    });
  }, [dashboardUrl, openToast, projectId]);
}
