import { FontWeights, neutral, orange } from "@faro-lotv/flat-ui";
import { Chip, Typography } from "@mui/material";

type BetaBadgeProps = {
  /** Whether dark mode styles should be applied. @default false */
  dark?: boolean;
};

/**
 * @returns a red chips to indicate that the feature is in beta
 */
export function BetaBadge({ dark }: BetaBadgeProps): JSX.Element {
  return <CustomBadge badgeText="BETA" dark={dark} />;
}

export type CustomBadgeProps = {
  /** text on custom badge */
  badgeText: string;

  /** Whether dark mode styles should be applied. @default false */
  dark?: boolean;
};

/**
 * @returns a red chips to with badge containing custom text
 */
function CustomBadge({ badgeText, dark }: CustomBadgeProps): JSX.Element {
  return (
    <Chip
      label={
        <Typography
          sx={{
            color: dark ? neutral[950] : neutral[100],
            /** Using pixel instead of rem units because the Badge is using it is set in pixel */
            fontSize: "10px",
            fontWeight: FontWeights.Bold,
            /** Using pixel instead of rem units because the Badge is using it is set in pixel */
            lineHeight: "12px",
          }}
        >
          {badgeText}
        </Typography>
      }
      sx={{
        py: "2px",
        px: "4px",
        height: "unset",
        backgroundColor: orange[500],
        width: "fit-content",

        "& .MuiChip-label": {
          p: 0,
        },
      }}
    />
  );
}
