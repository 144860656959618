import { Vector2, Vector3Tuple } from "three";
import { AlignmentTransform } from "./alignment-transform";

// alignment anchor positions in in W.C.S., in meters
// These anchor positions will be used to calculate transform matrix
// to align alignedElement (sheet or cloud) to cad model so that movingElementAnchor1 will match referenceElementAnchor1
// and movingElementAnchor2 will match referenceElementAnchor2
// (see https://faro01.atlassian.net/browse/CADBIM-282)
export type TwoPointsPairsAlignmentAnchorPositions = {
  // first anchor on moving element
  movingElementAnchor1?: Vector3Tuple;

  // second  anchor on moving element
  movingElementAnchor2?: Vector3Tuple;

  // first  anchor on reference (not moving) Element
  referenceElementAnchor1?: Vector3Tuple;

  // second  anchor on reference (not moving) Element
  referenceElementAnchor2?: Vector3Tuple;
};

// The minimum distance in meter
// If the distance between two anchor points at any side is less than this value, they will be treated as the same point.
const MINIMUM_DISTANCE = 0.001;

/**
 * Compute alignment transform using pairs of anchors in two screens
 *
 * @param alignmentAnchorPositions anchor positions from split screens
 * @param allowScaling if true allow scaling. if false preserve original scale of moving Element
 * @returns the alignmentTransform or undefined there is not enough anchors
 */
export function computeSplitScreenAlignment(
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions,
  allowScaling: boolean,
): AlignmentTransform | undefined {
  if (
    alignmentAnchorPositions.movingElementAnchor1 &&
    alignmentAnchorPositions.referenceElementAnchor1 &&
    alignmentAnchorPositions.movingElementAnchor2 &&
    alignmentAnchorPositions.referenceElementAnchor2
  ) {
    // compute alignment matrix with translation/rotation/scaling
    // Note: (z, x) in 3D -> (x, y) in 2D

    // center position in Z-X between reference anchor1 and reference anchor2
    const referenceCenter2d = new Vector2(
      alignmentAnchorPositions.referenceElementAnchor1[2] +
        alignmentAnchorPositions.referenceElementAnchor2[2],
      alignmentAnchorPositions.referenceElementAnchor1[0] +
        alignmentAnchorPositions.referenceElementAnchor2[0],
    ).divideScalar(2);
    // vector from reference anchor2 to reference anchor1 in Z-X
    const referenceVector2d = new Vector2(
      alignmentAnchorPositions.referenceElementAnchor1[2] -
        alignmentAnchorPositions.referenceElementAnchor2[2],
      alignmentAnchorPositions.referenceElementAnchor1[0] -
        alignmentAnchorPositions.referenceElementAnchor2[0],
    );
    if (referenceVector2d.length() < MINIMUM_DISTANCE) return undefined;

    // center position in Z-X between moving anchor1 and moving anchor2
    const movingCenter2d = new Vector2(
      alignmentAnchorPositions.movingElementAnchor1[2] +
        alignmentAnchorPositions.movingElementAnchor2[2],
      alignmentAnchorPositions.movingElementAnchor1[0] +
        alignmentAnchorPositions.movingElementAnchor2[0],
    ).divideScalar(2);
    // vector from moving anchor2 to moving anchor1 in Z-X
    const movingVector2d = new Vector2(
      alignmentAnchorPositions.movingElementAnchor1[2] -
        alignmentAnchorPositions.movingElementAnchor2[2],
      alignmentAnchorPositions.movingElementAnchor1[0] -
        alignmentAnchorPositions.movingElementAnchor2[0],
    );
    if (movingVector2d.length() < MINIMUM_DISTANCE) return undefined;

    // scaling factor (in case of cloud-to-cad alignment is always equal to one)
    const scaleFactor = allowScaling
      ? referenceVector2d.length() / movingVector2d.length()
      : 1;

    // angle to rotate around Y
    const theta = referenceVector2d.angle() - movingVector2d.angle();

    // scale and rotate the moving center
    movingCenter2d
      .multiplyScalar(scaleFactor)
      .rotateAround(new Vector2(0, 0), theta);

    return {
      // rotate around Y
      quaternion: [0, Math.sin(theta / 2), 0, Math.cos(theta / 2)],

      // scale to make distance between the 2 movingElement anchors equal to the distance between the 2 referenceElement anchors
      scale: [scaleFactor, scaleFactor, scaleFactor],

      // transpose movingElement anchor center to referenceElement anchor center
      position: [
        referenceCenter2d.y - movingCenter2d.y,
        0,
        referenceCenter2d.x - movingCenter2d.x,
      ],
    };
  }

  // eslint-disable-next-line func-style -- FIXME
  const translateInXZ = (
    startPoint: Vector3Tuple,
    endPoint: Vector3Tuple,
  ): AlignmentTransform => ({
    scale: [1, 1, 1],
    quaternion: [0, 0, 0, 1],
    position: [endPoint[0] - startPoint[0], 0, endPoint[2] - startPoint[2]],
  });

  if (
    alignmentAnchorPositions.movingElementAnchor1 &&
    alignmentAnchorPositions.referenceElementAnchor1
  ) {
    // compute alignment matrix with translation only: transpose movingElementAnchor1 to referenceElementAnchor1
    return translateInXZ(
      alignmentAnchorPositions.movingElementAnchor1,
      alignmentAnchorPositions.referenceElementAnchor1,
    );
  }

  if (
    alignmentAnchorPositions.movingElementAnchor2 &&
    alignmentAnchorPositions.referenceElementAnchor2
  ) {
    // compute alignment matrix with translation only: transpose movingElementAnchor2 to referenceElementAnchor2
    return translateInXZ(
      alignmentAnchorPositions.movingElementAnchor2,
      alignmentAnchorPositions.referenceElementAnchor2,
    );
  }
}
