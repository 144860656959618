import {
  EventType,
  OpenAnnotationAttachmentEventProperties,
  OpenTagManagementProperties,
  SaveAnnotationEventProperties,
} from "@/analytics/analytics-events";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectAnnotationAssignableUsers } from "@/store/project-selector";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectTags } from "@/store/tags/tags-selectors";
import { Tag, UNTAGGED } from "@/store/tags/tags-slice";
import { selectCurrentUser } from "@/store/user-selectors";
import { STATUS_OPTIONS } from "@faro-lotv/app-component-toolbox";
import { AnnotationEditor, UserAvatar } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  IElementMarkup,
  IElementType,
  IElementTypeHint,
  isIElementAttachment,
  isIElementMarkupAssignee,
  isIElementMarkupDueTime,
  isIElementMarkupState,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  selectAdvancedMarkupTemplateIds,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectMarkupsDisplayForViewers,
  selectRootId,
} from "@faro-lotv/project-source";
import {
  Mutation,
  createMutationAddAllowedLabel,
  createMutationAddLabel,
  createMutationRemoveLabel,
  createMutationSetElementDescription,
  createMutationSetElementName,
} from "@faro-lotv/service-wires";
import { isEqual } from "es-toolkit";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { TagsManagementDialog } from "../tags/tags-management-dialog";
import {
  createEditAssigneeMutation,
  createEditDueDateMutation,
  createEditStatusMutation,
} from "./annotation-mutations";
import { AnnotationProps, NewAttachment } from "./annotation-props";
import { createAttachments, deleteAttachments } from "./attachment-mutations";
import { useAnnotationAttachments } from "./use-annotation-attachments";
import { useAnnotationTagOptions } from "./use-annotation-tag-options";
import { useAnnotationUploadAttachments } from "./use-annotation-upload-attachments";

export type EditAnnotationProps = AnnotationProps & {
  /** IElement for the Markup(Markup), that is parent of the fields */
  iElement: IElementMarkup;

  /** Function called when the dialog should closed */
  onClose?(): void;
};

/**
 * @returns A form to edit an already existing annotation and update the project
 */
export function EditAnnotation({
  children,
  iElement,
  title: originalTitle,
  description: originalDescription,
  assignee: originalAssignee,
  status: originalStatus,
  dueDate: originalDueDate,
  onClose,
}: PropsWithChildren<EditAnnotationProps>): JSX.Element | null {
  const [title, setTitle] = useState(originalTitle);
  const [assignee, setAssignee] = useState(originalAssignee);
  const [status, setStatus] = useState(originalStatus);
  const [dueDate, setDueDate] = useState(originalDueDate);

  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState<string>();

  const templateIds = useAppSelector(selectAdvancedMarkupTemplateIds);
  assert(templateIds, "Expected project to have an advanced markup template");
  const allowViewers = useAppSelector(selectMarkupsDisplayForViewers);
  const projectUsers = useAppSelector(
    selectAnnotationAssignableUsers(!!allowViewers),
    isEqual,
  );
  const { handleErrorWithToast } = useErrorHandlers();

  const dispatch = useAppDispatch();
  const { getState } = useAppStore();

  const client = useCurrentProjectApiClient();

  // List of the pre-existing attachments for this annotation
  const attachmentsElements = useAppSelector(
    selectChildrenDepthFirst(iElement, isIElementAttachment),
    isEqual,
  );

  const currentUser = useAppSelector(selectCurrentUser);

  const { attachments, addNewAttachment } = useAnnotationAttachments(
    attachmentsElements,
    true,
    "Edit",
  );

  // List of all the attachments that the user removed from the annotations and should be removed from the project
  const attachmentsToDelete = useMemo(
    () =>
      attachmentsElements.filter(
        (el) => !attachments.find((a) => a.id === el.id),
      ),
    [attachmentsElements, attachments],
  );

  const { uploadAttachments, progress } = useAnnotationUploadAttachments();

  // Tags management
  const projectTags = useAppSelector(selectTags);
  const tags = useMemo(
    () => projectTags.filter((t) => t.name !== UNTAGGED.name),
    [projectTags],
  );
  const elementTags = useMemo(
    () => iElement.labels?.map((l) => ({ id: l.id, name: l.name })) ?? [],
    [iElement.labels],
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>(() => [
    ...elementTags,
  ]);
  const { tagsToRemove, tagsToAdd, newTags } = useEditedTags(
    tags,
    elementTags,
    selectedTags,
  );

  const rootId = useAppSelector(selectRootId);

  /**
   * Function called when the Edit button is clicked.
   * It will send the mutations to update the Annotation's fields and
   * it will fetch the changed subtree to update the store
   */
  const onEdit = useCallback(async () => {
    // Show spinner
    setIsSaving(true);

    let newAttachments: NewAttachment[] = [];
    try {
      newAttachments = await uploadAttachments(attachments);
    } catch (error) {
      handleErrorWithToast({ title: "Upload of attachments failed", error });
      setIsSaving(false);
      return;
    }

    Analytics.track<SaveAnnotationEventProperties>(EventType.saveAnnotation, {
      isNew: false,
      statusSet: !!status,
      assigneeSet: !!assignee,
      assignToCurrentUser: !!currentUser && assignee === currentUser.id,
      dateSet: !!dueDate,
      descriptionSet: !!description,
      numberOfAttachmentsAdded: newAttachments.length,
      tagsAdded: tagsToAdd.length,
      tagsRemoved: tagsToRemove.length,
    });

    const mutations: Mutation[] = [];

    if (title !== originalTitle) {
      mutations.push(createMutationSetElementName(iElement.id, title));
    }

    if (description !== originalDescription) {
      mutations.push(
        createMutationSetElementDescription(iElement.id, description ?? ""),
      );
    }

    if (assignee !== originalAssignee) {
      const assigneeChildrenElement = selectChildDepthFirst(
        iElement,
        isIElementMarkupAssignee,
      )(getState());
      mutations.push(
        ...createEditAssigneeMutation(
          iElement,
          assignee,
          assigneeChildrenElement?.id,
          templateIds.assigneeTemplateId,
        ),
      );
    }

    if (status !== originalStatus) {
      const statusChildrenElement = selectChildDepthFirst(
        iElement,
        isIElementMarkupState,
      )(getState());
      mutations.push(
        ...createEditStatusMutation(
          iElement,
          status,
          statusChildrenElement?.id,
          templateIds.statusTemplateId,
        ),
      );
    }

    if (dueDate?.valueOf() !== originalDueDate?.valueOf()) {
      const dueDateChildrenElement = selectChildDepthFirst(
        iElement,
        isIElementMarkupDueTime,
      )(getState());
      mutations.push(
        ...createEditDueDateMutation(
          iElement,
          dueDate,
          dueDateChildrenElement?.id,
          templateIds.dueDateTemplateId,
        ),
      );
    }

    // Check if the attachments group already exists
    const attachmentGroup = selectChildDepthFirst(iElement, (el) =>
      isIElementWithTypeAndHint(
        el,
        IElementType.group,
        IElementTypeHint.attachments,
      ),
    )(getState());

    mutations.push(
      ...createAttachments(
        iElement.rootId,
        iElement.id,
        newAttachments,
        attachmentGroup?.id,
      ),
      ...deleteAttachments(attachmentsToDelete),
    );

    // NOTE: Skip syncedWith since we don't support syncing

    // Tags management
    mutations.push(
      ...newTags.map((t) =>
        createMutationAddAllowedLabel({
          name: t.name,
          id: t.id,
          createdAt: new Date().toISOString(),
          resourceId: rootId ?? "",
        }),
      ),
      ...tagsToAdd.map((t) => createMutationAddLabel(iElement.id, t.id)),
      ...tagsToRemove.map((t) => createMutationRemoveLabel(iElement.id, t.id)),
    );

    // Update the project
    try {
      await client.applyMutations(mutations);

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        updateProject({
          projectApi: client,
          iElementQuery: {
            ancestorIds: [iElement.id],
          },
        }),
      );

      onClose?.();
    } catch (error) {
      handleErrorWithToast({ title: "Could not edit Annotation", error });
    }

    // Remove spinner
    setIsSaving(false);
  }, [
    assignee,
    attachments,
    attachmentsToDelete,
    client,
    currentUser,
    description,
    dispatch,
    dueDate,
    getState,
    handleErrorWithToast,
    iElement,
    newTags,
    onClose,
    originalAssignee,
    originalDescription,
    originalDueDate,
    originalStatus,
    originalTitle,
    rootId,
    status,
    tagsToAdd,
    tagsToRemove,
    templateIds,
    title,
    uploadAttachments,
  ]);

  const onAttachmentOpened = useCallback(
    (fileType: string) =>
      Analytics.track<OpenAnnotationAttachmentEventProperties>(
        EventType.openAnnotationAttachment,
        {
          fileType,
        },
      ),
    [],
  );

  const { tagsOptions, isProcessing } = useAnnotationTagOptions({
    tags,
    onDeleteButtonClick: (tag) => {
      // Remove the deleted tag from the selected tags
      setSelectedTags((prev: Tag[]) => prev.filter((t) => t.id !== tag.id));
    },
  });

  const [isTagsManagementOpen, setIsTagsManagementOpen] = useState(false);

  return (
    <>
      <AnnotationEditor
        onDescriptionError={(error) =>
          handleErrorWithToast({ title: "Error in description", error })
        }
        disabled={isSaving || isProcessing}
        showConfirmButtonSpinner={isSaving}
        title={title}
        initialDescription={originalDescription}
        assignee={assignee}
        status={status}
        initialDate={originalDueDate}
        selectedAnnotationType=""
        attachments={attachments}
        tags={selectedTags}
        addNewAttachment={addNewAttachment}
        onTagsChange={setSelectedTags}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
        onAssigneeChange={(e) => setAssignee(e.target.value)}
        onStatusChange={(e) => setStatus(e.target.value)}
        onAnnotationTypeChange={() => {}}
        onDueDateChange={(date) => {
          setDueDate(date);
        }}
        assigneeOptions={projectUsers.map((user) => ({
          key: user.id ?? user.email,
          value: user.id ?? user.email,
          label: (
            <UserAvatar
              userDisplayInfo={user}
              size="xs"
              shouldShowWhiteRim={false}
              displayOption="nameAndEmail"
              dark
            />
          ),
        }))}
        statusOptions={STATUS_OPTIONS}
        annotationTypeOptions={[]}
        tagsOptions={tagsOptions}
        // We don't support editing external annotation yet
        showAnnotationTypeDropdown={false}
        shouldShowAttachments
        onManageTagsClick={() => {
          Analytics.track<OpenTagManagementProperties>(
            EventType.openTagManagement,
            { via: "annotation" },
          );
          setIsTagsManagementOpen(true);
        }}
        onCancelButtonClick={() => {
          Analytics.track(EventType.cancelAnnotationEditing);
          onClose?.();
        }}
        onConfirmButtonClick={onEdit}
        progress={progress}
        onAttachmentOpened={onAttachmentOpened}
      >
        {children}
      </AnnotationEditor>
      <TagsManagementDialog
        open={isTagsManagementOpen}
        onClose={() => setIsTagsManagementOpen(false)}
      />
    </>
  );
}

type EditedTags = {
  tagsToRemove: Tag[];
  tagsToAdd: Tag[];
  newTags: Tag[];
};

/**
 * @returns The array containing which tags should be:
 *          - added to the project
 *          - added to the element
 *          - removed from the element
 * @param tags The list of all tags in the project
 * @param elementTags The list of tags already assigned to the element
 * @param selectedTags The new list of tags for the element selected by the user
 */
function useEditedTags(
  tags: Tag[],
  elementTags: Tag[],
  selectedTags: Tag[],
): EditedTags {
  return useMemo(() => {
    const tagsToAdd: Tag[] = [];
    const newTags: Tag[] = [];
    for (const tag of selectedTags) {
      if (!tags.find((t) => tag.id === t.id)) {
        newTags.push(tag);
      }
      if (!elementTags.find((t) => tag.id === t.id)) {
        tagsToAdd.push(tag);
      }
    }
    const tagsToRemove = elementTags.filter(
      (t) => !selectedTags.find((tag) => t.id === tag.id),
    );
    return { tagsToRemove, tagsToAdd, newTags };
  }, [elementTags, tags, selectedTags]);
}
