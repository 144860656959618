import { createAlignmentMutation } from "@/modes/alignment-modes-commons/project-alignment-mutations";
import { store } from "@/store/store";
import { assert } from "@faro-lotv/foundation";
import {
  IElementGenericPointCloudStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { ProjectApi } from "@faro-lotv/service-wires";
import { Matrix4, Matrix4Tuple } from "three";

/**
 * Updates the pose of the given model point cloud in the projectApi, based on the provided transformation matrix.
 *
 * @param projectApi projectApi instance to send the mutation with.
 * @param modelPointCloud pointcloud stream of the model point cloud that should be transformed.
 * @param modelPcTransformRaw the transform that should be applied to the model point cloud.
 */
export async function applyPairwiseRegistration(
  projectApi: ProjectApi,
  modelPointCloud: IElementGenericPointCloudStream,
  modelPcTransformRaw: Matrix4Tuple,
): Promise<void> {
  const modelPcTransform = new Matrix4().fromArray(modelPcTransformRaw);

  const modelPointCloudSection = selectAncestor(
    modelPointCloud,
    isIElementSectionDataSession,
  )(store.getState());

  assert(
    modelPointCloudSection,
    "Model PointCloud is not part of the project, unable to find PointCloud section",
  );

  await projectApi.applyMutations([
    createAlignmentMutation(
      modelPointCloud,
      modelPcTransform,
      modelPointCloudSection,
      store.getState(),
    ),
  ]);
}
