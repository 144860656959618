import {
  ScaleReset,
  useForwardProp,
  useForwardPropIfDefined,
  useLotvDispose,
} from "@faro-lotv/app-component-toolbox";
import { BillboardScreenSprite } from "@faro-lotv/lotv";
import { Vector3 as Vector3Prop } from "@react-three/fiber";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useEffect, useMemo } from "react";
import { CircleGeometry, Color, ColorRepresentation, Texture } from "three";
import {
  INACTIVE_MEASURES_OPACITY,
  MeasureDepthOffsets,
} from "./measure-constants";

// Common geometry to reuse for the handlers
const geometry = new CircleGeometry(0.1);

type HandlerRendererProps = EventHandlers & {
  /** Position of the handler */
  position: Vector3Prop;

  /** Size in px of the diameter of the renderer */
  size: number;

  /** Color of the handler */
  color: ColorRepresentation;

  /** A custom texture to use for the handler */
  texture?: Texture;

  /** True if this label should be transparent at INACTIVE_MEASURES_OPACITY, false if it should be opaque. */
  transparent?: boolean;
};

/**
 * @returns a billboard, circular 3d object that has a fixed screen size in pixels.
 */
export function HandlerRenderer({
  position,
  size,
  color,
  texture,
  transparent,
  ...rest
}: HandlerRendererProps): JSX.Element {
  const sprite = useMemo(() => {
    const theSprite = new BillboardScreenSprite(geometry);
    theSprite.material.zOffset = MeasureDepthOffsets.handler;
    return theSprite;
  }, []);

  useLotvDispose(sprite);

  useForwardProp(sprite, "size", size);
  useForwardPropIfDefined(sprite.material, "map", texture);
  useForwardProp(
    sprite,
    "opacity",
    transparent ? INACTIVE_MEASURES_OPACITY : 1.0,
  );
  useEffect(() => {
    sprite.material.color = new Color(color);
  }, [sprite, color]);

  return (
    <ScaleReset position={position}>
      <primitive object={sprite} {...rest} />
    </ScaleReset>
  );
}
