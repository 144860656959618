import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanAllMembersExportPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import { selectHasWritePermission } from "@/store/user-selectors";
import { useAuthContext } from "@faro-lotv/gate-keepers";

type ExportModePermissions = {
  /** True if at least one export tool is available */
  canUseExportMode: boolean;

  /** True if the point cloud volume export is available */
  canUsePointCloudExport: boolean;

  /** True if the ortho photo export is available */
  canUseOrthoPhotoExport: boolean;
};

/** @returns whether the current user is allowed to use the export mode */
export function useExportModePermissions(): ExportModePermissions {
  // The point cloud export requires a userId to be started
  const { isLoggedIn } = useAuthContext();

  // Everyone with write permissions is allowed to export
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  // Users with read permissions can export, when it has been enabled for the company
  // We also allow the orthophoto export when the point cloud export is allowed.
  const canAllMembersExportPointCloud = useAppSelector(
    selectCanAllMembersExportPointCloud,
  );

  // We need this flag to allow our third part supplier of generated floor plans to access
  // the ortho photo export.
  const canUseOrthoPhotoExportFlag = useAppSelector(
    selectHasFeature(Features.AllowOrthoPhotoExport),
  );

  const canUsePointCloudExport =
    isLoggedIn && (hasWritePermission || canAllMembersExportPointCloud);
  const canUseOrthoPhotoExport =
    canUsePointCloudExport || canUseOrthoPhotoExportFlag;

  return {
    canUseOrthoPhotoExport,
    canUsePointCloudExport,
    canUseExportMode: canUsePointCloudExport || canUseOrthoPhotoExport,
  };
}
