import { FaroPopover, FaroPopoverProps } from "@faro-lotv/flat-ui";

export type NewFeaturePopupProps = Pick<
  FaroPopoverProps,
  "anchorEl" | "title" | "description" | "onClose" | "placement" | "chipLabel"
>;

/**
 * @returns the component to show the popover for a new feature multi-layer tool.
 */
export function NewFeaturePopup({
  anchorEl,
  title,
  description,
  placement,
  onClose,
  chipLabel,
}: NewFeaturePopupProps): JSX.Element | null {
  return anchorEl ? (
    <FaroPopover
      dark
      open
      anchorEl={anchorEl}
      onClose={onClose}
      closeOnClickOutside={false}
      title={title}
      description={description}
      actions={[{ children: "Got it", onClick: onClose }]}
      chipLabel={chipLabel ?? "new"}
      placement={placement}
    />
  ) : null;
}
