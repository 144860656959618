import {
  CopyIcon,
  FaroIconButton,
  FaroIconButtonProps,
  neutral,
  useToast,
} from "@faro-lotv/flat-ui";
import { Tooltip } from "@mui/material";

type CopyButtonProps = {
  /** The text to copy with the button */
  text: string;

  /** The size of the button. @default s */
  size?: FaroIconButtonProps["size"];

  /** Whether to use dark styles. @default false */
  dark?: boolean;
};

/** @returns an icon button to copy text with feedback for the user */
export function CopyButton({
  text,
  size = "s",
  dark,
}: CopyButtonProps): JSX.Element {
  const { openToast } = useToast();

  return (
    <Tooltip title="Copy to clipboard">
      <FaroIconButton
        size={size}
        margin={0.5}
        onClick={() => {
          navigator.clipboard.writeText(text);
          openToast({ title: "Copied to clipboard" });
        }}
        color={dark ? neutral[100] : neutral[800]}
      >
        <CopyIcon color="inherit" />
      </FaroIconButton>
    </Tooltip>
  );
}
