import { useAppSelector } from "@/store/store-hooks";
import { usePanoCameraTransform } from "@/utils/camera-transform";
import {
  selectHasValidPose,
  selectIElementWorldPosition,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { Vector3 as Vector3Prop, useThree } from "@react-three/fiber";
import { useMemo } from "react";
import { Vector3 } from "three";
import { WalkSceneActiveElement } from "../walk-types";
import { Models3dAnimation } from "./models-3d-animation";

type ModelToPanoProp = {
  /** The element currently active in the 3d scene */
  currentElement: WalkSceneActiveElement;

  /** The target panorama of the animation */
  targetElement: IElementImg360;

  /** sheet used to get the pano elevation */
  sheetForElevation?: IElementGenericImgSheet;

  /** Define as true to mark this scene as a secondary view for resource sharing */
  isSecondaryView: boolean;

  /** The callback executed when the animation finishes */
  onAnimationFinished(target: IElementImg360): void;

  /** The callback executed when the camera changes position */
  onCameraMoved?(position: Vector3Prop): void;
};

/**
 * @returns The pc/cad to pano animation for the walk scene
 * @todo This animation should be improved in performance and quality in
 *       the following ticket https://faro01.atlassian.net/browse/SWEB-1544
 */
export function ModelToPano({
  currentElement,
  targetElement,
  sheetForElevation,
  isSecondaryView,
  onCameraMoved,
  onAnimationFinished,
}: ModelToPanoProp): JSX.Element {
  const modelHasValidPose = useAppSelector(selectHasValidPose(targetElement));
  const targetPositionMinimap = useAppSelector(
    selectIElementWorldPosition(targetElement.id),
  );

  const camera = useThree((s) => s.camera);

  /** Trigger the camera movement after mounting the animation so the minimap can update during the animation  */
  useNonExhaustiveEffect(() => {
    if (modelHasValidPose) {
      onCameraMoved?.(targetPositionMinimap);
    } else {
      onCameraMoved?.(camera.getWorldPosition(new Vector3()));
    }
  }, [targetElement]);

  const targetPose = usePanoCameraTransform(targetElement, sheetForElevation);

  const targetPosition = useMemo(
    () => new Vector3().fromArray(targetPose.position),
    [targetPose],
  );

  return (
    <Models3dAnimation
      targetCameraPosition={targetPosition}
      mainElement={currentElement}
      overlayElement={undefined}
      onCompleted={() => {
        onAnimationFinished(targetElement);
      }}
      lightsOn={false}
      isSecondaryView={isSecondaryView}
    />
  );
}
