import { RegistrationEditMode } from "../store/data-preparation-ui/data-preparation-ui-slice";
import { DecodedDeepLinkData } from "./deep-link-data-decoded";
import { EncodedDeepLinkData, EncodedEditMode } from "./deep-link-data-encoded";

/**
 * @param decodedData The deep link data to encode/compress.
 * @returns the raw, encoded deep link data.
 */
export function encodeDeepLinkData(
  decodedData: DecodedDeepLinkData,
): EncodedDeepLinkData {
  return {
    edit: decodedData.edit ? encodeEditMode(decodedData.edit) : undefined,
  };
}

function encodeEditMode(
  decodedEditMode: RegistrationEditMode,
): EncodedEditMode {
  switch (decodedEditMode) {
    case RegistrationEditMode.editScans:
      return EncodedEditMode.editScans;
    case RegistrationEditMode.addConnection:
      return EncodedEditMode.addConnection;
    case RegistrationEditMode.deleteConnection:
      return EncodedEditMode.deleteConnection;
  }
}
