import {
  ClearScanSelectionProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { ContextualToolbar, TranslateVar } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { selectSelectedScansRecursive } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import { setSelectedEntityIds } from "../store/data-preparation-ui/data-preparation-ui-slice";

/** @returns A toolbar to provide contextual actions on the selected scans in the revision. */
export function RevisionContextualToolbar(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const selectedScans = useAppSelector(selectSelectedScansRecursive);
  const selectedCount = selectedScans.length;

  return selectedCount > 0 ? (
    <ContextualToolbar
      label={
        <>
          <TranslateVar name="selectedScanCount">{selectedCount}</TranslateVar>{" "}
          {selectedCount === 1 ? "scan" : "scans"}
          {" selected"}
        </>
      }
      clearSelection={() => {
        Analytics.track<ClearScanSelectionProperties>(
          EventType.clearScanSelection,
          {
            via: "context toolbar",
            selectedScanCount: selectedCount,
          },
        );
        dispatch(setSelectedEntityIds([]));
      }}
    />
  ) : null;
}
