import { updateProject } from "@/components/common/project-provider/update-project";
import { validateMultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { selectBackgroundTasks } from "@/store/background-tasks/background-tasks-selector";
import {
  addBackgroundTask,
  updateBackgroundTask,
} from "@/store/background-tasks/background-tasks-slice";
import { AppDispatch, AppThunk } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  BackgroundTask,
  BackgroundTaskTypes,
  C2CRegistrationTask,
  PointCloudExportTask,
  RegisterMultiCloudDataSetTask,
  SceneConversionTask,
  isCaptur3dFloorplanGenerationResult,
  validateC2CRegistrationResult,
} from "@/utils/background-tasks";
import {
  selectAncestor,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { compareDateTimes } from "@faro-lotv/foundation";
import {
  GUID,
  IElementBase,
  isIElementBimModelGroup,
  isIElementSection,
  isIElementSectionDataSession,
  isIElementSectionLayer,
  isIElementVideoRecording,
} from "@faro-lotv/ielement-types";
import {
  BackgroundTaskDetails,
  BackgroundTaskState,
  ProgressApiClient,
  ProgressApiSupportedTaskTypes,
  isBackgroundTaskActive,
  isBackgroundTaskInfoProgress,
  isBackgroundTaskInfoState,
  isProgressApiSupportedTaskType,
  useApiClientContext,
  validateDownloadResult,
  validateSceneConversionResult,
} from "@faro-lotv/service-wires";
import { useCallback, useEffect, useState } from "react";

/** Default interval at which the polling is executed */
const DEFAULT_INTERVAL = 3000;

/**
 * Create a BackgroundTask to track a backend task only if it's needed
 *
 * Do not track already completed, failed tasks or tasks specific for another user
 *
 * @param task Task data received from the ProgressApi
 * @param currentUserId id of the current user if this is an authenticated session
 * @param dispatch Function to update the application store
 */
function createTaskIfNeeded(
  task: BackgroundTaskDetails,
  currentUserId: GUID | undefined,
  dispatch: AppDispatch,
): void {
  // Ignore un-supported tasks
  if (!task.taskType || !isProgressApiSupportedTaskType(task.taskType)) {
    return;
  }

  // Ignore tasks for an user that is not the current one
  if (shouldHideForThisUser(task, currentUserId)) {
    return;
  }

  if (
    isBackgroundTaskInfoState(task.status) &&
    isBackgroundTaskActive(task.status.state)
  ) {
    // Init a new BackgroundTask object as the backend process is new but is active
    dispatch(
      addBackgroundTask({
        id: task.id,
        createdAt: task.createdAt,
        changedAt: task.status.changedAt,
        iElementId: task.context.elementId ?? task.context.correlationId,
        type: task.taskType,
        devMessage: task.status.devMessage,
        errorCode: task.status.errorCode ?? undefined,
        state: task.status.state,
        progress: 0,
        metadata: { jobId: task.context.jobId },
        tags: task.tags,
      }),
    );
  } else if (isBackgroundTaskInfoProgress(task.status)) {
    // Init a new BackgroundTask object as the backend process is already running
    dispatch(
      addBackgroundTask({
        id: task.id,
        createdAt: task.createdAt,
        changedAt: task.status.changedAt,
        iElementId: task.context.elementId ?? task.context.correlationId,
        type: task.taskType,
        devMessage: task.status.devMessage,
        errorCode: task.status.errorCode ?? undefined,
        state: BackgroundTaskState.started,
        progress:
          (task.status.progress.current / task.status.progress.total) * 100,
        metadata: { jobId: task.context.jobId },
        tags: task.tags,
      }),
    );
  } else if (task.status.state === BackgroundTaskState.succeeded) {
    // Track completed registration tasks to display metric reports
    // Maybe we can track this in a different way in the future https://faro01.atlassian.net/browse/NRT-688
    const { result } = task.status;

    const payload = {
      id: task.id,
      createdAt: task.createdAt,
      changedAt: task.status.changedAt,
      iElementId: task.context.elementId ?? task.context.correlationId,
      devMessage: task.status.devMessage,
      errorCode: task.status.errorCode ?? undefined,
      state: BackgroundTaskState.succeeded,
      progress: 100,
      metadata: { jobId: task.context.jobId },
      tags: task.tags,
    };

    if (
      task.taskType === ProgressApiSupportedTaskTypes.registerMultiCloudDataSet
    ) {
      dispatch(
        addBackgroundTask({
          ...payload,
          type: task.taskType,
          result: validateMultiRegistrationReport(result) ? result : undefined,
        }),
      );
    } else if (
      task.taskType ===
      ProgressApiSupportedTaskTypes.captur3dFloorplanGeneration
    ) {
      dispatch(
        addBackgroundTask({
          ...payload,
          type: task.taskType,
          result: isCaptur3dFloorplanGenerationResult(result)
            ? result
            : undefined,
        }),
      );
    }
  }
}

function computeTaskWithMetadata(
  task: BackgroundTaskDetails,
  backgroundTask: BackgroundTask,
): BackgroundTask {
  switch (backgroundTask.type) {
    case ProgressApiSupportedTaskTypes.pointCloudExport: {
      const downloadUrl = validateDownloadResult(task.status.result)
        ? task.status.result.downloadUrl
        : undefined;
      return {
        ...backgroundTask,
        shouldPreventWindowClose: true,
        metadata: {
          ...backgroundTask.metadata,
          downloadUrl,
        },
      } satisfies PointCloudExportTask;
    }
    case ProgressApiSupportedTaskTypes.sceneConversion: {
      const result = validateSceneConversionResult(task.status.result)
        ? task.status.result
        : undefined;

      return {
        ...backgroundTask,
        metadata: {
          ...backgroundTask.metadata,
          result,
        },
      } satisfies SceneConversionTask;
    }
    case ProgressApiSupportedTaskTypes.c2cRegistration: {
      const result = validateC2CRegistrationResult(task.status.result)
        ? task.status.result
        : undefined;
      return {
        ...backgroundTask,
        result,
      } satisfies C2CRegistrationTask;
    }
    case ProgressApiSupportedTaskTypes.registerMultiCloudDataSet: {
      const result = validateMultiRegistrationReport(task.status.result)
        ? task.status.result
        : undefined;
      return {
        ...backgroundTask,
        result,
      } satisfies RegisterMultiCloudDataSetTask;
    }
  }
  return { ...backgroundTask };
}

/**
 * Update a background task with the new information received from the backend
 *
 * @param task information received from the ProgressApi
 * @param backgroundTask application stored information about this task
 * @param dispatch function to update the application state
 * @param onTaskCompleted callback called when a task move to the succeeded state
 */
function updateTask(
  task: BackgroundTaskDetails,
  backgroundTask: BackgroundTask,
  dispatch: AppDispatch,
  onTaskCompleted: (task: BackgroundTask) => void,
): void {
  // We received an update for a task that's in the store
  const taskWithMetadata = computeTaskWithMetadata(task, backgroundTask);
  if (
    isBackgroundTaskInfoState(task.status) &&
    backgroundTask.state !== task.status.state
  ) {
    // The task changed state
    const updatedTask: BackgroundTask = {
      ...taskWithMetadata,
      state: task.status.state,
      changedAt: task.status.changedAt,
      devMessage: task.status.devMessage,
      errorCode: task.status.errorCode ?? undefined,
      tags: task.tags,
    };
    dispatch(updateBackgroundTask(updatedTask));
    if (updatedTask.state === BackgroundTaskState.succeeded) {
      onTaskCompleted(updatedTask);
    }
  } else if (isBackgroundTaskInfoProgress(task.status)) {
    // The progress of this task changed
    const updatedTask: BackgroundTask = {
      ...taskWithMetadata,
      state: BackgroundTaskState.started,
      changedAt: task.status.changedAt,
      progress:
        (task.status.progress.current / task.status.progress.total) * 100,
    };
    dispatch(updateBackgroundTask(updatedTask));
  }
}

/**
 * A map from a background task type to a type guard.
 *
 * The type guard determines which ancestor is selected
 * for refreshing the IElement tree.
 */
const TASK_TO_ANCESTOR_TYPE_GUARD_MAP: Record<
  BackgroundTaskTypes,
  ((iElement: IElementBase) => boolean) | undefined
> = {
  FileUpload: isIElementSection,
  Orthophoto: undefined,
  [ProgressApiSupportedTaskTypes.pointCloudExport]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.pointCloudToPotree]:
    isIElementSectionDataSession,
  // TODO: Remove after backend update: https://faro01.atlassian.net/browse/SWEB-1980
  [ProgressApiSupportedTaskTypes.pointCloudLazToPotree]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.pointCloudE57ToLaz]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.pointCloudGSToLaz]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.c2cRegistration]: isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.registerMultiCloudDataSet]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.mergePointClouds]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.bimModelImport]: isIElementBimModelGroup,
  [ProgressApiSupportedTaskTypes.videoMode]: isIElementVideoRecording,
  [ProgressApiSupportedTaskTypes.sceneConversion]: undefined,
  [ProgressApiSupportedTaskTypes.captur3dFloorplanGeneration]:
    isIElementSectionLayer,
};

/**
 * @returns a component to track the ProgressApi and keep the store in sync
 * Must be used within an `ApiClientContextProvider`.
 */
export function ProgressApiTracker(): null {
  // For now we poll with a fixed delta, we should evaluate in the future to use a different polling
  // if there's an active task in the backend
  const [polling] = useState<number>(DEFAULT_INTERVAL);

  const { progressApiClient: progressApi } = useApiClientContext();
  const appStore = useAppStore();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUser)?.id;

  const { projectApiClient: projectApi } = useApiClientContext();

  const onTaskCompleted = useCallback(
    (task: BackgroundTask) => {
      // If the task is a SceneConversion, instead of fetching a subtree,
      // refresh the entire page so that the app can be correctly initialized
      if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
        window.location.reload();
        return;
      }

      // The element of which the subtree needs to be fetched once the tasked is completed
      const ancestorToFetch = TASK_TO_ANCESTOR_TYPE_GUARD_MAP[task.type];

      if (ancestorToFetch) {
        if (!task.iElementId) return;

        const element = selectIElement(task.iElementId)(appStore.getState());
        // A background task updated an element that is not currently loaded, nothing to do
        if (!element) return;

        // A background task changed an element that is loaded, we need to refresh that element sub-tree
        // find the section containing this IElement to update
        const elementSection = selectAncestor(
          element,
          ancestorToFetch,
        )(appStore.getState());

        dispatch(
          updateProject({
            projectApi,
            iElementQuery: {
              ancestorIds: [elementSection?.id ?? element.id],
            },
          }),
        );
      }
    },
    [appStore, dispatch, projectApi],
  );

  useEffect(() => {
    async function updateBackgroundTasks(): Promise<void> {
      const bgTasks = selectBackgroundTasks()(appStore.getState());

      await updateTasks(progressApi, currentUserId, dispatch, onTaskCompleted);

      // Manage the scene conversions separately, since updateTasks requests only the first 30
      // jobs and the Scene conversion may not be there.
      // TODO: Improve the queries to the ProgressAPI https://faro01.atlassian.net/browse/SWEB-5358
      await updateSceneConversionTasks(progressApi, bgTasks, dispatch);
    }
    const id = setInterval(() => updateBackgroundTasks(), polling);

    return () => clearInterval(id);
  }, [
    polling,
    dispatch,
    appStore,
    progressApi,
    onTaskCompleted,
    currentUserId,
  ]);

  // Fetches all floor plan generation tasks, since the cloud activity menu is the only way to interact with them.
  useEffect(() => {
    const controller = new AbortController();

    async function fetchAllFloorPlanGenerationTasks(): Promise<void> {
      const tasks = await progressApi
        .requestAllProgress({
          signal: controller.signal,
          direction: "lastToFirst",
          taskType: ProgressApiSupportedTaskTypes.captur3dFloorplanGeneration,
        })
        // If the connection with the ProgressAPI fails, silently ignore it
        .catch(() => []);

      dispatch(createOrUpdateTasks(tasks, currentUserId, onTaskCompleted));
    }

    fetchAllFloorPlanGenerationTasks();

    return () => controller.abort();
  }, [currentUserId, dispatch, onTaskCompleted, progressApi]);

  return null;
}

/**
 * @param task to check
 * @param userId of the current logged in user
 * @returns true if this task should be hidden from the current user
 */
function shouldHideForThisUser(
  task: BackgroundTaskDetails,
  userId?: GUID,
): boolean {
  return (
    task.taskType === ProgressApiSupportedTaskTypes.pointCloudExport &&
    task.context.userId !== userId
  );
}

/**
 * Create or update local tasks by requesting the current state to the ProgressAPI
 *
 * @param progressApi The client to make requests to the ProgressAPI
 * @param backgroundTasks The list of tasks currently stored by the app
 * @param currentUserId The id of the current user
 * @param dispatch The function to dispatch the updates to the store
 * @param onTaskCompleted A callback executed when a task is completed
 */
async function updateTasks(
  progressApi: ProgressApiClient,
  currentUserId: string | undefined,
  dispatch: AppDispatch,
  onTaskCompleted: (task: BackgroundTask) => void,
): Promise<void> {
  // If the connection with the ProgressAPI fails do not error out but just
  // act like we got no updates as we don't want the app to fail if the ProgressAPI
  // does not respond
  const { data } = await progressApi
    .requestProgress()
    .catch(() => ({ data: [] }));

  dispatch(createOrUpdateTasks(data, currentUserId, onTaskCompleted));
}

/**
 * @returns redux action to create or update a list of newly fetched tasks, depending on whether they already exist in the store.
 * @param tasks the newly fetched tasks
 * @param currentUserId the current user id
 * @param onTaskCompleted callback, when a task was completed by an update
 */
function createOrUpdateTasks(
  tasks: BackgroundTaskDetails[],
  currentUserId: string | undefined,
  onTaskCompleted: (task: BackgroundTask) => void,
): AppThunk<void> {
  return (dispatch, getState) => {
    const allTasks = selectBackgroundTasks()(getState());

    for (const task of tasks) {
      const backgroundTask = allTasks.find(
        (backgroundTask) => backgroundTask.id === task.id,
      );
      if (backgroundTask) {
        updateTask(task, backgroundTask, dispatch, onTaskCompleted);
      } else {
        createTaskIfNeeded(task, currentUserId, dispatch);
      }
    }
  };
}

/**
 * Update the store with the state of the latest scene conversion task
 *
 * @param progressApi The client to make requests to the ProgressAPI
 * @param backgroundTasks The list of tasks currently stored by the app
 * @param dispatch The function to dispatch the updates to the store
 */
async function updateSceneConversionTasks(
  progressApi: ProgressApiClient,
  backgroundTasks: BackgroundTask[],
  dispatch: AppDispatch,
): Promise<void> {
  const { data: sceneConversions } = await progressApi.requestProgress({
    taskType: ProgressApiSupportedTaskTypes.sceneConversion,
  });
  // Sort the scene conversions by getting the latest first
  sceneConversions.sort((a, b) => compareDateTimes(b.createdAt, a.createdAt));
  const sceneConversion = sceneConversions.at(0);
  // If the latest task failed and the task is not in the store yet, add it
  if (
    sceneConversion?.status.state === BackgroundTaskState.failed &&
    !backgroundTasks.find(
      (backgroundTask) => backgroundTask.id === sceneConversion.id,
    )
  ) {
    dispatch(
      addBackgroundTask({
        id: sceneConversion.id,
        createdAt: sceneConversion.createdAt,
        changedAt: sceneConversion.status.changedAt,
        iElementId:
          sceneConversion.context.elementId ??
          sceneConversion.context.correlationId,
        type: ProgressApiSupportedTaskTypes.sceneConversion,
        devMessage: sceneConversion.status.devMessage,
        errorCode: sceneConversion.status.errorCode ?? undefined,
        state: sceneConversion.status.state,
        progress: 0,
        metadata: {
          jobId: sceneConversion.context.jobId,
          result: validateSceneConversionResult(sceneConversion.status.result)
            ? sceneConversion.status.result
            : undefined,
        },
        tags: sceneConversion.tags,
      }),
    );
  }
}
