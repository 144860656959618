import {
  EditRegistrationProperties,
  EventType,
  OpenRegistrationProperties,
} from "@/analytics/analytics-events";
import { useUpdateProjectMetadata } from "@/components/common/project-provider/use-project-metadata";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { THRESHOLD_SET_STATIONARY } from "@/registration-tools/common/registration-report/registration-thresholds";
import { ThresholdSetProvider } from "@/registration-tools/common/registration-report/threshold-set-context";
import {
  selectRegistrationAlgorithm,
  selectRegistrationAlgorithmSettings,
} from "@/registration-tools/common/store/registration-parameters/registration-parameters-selectors";
import { getRegistrationReportError } from "@/registration-tools/utils/multi-registration-report";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  generateViewerUrl,
  redirectToDashboardProjectPage,
  redirectToDataManagementUrl,
} from "@/utils/redirects";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert, isLoading } from "@faro-lotv/foundation";
import {
  getProjectPreparedDataPage,
  selectDashboardUrl,
} from "@faro-lotv/project-source";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Stack, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { SphereViewerToolHeaderBar } from "../components/ui/tool-header-bar";
import {
  DataPreparationOverlayElementsContext,
  useDataPreparationOverlayElementsInitialState,
} from "./data-preparation-overlay-context";
import { useScreenSizeCheck } from "./hooks/use-screen-size-check";
import { useLoadRegistrationReport } from "./loading/use-load-registration-report";
import { useLoadRevisionPointCloudStreams } from "./loading/use-load-revision-point-cloud-streams";
import { RevisionScansScene } from "./rendering/revision-scans-scene";
import {
  selectIsAnyEditModeEnabled,
  selectIsEditingScans,
  selectPointCloudStreamIdsForHoveredEntities,
  selectPointCloudStreamIdsForSelectedEntities,
} from "./store/data-preparation-ui/data-preparation-ui-selectors";
import {
  disableEditMode,
  enableDefaultEditMode,
} from "./store/data-preparation-ui/data-preparation-ui-slice";
import { selectRevisionScans } from "./store/revision-selectors";
import { DataPreparationBanners } from "./ui/data-preparation-banners";
import { DataPreparationLoadingSpinner } from "./ui/data-preparation-loading-spinner";
import {
  HorizontalCanvasOverlay,
  VerticalCanvasOverlay,
} from "./ui/data-preparation-overlay";
import { DataPreparationReportSidebar } from "./ui/data-preparation-report-sidebar";
import { DataPreparationSidebar } from "./ui/data-preparation-sidebar";
import { Projection } from "./ui/projection-switch";

export type DataPreparationUiProps = {
  /** The revision that is used for the data preparation. */
  revision: RegistrationRevision;
};

/**
 * @returns UI for the data preparation page.
 */
export function DataPreparationUi({
  revision,
}: DataPreparationUiProps): JSX.Element {
  const { projectApiClient, registrationApiClient } = useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();
  const dispatch = useAppDispatch();

  // Non-blocking: Load project meta-data, e.g. to get the project name for the breadcrumbs
  useUpdateProjectMetadata(projectApiClient.projectId);

  const isEditModeEnabled = useAppSelector(selectIsAnyEditModeEnabled);
  const isEditingScans = useAppSelector(selectIsEditingScans);

  const [isRegistrationRestarted, setRestartRegistration] = useState(false);

  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const scanEntities = useAppSelector(selectRevisionScans);
  const pointCloudStreams = useLoadRevisionPointCloudStreams(scanEntities);

  const hoveredPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForHoveredEntities,
  );
  const selectedPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForSelectedEntities,
  );

  const registrationReport = useLoadRegistrationReport(revision);

  const [isReportOpen, setIsReportOpen] = useState(false);

  const handleReportToggle = (data: boolean) => setIsReportOpen(data);

  const { openToast } = useToast();

  const sphereProjectUrl = useMemo(
    () => generateViewerUrl(projectApiClient.projectId),
    [projectApiClient.projectId],
  );

  const exitHref = dashboardUrl
    ? getProjectPreparedDataPage(dashboardUrl, projectApiClient.projectId)
    : sphereProjectUrl;

  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );

  const registrationAlgorithm = useAppSelector(selectRegistrationAlgorithm);
  const registrationSettings = useAppSelector(
    selectRegistrationAlgorithmSettings,
  );

  // Callback to allow us to edit the registration when connections were missing during the registration
  const enableEditRegistrationWhenConnectionsMissing = useCallback(() => {
    Analytics.track<EditRegistrationProperties>(EventType.editRegistration, {
      via: "banner",
    });
    // then we can toggle the edit registration
    dispatch(enableDefaultEditMode());
  }, [dispatch]);

  useNonExhaustiveEffect(() => {
    if (isLoading(registrationReport)) {
      return;
    }

    const registrationError = getRegistrationReportError(registrationReport);

    Analytics.track<OpenRegistrationProperties>(EventType.openRegistration, {
      registrationState: revision.state,
      registrationError,
    });
  }, [registrationReport]);

  // Callback to allow us to rerun a registration if it failed to be published
  const rerunRegistrationWhenPublishFailed = useCallback(async () => {
    setRestartRegistration(true);
    try {
      await registrationApiClient?.startCaptureTreeRegistration({
        revisionId: revision.id,
        registrationAlgorithm: hasRegistrationDevFeature
          ? registrationAlgorithm
          : undefined,
        parameters: hasRegistrationDevFeature
          ? registrationSettings
          : undefined,
      });
      assert(projectApiClient.projectId, "Project ID is undefined");

      redirectToDataManagementUrl(projectApiClient.projectId, dashboardUrl);
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to Rerun Registration",
      });
    }

    setRestartRegistration(false);
  }, [
    registrationApiClient,
    revision.id,
    hasRegistrationDevFeature,
    registrationAlgorithm,
    registrationSettings,
    projectApiClient.projectId,
    dashboardUrl,
    handleErrorWithToast,
  ]);

  const theme = useTheme();
  // The tool doesn't work on small screens
  // Show an error page instead and ask the user to use a tablet or desktop PC
  useScreenSizeCheck({
    threshold: theme.breakpoints.values.md,
    handleRedirect: () =>
      redirectToDashboardProjectPage(projectApiClient.projectId),
    label: "Return to Dashboard",
  });

  const dataPreparationOverlayElements =
    useDataPreparationOverlayElementsInitialState();

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <SphereViewerToolHeaderBar
        toolName={
          isEditModeEnabled ? "Registration - Edit" : "Registration - Inspect"
        }
        exitHref={exitHref}
        exitText="Data"
        onExit={() => {
          Analytics.track(EventType.exitRegistrationView);
        }}
      />
      <ThresholdSetProvider
        defaultThresholdSet={THRESHOLD_SET_STATIONARY}
        showThresholdSetSwitch={false}
      >
        <Stack sx={{ height: "100%", width: "100%" }}>
          <DataPreparationBanners
            revision={revision}
            scanEntities={scanEntities}
            pointCloudStreams={pointCloudStreams}
            registrationReport={registrationReport}
            isRegistrationRerunning={isRegistrationRestarted}
            onBackToRegistrationStepKey={
              enableEditRegistrationWhenConnectionsMissing
            }
            onRerunRegistration={rerunRegistrationWhenPublishFailed}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <DataPreparationSidebar />
            <DataPreparationOverlayElementsContext.Provider
              value={dataPreparationOverlayElements}
            >
              <RevisionScansScene
                scanEntities={scanEntities}
                pointCloudStreams={pointCloudStreams}
                hoveredPointCloudIds={hoveredPointCloudIds}
                selectedPointCloudIds={selectedPointCloudIds}
                overlay={
                  <>
                    <DataPreparationLoadingSpinner
                      revisionState={revision.state}
                    />
                    <HorizontalCanvasOverlay
                      revision={revision}
                      registrationReport={registrationReport}
                      onToggleReport={handleReportToggle}
                      isEditRegistrationEnabled={isEditModeEnabled}
                    />
                    <VerticalCanvasOverlay />
                  </>
                }
                isEditingScans={isEditingScans}
                onProjectionChanged={(projection) => {
                  if (
                    isEditModeEnabled &&
                    projection !== Projection.twoDimensional
                  ) {
                    dispatch(disableEditMode());
                    openToast({
                      title: "Edit registration disabled",
                      message:
                        "Registration editing is only available in 2D projection.",
                      variant: "warning",
                    });
                  }
                }}
              />
            </DataPreparationOverlayElementsContext.Provider>

            <DataPreparationReportSidebar
              registrationReport={registrationReport}
              isReportOpen={isReportOpen}
              onToggleReport={setIsReportOpen}
            />
          </Stack>
        </Stack>
      </ThresholdSetProvider>
    </Stack>
  );
}
