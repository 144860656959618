import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch } from "@/store/store-hooks";
import { IElement, ILabel } from "@faro-lotv/ielement-types";
import {
  createMutationRemoveLabel,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";

type RemoveTagFromScanCallback = (tag: ILabel) => Promise<void>;

/**
 * @returns An asynchronous callback to remove a tag from an iElement and update the project
 * @param iElement The iElement from which the label should be removed
 */
export function useRemoveTagFromScan(
  iElement: IElement | undefined,
): RemoveTagFromScanCallback {
  const { projectApiClient } = useApiClientContext();
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorHandlers();

  return useCallback(
    async (tag: ILabel) => {
      if (!iElement) return;
      try {
        await projectApiClient.applyMutations([
          createMutationRemoveLabel(iElement.id, tag.id),
        ]);

        // Fetch the changed sub-tree and update the local copy of the project
        await dispatch(
          updateProject({
            projectApi: projectApiClient,
            iElementQuery: {
              ancestorIds: [iElement.id],
            },
          }),
        );
      } catch (error) {
        handleErrorWithToast({ title: "Could not remove tag", error });
      }
    },
    [dispatch, handleErrorWithToast, iElement, projectApiClient],
  );
}
