import { useLoadProjectArea } from "@/components/common/project-provider/project-loading-context";
import { selectAreaSections } from "@/components/ui/area-navigation/area-navigation-utils";
import { TagsManagementDialog } from "@/components/ui/tags/tags-management-dialog";
import { useAppSelector } from "@/store/store-hooks";
import {
  CircularProgress,
  Dropdown,
  FaroButton,
  FaroText,
  NoTranslate,
  Option,
} from "@faro-lotv/flat-ui";
import { selectIElement } from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { isEqual } from "es-toolkit";
import { useMemo, useState } from "react";
import { selectCurrentAreaData } from "../mode-selectors";
import { useTagsManagementContext } from "./tags-management-context";
import { selectTagsManagementScene } from "./tags-management-selector";
import { TagsManagementTree } from "./tags-management-tree";

const ENTIRE_PROJECT_KEY = "entire-project-key";

/** @returns A view on the project that the user can interact with */
export function TagsManagementPanel(): JSX.Element | null {
  const { activeAreaId, setActiveAreaId } = useTagsManagementContext();
  const activeArea = useAppSelector(selectIElement(activeAreaId));
  const areaData = useAppSelector(selectCurrentAreaData(activeArea), isEqual);
  const currentScene = useAppSelector(
    selectTagsManagementScene(areaData),
    isEqual,
  );

  const isLoading = useLoadProjectArea(activeAreaId);

  const areas = useAppSelector(selectAreaSections);
  const areaOptions: Option[] = useMemo(
    () =>
      areas.map((area) => ({
        key: area.id,
        value: area.id,
        label: <NoTranslate>{area.name}</NoTranslate>,
      })),
    [areas],
  );

  if (!areaData) {
    return null;
  }

  return (
    <Stack
      sx={{
        width: "100%",
        flex: 1,
        overflow: "auto",
        height: "100%",
        padding: 2,
      }}
      gap={3}
    >
      <TagsManagementPanelHeader />
      <FaroText variant="placeholder">
        List of available elements that support tags.
      </FaroText>
      <Stack gap={0.5} sx={{ width: "100%", flex: 1, overflow: "auto" }}>
        <Dropdown
          label={<FaroText variant="labelM">Areas</FaroText>}
          options={areaOptions}
          value={activeArea?.id ?? ENTIRE_PROJECT_KEY}
          onChange={(ev) =>
            ev.target.value === ENTIRE_PROJECT_KEY
              ? setActiveAreaId(undefined)
              : setActiveAreaId(ev.target.value)
          }
          shouldCapitalize={false}
        />
        {isLoading && <CircularProgress size={20} />}
        <TagsManagementTree currentScene={currentScene} isLoading={isLoading} />
      </Stack>
    </Stack>
  );
}

function TagsManagementPanelHeader(): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%" }} alignItems="center">
        <FaroText variant="heading14" sx={{ flex: "1" }}>
          Tags
        </FaroText>
        <FaroButton
          variant="ghost"
          size="xs"
          onClick={() => setIsDialogOpen(true)}
        >
          Manage Tags
        </FaroButton>
      </Stack>

      <TagsManagementDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
}
