import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { areVector3ArraysEqual } from "@/utils/equality";
import {
  selectAltitudeDifferenceToFloor,
  selectIElementWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementImg360,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { Vector3 } from "three";

/** Default distance to keep between the floor and the placeholders so they don't z-fight */
export const DEFAULT_PLACEHOLDER_FLOOR_OFFSET = 0.012;

/**
 * Calculates the placeholder positions for a given set of IElements on a sheet
 *
 * @param placeholders we want to render
 * @param floorForElevation sheet whose elevation will be used to place the placeholders
 * @param heightOffset added to the positions to avoid z-fighting with other ui elements
 * @returns the computed list of positions for the placeholders
 */
export function useMapPlaceholderPositions(
  placeholders: IElement[],
  floorForElevation?: IElement,
  heightOffset: number = DEFAULT_PLACEHOLDER_FLOOR_OFFSET,
): Vector3[] {
  return useAppSelector(
    selectMapPlaceholdersPositions(
      placeholders,
      floorForElevation,
      heightOffset,
    ),
    areVector3ArraysEqual,
  );
}

/**
 * Special purpose store selector to compute the adjusted position for all the placeholders relative to a floor
 *
 * Need to be used with a specific comparator as the selector will generate new objects every call
 *
 * @param placeholders we want to render
 * @param floorForElevation where to place the placeholders
 * @param heightOffset offset added to the positions to avoid z-fighting with other ui elements
 * @returns the computed list of positions for the placeholders
 */
function selectMapPlaceholdersPositions(
  placeholders: IElement[],
  floorForElevation: IElement | undefined,
  heightOffset: number,
) {
  return (state: RootState) => {
    const positions: Vector3[] = [];
    for (const placeholder of placeholders) {
      const heightFix =
        (floorForElevation
          ? selectAltitudeDifferenceToFloor(
              placeholder.id,
              floorForElevation.id,
            )(state)
          : 0) - heightOffset;
      const worldPos = selectIElementWorldPosition(placeholder.id)(state);
      positions.push(
        new Vector3(worldPos[0], worldPos[1] - heightFix, worldPos[2]),
      );
    }
    return positions;
  };
}

/**
 *
 * @param panos All panos belonging to an odometry path.
 * @returns The list of indices of POIs within the argument panos.
 */
export function useSelectPathPoiIndices(panos: IElementImg360[]): number[] {
  return useMemo(() => {
    const indices: number[] = [];
    for (let i = 0; i < panos.length; i++) {
      const pano = panos[i];
      // 'poiHighRes' is only for flash scans. 'poiLowRes' or 'poi'
      // are used when the user stops on a POI to capture a pano.
      if (
        pano.typeHint === IElementTypeHint.poiLowRes ||
        pano.typeHint === IElementTypeHint.poi
      ) {
        indices.push(i);
      }
    }
    return indices;
  }, [panos]);
}
