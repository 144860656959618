import { SnapshotRenderer } from "@/components/r3f/renderers/snapshot-renderer";
import {
  PanoObject,
  useCached3DObjectIfExists,
  useSuspendWhilePreload3DObjects,
} from "@/object-cache";
import { setPanoIndexDuringSnakeAnimation } from "@/store/modes/walk-mode-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { getCameraAnimationTime } from "@/utils/camera-animation-time";
import { selectPanoCameraTransform } from "@/utils/camera-transform";
import { UniformLights } from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { Pano, PanoBlend, TiledPano } from "@faro-lotv/lotv";
import {
  CachedWorldTransform,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { useFrame, useThree } from "@react-three/fiber";
import { isEqual, range } from "es-toolkit";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Camera,
  CatmullRomCurve3,
  Euler,
  Matrix4,
  Vector3,
  Vector3Tuple,
  WebGLRenderer,
} from "three";
import { PanoToPanoProps } from "./pano-to-pano-types";

interface PanoToPanoSeriesAnimationProps extends PanoToPanoProps {
  /** All panos in the series to animate in. */
  panos: IElementImg360[];

  /** Sheet to project the panos position without a valid height */
  sheetForElevation?: IElementGenericImgSheet;

  /** The index to start the animation from. */
  currentIndex: number;

  /** The index to animate towards. Can be lower or higher than the currentIndex. */
  targetIndex: number;
}

/** A path on which the camera should be animated */
type Path = {
  /** The panos of the path */
  panos: IElementImg360[];

  /** The camera positions corresponding to the panos */
  points: Vector3Tuple[];
};

/** @returns a pano-to-pano animation that transitions over multiple panos to the destination */
export function PanoToPanoSeries({
  targetElement,
  sheetForElevation,
  onCameraMoved,
  onAnimationFinished,
  currentIndex,
  targetIndex,
  panos,
}: PanoToPanoSeriesAnimationProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const { camera, gl } = useThree((s) => s);

  const [index, setIndex] = useState(0);

  const { panos: pathPanos, points } = useAppSelector(
    selectCameraPath(panos, sheetForElevation, currentIndex, targetIndex),
    isEqual,
  );
  const curve = useMemo(
    () => new CatmullRomCurve3(points.map((p) => new Vector3().fromArray(p))),
    [points],
  );

  useSuspendWhilePreload3DObjects(pathPanos);

  const currentPano = useCached3DObjectIfExists(pathPanos[index]);
  const targetPano = useCached3DObjectIfExists(pathPanos[index + 1]);

  const currentPose = useAppSelector(
    selectIElementWorldTransform(currentPano?.iElement.id),
  );
  const targetPose = useAppSelector(
    selectIElementWorldTransform(targetPano?.iElement.id),
  );

  const targetView = useGetPanoView(targetPano, targetPose);
  const currentView = useGetPanoView(currentPano, currentPose);
  const panoBlend = usePanoBlend(currentView, targetView, camera, gl);

  // Duration should not be revaluated, because it could change
  // when the camera position changes
  const [duration] = useState(() =>
    getCameraAnimationTime(camera, points[points.length - 1], {
      min: 0.5,
      max: 10,
      scale: 2,
    }),
  );
  const elapsedTime = useRef(0);

  useEffect(() => {
    const lastPos = points.at(-1);
    if (lastPos && onCameraMoved) {
      onCameraMoved(new Vector3().fromArray(lastPos));
    }
  }, [onCameraMoved, points]);

  // Moves the animation time forward, ends the animation once the time reaches the end and
  // selects the current set of panos to blend between based on the animation time.
  useFrame(({ camera }, delta) => {
    elapsedTime.current += delta;
    const step = elapsedTime.current / duration;

    if (elapsedTime.current >= duration) {
      camera.position.fromArray(points[points.length - 1]);

      onAnimationFinished(targetElement);

      return;
    }

    if (!currentPano || !targetPano) return;

    const currentPosition = curve.getPointAt(Math.min(step, 1));

    camera.position.copy(currentPosition);

    // Compute the best panos given the current position
    if (index < points.length && index + 1 < points.length) {
      const startPosition = new Vector3().fromArray(points[index]);
      const endPosition = new Vector3().fromArray(points[index + 1]);
      const distanceFromCurrent = new Vector3()
        .subVectors(currentPosition, startPosition)
        .lengthSq();
      const segmentDistance = new Vector3()
        .subVectors(endPosition, startPosition)
        .lengthSq();
      if (distanceFromCurrent > segmentDistance) {
        setIndex(index + 1);
      } else if (panoBlend) {
        panoBlend.blendFactor = distanceFromCurrent / segmentDistance;
      }
    }
  }, 0);

  useEffect(() => {
    dispatch(
      setPanoIndexDuringSnakeAnimation(
        targetIndex >= currentIndex
          ? currentIndex + index
          : currentIndex - index,
      ),
    );
    return () => {
      dispatch(setPanoIndexDuringSnakeAnimation(undefined));
    };
  }, [currentIndex, dispatch, index, targetIndex]);

  if (!currentView || !targetView || !panoBlend) {
    return <SnapshotRenderer />;
  }

  return (
    <>
      <primitive object={panoBlend} />

      <UniformLights />
    </>
  );
}

/**
 * Creates a copy of a pano object at the correct transform to be used in a PanoBlend.
 *
 * @param panoObj The pano object to create the view for.
 * @param pose The transform of the targeted panorama image.
 * @returns The tiled and positioned pano.
 */
function useGetPanoView(
  panoObj: PanoObject | null,
  pose: CachedWorldTransform,
): Pano | null {
  return useMemo(() => {
    if (!panoObj) return null;

    const pano = Object.assign(
      new TiledPano(panoObj.tiledPano.texture?.clone(), new Matrix4()),
      { iElement: panoObj.iElement },
    );
    const rotation = new Matrix4().makeRotationFromEuler(
      new Euler(-Math.PI / 2, 0, Math.PI),
    );

    pano.applyMatrix4(rotation);
    pano.applyMatrix4(new Matrix4().fromArray(pose.worldMatrix));

    // remove all scaling that might come e.g. from the floorplan
    pano.scale.set(1, 1, 1);

    return pano;
  }, [panoObj, pose]);
}

/**
 * Creates a PanoBlend instance of for two Panos.
 *
 * @param currentView The pano at the start of the animation.
 * @param targetView The pano at the end of the animation.
 * @param camera The camera to use for the transition.
 * @param gl The WebGL renderer.
 * @returns The transition between the two panorama images.
 */
function usePanoBlend(
  currentView: Pano | null,
  targetView: Pano | null,
  camera: Camera,
  gl: WebGLRenderer,
): PanoBlend | null {
  return useMemo(() => {
    if (!currentView || !targetView) return null;

    const panoBlend = new PanoBlend(currentView, targetView, camera, gl);

    panoBlend.material.depthTest = false;
    panoBlend.material.depthWrite = true;
    panoBlend.renderOrder = 1;

    return panoBlend;
  }, [camera, currentView, gl, targetView]);
}

/**
 * @param panos The panorama images involved in the animation.
 * @param sheetForElevation To project the Img360 position without a valid height
 * @param currentIndex The index of the panorama image that is currently selected.
 * @param targetIndex The index of the panorama image that is the goal of the animation.
 * @returns The camera path to animate between the pano series.
 */
function selectCameraPath(
  panos: IElementImg360[],
  sheetForElevation: IElementGenericImgSheet | undefined,
  currentIndex: number,
  targetIndex: number,
) {
  return (state: RootState) => {
    const path: Path = {
      points: [],
      panos: [],
    };

    for (const i of range(currentIndex, targetIndex).concat([targetIndex])) {
      const cameraTransform = selectPanoCameraTransform(
        panos[i],
        sheetForElevation,
      )(state);
      path.points.push(cameraTransform.position);
      path.panos.push(panos[i]);
    }

    return path;
  };
}
