import { EventType } from "@/analytics/analytics-events";
import { AppDispatch, RootState } from "@/store/store";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID } from "@faro-lotv/foundation";
import { selectIsAddingConnections } from "../store/data-preparation-ui/data-preparation-ui-selectors";
import {
  addUserEditAddConnection,
  setSelectedEntityIds,
} from "../store/data-preparation-ui/data-preparation-ui-slice";

/**
 * Handles adding a connection when the user selects a scan in connection adding mode.
 *
 * @param dispatch - The dispatch function.
 * @param state - The current state.
 * @param previousSelectionIds - The previously selected entity IDs.
 * @param selectedScanId - The ID of the newly selected scan.
 * @returns `true` if a connection was added, `false` otherwise.
 */
export function handleAddConnection(
  dispatch: AppDispatch,
  state: RootState,
  previousSelectionIds: GUID[],
  selectedScanId: GUID | undefined,
): boolean {
  const isAddingConnections = selectIsAddingConnections(state);
  // Prevent edges to the same scan
  const isDifferentEntity =
    !!selectedScanId &&
    previousSelectionIds.length &&
    !previousSelectionIds.includes(selectedScanId);

  if (!!selectedScanId && isAddingConnections && isDifferentEntity) {
    // Add connection
    Analytics.track(EventType.addRegistrationConnection);
    dispatch(
      addUserEditAddConnection({
        sourceId: previousSelectionIds[0],
        targetId: selectedScanId,
      }),
    );
    // Clear selection
    dispatch(setSelectedEntityIds([]));
    return true;
  }

  return false;
}
