import { updateProject } from "@/components/common/project-provider/update-project";
import { selectAreaSections } from "@/components/ui/area-navigation/area-navigation-utils";
import { DeleteElementDialogContent } from "@/components/ui/delete-element-dialog-content";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import {
  selectActiveArea,
  selectActiveElement,
} from "@/store/selections-selectors";
import { setActiveArea, setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { TranslateVar, useDialog } from "@faro-lotv/flat-ui";
import { IElementSection } from "@faro-lotv/ielement-types";
import { removeIElement, selectAncestor } from "@faro-lotv/project-source";
import {
  createMutationDeleteElement,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";

/** @returns a callback for the user to request the deletion of an area */
export function useDeleteArea(): (area: IElementSection) => Promise<void> {
  const { createDialog } = useDialog();

  const { projectApiClient } = useApiClientContext();

  const dispatch = useAppDispatch();
  const { getState } = useAppStore();

  const { handleErrorWithDialog } = useErrorHandlers();

  const activeArea = useAppSelector(selectActiveArea);

  const isLastArea = useAppSelector(
    (state) => selectAreaSections(state).length === 1,
  );

  return useCallback(
    async (area: IElementSection) => {
      // Create a delete confirmation dialog
      const hasConfirmed = await createDialog({
        title: "Delete Area?",
        confirmText: "Delete",
        content: (
          <DeleteElementDialogContent
            name={
              <>
                <TranslateVar name="areaName">{area.name}</TranslateVar> and all
                its contents
              </>
            }
          />
        ),
        variant: "danger",
      });

      if (!hasConfirmed) return;

      try {
        dispatch(setShowSpinner(true));

        await projectApiClient.applyMutations([
          createMutationDeleteElement(area.id),
        ]);

        // Switch to the "Entire project" if the current area is deleted to revert to a known valid state
        if (area.id === activeArea?.id) {
          dispatch(setActiveArea(undefined));
        }

        // Restart the app to show the "create first area" mode
        if (isLastArea) {
          dispatch(changeMode("start"));
          return;
        }

        // Deselect the active element, if it is deleted as part of the area
        const activeElement = selectActiveElement(getState());
        const isAreaAncestorOfActiveElement = !!selectAncestor(
          activeElement,
          (el) => el.id === area.id,
        )(getState());
        if (isAreaAncestorOfActiveElement) {
          dispatch(setActiveElement(area.rootId));
        }

        dispatch(removeIElement(area.id));
        dispatch(
          updateProject({
            projectApi: projectApiClient,
            // Invalidate the volume queries of other areas, because those could've included elements deleted with this area
            invalidateAreaContents: true,
          }),
        );
      } catch (error) {
        handleErrorWithDialog({
          title: "Failed to delete area",
          error,
        });
      } finally {
        dispatch(setShowSpinner(false));
      }
    },
    [
      activeArea?.id,
      createDialog,
      dispatch,
      getState,
      handleErrorWithDialog,
      isLastArea,
      projectApiClient,
    ],
  );
}
