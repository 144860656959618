import { HelpPopover } from "@/components/ui/help-popover";
import { TREE_NODE_HEIGHT } from "@/components/ui/tree/tree-node";
import { selectSelectedEntityIds } from "@/data-preparation-tool/store/data-preparation-ui/data-preparation-ui-selectors";
import { setSelectedEntityIds } from "@/data-preparation-tool/store/data-preparation-ui/data-preparation-ui-slice";
import { selectFilteredDisjunctGroupsExcludingOldest } from "@/data-preparation-tool/store/revision-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  ExclamationMarkCircleFillIcon,
  FaroIconButton,
  FaroText,
  TranslateVar,
  neutral,
  yellow,
} from "@faro-lotv/flat-ui";
import { CaptureTreeEntityRevision } from "@faro-lotv/service-wires";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
} from "@mui/material";
import { isEqual } from "es-toolkit";
import { useEffect, useMemo, useRef, useState } from "react";
import { Tree, TreeApi } from "react-arborist";
import { handleAddConnection } from "../handle-add-connection";
import { useEntityChildrenMap } from "../scan-tree/scan-tree";
import { ScanTreeNode } from "../scan-tree/scan-tree-node";

/**
 * @param entities The entities to use in search of the issues in the IssuesPanel.
 */
export type IssuesPanelProps = {
  entities: CaptureTreeEntityRevision[];
};

/** @returns a panel displaying the issues that exist with the scans */
export function IssuesPanel({
  entities,
}: IssuesPanelProps): JSX.Element | null {
  const [expanded, setExpanded] = useState(true);

  const disjunctGroups = useAppSelector(
    selectFilteredDisjunctGroupsExcludingOldest,
  );
  const unconnectedEntities = useMemo(
    () => getUnconnectedEntities(entities, disjunctGroups),
    [entities, disjunctGroups],
  );
  const issueCount = unconnectedEntities.length;

  if (issueCount === 0) {
    return null;
  }

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Stack gap={2}>
        <Stack gap={1.5}>
          <Accordion
            // Styled to match the design of the `RegistrationCard` component
            sx={{
              width: "100%",
              height: "100%",
              background: yellow[50],
              boxShadow: "none",
              borderRadius: 0.5,
              border: "solid rgba(0, 0, 0, 0.12) 1px",
              overflow: "hidden",
            }}
            expanded={expanded}
          >
            <AccordionSummary
              aria-controls="panel-content"
              expandIcon={
                <FaroIconButton onClick={() => setExpanded((prev) => !prev)}>
                  <ExpandMoreIcon />
                </FaroIconButton>
              }
            >
              <ExclamationMarkCircleFillIcon sx={{ color: "warning.main" }} />
              <FaroText variant="heading16" sx={{ ml: 1 }}>
                <TranslateVar name="registrationIssueCount">
                  {issueCount}
                </TranslateVar>{" "}
                {issueCount === 1 ? "issue" : "issues"}
              </FaroText>
            </AccordionSummary>
            <Divider sx={{ mx: 2, mb: 2 }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mx: 2 }}
            >
              <FaroText
                variant="heading12"
                color={neutral[500]}
                sx={{ textTransform: "uppercase" }}
              >
                Unconnected Scans
              </FaroText>
              <HelpPopover
                title="Unconnected Scans"
                description="The scans listed here are not linked to the main group - they are either completely disconnected or form smaller separate groups."
                variant="darkInfo"
              />
            </Stack>
            <AccordionDetails
              sx={{
                height: "auto",
                overflow: "hidden",
              }}
            >
              <Stack gap={1} sx={{ mt: 2 }}>
                <IssuePanelTree entities={unconnectedEntities} />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </>
  );
}

/** @returns a panel displaying the issues that exist with the scans */
export function IssuePanelTree({
  entities,
}: IssuesPanelProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const { getState } = useAppStore();
  const selectedEntityIds = useAppSelector(selectSelectedEntityIds);

  const selectedIssuePanelIds = useMemo(
    () =>
      entities
        .map((entity) => entity.id)
        .filter((id) => selectedEntityIds.includes(id)),
    [entities, selectedEntityIds],
  );

  // Map for a quick children lookup by id
  const unconnectedEntityChildrenMap = useEntityChildrenMap(entities);

  const issuePanelTreeRef = useRef<TreeApi<CaptureTreeEntityRevision>>();

  // Sync the selection state with the tree
  useEffect(() => {
    const tree = issuePanelTreeRef.current;
    if (!tree) return;

    tree.setSelection({
      ids: selectedIssuePanelIds,
      anchor: selectedIssuePanelIds.at(-1) ?? null,
      mostRecent: selectedIssuePanelIds.at(-1) ?? null,
    });
  }, [selectedIssuePanelIds]);

  const numberOfUnconnectedScans = entities.length;

  return (
    <div>
      <Tree
        ref={issuePanelTreeRef}
        data={entities}
        width="100%"
        height={
          numberOfUnconnectedScans < 5
            ? TREE_NODE_HEIGHT * numberOfUnconnectedScans
            : TREE_NODE_HEIGHT * 5
        }
        disableDrag
        disableDrop
        rowHeight={TREE_NODE_HEIGHT}
        disableMultiSelection
        onSelect={(nodes) => {
          const selectedIds = nodes.map((node) => node.data.id);
          const lastScanId = selectedIds.at(-1);

          // Prevent infinite loop due to state sync
          if (isEqual(selectedIds, selectedIssuePanelIds)) return;

          if (
            handleAddConnection(
              dispatch,
              getState(),
              selectedEntityIds,
              lastScanId,
            )
          ) {
            return;
          }

          dispatch(setSelectedEntityIds(selectedIds));
        }}
        childrenAccessor={(node) =>
          unconnectedEntityChildrenMap.get(node.id) ?? null
        }
      >
        {ScanTreeNode}
      </Tree>
    </div>
  );
}

/**
 * @param entities The entities to extract names from.
 * @param disjunctGroups The disjunct groups to extract names from.
 * @returns An array of names from the entities and disjunct groups.
 */
function getUnconnectedEntities(
  entities: CaptureTreeEntityRevision[],
  disjunctGroups: string[][] | undefined,
): CaptureTreeEntityRevision[] {
  if (!disjunctGroups) return [];

  return entities.filter(
    (entity) => entity.parentId && disjunctGroups.flat().includes(entity.id),
  );
}
