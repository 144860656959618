import { userControlledFeatures } from "@/store/features/features";
import { AppDispatch } from "@/store/store";
import { useAppDispatch } from "@/store/store-hooks";
import {
  ToastReturn,
  useNonExhaustiveEffect,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setEditMode } from "../store/data-preparation-ui/data-preparation-ui-slice";
import { DecodedDeepLinkData } from "./deep-link-data-decoded";
import { isEncodedDeepLinkData } from "./deep-link-data-encoded";
import { decodeDeepLinkData } from "./deep-link-decode";

/** Search param keys which are used for other parts of the application. */
const IGNORE_KEYS = ["revisionId"];

/**
 * Handle deep links in the data preparation tool.
 *
 * Deep links are used to share a specific view with another user.
 * They are encoded in the URL search params and removed upon page load.
 */
export function useDataPreparationDeepLink(): void {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openToast } = useToast();
  const [searchParams] = useSearchParams();

  useNonExhaustiveEffect(() => {
    const deepLinkData = extractDeepLinkData(searchParams, openToast);
    removeDeepLinkFromUrl(navigate, searchParams, deepLinkData);

    initializeStateFromDeepLink(dispatch, deepLinkData);
  }, []);
}

/**
 * Initialize the store state based on the data encoded in the deep link.
 *
 * @param dispatch Dispatch function to update the store.
 * @param data The data encoded in the deep link.
 */
function initializeStateFromDeepLink(
  dispatch: AppDispatch,
  data: DecodedDeepLinkData,
): void {
  if (data.edit) {
    dispatch(setEditMode(data.edit));
  }
}

/**
 * @param searchParams The search params of the current URL.
 * @param openToast Function to show a toast message, for error handling.
 * @returns The data encoded in the deep link.
 */
function extractDeepLinkData(
  searchParams: URLSearchParams,
  openToast: ToastReturn["openToast"],
): DecodedDeepLinkData {
  const data: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    if (!isDeepLinkKey(key)) continue;

    data[key] = decodeURIComponent(value);
  }

  if (!isEncodedDeepLinkData(data)) {
    openToast({
      title: "Invalid deep link",
      message: "Your view might not match the shared one.",
      variant: "warning",
    });
    return {};
  }

  return decodeDeepLinkData(data);
}

/**
 * @param navigate The function to navigate to a new URL.
 * @param searchParams The search params of the current URL.
 * @param deepLinkData The data encoded in the deep link.
 */
function removeDeepLinkFromUrl(
  navigate: NavigateFunction,
  searchParams: URLSearchParams,
  deepLinkData: DecodedDeepLinkData,
): void {
  const deepLinkKeys = Object.keys(deepLinkData);

  if (deepLinkKeys.length === 0) return;

  const otherParams = new URLSearchParams();

  for (const [key, value] of searchParams.entries()) {
    if (!deepLinkKeys.includes(key)) {
      otherParams.append(key, value);
    }
  }

  navigate(
    {
      search: `?${otherParams.toString()}`,
    },
    {
      replace: true,
    },
  );
}

/**
 * @param key the search param key to check.
 * @returns `true` if the search param key should be considered part of the deep link data.
 */
function isDeepLinkKey(key: string): boolean {
  return (
    !IGNORE_KEYS.includes(key) &&
    !userControlledFeatures().some(
      (feature) => feature.toLowerCase() === key.toLowerCase(),
    )
  );
}
