import { Tag } from "@/store/tags/tags-slice";
import { ActionTextField, FilterMenuOptionType } from "@faro-lotv/flat-ui";
import { useMemo, useState } from "react";
import { validateNewTagName } from "../tags/tag-utils";
import { useDeleteTag } from "../tags/use-delete-tag";
import { useUpdateTag } from "../tags/use-update-tag";

type AnnotationTagOptionsProperties = {
  /** The tags list to display */
  tags: Tag[];
  /** The callback to call when a tag is deleted */
  onDeleteButtonClick?(tag: Tag): void;
};

type AnnotationTagOptionReturn = {
  /** The options for the annotation tag filter menu */
  tagsOptions: FilterMenuOptionType[];
  /** True if the tag is being processed (updating or deleting) */
  isProcessing: boolean;
};

/**
 * Hook used to get the options for the annotation tag filter menu.
 * It will handle the update and delete of tags, and will return if the project is being processed.
 *
 * @returns The options for the annotation tag filter menu and the processing state
 */
export function useAnnotationTagOptions({
  tags,
  onDeleteButtonClick,
}: AnnotationTagOptionsProperties): AnnotationTagOptionReturn {
  const [isProcessing, setIsProcessing] = useState(false);
  const updateTag = useUpdateTag(setIsProcessing);
  const deleteTag = useDeleteTag(setIsProcessing);

  const tagsOptions = useMemo(
    () =>
      tags
        .map((tag) => ({
          key: tag.id,
          value: tag.name,
          // Don't show a tooltip for the option, since the tooltip is already handled by the TextField component
          tooltip: "",
          label: (
            <ActionTextField
              inputText={tag.name}
              dark
              disabled={isProcessing}
              sx={{ width: "200px" }}
              validate={(newName) => validateNewTagName(tag.id, newName, tags)}
              onConfirmButtonClick={(newName) => {
                updateTag(tag, newName);
              }}
              onDeleteButtonClick={() => {
                deleteTag(tag);
                onDeleteButtonClick?.(tag);
              }}
            />
          ),
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [deleteTag, isProcessing, onDeleteButtonClick, tags, updateTag],
  );

  return { tagsOptions, isProcessing };
}
