import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import {
  selectAlignedLayerForLayerToLayerAlignment,
  selectIncrementalSheetTransformForLayerToLayerAlignment,
  selectReferenceForLayerToLayerAlignment,
} from "@/store/modes/layer-to-layer-alignment-mode-selectors";
import { resetLayerToLayerAlignment } from "@/store/modes/layer-to-layer-alignment-mode-slice";
import { setActiveElement, setActiveSheets } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { selectAncestor } from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  poseToMatrix4,
  selectIElement,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import { createMutationAlignSheetWithSheet } from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { Quaternion, Vector3 } from "three";
import {
  alignmentTransformToMatrix4,
  IDENTITY,
} from "../alignment-modes-commons/alignment-transform";
import { LayerToLayerAlignmentProgressBar } from "./layer-to-layer-alignment-progress-bar";
import { LayerToLayerAlignmentSplitScreen } from "./layer-to-layer-alignment-split-screen";

/** @returns The overlay for layer-to-layer alignment mode */
export function LayerToLayerAlignmentModeOverlay(): JSX.Element {
  const { openToast } = useToast();
  const store = useAppStore();

  const [showSpinner, setShowSpinner] = useState(false);

  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const alignedLayerId = useAppSelector(
    selectAlignedLayerForLayerToLayerAlignment,
  );

  const referenceLayerId = useAppSelector(
    selectReferenceForLayerToLayerAlignment,
  );

  const applyLayerMutation = useCallback(async () => {
    setShowSpinner(true);

    const alignedLayer = selectIElement(alignedLayerId)(store.getState());
    const referenceLayer = selectIElement(referenceLayerId)(store.getState());
    assert(
      alignedLayerId && alignedLayer && referenceLayerId && referenceLayer,
      "alignedLayer or referenceLayer not defined.",
    );

    const incrementalTransform =
      selectIncrementalSheetTransformForLayerToLayerAlignment(
        store.getState(),
      ) ?? IDENTITY;

    const incrementalMatrix = alignmentTransformToMatrix4(incrementalTransform);

    const alignedLayerWorldMatrix = selectIElementWorldMatrix4(alignedLayerId)(
      store.getState(),
    );
    const newWorldTransformMovingSheet = incrementalMatrix.multiply(
      alignedLayerWorldMatrix,
    );

    const sectionArea = selectAncestor(
      alignedLayer,
      isIElementAreaSection,
    )(store.getState());
    assert(sectionArea, "Section Area not found for selected aligned sheet.");

    const areaWorldMatrix = selectIElementWorldMatrix4(sectionArea.id)(
      store.getState(),
    );
    // get local transform matrix of the aligned layer (relative to parent area)
    const layerMatrix = areaWorldMatrix
      .invert()
      .multiply(newWorldTransformMovingSheet);

    // get local pose of the aligned layer IElement
    const { pos, rot, scale } = selectIElementProjectApiLocalPose(
      alignedLayer,
      layerMatrix,
    )(store.getState());

    // convert reference layer IElement local pose to  matrix
    const referencePoseMatrix = poseToMatrix4(referenceLayer.pose);

    // convert moving layer IElement local pose to matrix
    const layerPoseMatrix = poseToMatrix4({
      pos,
      rot,
      scale,
      isWorldRot: false,
      gps: null,
    });

    // calculate moving layer local matrix relative to reference layer
    const relativeMatrix = referencePoseMatrix
      .invert()
      .multiply(layerPoseMatrix);

    const relPos = new Vector3();
    const relRot = new Quaternion();
    const relScale = new Vector3();
    relativeMatrix.decompose(relPos, relRot, relScale);

    try {
      await client.applyMutations([
        createMutationAlignSheetWithSheet(alignedLayerId, referenceLayerId, {
          // set translation in y to zero to have layer elevation equal to reference layer
          pos: { x: relPos.x, y: 0, z: relPos.z },
          rot: { x: relRot.x, y: relRot.y, z: relRot.z, w: relRot.w },
          scale: relScale,
          isWorldRot: false,
          gps: null,
        }),
      ]);
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });
    }

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      fetchProjectIElements({
        fetcher: async () =>
          // Refresh the area node to get new transform
          await client.getAllIElements({
            ancestorIds: [sectionArea.id],
          }),
      }),
    );

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetLayerToLayerAlignment());

    // force aligned layer to be new visible to immediately show results of alignment
    dispatch(setActiveElement(alignedLayerId));
    dispatch(setActiveSheets([alignedLayerId]));

    setShowSpinner(false);
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to 2D mode as most convenient to validate alignment result
    dispatch(changeMode("sheet"));
  }, [
    store,
    dispatch,
    alignedLayerId,
    openToast,
    client,
    referenceLayerId,
    handleErrorWithToast,
  ]);

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <LayerToLayerAlignmentProgressBar apply={applyLayerMutation} />
        <LayerToLayerAlignmentSplitScreen />
      </Stack>
    </>
  );
}
