import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import { AlignmentViewLayout } from "@/store/modes/alignment-ui-types";
import {
  selectCloudForSheetToCloudAlignment,
  selectIncrementalSheetTransform,
  selectSheetToCloudAlignmentLayout,
  selectSheetToCloudAlignmentSheetElevation,
  selectSheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-selectors";
import {
  resetSheetToCloudAlignment,
  setSheetToCloudAlignmentLayout,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { ViewDiv } from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIElement,
  selectIElementProjectApiLocalPose,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { createMutationSetDataSessionWorldPose } from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/system";
import { useCallback } from "react";
import {
  alignmentTransformToMatrix4,
  IDENTITY,
  matrix4ToAlignmentTransform,
} from "../alignment-modes-commons/alignment-transform";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { createAreaAlignmentMutations } from "../alignment-modes-commons/project-alignment-mutations";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { SheetToCloudAlignmentOverlayScreen } from "./sheet-to-cloud-alignment-overlay-screen";
import { SheetToCloudAlignmentProgressBar } from "./sheet-to-cloud-alignment-progreess-bar";
import { SheetToCloudAlignmentSplitScreen } from "./sheet-to-cloud-alignment-split-screen";

/** @returns The overlay for the sheet to cloud alignment mode */
export function SheetToCloudAlignmentModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const { openToast } = useToast();
  const { handleErrorWithToast } = useErrorHandlers();

  const client = useCurrentProjectApiClient();
  const incrementalTransform = useAppSelector(selectIncrementalSheetTransform);
  const sheetElevation = useAppSelector(
    selectSheetToCloudAlignmentSheetElevation,
  );
  const sheet = useSheetSelectedForAlignment("sheetToCloud");

  const cloudId = useAppSelector(selectCloudForSheetToCloudAlignment);
  const cloud = useAppSelector(selectIElement(cloudId));
  assert(cloud, "invalid point cloud");

  const applyAreaMutation = useCallback(async () => {
    dispatch(setShowSpinner(true));

    const pointCloudSection = selectAncestor(
      cloud,
      isIElementSectionDataSession,
    )(store.getState());

    assert(pointCloudSection, "invalid point cloud");

    const cloudSectionWorldMatrix = selectIElementWorldMatrix4(
      pointCloudSection.id,
    )(store.getState());

    const sectionArea = selectAncestor(
      selectIElement(sheet.id)(store.getState()),
      isIElementAreaSection,
    )(store.getState());

    if (!sectionArea) {
      throw new Error("Section Area not found for selected sheet.");
    }
    const sectionAreaWorldMatrix = selectIElementWorldMatrix4(sectionArea.id)(
      store.getState(),
    );
    const sectionAreaWorldTransform = matrix4ToAlignmentTransform(
      sectionAreaWorldMatrix,
    );

    const sheetWorldPosition = selectIElementWorldPosition(sheet.id)(
      store.getState(),
    );

    const transform = incrementalTransform ?? IDENTITY;

    const sheetToCloudMatrix = alignmentTransformToMatrix4(transform);
    const alignmentTransform = matrix4ToAlignmentTransform(
      sheetToCloudMatrix.multiply(sectionAreaWorldMatrix),
    );

    // We are computing the delta elevation to apply to the section area
    alignmentTransform.position[1] =
      sheetElevation +
      sectionAreaWorldTransform.position[1] -
      sheetWorldPosition[1];

    const localTransform = selectIElementProjectApiLocalPose(
      sectionArea,
      alignmentTransformToMatrix4(alignmentTransform),
    )(store.getState());

    const mutations = createAreaAlignmentMutations(sectionArea, {
      position: [
        localTransform.pos.x,
        localTransform.pos.y,
        localTransform.pos.z,
      ],
      quaternion: [
        localTransform.rot.x,
        localTransform.rot.y,
        localTransform.rot.z,
        localTransform.rot.w,
      ],
      scale: [
        localTransform.scale.x,
        localTransform.scale.y,
        localTransform.scale.z,
      ],
    });

    const cloudParentArea = selectAncestor(
      selectIElement(pointCloudSection.id)(store.getState()),
      isIElementAreaSection,
    )(store.getState());

    // If a cloud is aligned with an area as its parent and has isWorldPose === false,
    // we need to detach it from the area and perform a mutation to set isWorldPose to true.
    // However, no mutation is required if the pose is already in world coordinates or if the cloud's parent is a different area.
    if (
      cloudParentArea?.id === sectionArea.id &&
      !pointCloudSection.pose?.isWorldPose
    ) {
      const cloudLocalTransform = selectIElementProjectApiLocalPose(
        sectionArea,
        cloudSectionWorldMatrix,
      )(store.getState());

      const cloudMutation = createMutationSetDataSessionWorldPose(
        pointCloudSection.id,
        cloudLocalTransform.scale.x,
        {
          x: cloudLocalTransform.rot.x,
          y: cloudLocalTransform.rot.y,
          z: cloudLocalTransform.rot.z,
          w: cloudLocalTransform.rot.w,
        },
        {
          x: cloudLocalTransform.pos.x,
          y: cloudLocalTransform.pos.y,
          z: cloudLocalTransform.pos.z,
        },
      );

      mutations.push(cloudMutation);
    }

    try {
      await client.applyMutations(mutations);
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      dispatch(setShowSpinner(false));

      return;
    }

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      updateProject({
        projectApi: client,
        // Refresh the area node to get new transform
        iElementQuery: {
          ancestorIds: [sectionArea.id],
        },
        invalidateAreaContents: true,
      }),
    );

    // after new alignment applied user will be redirected to 3D view in "overview" mode
    // force sheet to be new active area to immediately show results of alignment
    dispatch(setActiveElement(sheet.id));

    dispatch(setShowSpinner(false));
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetSheetToCloudAlignment());
  }, [
    client,
    cloud,
    dispatch,
    handleErrorWithToast,
    incrementalTransform,
    openToast,
    sheet.id,
    sheetElevation,
  ]);

  const step = useAppSelector(selectSheetToCloudAlignmentStep);
  const alignmentViewLayout = useAppSelector(selectSheetToCloudAlignmentLayout);
  const changeAlignmentViewLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setSheetToCloudAlignmentLayout(value)),
    [dispatch],
  );

  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <SheetToCloudAlignmentProgressBar applyAlignment={applyAreaMutation} />
      {step === SheetToCloudAlignmentStep.setElevation && (
        <SheetToCloudAlignmentElevation />
      )}
      {step === SheetToCloudAlignmentStep.alignIn2d &&
        alignmentViewLayout === AlignmentViewLayout.splitScreen && (
          <SheetToCloudAlignmentSplitScreen />
        )}
      {step === SheetToCloudAlignmentStep.alignIn2d &&
        alignmentViewLayout === AlignmentViewLayout.overlay && (
          <SheetToCloudAlignmentOverlayScreen />
        )}
      {step === SheetToCloudAlignmentStep.alignIn2d && (
        <AlignmentViewLayoutToggle
          alignmentLayout={alignmentViewLayout}
          changeAlignmentScreenLayout={changeAlignmentViewLayout}
        />
      )}
    </Stack>
  );
}

/** @returns The sheet to cloud alignment elevation sub-view */
function SheetToCloudAlignmentElevation(): JSX.Element {
  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          border: 3,
          borderColor: neutral[100],
          height: "100%",
        }}
      />
    </ViewDiv>
  );
}
