import { AlignmentTransform } from "@/modes/alignment-modes-commons/alignment-transform";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/modes/alignment-modes-commons/compute-split-screen-alignment";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "@/store/modes/alignment-ui-types";
import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";

/**
 * @param state  Current state
 * @returns ID of reference layer used in current session of layer-to-layer alignment
 */
export function selectReferenceForLayerToLayerAlignment(
  state: RootState,
): GUID | undefined {
  return state.layerToLayerAlignmentMode.referenceLayerId;
}

/**
 * @param state  Current state
 * @returns ID of aligned sheet used in current session of layer-to-layer alignment
 */
export function selectAlignedLayerForLayerToLayerAlignment(
  state: RootState,
): GUID | undefined {
  return state.layerToLayerAlignmentMode.alignedLayerId;
}

/**
 * @param state  Current state
 * @returns  anchor positions in split screen for layer-to-layer alignment
 */
export function selectAnchorPositionsForLayerToLayerAlignment(
  state: RootState,
): TwoPointsPairsAlignmentAnchorPositions {
  return state.layerToLayerAlignmentMode.alignmentAnchorPositions;
}

/**
 * @param state  Current state
 * @returns incremental layer-to-layer transform in CS of previous pose or undefined if position/scale was not modified
 */
export function selectIncrementalSheetTransformForLayerToLayerAlignment(
  state: RootState,
): AlignmentTransform | undefined {
  return state.layerToLayerAlignmentMode.incrementalTransform;
}

/**
 * @param state  Current state
 * @returns the current layer-to-layer alignment screen layout
 */
export function selectLayoutForLayerToLayerAlignment(
  state: RootState,
): AlignmentViewLayout {
  return state.layerToLayerAlignmentMode.alignmentLayout;
}

/**
 * @param state  Current state
 * @returns the current alignment screen split direction
 */
export function selectSplitDirectionForLayerToLayerAlignment(
  state: RootState,
): SplitDirection {
  return state.layerToLayerAlignmentMode.splitDirection;
}
