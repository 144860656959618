import {
  EventType,
  OpenHelpCenterResourceProperties,
} from "@/analytics/analytics-events";
import { runtimeConfig } from "@/runtime-config";
import { HelpCenterMenu, codeToLanguageCodes } from "@faro-lotv/flat-ui";
import { Analytics, useLocalizeLanguage } from "@faro-lotv/foreign-observers";

/**
 * @returns the Faro HelpCenterMenu configured for the Sphere Viewer app
 */
export function ViewerHelpCenterMenu(): JSX.Element {
  const language = useLocalizeLanguage();

  const helpCenterLanguage = codeToLanguageCodes(language.code);

  return (
    <HelpCenterMenu
      onOpenHelpCenter={() => {
        Analytics.track(EventType.openHelpMenu);
      }}
      onOpenHelpLink={(helpType) => {
        Analytics.track<OpenHelpCenterResourceProperties>(
          EventType.openHelpResource,
          {
            helpType,
          },
        );
      }}
      helpCenterLanguages={helpCenterLanguage}
      sendFeedback={
        runtimeConfig.externalLinks.feedbackForm
          ? () => {
              window.open(runtimeConfig.externalLinks.feedbackForm, "blank");
            }
          : undefined
      }
    />
  );
}
