import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { useCached3DObjectIfExists } from "@/object-cache";
import { isPanoObject } from "@/object-cache-type-guard";
import { selectAll3DMeasurements } from "@/store/measurement-tool-selector";
import { useAppSelector } from "@/store/store-hooks";
import { ToolName } from "@/store/ui/ui-slice";
import { ActiveToolAndPicking, PickingToolsRef } from "@/tools/picking-tools";
import {
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  isIElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { Box3, Plane, Vector3 } from "three";
import { WalkModelsStaticScene } from "./static/walk-models-static-scene";
import { WalkPanorama } from "./walk-panorama";
import {
  ViewType,
  WalkSceneActiveElement,
  WalkSceneOverlayElement,
} from "./walk-types";

type WalkSceneStaticProps = PickingToolsRef & {
  /** The main element of the scene to render */
  currentElement: WalkSceneActiveElement;

  /** Optional overlay element, to enable e.g. CAD/cloud overlay */
  overlayElement?: WalkSceneOverlayElement;

  /** The floor image where the placeholders are placed */
  sheetForElevation?: IElementGenericImgSheet;

  /** Which view is this: main view in walk mode, left view, or right view in splitscreen mode */
  viewType: ViewType;

  /** List of all the placeholders to show */
  placeholders: IElementImg360[];

  /** If true other panorama's placeholders will be rendered  */
  shouldShowPlaceholders: boolean;

  /** List of the annotations for this area */
  annotations: IElementGenericAnnotation[];

  /** Describe the active tool, and whether this tool is using picking */
  activeToolAndPicking: ActiveToolAndPicking;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Whether the 3d models should be rendered on demand */
  renderOnDemand: boolean;

  /** Callback executed when walks to a new position in the scene */
  onUserWalkedTo(p: Vector3): void;

  /** Callback executed when the active element should be changed */
  onTargetElementChanged(element: WalkSceneActiveElement): void;

  /** Callback called when the active waypoint target element inside the walk scene has changed. */
  onWayPointChanged(target: WayPointTarget): void;

  /** Optional callback to signal outside that the bounding box of all models has changed. */
  onAllModelsBoxChanged?(box: Box3): void;
};

/** @returns A static scene for the walk mode */
export function WalkSceneStatic({
  currentElement,
  overlayElement,
  viewType,
  placeholders,
  shouldShowPlaceholders: shouldShowPlaceholdersProp,
  sheetForElevation,
  activeToolAndPicking,
  annotations,
  clippingPlanes,
  renderOnDemand,
  onUserWalkedTo,
  onTargetElementChanged,
  onWayPointChanged,
  onAllModelsBoxChanged,
  ...toolEvents
}: WalkSceneStaticProps): JSX.Element | null {
  /** Get the current 3D object (do not report loading failure for the IElementModel3dStream)*/
  const displayLoadingErrorCurrentElement =
    !isIElementModel3dStream(currentElement);
  const currentObject = useCached3DObjectIfExists(
    currentElement,
    displayLoadingErrorCurrentElement,
  );
  const displayLoadingErrorOverlayElement =
    !!overlayElement && !isIElementModel3dStream(overlayElement);
  const overlayObject = useCached3DObjectIfExists(
    overlayElement,
    displayLoadingErrorOverlayElement,
  );

  const measurements = useAppSelector(selectAll3DMeasurements);

  const shouldShowPlaceholders =
    shouldShowPlaceholdersProp &&
    (!activeToolAndPicking.isPickingToolActive ||
      activeToolAndPicking.activeTool === ToolName.measurement);
  const shouldShowAnnotations = !activeToolAndPicking.isPickingToolActive;

  if (isPanoObject(currentObject)) {
    return (
      <WalkPanorama
        placeholders={placeholders}
        shouldShowPlaceholders={shouldShowPlaceholders}
        shouldShowAnnotations={shouldShowAnnotations}
        isPickingToolActive={activeToolAndPicking.isPickingToolActive}
        pano={currentObject}
        annotations={annotations}
        measurements={measurements}
        sheetForElevation={sheetForElevation}
        visible
        clippingPlanes={clippingPlanes}
        isSecondaryView={viewType === ViewType.RightView}
        onTargetElementChanged={onTargetElementChanged}
        onWayPointChanged={onWayPointChanged}
        {...toolEvents}
      />
    );
  }
  return (
    <WalkModelsStaticScene
      mainModel={currentObject}
      overlayModel={overlayObject}
      sheetForElevation={sheetForElevation}
      onUserWalkedTo={onUserWalkedTo}
      placeholders={placeholders}
      shouldShowPlaceholders={shouldShowPlaceholders}
      shouldShowAnnotations={shouldShowAnnotations}
      isPickingToolActive={activeToolAndPicking.isPickingToolActive}
      annotations={annotations}
      measurements={measurements}
      onWayPointChanged={onWayPointChanged}
      clippingPlanes={clippingPlanes}
      renderOnDemand={renderOnDemand}
      viewType={viewType}
      onAllModelsBoxChanged={onAllModelsBoxChanged}
      {...toolEvents}
    />
  );
}
