import { EventType } from "@/analytics/analytics-events";
import { useThresholdSetContext } from "@/registration-tools/common/registration-report/threshold-set-context";
import { QualityStatus } from "@/registration-tools/utils/metrics";
import {
  determineReportQuality,
  isRegistrationReportEmpty,
  MultiRegistrationReport,
} from "@/registration-tools/utils/multi-registration-report";
import { FaroIconButton, FaroSvgIcon, FaroTooltip } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { isDefinedAndLoaded, MaybeLoading } from "@faro-lotv/foundation";
import {
  CaptureApiClient,
  RegistrationRevision,
  RegistrationState,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { ReactComponent as RegistrationQualityIndicatorDefault } from "../icons/registration-quality-indicator-default.svg";
import { ReactComponent as RegistrationQualityIndicatorGood } from "../icons/registration-quality-indicator-good.svg";
import { ReactComponent as RegistrationQualityIndicatorMedium } from "../icons/registration-quality-indicator-medium.svg";
import { ReactComponent as RegistrationQualityIndicatorPoor } from "../icons/registration-quality-indicator-poor.svg";
import { QualityPopover, QualityScope } from "./quality-popover";

export type ShowQualityReportButtonProps = {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The loading status of registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Callback, when the report should be opened */
  onShowReport(): void;
};

/**
 * @returns A button to show the status of the registration.
 */
export function RegistrationQualityIndicator({
  revision,
  registrationReport,
  onShowReport,
}: ShowQualityReportButtonProps): JSX.Element | null {
  const { thresholdSet } = useThresholdSetContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isRegistered =
    revision.state === RegistrationState.registered ||
    revision.state === RegistrationState.merged;
  // Whether the registration was triggered by SCENE
  // In this case, we don't have a full registration report available
  const isByScene =
    revision.createdByClient === CaptureApiClient.scene ||
    (isDefinedAndLoaded(registrationReport) &&
      registrationReport.additionalData?.triggeredByClient ===
        CaptureApiClient.scene);
  const registeredByScene = isRegistered && isByScene;

  const isEnabled =
    isDefinedAndLoaded(registrationReport) &&
    !isRegistrationReportEmpty(registrationReport) &&
    !registeredByScene;

  const overallQuality = useMemo(
    () =>
      isEnabled
        ? determineReportQuality(registrationReport, thresholdSet)
        : QualityStatus.UNKNOWN,
    [isEnabled, registrationReport, thresholdSet],
  );

  const svg = useMemo(() => {
    switch (overallQuality) {
      case QualityStatus.GOOD:
        return RegistrationQualityIndicatorGood;
      case QualityStatus.MEDIUM:
        return RegistrationQualityIndicatorMedium;
      case QualityStatus.POOR:
        return RegistrationQualityIndicatorPoor;
      default:
        return RegistrationQualityIndicatorDefault;
    }
  }, [overallQuality]);

  if ((!registrationReport && !registeredByScene) || !isRegistered) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <FaroTooltip title="Registration Quality">
        <FaroIconButton
          size="xl"
          ref={buttonRef}
          onClick={() => {
            setIsPopoverOpen(true);
            Analytics.track(EventType.showSimpleRegistrationQualityReport);
          }}
        >
          <FaroSvgIcon
            source={svg}
            aria-label="Total Registration Quality Indicator"
          />
        </FaroIconButton>
      </FaroTooltip>
      {isPopoverOpen && (
        <QualityPopover
          aria-label="simple registration quality report"
          scope={QualityScope.CLUSTER}
          quality={overallQuality}
          anchorEl={buttonRef.current}
          isOpen={isPopoverOpen}
          isSceneRegistered={registeredByScene}
          onDetailsButtonClick={() => {
            setIsPopoverOpen(false);
            Analytics.track(EventType.showDetailedRegistrationQualityReport);
            onShowReport();
          }}
          closePopover={() => {
            setIsPopoverOpen(false);
          }}
        />
      )}
    </Stack>
  );
}
