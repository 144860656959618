import { RegistrationEditMode } from "../store/data-preparation-ui/data-preparation-ui-slice";
import { DecodedDeepLinkData } from "./deep-link-data-decoded";
import { EncodedDeepLinkData, EncodedEditMode } from "./deep-link-data-encoded";

/**
 * @param encodedData The deep link data as encoded in the URL search parameters.
 * @returns the parsed/decoded deep link data.
 */
export function decodeDeepLinkData(
  encodedData: EncodedDeepLinkData,
): DecodedDeepLinkData {
  return {
    edit: encodedData.edit ? decodeEditMode(encodedData.edit) : undefined,
  };
}

function decodeEditMode(
  encodedEditMode: EncodedEditMode,
): RegistrationEditMode {
  switch (encodedEditMode) {
    case EncodedEditMode.editScans:
      return RegistrationEditMode.editScans;
    case EncodedEditMode.addConnection:
      return RegistrationEditMode.addConnection;
    case EncodedEditMode.deleteConnection:
      return RegistrationEditMode.deleteConnection;
  }
}
