import { EventType } from "@/analytics/analytics-events";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import {
  selectControlPointsAlignmentAnchorPositions,
  selectControlPointsSheetElevation,
  selectElementToAlignWithControlPointsAlignment,
} from "@/store/modes/control-points-alignment-mode-selectors";
import { resetControlPointsAlignment } from "@/store/modes/control-points-alignment-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  selectIElement,
  selectIElementProjectApiLocalPose,
  ViewDiv,
} from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { isIElementAreaSection } from "@faro-lotv/ielement-types";
import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import {
  AlignmentElementLabel,
  LabelType,
} from "../alignment-modes-commons/alignment-element-label";
import {
  alignmentTransformToMatrix4,
  IDENTITY,
  matrix4ToAlignmentTransform,
} from "../alignment-modes-commons/alignment-transform";
import { computeSplitScreenAlignment } from "../alignment-modes-commons/compute-split-screen-alignment";
import { createAreaAlignmentMutations } from "../alignment-modes-commons/project-alignment-mutations";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ControlPointsAlignmentProgressBar } from "./control-points-alignment-progress-bar";
import { ControlPointsAlignmentSetPointsPanel } from "./control-points-alignment-set-points-panel";

/** @returns The overlay for control-points alignment mode */
export function ControlPointsAlignmentModeOverlay(): JSX.Element {
  const [showSpinner, setShowSpinner] = useState(false);
  const store = useAppStore();
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const elementToAlignId = useAppSelector(
    selectElementToAlignWithControlPointsAlignment,
  );

  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));
  assert(elementToAlignId && elementToAlign, "Element to align not assigned");

  // TODO: support for layers alignment will be done in https://faro01.atlassian.net/browse/CADBIM-1222 )
  assert(
    isIElementAreaSection(elementToAlign),
    "Control points alignment is supported at the moment only for areas",
  );

  const sectionAreaWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(elementToAlignId),
  );

  const applyAreaMutation = useCallback(async () => {
    setShowSpinner(true);

    const elevation = selectControlPointsSheetElevation(store.getState()) ?? 0;
    const alignmentAnchorPositions =
      selectControlPointsAlignmentAnchorPositions(store.getState());

    const computed2PairsTransform =
      computeSplitScreenAlignment(alignmentAnchorPositions, true) ?? IDENTITY;

    const computedTransformMatrix = alignmentTransformToMatrix4(
      computed2PairsTransform,
    );
    const finalTransform = matrix4ToAlignmentTransform(
      computedTransformMatrix.multiply(sectionAreaWorldMatrix),
    );

    //  apply elevation from user input to the final transform
    finalTransform.position[1] = elevation;

    const localTransform = selectIElementProjectApiLocalPose(
      elementToAlign,
      alignmentTransformToMatrix4(finalTransform),
    )(store.getState());

    Analytics.track(EventType.confirmControlPointsAlignmentArea);

    try {
      await client.applyMutations(
        createAreaAlignmentMutations(elementToAlign, {
          position: [
            localTransform.pos.x,
            localTransform.pos.y,
            localTransform.pos.z,
          ],
          quaternion: [
            localTransform.rot.x,
            localTransform.rot.y,
            localTransform.rot.z,
            localTransform.rot.w,
          ],
          scale: [
            localTransform.scale.x,
            localTransform.scale.y,
            localTransform.scale.z,
          ],
        }),
      );
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      setShowSpinner(false);
      return;
    }

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetControlPointsAlignment());

    // Fetch the changed section area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      updateProject({
        projectApi: client,
        iElementQuery: {
          // Refresh the area node to get new transform
          ancestorIds: [elementToAlign.id],
        },
      }),
    );

    setShowSpinner(false);

    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to 2D mode
    dispatch(changeMode("sheet"));
  }, [
    client,
    dispatch,
    handleErrorWithToast,
    openToast,
    elementToAlign,
    sectionAreaWorldMatrix,
    store,
  ]);

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <ControlPointsAlignmentProgressBar apply={applyAreaMutation} />

        <Stack direction="row" sx={{ height: "100%" }}>
          <ViewDiv
            eventDivRef={singleScreenRef}
            sx={{
              height: "100%",
              width: "100%",
              borderRight: 3,
              borderRightColor: neutral[200],
            }}
          >
            <AlignmentElementLabel
              element={elementToAlign}
              labelType={LabelType.alignedElement}
              sx={{
                top: "10px",
                left: "30px",
              }}
            />
          </ViewDiv>
          <ControlPointsAlignmentSetPointsPanel
            layerOrAreaId={elementToAlignId}
          />
        </Stack>
      </Stack>
    </>
  );
}
