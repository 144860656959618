import { UnrecoverableError } from "@/errors/unrecoverable-error";
import { useEffect } from "react";

/**
 * Properties for the screen size check hook.
 */
interface ScreenSizeCheckProps {
  /**
   * The minimum screen width required for the workflow.
   */
  threshold: number;

  /**
   * The function to call for the redirect.
   */
  handleRedirect(): void;

  /**
   * The label for the redirect action.
   */
  label: string;
}

/**
 * Checks if the screen size is too small for the current workflow.
 * If the screen size is too small, an UnrecoverableError is thrown.
 *
 * @param props - The properties for the screen size check.
 */
export function useScreenSizeCheck({
  threshold,
  handleRedirect,
  label,
}: ScreenSizeCheckProps): void {
  useEffect(() => {
    if (window.screen.width < threshold) {
      throw new UnrecoverableError(null, {
        errorStatement: "Optimized for Larger Screens",
        helpText:
          "This workflow contains features that are not supported on smaller devices. Please use a tablet or desktop for the best experience.",
        actions: [
          {
            action: handleRedirect,
            label,
            primary: true,
          },
        ],
      });
    }
  }, [handleRedirect, threshold, label]);
}
