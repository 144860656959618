import { SplitDirection } from "@/store/modes/alignment-ui-types";
import {
  selectCrossSectionEnabledForCloudToSheetAlignment,
  selectElevationForCloudToSheetAlignment,
  selectSplitDirectionForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import {
  setCrossSectionEnabledForCloudToSheetAlignment,
  setSplitDirectionForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { useCallback, useMemo } from "react";
import {
  AlignmentModeSplitScreenOverlay,
  CrossSectionProps,
} from "../alignment-modes-commons/alignment-mode-split-screen-overlay";
import { useCloudToSheetAlignmentElements } from "./use-cloud-to-sheet-alignment-elements";

/** @returns The cloud-to-sheet alignment split screen overlay*/
export function CloudToSheetAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const splitDirection = useAppSelector(
    selectSplitDirectionForCloudToSheetAlignment,
  );

  // callback function for changing split-screen direction
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setSplitDirectionForCloudToSheetAlignment(newDirection));
  }, [dispatch, splitDirection]);

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForCloudToSheetAlignment,
  );
  const elevation = useAppSelector(selectElevationForCloudToSheetAlignment);

  const cloudCrossSectionProps: CrossSectionProps = useMemo(
    () => ({
      enabled: isCrossSectionEnabled,
      elevation,
      onToggleCrossSection: () => {
        dispatch(
          setCrossSectionEnabledForCloudToSheetAlignment(
            !isCrossSectionEnabled,
          ),
        );
      },
    }),
    [dispatch, isCrossSectionEnabled, elevation],
  );

  const alignmentElements = useCloudToSheetAlignmentElements();

  return (
    <AlignmentModeSplitScreenOverlay
      splitDirection={splitDirection}
      onChangeSplitDirection={changeSplitDirection}
      firstScreenCrossSection={cloudCrossSectionProps}
      alignedElement={alignmentElements.cloudDataSession}
      referenceElement={alignmentElements.sectionArea}
    />
  );
}
