import {
  CloudToSheetAlignmentStep,
  setStepForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface CloudToSheetAlignmentProgressBarProps {
  /** Method to call when the user confirms the alignment */
  applyAlignment(): void;
}

/**
 * @returns The bar that is shown in the top of the app
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function CloudToSheetAlignmentProgressBar({
  applyAlignment,
}: CloudToSheetAlignmentProgressBarProps): JSX.Element {
  const dispatch = useAppDispatch();

  /** defines steps for cloud-to-sheet alignment progressbar */
  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: CloudToSheetAlignmentStep.setElevation,
        label: "Elevation",
        onBeforeNext: () => {
          dispatch(
            setStepForCloudToSheetAlignment(
              CloudToSheetAlignmentStep.pairPoints,
            ),
          );
          return Promise.resolve();
        },
      },
      {
        key: CloudToSheetAlignmentStep.pairPoints,
        label: "Align",
        onBeforeBack: () => {
          dispatch(
            setStepForCloudToSheetAlignment(
              CloudToSheetAlignmentStep.setElevation,
            ),
          );
          return Promise.resolve();
        },
      },
    ],
    [dispatch],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Confirm Alignment"
      onLastStepButtonClicked={applyAlignment}
    />
  );
}
