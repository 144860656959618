import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { FaroButton } from "@faro-lotv/flat-ui";
import { MaybeLoading } from "@faro-lotv/foundation";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { EditRegistrationButton } from "../inspect-and-publish/edit-registration-button";
import { PublishButton } from "../inspect-and-publish/inspect-and-publish-publish-button";
import { DataPrepParametersMenu } from "../ui/parameters-menu/data-prep-parameters-menu";
import { RefineAndPublishButton } from "./refine-and-publish-button";
import { RegistrationQualityIndicator } from "./registration-quality-indicator";

type DataPreparationActionsProps = {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Callback, when the report should be opened/closed */
  onToggleReport(shouldOpen: boolean): void;

  /** Whether the registration editing mode is active. */
  isEditRegistrationEnabled: boolean;

  /** Callback to disable the registration editing mode. */
  onCancelEditRegistration(): void;
};

/**
 * @returns The main actions the user can execute in the data preparation page.
 */
export function DataPreparationActions({
  revision,
  registrationReport,
  onToggleReport,
  isEditRegistrationEnabled,
  onCancelEditRegistration,
}: DataPreparationActionsProps): JSX.Element {
  if (isEditRegistrationEnabled) {
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <FaroButton
          aria-label="cancel editing"
          variant="ghost"
          onClick={onCancelEditRegistration}
          sx={{
            textWrap: "nowrap",
          }}
          fullWidth
        >
          Cancel editing
        </FaroButton>

        <DataPrepParametersMenu />
        <RefineAndPublishButton revision={revision} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <RegistrationQualityIndicator
        revision={revision}
        registrationReport={registrationReport}
        onShowReport={() => onToggleReport(true)}
      />
      <EditRegistrationButton revisionState={revision.state} />
      <PublishButton
        aria-label="publish registration revision"
        revision={revision}
        disabledDueToError={false}
        showWithWarning={false}
      />
    </Stack>
  );
}
