import {
  AlignPointCloudEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { selectAreaFor } from "@/store/selections-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { NoTranslate } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { GUID, assert } from "@faro-lotv/foundation";
import {
  isIElementBimModelSection,
  isIElementGenericPointCloudStream,
  isIElementGenericStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
  selectIsPointCloudAligned,
  selectNumberOfPointCloudsOnFloor,
} from "@faro-lotv/project-source";
import { useCallback } from "react";
import { ElementIconType } from "../../icons";
import {
  disableAlignAreaMessage,
  useDisableCaptureTreeAlignment,
} from "../../tree/cad-model-tree/use-disable-capture-tree-alignment";
import { GenericCardLayout } from "../layouts/generic-card-layout";

type ToAlignCardProps = {
  /** ID of the LaserScan Section of the PointCloud to align */
  iElementID: GUID;
};

/** @returns the upload menu card to ask the user to align a processed point cloud */
export function ToAlignCard({ iElementID }: ToAlignCardProps): JSX.Element {
  const iElement = useAppSelector(selectIElement(iElementID));
  assert(
    iElement,
    "a ToAlignCard need to be created on an existing and loaded IElement",
  );
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const activeArea = useAppSelector(selectAreaFor(iElement));
  const isCad = isIElementBimModelSection(iElement);

  const disableAlignment = useDisableCaptureTreeAlignment(activeArea);

  const startAlignment = useCallback(() => {
    const state = store.getState();
    const elementToAlign = selectChildDepthFirst(
      iElement,
      isIElementGenericStream,
    )(state);

    let dataSession;

    if (
      !isCad &&
      elementToAlign &&
      isIElementGenericPointCloudStream(elementToAlign)
    ) {
      const isAligned = selectIsPointCloudAligned(elementToAlign)(state);

      const numberOfAlignedPointClouds = selectNumberOfPointCloudsOnFloor(
        activeArea,
        true,
      )(state);
      Analytics.track<AlignPointCloudEventProperties>(
        EventType.alignPointCloud,
        {
          via: "context menu",
          alreadyAligned: isAligned,
          numberOfAlignedPCs: numberOfAlignedPointClouds,
        },
      );

      // currently that logic supports only data in the BI-tree.
      // we know that it's not compatible with SCENE data structure and new capture tree.
      // similar problem present in many workflows related to alignments.
      // Those problems will be addressed in the epic https://faro01.atlassian.net/browse/CADBIM-715
      dataSession = selectAncestor(
        elementToAlign,
        isIElementSectionDataSession,
      )(state);
    }

    assert(
      elementToAlign && activeArea,
      "should not be called when there is no cloud or area",
    );

    openAlignmentWizard({
      elementIdToAlign: dataSession ? dataSession.id : elementToAlign.id,
      dispatch,
    });
  }, [activeArea, dispatch, iElement, isCad, store]);

  const cardName = <NoTranslate>{iElement.name}</NoTranslate>;

  return (
    <>
      {isCad && (
        <GenericCardLayout
          name={cardName}
          subText="3D Model"
          icon={ElementIconType.CadModel}
          startTime={iElement.createdAt}
        />
      )}

      {!isCad && (
        <GenericCardLayout
          name={cardName}
          startTime={iElement.createdAt}
          icon={ElementIconType.PointCloudIcon}
          action={{
            name: "Align",
            callback: startAlignment,
            disableMessage: disableAlignment
              ? disableAlignAreaMessage
              : undefined,
          }}
        />
      )}
    </>
  );
}
