import {
  CreateAreaToolProperties,
  EventType,
} from "@/analytics/analytics-events";
import { OverviewImagePreview } from "@/components/r3f/utils/overview-image-preview";
import { selectAreaSections } from "@/components/ui/area-navigation/area-navigation-utils";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useCreateAreaAndVolume } from "@/hooks/use-create-area-and-volume";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { changeMode } from "@/store/mode-slice";
import { setActiveArea, setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  useBoxControlsClippingPlanes,
  useBoxControlsContext,
} from "@/utils/box-controls-context";
import { volumeFromPlanes } from "@/utils/volume-utils";
import {
  Canvas,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import {
  FaroButton,
  FaroText,
  TextField,
  neutral,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { LotvRenderer, assert } from "@faro-lotv/lotv";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { PerformanceMonitor } from "@react-three/drei";
import { useCallback, useMemo, useState } from "react";
import { Color } from "three";
import { useComponentScreenshot } from "../../hooks/use-component-screenshot";
import { useCurrentScene } from "../mode-data-context";
import { selectNewAreaDefaultName } from "./clipping-box-selectors";
import { ExistingAreasList } from "./existing-areas-list";

const DEFAULT_WIDTH = 1024;

/**
 * Check if the area name is valid
 * It must have at least one character and at not more than the backend limit
 *
 * @param areaName The name of the area
 * @returns true if the area name is valid, false otherwise
 */
function isAreaNameValid(areaName: string | undefined): boolean {
  if (!areaName) return false;

  return areaName.length > 0 && areaName.length <= MAX_NAME_LENGTH;
}

/**
 * @returns a sidebar next to the canvas, used for the create area workflow
 */
export function ClippingBoxModeDrawer(): JSX.Element | null {
  const { main } = useCurrentScene();
  assert(main, "There should be a main element in the scene");

  const areaDefaultName = useAppSelector(selectNewAreaDefaultName(main));

  // Use the dataset or data session name as the default area name
  const [areaName, setAreaName] = useState(areaDefaultName ?? "");
  // If true, the user wants to create another area after the current one
  // So, if true, after the creation, the area name and the clipping box will be reset
  // Otherwise, the user will be redirected to the sheet mode with the new area selected
  const [shouldCreateAnotherArea, setShouldCreateAnotherArea] = useState(false);

  // If the user has already changed the default name
  const [hasEditedName, setHasEditedName] = useState(false);

  // If true the area is being created and a spinner is shown
  const [isCreatingArea, setIsCreatingArea] = useState(false);

  // If the user has already focused the input field once
  const [isFirstFocus, setIsFirstFocus] = useState(true);
  // If the user has already clicked the create button once
  const [isFirstClick, setIsFirstClick] = useState(true);

  // Error message to show when the area name is invalid
  // It's only shown after the first click or if the user has already focused the input field
  const errorMessage = useMemo(() => {
    if (!isAreaNameValid(areaName) && (!isFirstClick || !isFirstFocus)) {
      return "Field cannot be empty";
    }
  }, [areaName, isFirstClick, isFirstFocus]);

  const shouldShowDrawer = useAppSelector(
    selectHasFeature(Features.CreateArea),
  );

  const [previewObjectAspectRatio, setPreviewObjectAspectRatio] = useState(1);

  const clippingPlanes = useBoxControlsClippingPlanes();

  // Component rendered in an offscreen canvas to take a screenshot
  // This is what shows up in the screenshot
  // It's using a useMemo to avoid re-rendering too many times the component
  const renderComponent = useMemo(
    () => (
      <OverviewImagePreview
        clippingPlanesBox={clippingPlanes}
        showBackgroundPlane={false}
        onAspectRatioChanged={setPreviewObjectAspectRatio}
      />
    ),
    [clippingPlanes],
  );

  // Assign the static dimension to the biggest side of the object
  // This is done to avoid the screenshot from being too big
  const { screenshotWidth, screenshotHeight } = useMemo(() => {
    if (previewObjectAspectRatio > 1) {
      return {
        screenshotWidth: DEFAULT_WIDTH,
        screenshotHeight: Math.round(DEFAULT_WIDTH / previewObjectAspectRatio),
      };
    }
    return {
      screenshotWidth: Math.round(DEFAULT_WIDTH * previewObjectAspectRatio),
      screenshotHeight: DEFAULT_WIDTH,
    };
  }, [previewObjectAspectRatio]);

  const transform = useAppSelector(selectIElementWorldTransform(main.id));

  const dispatch = useAppDispatch();

  // The function to reset the clipping box is already set in the mode's scene component
  const { resetBoxEvent } = useBoxControlsContext();
  const clippingPlanesBox = useBoxControlsClippingPlanes();
  const volumeInfo = volumeFromPlanes(clippingPlanesBox, transform);

  const createNewArea = useCreateAreaAndVolume();
  const { openToast } = useToast();
  const { handleErrorWithToast } = useErrorHandlers();

  const existingAreas = useAppSelector(selectAreaSections);

  const resetModeContext = useCallback(() => {
    setAreaName(areaDefaultName ?? "");
    setIsFirstFocus(true);
    setIsFirstClick(true);
    resetBoxEvent.emit();
  }, [areaDefaultName, resetBoxEvent]);

  const onComponentRendered = useCallback(
    async (canvas: HTMLCanvasElement) => {
      // Get the content of the canvas as a blob
      const blob: Blob | null = await new Promise((resolve) =>
        canvas.toBlob(resolve),
      );
      assert(blob, "Could not create a blob from the canvas");

      // Create an image file from the canvas blob
      const imageFile = new File([blob], `${areaName}.png`, {
        type: "image/png",
      });
      assert(imageFile, "Could not create the image file");

      try {
        // Create the area, the volume and the sheet
        const areaId = await createNewArea({
          areaName,
          file: imageFile,
          volume: volumeInfo,
        });

        // Show a toast message when the area is created
        openToast({
          title: `${areaName} area successfully created.`,
        });

        if (shouldCreateAnotherArea) {
          // Clear the area name and the clipping box
          resetModeContext();
        } else {
          // Select the new area and change mode
          dispatch(setActiveArea(areaId));
          dispatch(setActiveElement(areaId));
          dispatch(changeMode("sheet"));
        }
      } catch (error) {
        // Show an error toast when the area creation fails
        handleErrorWithToast({
          title: `Failed to create ${areaName} area.`,
          error,
        });
      } finally {
        // Remove the spinner
        setIsCreatingArea(false);
      }
    },
    [
      areaName,
      createNewArea,
      dispatch,
      handleErrorWithToast,
      openToast,
      resetModeContext,
      shouldCreateAnotherArea,
      volumeInfo,
    ],
  );

  // Callback used to generate a screenshot
  const createImage = useComponentScreenshot(
    renderComponent,
    screenshotWidth,
    screenshotHeight,
    onComponentRendered,
  );

  // Create an image only if the area name is valid
  const onCreateButtonClicked = useCallback(() => {
    Analytics.track<CreateAreaToolProperties>(EventType.createNewArea, {
      hasEditedName,
      createAnotherArea: shouldCreateAnotherArea,
    });

    if (isFirstClick) {
      setIsFirstClick(false);
    }

    if (isAreaNameValid(areaName)) {
      // Show a spinner while the area is being created
      setIsCreatingArea(true);

      try {
        createImage();
      } catch {
        // Remove the spinner if an error occurs
        setIsCreatingArea(false);

        handleErrorWithToast({
          title: "Failed to create image for area",
          error: "Error creating image for area",
        });
      }
    }
  }, [
    areaName,
    createImage,
    handleErrorWithToast,
    hasEditedName,
    isFirstClick,
    shouldCreateAnotherArea,
  ]);

  if (!shouldShowDrawer) return null;

  return (
    <Drawer
      open
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          position: "unset",
          width: 300,
          backgroundColor: neutral[50],
          p: 1.5,
          gap: 3,
        },
      }}
    >
      <FaroText variant="heading16">Areas</FaroText>
      <FaroText variant="placeholder">
        Fill and check the information about your area.
      </FaroText>
      <Stack
        sx={{
          p: 1.5,
          gap: 2,
          borderRadius: 0.5,
          backgroundColor: neutral[0],
        }}
      >
        <TextField
          label="Name"
          placeholder="Area 1"
          text={areaName}
          // Stop the box controls from being triggered
          onKeyDown={(e) => e.stopPropagation()}
          onTextChanged={(text) => {
            if (!hasEditedName) {
              setHasEditedName(true);
            }

            setAreaName(text);
          }}
          fullWidth
          // Allow only a certain number of characters
          inputProps={{ maxLength: MAX_NAME_LENGTH }}
          maxCharacterCount={MAX_NAME_LENGTH}
          error={errorMessage}
          disabled={isCreatingArea}
          onFocus={(e) => {
            // Select the text on the first focus, so it's easier to edit the default value
            if (isFirstFocus) {
              setIsFirstFocus(false);
              e.target.select();
            }
          }}
        />
        <Stack>
          <FaroText variant="labelM">Area overview image</FaroText>
          <OverviewImage />
          <FaroText variant="helpText">
            An image of the area will be automatically generated
          </FaroText>
          <FormControlLabel
            label="Create another area"
            control={
              <Checkbox
                checked={shouldCreateAnotherArea}
                disabled={isCreatingArea}
                onChange={(ev) => setShouldCreateAnotherArea(ev.target.checked)}
              />
            }
            sx={{
              my: 2,
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: neutral[800],
              },
            }}
          />
          <FaroButton
            onClick={onCreateButtonClicked}
            isLoading={isCreatingArea}
          >
            Create
          </FaroButton>
        </Stack>
      </Stack>

      {existingAreas.length && (
        <>
          <Divider />
          <ExistingAreasList existingAreas={existingAreas} />
        </>
      )}
    </Drawer>
  );
}

function OverviewImage(): JSX.Element {
  const clippingBox = useBoxControlsClippingPlanes();

  return (
    <Box
      component="span"
      sx={{
        width: "100%",
        AspectRatio: "1.75",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: neutral[200],
      }}
    >
      <Canvas
        gl={(canvas) => {
          const renderer = new LotvRenderer({
            canvas,
            premultipliedAlpha: false,
          });
          // enabling the 'localClippingEnabled' property
          // since the app is going to use a global bounding box
          // in most of its scenes.
          renderer.localClippingEnabled = true;
          return renderer;
        }}
        onCreated={(state) => (state.scene.background = new Color(neutral[0]))}
      >
        <PerformanceMonitor>
          <OverviewImagePreview
            showBackgroundPlane
            clippingPlanesBox={clippingBox}
          />
        </PerformanceMonitor>
      </Canvas>
    </Box>
  );
}
