import {
  selectIsControlPointLayerAlignment,
  selectWizardElementToAlignId,
  selectWizardPointCloudStreamToAlignId,
  selectWizardReferenceElementId,
  selectWizardReferencePointCloudStreamId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import {
  setWizardPointCloudStreamToAlignId,
  setWizardReferencePointCloudStreamId,
} from "@/store/modes/alignment-wizard-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  neutral,
  selectIElement,
  ViewDiv,
} from "@faro-lotv/app-component-toolbox";
import { Stack } from "@mui/material";
import {
  AlignmentElementLabel,
  LabelType,
} from "../alignment-modes-commons/alignment-element-label";
import { ControlPointsAlignmentDescription } from "../control-points-alignment-mode/control-points-alignment-description";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ReferenceSelectorTool } from "./align-wizard-reference-selector-tool";

const referenceSelectorToolWidthInPixels = "280px";

/**
 * @returns Renderer of split screen for Alignment Wizard
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side views of aligned and reference elements
 * - Facilitates selection of appropriate elements for alignment
 */
export function AlignWizardSplitScreen(): JSX.Element {
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const alignedElement = useAppSelector(selectIElement(elementToAlignId));
  const pointCloudStreamToAlignId = useAppSelector(
    selectWizardPointCloudStreamToAlignId,
  );

  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const referenceElement = useAppSelector(selectIElement(referenceElementId));
  const referencePointCloudStreamId = useAppSelector(
    selectWizardReferencePointCloudStreamId,
  );

  const dispatch = useAppDispatch();

  const isControlPointLayerAlignment = useAppSelector(
    selectIsControlPointLayerAlignment,
  );

  return (
    <Stack
      direction="row"
      justifyItems="stretch"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {alignedElement && (
          <AlignmentElementLabel
            element={alignedElement}
            showMultiScansDropdown
            currentScanId={pointCloudStreamToAlignId}
            labelType={LabelType.alignedElement}
            sx={{
              top: "10px",
              left: "30px",
            }}
            onActiveScanChanged={(id) => {
              dispatch(setWizardPointCloudStreamToAlignId(id));
            }}
          />
        )}
      </ViewDiv>

      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {referenceElement && (
          <AlignmentElementLabel
            element={referenceElement}
            showMultiScansDropdown
            currentScanId={referencePointCloudStreamId}
            labelType={LabelType.referenceElement}
            sx={{
              top: "10px",
              left: "300px",
            }}
            onActiveScanChanged={(id) => {
              dispatch(setWizardReferencePointCloudStreamId(id));
            }}
          />
        )}
        {alignedElement && (
          <ReferenceSelectorTool
            sx={{ width: referenceSelectorToolWidthInPixels }}
          />
        )}

        {isControlPointLayerAlignment && (
          <ControlPointsAlignmentDescription
            sx={{ ml: referenceSelectorToolWidthInPixels }}
          />
        )}
      </ViewDiv>
    </Stack>
  );
}
