import { generateGUID, GUID } from "@faro-lotv/foundation";
import { ILabel } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Tag = Pick<ILabel, "id" | "name">;

/** The custom tag used for the "untagged" value in the filter menu */
export const UNTAGGED: Tag = {
  id: generateGUID(),
  name: "Untagged",
};

export type TagsState = {
  /** The list of tags in the project */
  tags: Tag[];

  /** The list of tags selected by the user */
  selectedTags: Tag[];
};

export const initialState: Readonly<TagsState> = Object.freeze({
  tags: [UNTAGGED],
  selectedTags: [],
});

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    /**
     * Add new tags to the store
     *
     * @param state The current state
     * @param action The list of new tags to add
     */
    addNewTags(state, action: PayloadAction<Tag[]>) {
      for (const tag of action.payload) {
        if (!state.tags.find((t) => t.id === tag.id)) {
          state.tags.push(tag);
        }
      }
    },
    /**
     * Update the name of a tag
     *
     * @param state The current state
     * @param action The id of the tag to update and the new name
     */
    setTagName(state, action: PayloadAction<{ id: string; name: string }>) {
      const { id, name } = action.payload;
      const index = state.tags.findIndex((t) => t.id === id);
      if (index !== -1) {
        state.tags[index].name = name;
      }
    },
    /**
     * Set which tags the user selected
     *
     * @param state The current state
     * @param action The list of tags to set as selected
     */
    setSelectedTags(state, action: PayloadAction<Tag[]>) {
      state.selectedTags = [...action.payload];
    },
    /**
     * Remove tag from the store
     *
     * @param state The current state
     * @param action The tag to remove
     */
    removeTag(state, action: PayloadAction<Tag>) {
      removeFromList(state.tags, action.payload.id);
      removeFromList(state.selectedTags, action.payload.id);
    },
  },
});

export const tagsReducer = tagsSlice.reducer;

export const { addNewTags, setTagName, setSelectedTags, removeTag } =
  tagsSlice.actions;

/**
 * Remove a tag from an array of tags
 *
 * @param list The array of tags
 * @param id The id of the tag to remove
 */
function removeFromList(list: Tag[], id: GUID): void {
  const index = list.findIndex((tag) => tag.id === id);
  if (index >= 0) list.splice(index, 1);
}
