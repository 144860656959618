import { RootState } from "@/store/store";
import {
  clearStore,
  selectIElement,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import {
  CadLevelsDescription,
  GUID,
  IElementModel3dStream,
  isIElementModel3dStream,
  MetaDataFromSvfDescription,
} from "@faro-lotv/ielement-types";
import { GPUvendor, LotvRenderer } from "@faro-lotv/lotv";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebGLRenderer } from "three";
import { UAParser } from "ua-parser-js";
import { selectCanReadCAD } from "../subscriptions/subscriptions-selectors";

/**
 * Shared state of current active CAD in the project
 */
export type CadState = {
  /** The current active CAD */
  activeCad?: GUID;

  /**
   * False if the active CAD has no mesh to display (e.g. failed to convert or has no geometry);
   * True is the active CAD has a mesh to display.
   * Undefined if we do not know yet (i.e. metadata being asynchronously loaded).
   */
  activeCadHasMesh?: boolean;

  /**
   * Error message if the active CAD failed to load; empty string if no error.
   */
  activeCadLoadingError: string;

  /**
   * array of Levels from CAD metadata (using the exported mesh origin as reference)
   */
  cadLevelsInMeshCs?: CadLevelsDescription;

  /**
   * Optional metadata extracted form the CAD (undefined for model converted before these were supported)
   */
  cadSvfMetadata?: MetaDataFromSvfDescription;

  /**
   * Default maximum number of bytes to load in memory for the active CAD model.
   * This value depends solely on the webGL driver and platform, not on user's preferences.
   */
  defaultMaxByteToLoad: number;
};

export const initialState: CadState = {
  activeCad: undefined,
  activeCadHasMesh: undefined,
  activeCadLoadingError: "",
  cadLevelsInMeshCs: undefined,
  cadSvfMetadata: undefined,
  defaultMaxByteToLoad: getDefaultMaximumModelSize(undefined),
};

/**
 * Slice to access global information about the current active CAD in the project
 */
const cadSlice = createSlice({
  name: "cad",
  initialState,
  reducers: {
    /**
     * Change the current active CAD the user is interacting with.
     * Also reset activeCadHasMesh to undefined so this will be computed later.
     *
     * @param state Current state
     * @param action CAD to set
     */
    setActiveCad(state, action: PayloadAction<GUID | undefined>) {
      const cadHasChanged = state.activeCad !== action.payload;
      state.activeCad = action.payload;
      // reset activeCadHasMesh (only if the cad has changed) as it will later be set once we loaded the metadata
      if (cadHasChanged) {
        state.activeCadHasMesh = undefined;
        state.activeCadLoadingError = "";
      }
    },

    /**
     * Set the loading error in case the active CAD could not be loaded.
     *
     * @param state Current state
     * @param action error message to set, or empty string to reset the message (i.e. no message)
     */
    setActiveCadLoadingError(state, action: PayloadAction<string>) {
      state.activeCadLoadingError = action.payload;
    },

    /**
     * Change the value of `activeCadHasMesh`.
     *
     * @param state Current state
     * @param action true once we know the active CAD has a valid mesh; false if we know it has no CAD
     */
    setActiveCadHasMesh(state, action: PayloadAction<boolean>) {
      state.activeCadHasMesh = action.payload;
    },

    /**
     * Set default maximum memory to use for cad loading (in bytes).
     *
     * @param state Current state
     * @param action new value in bytes for defaultMaxByteToLoad
     */
    setDefaultMaxByteToLoad(state, action: PayloadAction<number>) {
      state.defaultMaxByteToLoad = action.payload;
    },

    /**
     * Set information about CAD levels if available in metadata.
     *
     * @param state Current state
     * @param action information about CAD levels if available in metadata, or undefined if not available
     */
    setCadLevelsInMeshCs(
      state,
      action: PayloadAction<CadLevelsDescription | undefined>,
    ) {
      state.cadLevelsInMeshCs = action.payload;
    },

    /**
     * Set information about CAD coordinates systems if available in metadata.
     *
     * @param state Current state
     * @param action information about CAD levels if available in metadata, or undefined if not available
     */
    setCadSvfMetadata(
      state,
      action: PayloadAction<MetaDataFromSvfDescription | undefined>,
    ) {
      state.cadSvfMetadata = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const cadReducer = cadSlice.reducer;

export const {
  setActiveCad,
  setActiveCadLoadingError,
  setActiveCadHasMesh,
  setCadLevelsInMeshCs,
  setCadSvfMetadata,
  setDefaultMaxByteToLoad,
} = cadSlice.actions;

/**
 *
 * @param state Current state
 * @returns The GUID of current active CAD model if exists
 */
export function selectActiveCadId(state: RootState): GUID | undefined {
  // If mesh support is disabled return undefined at all CAD model queries
  // this remove the CAD model from the entire app with a change only here
  if (!selectCanReadCAD(state)) {
    return;
  }

  return state.cad.activeCad;
}

/**
 *
 * @param state Current state
 * @returns true if the active CAD has a mesh, false if no mesh, undefined while metadata is being loading
 */
export function selectActiveCadHasMesh(state: RootState): boolean | undefined {
  return state.cad.activeCadHasMesh;
}

/**
 *
 * @param state Current state
 * @returns information about CAD levels (name, height, elevation in mesh CS = ignoring CAD's POSE)
 * To get the levels in Sphere world CS instead, call selectCadLevelsInWorldCs.
 */
export function selectCadLevelsInMeshCs(
  state: RootState,
): CadLevelsDescription | undefined {
  return state.cad.cadLevelsInMeshCs;
}

/**
 *
 * @param state Current state
 * @returns information about CAD levels (name, height, elevation in mesh CS)
 */
export const selectCadLevelsInWorldCs = createSelector(
  [
    (state: RootState) => {
      // get the active CAD's offset along vertical axis
      const activeCad = selectActiveCadModel(state);
      if (!activeCad) return;
      const { position } = selectIElementWorldTransform(activeCad.id)(state);
      return position[1];
    },
    (state: RootState) => selectCadLevelsInMeshCs(state),
  ],
  (
    activeCadVerticalOffset: number | undefined,
    levelsInMeshCs: CadLevelsDescription | undefined,
  ): CadLevelsDescription | undefined => {
    // 0 is a valid number for activeCadVerticalOffset so we need to explicitly check for undefined
    if (activeCadVerticalOffset === undefined) return;

    // apply the vertical offset to the levels
    const levelsInWorldCs = levelsInMeshCs?.map((level) => ({
      ...level,
      Elevation: level.Elevation + activeCadVerticalOffset,
    }));

    return levelsInWorldCs;
  },
);

/**
 *
 * @param state Current state
 * @returns information about CAD metadata
 */
export function selectCadSvfMetadata(
  state: RootState,
): MetaDataFromSvfDescription | undefined {
  return state.cad.cadSvfMetadata;
}

/**
 *
 * @param state Current state
 * @returns The current active CAD model if exists
 */
export function selectActiveCadModel(
  state: RootState,
): IElementModel3dStream | undefined {
  // If mesh support is disabled return undefined at all CAD model queries
  // this remove the CAD model from the entire app with a change only here
  if (!selectCanReadCAD(state)) {
    return;
  }

  if (state.cad.activeCad) {
    const cadElement = selectIElement(state.cad.activeCad)(state);
    if (cadElement && isIElementModel3dStream(cadElement)) return cadElement;
  }
}

/**
 *
 * @param state Current state
 * @returns error message in case the active CAD has failed to load, empty string otherwise
 */
export function selectActiveCadLoadingError(state: RootState): string {
  return state.cad.activeCadLoadingError;
}

/**
 * Test whether it is possible to align to the CAD, and return user error message if not.
 *
 * @param state Current state
 * @returns if alignment is not possible, return a string that could be reported to the user to explain why alignment is not possible;
 * undefined if alignment is possible
 */
export function selectCadAlignmentErrorMessage(
  state: RootState,
): string | undefined {
  if (!selectActiveCadId(state)) {
    return "At least one 3D Model in the project required for alignment";
  }
  const activeCadHasMesh = selectActiveCadHasMesh(state);
  if (activeCadHasMesh === undefined) {
    return "Model is still loading";
  } else if (!activeCadHasMesh) {
    return "Model has no geometry to display";
  }
}

/**
 * @returns the default maximum number of byte to load in memory for 3D model (to be used when user did not overwritten it)
 * DESIGN NOTES
 * This value depends solely on the webGL driver and platform, not on user's preferences.
 */
export function selectDefaultCadMaxByteToLoad() {
  return (state: RootState) => state.cad.defaultMaxByteToLoad;
}

/**
 * @returns The string identifying the OS (see definition of UAParser's IOS.name)
 */
function getOs(): string | undefined {
  const parser = new UAParser(navigator.userAgent);
  const osName = parser.getOS().name;
  return osName;
}

/**
 * Evaluate the best maximum size of the 3D Model to be loaded in memory for one specific machine/platform
 *
 * @param renderer LotvRenderer used to render the model
 * @returns The suggested maximum size in bytes of the model to be loaded for one specific machine/platform
 */
export function getDefaultMaximumModelSize(
  renderer: WebGLRenderer | LotvRenderer | undefined,
): number {
  // memory allocated for desktop machines = 2 GB
  const memoryForDesktopDevices = 2 * 1024 * 1024 * 1024;
  // memory allocated for other machines = 512 MB
  const memoryForOtherDevices = 512 * 1024 * 1024;
  // default = 512 MB
  let returnedValue = memoryForOtherDevices;
  if (renderer instanceof LotvRenderer) {
    const osName = getOs();
    const { gpuVendor } = renderer;
    switch (gpuVendor) {
      case GPUvendor.NVIDIA:
      case GPUvendor.Intel:
        // Desktop machine with Nvidia or integrated Intel GPU = 2GB
        returnedValue = memoryForDesktopDevices;
        break;

      case GPUvendor.Apple:
        if (osName === "Mac OS") {
          // Desktop iMac, mini Mac, MacBook, etc... = 2 GB
          returnedValue = memoryForDesktopDevices;
        } else {
          // iPod, iPhone, or other iOS device = 512 MB
          returnedValue = memoryForOtherDevices;
        }
        break;

      case GPUvendor.Qualcomm:
        // Android smartphone = 512 MB
        returnedValue = memoryForOtherDevices;
        break;
    }
  }
  return returnedValue;
}
