import { ProjectOverviewBase } from "@/components/ui/project-overview";
import { Mode, SceneFilterLookAtInitialState } from "@/modes/mode";
import {
  selectCanReadCAD,
  selectCanReadPointCloud,
} from "@/store/subscriptions/subscriptions-selectors";
import { isIElementValidPointCloudStream } from "@/types/type-guards";
import { selectChildDepthFirst } from "@faro-lotv/app-component-toolbox";
import { isIElementModel3dStream } from "@faro-lotv/ielement-types";
import { OverviewOverlay } from "./overview-overlay";
import { OverviewScene } from "./overview-scene";
import { OVERVIEW_MODE_INITIAL_STATE } from "./overview-state";
import { OverviewTransition } from "./overview-transition";

export const overviewMode: Mode<SceneFilterLookAtInitialState> = {
  name: "overview",
  initialState: OVERVIEW_MODE_INITIAL_STATE,
  ModeScene: OverviewScene,
  ModeTransition: OverviewTransition,
  ModeOverlay: OverviewOverlay,
  ModePanel: ProjectOverviewBase,
  canBeStartedWith(activeElement, state) {
    const canReadCAD = selectCanReadCAD(state);
    const hasPcSupport = selectCanReadPointCloud(state);

    if (
      canReadCAD &&
      !!selectChildDepthFirst(activeElement, isIElementModel3dStream)(state)
    ) {
      return true;
    }

    return (
      hasPcSupport &&
      !!selectChildDepthFirst(
        activeElement,
        isIElementValidPointCloudStream,
      )(state)
    );
  },
};
