import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import {
  addBackgroundTask,
  removeBackgroundTask,
  updateBackgroundTask,
} from "@/store/background-tasks/background-tasks-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { BackgroundTask, FileUploadTask } from "@/utils/background-tasks";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { UploadElementType } from "../point-cloud-file-upload-context/use-upload-element";
import {
  UpdateTaskProps,
  UploadManager,
  UploadManagerInterface,
} from "./upload-manager";

/**
 * The File Upload Context provides an object that store all ongoing
 * file uploads and allows to manage them.
 *
 * WARNING: this type should not be used directly, it is only needed to implement
 * the FileUploadContextProvider and the hooks useFileUploader and useCancelUpload.
 */
type FileUploadContext = {
  uploadManager: UploadManagerInterface;
};

/** Context which provides the file upload functionalities */
export const FileUploadContext = createContext<FileUploadContext | undefined>(
  undefined,
);

/**
 * @returns a context useful to start, store, cancel, monitor and complete file upload tasks.
 *
 * WARNING: this type should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUploader and useCancelUpload.
 */
export function useFileUploadContext(): FileUploadContext {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error("FileUploadContext is not initialized.");
  }
  return context;
}

/**
 * @returns a provider component for the FileUploadContext
 * All children of this component may start, monitor, and finish file uploads
 * and the uploads are stored in the context, therefore they outlive any particular
 * mode or component.
 */
export function FileUploadContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const startTaskInStore = useCallback(
    (
      id: GUID,
      projectId: string,
      file: File,
      uploadElementType: UploadElementType,
      silent: boolean,
      floorId?: GUID,
      elementName?: string,
    ) => {
      const isoDate = new Date().toISOString();

      const backgroundTask: FileUploadTask = {
        id,
        createdAt: isoDate,
        changedAt: isoDate,
        type: "FileUpload",
        devMessage: `Uploading file ${file.name} to project ${projectId}`,
        state: BackgroundTaskState.created,
        progress: 0,
        shouldPreventWindowClose: true,
        iElementId: floorId ?? null,
        canBeCanceled: true,
        metadata: {
          filename: file.name,
          uploadElementType,
          elementName,
        },
        silent,
      };
      dispatch(addBackgroundTask(backgroundTask));
    },
    [dispatch],
  );

  const updateTaskInStore = useCallback(
    (id: GUID, propsToUpdate: UpdateTaskProps) => {
      const task = selectBackgroundTask(id)(store.getState());
      if (task === undefined) return;
      const updatedTask: BackgroundTask = {
        ...task,
        ...propsToUpdate,
        changedAt: new Date().toISOString(),
      };
      dispatch(updateBackgroundTask(updatedTask));
    },
    [dispatch, store],
  );

  const removeTaskFromStore = useCallback(
    (id: GUID) => {
      dispatch(removeBackgroundTask(id));
    },
    [dispatch],
  );

  const uploadManager = useMemo(
    () =>
      new UploadManager(
        startTaskInStore,
        updateTaskInStore,
        removeTaskFromStore,
      ),
    [startTaskInStore, updateTaskInStore, removeTaskFromStore],
  );

  const value = useMemo<FileUploadContext>(
    () => ({
      uploadManager,
    }),
    [uploadManager],
  );

  return (
    <FileUploadContext.Provider value={value}>
      {children}
    </FileUploadContext.Provider>
  );
}
