import {
  ChangeRegistrationCameraMode,
  ChangeRegistrationCameraPerspectiveProperties,
  EventType,
} from "@/analytics/analytics-events";
import { ClickThroughStack } from "@/components/ui/click-through-stack";
import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { useAppDispatch } from "@/store/store-hooks";
import { ToolName } from "@/store/ui/ui-slice";
import { Analytics } from "@faro-lotv/foreign-observers";
import { MaybeLoading } from "@faro-lotv/foundation";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { useCallback } from "react";
import { useDataPreparationOverlayElements } from "../data-preparation-overlay-context";
import { clearUserEdits } from "../store/data-preparation-actions";
import { disableEditMode } from "../store/data-preparation-ui/data-preparation-ui-slice";
import { CameraSwitch } from "./camera-switch";
import { DataPreparationActions } from "./data-preparation-actions";
import { DataPreparationHelpBanner } from "./data-preparation-help-banner";
import { EditModeToolbar } from "./edit-mode-toolbar";
import { PerspectiveSwitch } from "./perspective-switch";
import { Projection, ProjectionSwitch } from "./projection-switch";
import { RevisionContextualToolbar } from "./revision-contextual-toolbar";
import { DataPreparationViewSettingsToolbar } from "./view-settings-toolbar/data-preparation-view-settings-toolbar";

type HorizontalOverlayProps = {
  revision: RegistrationRevision;
  registrationReport: MaybeLoading<MultiRegistrationReport>;
  isEditRegistrationEnabled: boolean;
  onToggleReport(data: boolean): void;
};

/**
 * @returns Horizontal canvas overlay.
 */
export function HorizontalCanvasOverlay({
  revision,
  registrationReport,
  isEditRegistrationEnabled,
  onToggleReport,
}: HorizontalOverlayProps): JSX.Element {
  const dispatch = useAppDispatch();
  return (
    <ClickThroughStack
      justifyContent="space-between"
      alignItems="center"
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        width: "100%",
        height: "100%",
        p: 2,
      }}
    >
      <ClickThroughStack
        direction="row"
        gap={1}
        justifyContent="space-between"
        alignItems="start"
        sx={{
          width: "100%",
        }}
      >
        {/* Placeholder for layout */}
        <Stack component="div" sx={{ flexGrow: 1 }} />

        <DataPreparationHelpBanner />

        <DataPreparationActions
          revision={revision}
          registrationReport={registrationReport}
          onToggleReport={onToggleReport}
          isEditRegistrationEnabled={isEditRegistrationEnabled}
          onCancelEditRegistration={() => {
            Analytics.track(EventType.cancelEditing);
            dispatch(disableEditMode());
            dispatch(clearUserEdits());
          }}
        />
      </ClickThroughStack>

      <RevisionContextualToolbar />
    </ClickThroughStack>
  );
}

/**
 * @returns Vertical canvas overlay.
 */
export function VerticalCanvasOverlay(): JSX.Element {
  const {
    projection,
    setProjection,
    perspective,
    setPerspective,
    cameraOptions3D,
    setCameraOptions3D,
    centerCameraEvent,
    activeTool,
    setActiveTool,
  } = useDataPreparationOverlayElements();

  const toggleTool = useCallback(
    (tool: ToolName) => {
      if (activeTool === tool) {
        setActiveTool(undefined);
      } else {
        setActiveTool(tool);
      }
    },
    [activeTool, setActiveTool],
  );

  return (
    <ClickThroughStack
      direction="row"
      justifyContent="space-between"
      sx={{ p: 2, width: "100%", height: "100%" }}
    >
      <ClickThroughStack
        justifyContent="end"
        alignItems="start"
        height="100%"
        gap={1}
      >
        {projection === Projection.twoDimensional && (
          <PerspectiveSwitch
            perspective={perspective}
            onPerspectiveChange={(value) => {
              Analytics.track<ChangeRegistrationCameraPerspectiveProperties>(
                EventType.changeRegistrationCameraPerspective,
                {
                  perspective: value,
                },
              );
              setPerspective(value);
              centerCameraEvent.emit(value);
            }}
          />
        )}

        {projection === Projection.threeDimensional && (
          <CameraSwitch
            cameraOptions3D={cameraOptions3D}
            onCameraChange={(value) => {
              setCameraOptions3D(value);
            }}
          />
        )}

        <ProjectionSwitch
          projection={projection}
          onProjectionChange={(newProjection) => {
            Analytics.track<ChangeRegistrationCameraMode>(
              EventType.changeRegistrationCameraMode,
              {
                variant: newProjection,
              },
            );

            setProjection(newProjection);
          }}
        />
      </ClickThroughStack>
      <ClickThroughStack justifyContent="end" alignItems="start" py={20}>
        <DataPreparationViewSettingsToolbar
          onRecenterView={() => centerCameraEvent.emit(perspective)}
          activeTool={activeTool}
          toggleTool={toggleTool}
        />
        <ClickThroughStack
          direction="column"
          flexGrow={1}
          justifyContent="center"
        >
          <EditModeToolbar />
        </ClickThroughStack>
      </ClickThroughStack>
    </ClickThroughStack>
  );
}
