import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { runtimeConfig } from "@/runtime-config";
import { AppDispatch, AppStore } from "@/store/store";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { appId } from "@/utils/appid";
import { GUID } from "@faro-lotv/foundation";
import { ProjectApi, useCoreApiClient } from "@faro-lotv/service-wires";
import { useCallback, useRef } from "react";
import { useFileUploader } from "../file-upload-context/use-file-uploader";
import { useCurrentProjectApiClient } from "../project-provider/project-loading-context";
import { updateProjectWithCad } from "./cad-upload-utils";
import { updateAreaWithImgSheet } from "./image-sheet-utils";
import { updateProjectWithPointCloud } from "./point-cloud-upload-utils";

type UploadElementCallbackArgs = {
  /** The main file to upload */
  file: File;

  /** Additional files to upload. These uploads have to complete before the element is created. */
  additionalFiles?: File[];

  /** Name to use for the element */
  name: string;

  /** ID of the area to attach the element to */
  areaId?: GUID;

  /** Date to use for the creation date */
  createdAt: Date;

  /** Truthy for geo-referenced cloud; falsy otherwise */
  isGeoReferenced?: boolean;

  /** Function called after the file is uploaded */
  onFileUploaded?(): void;
};

export enum UploadElementType {
  /** To be used when the file upload shouldn't trigger an update of the project */
  none = "None",
  cad = "Cad",
  pointcloud = "Pointcloud",
  areaImage = "AreaImage",
}

/**
 * Callback returned by the useUploadElement hook. Given a file name
 * it will upload the element to the backend and it will add it to the project.
 */
type UploadElementCallback = (args: UploadElementCallbackArgs) => void;

/**
 * @param uploadElementType Type of the element to upload
 * @returns A callback to upload a file to the backend and add it to the project
 */
export function useUploadElement(
  uploadElementType: UploadElementType,
): UploadElementCallback {
  const uploadFile = useFileUploader();
  const appStore = useAppStore();
  const dispatch = useAppDispatch();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithDialog } = useErrorHandlers();

  const coreApi = useCoreApiClient(
    runtimeConfig.backendEndpoints.coreApiUrl,
    appId(),
  );

  // A promise capturing the current project update
  // as only one update can happen at a time as the first update
  // will have the side effect of creating the parent nodes
  // required by the follow up updates
  const updateProjectPromise = useRef<Promise<void>>();

  return useCallback<UploadElementCallback>(
    ({
      file,
      additionalFiles = [],
      name,
      areaId,
      createdAt,
      isGeoReferenced,
      onFileUploaded,
    }) => {
      const additionalUploadsPromise = Promise.all(
        additionalFiles.map(
          (file) =>
            new Promise<string>((resolve, reject) => {
              uploadFile({
                file,
                uploadElementType,
                projectId: projectApi.projectId,
                coreApiClient: coreApi,
                areaId,
                onUploadCompleted: (_, downloadUrl) => resolve(downloadUrl),
                onUploadFailed: reject,
                silent: true,
                elementName:
                  uploadElementType === UploadElementType.areaImage
                    ? name
                    : undefined,
              });
            }),
        ),
      );

      // eslint-disable-next-line func-style -- FIXME
      const finalizeDownload = async (
        downloadUrl: string,
        md5Hash: string,
      ): Promise<void> => {
        const additionalUrls = await additionalUploadsPromise;

        // Allow only one project update at a time
        // as the first project update on an area will create the parent elements
        // required by the other updates
        if (updateProjectPromise.current) {
          await updateProjectPromise.current;
        }

        // eslint-disable-next-line require-atomic-updates -- FIXME
        updateProjectPromise.current = updateProject(uploadElementType, {
          appStore,
          dispatch,
          projectApi,
          file,
          name,
          createdAt,
          areaId,
          downloadUrl,
          additionalUrls,
          md5Hash,
          isGeoReferenced,
        }).catch((error) => {
          handleErrorWithDialog({
            title: `Upload ${uploadElementType}`,
            error,
          });
        });

        return updateProjectPromise.current;
      };

      uploadFile({
        file,
        uploadElementType,
        projectId: projectApi.projectId,
        coreApiClient: coreApi,
        finalizer: finalizeDownload,
        areaId,
        elementName:
          uploadElementType === UploadElementType.areaImage ? name : undefined,
        onUploadCompleted: onFileUploaded,
      });
    },
    [
      uploadFile,
      projectApi,
      coreApi,
      uploadElementType,
      appStore,
      dispatch,
      handleErrorWithDialog,
    ],
  );
}

export type UpdateProjectParam = {
  /** Current app store */
  appStore: AppStore;
  /** Function used to update the store */
  dispatch: AppDispatch;
  /** Client of the ProjectAPI used to update the project */
  projectApi: ProjectApi;
  /** File to upload */
  file: File;
  /** Name of the element that will be created */
  name: string;
  /** Date of creation of the element */
  createdAt: Date;
  /** Id of the area under which the element will be created */
  areaId?: GUID;
  /** Url to the new file */
  downloadUrl: string;
  /** Urls to additionally uploaded files */
  additionalUrls: string[];
  /** Unique string used to differentiate the created elements */
  md5Hash: string;
  /** Truthy for for geo-referenced cloud object; falsy otherwise */
  isGeoReferenced?: boolean;
};

function updateProject(
  uploadElementType: UploadElementType,
  updateProjectParam: UpdateProjectParam,
): Promise<void> {
  switch (uploadElementType) {
    case UploadElementType.none:
      return Promise.resolve();
    case UploadElementType.cad:
      return updateProjectWithCad(updateProjectParam);
    case UploadElementType.pointcloud:
      return updateProjectWithPointCloud(updateProjectParam);
    case UploadElementType.areaImage:
      return updateAreaWithImgSheet(updateProjectParam);
  }
}
