import { useClippingPlanes } from "@/hooks/use-clipping-planes";
import { useDetectTooManyRedraws } from "@/hooks/use-detect-too-many-redraws";
import { useCached3DObjectsIfReady } from "@/object-cache";
import { computeCombinedPointCloudBoundingBox } from "@/registration-tools/utils/camera-views";
import { useAppDispatch } from "@/store/store-hooks";
import { Canvas, CanvasOverlay } from "@faro-lotv/app-component-toolbox";
import { CircularProgress } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { Box } from "@mui/material";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Box3 } from "three";
import { useDataPreparationOverlayElements } from "../data-preparation-overlay-context";
import { addEntityTransformOverride } from "../store/revision-slice";
import { Projection } from "../ui/projection-switch";
import {
  RegistrationConnections,
  TOOLTIP_Z_INDEX,
} from "./registration-connections";
import { RevisionRenderingPipeline } from "./revision-rendering-pipeline";
import { RevisionScansControls } from "./revision-scans-controls";
import { RevisionScansRenderer } from "./revision-scans-renderer";
import { RevisionScansTools } from "./revision-scans-tools";

type RevisionScansProps = {
  /** The scans to render in the scene. */
  scanEntities: RevisionScanEntity[];

  /** The point cloud streams for the scan entities. undefined if they are still loading. */
  pointCloudStreams?: IElementGenericPointCloudStream[];

  /** The point clouds to highlight in a hover interaction */
  hoveredPointCloudIds?: GUID[];

  /** The point clouds to highlight in a selection interaction */
  selectedPointCloudIds?: GUID[];

  /** A UI overlay to display on top of the canvas. */
  overlay?: React.ReactNode;

  /** Whether the user can move and rotate the scans. */
  isEditingScans: boolean;

  /** Callback when the projection changes */
  onProjectionChanged?(projection: Projection): void;
};

/** @returns A 3D scene displaying the scans included in the revision. */
export function RevisionScansScene({
  scanEntities,
  pointCloudStreams,
  hoveredPointCloudIds,
  selectedPointCloudIds,
  overlay,
  isEditingScans,
  onProjectionChanged,
}: RevisionScansProps): JSX.Element | null {
  useDetectTooManyRedraws("RevisionScansScene");

  const canvas = useRef<HTMLCanvasElement>(null);
  const dispatch = useAppDispatch();

  const [portal, setPortal] = useState<MutableRefObject<HTMLElement>>();
  const pointCloudObjects = useCached3DObjectsIfReady(pointCloudStreams);

  const {
    projection,
    perspective,
    cameraOptions3D,
    centerCameraEvent,
    activeTool,
  } = useDataPreparationOverlayElements();

  const [combinedPcBoundingBox, setCombinedPcBoundingBox] = useState<Box3>();

  const { clippingPlanes, setClippingPlanes } = useClippingPlanes();

  useEffect(() => {
    onProjectionChanged?.(projection);
  }, [onProjectionChanged, projection]);

  // Display a loading spinner if the point cloud streams are still loading
  if (
    !pointCloudStreams ||
    pointCloudObjects.length !== pointCloudStreams.length
  ) {
    return (
      <Box
        component="div"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ display: "flex", height: "100%" }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box
      ref={setPortal}
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
        "& div": {
          zIndex: 0,
        },
      }}
    >
      <Canvas style={{ position: "absolute" }} orthographic ref={canvas}>
        <RevisionScansRenderer
          scanEntities={scanEntities}
          pointCloudObjects={pointCloudObjects}
          hoveredPointCloudIds={hoveredPointCloudIds}
          selectedPointCloudIds={selectedPointCloudIds}
          onInitialPositioning={() => {
            centerCameraEvent.emit(perspective);

            // The world space bounding box can only be computed after the point clouds have been positioned
            setCombinedPcBoundingBox(
              computeCombinedPointCloudBoundingBox(
                pointCloudObjects,
                new Box3(),
              ),
            );
          }}
          clippingPlanes={clippingPlanes}
        />
        <RevisionScansControls
          centerCameraEvent={centerCameraEvent}
          pointCloudObjects={pointCloudObjects}
          projection={projection}
          cameraOptions3D={cameraOptions3D}
          isEditingScans={isEditingScans}
          onManualOverrideAdded={(id, localTransform) => {
            dispatch(addEntityTransformOverride({ id, localTransform }));
          }}
        />

        <RegistrationConnections scanEntities={scanEntities} portal={portal} />

        <RevisionScansTools
          activeTool={activeTool}
          combinedPcBoundingBox={combinedPcBoundingBox}
          onClippingPlanesChanged={setClippingPlanes}
        />

        <RevisionRenderingPipeline pointCloudObjects={pointCloudObjects} />
      </Canvas>

      <CanvasOverlay
        canvas={canvas}
        sx={{
          // Fix 3d HTML elements overlapping other UI
          "& div": {
            zIndex: TOOLTIP_Z_INDEX,
          },
        }}
      >
        {overlay}
      </CanvasOverlay>
    </Box>
  );
}
