import { EventType } from "@/analytics/analytics-events";
import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { useCurrentArea } from "@/modes/mode-data-context";
import { setActiveMeasurement } from "@/store/measurement-tool-slice";
import { selectModeName } from "@/store/mode-selectors";
import { changeMode } from "@/store/mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  selectCurrentUser,
  selectHasWritePermission,
} from "@/store/user-selectors";
import {
  AnnotationIcon,
  ChangeIndicatorBadge,
  ClipboxExportIcon,
  ExportIcon,
  FaroButton,
  FontWeights,
  HeatmapIcon,
  OpacitySettingsIcon,
  PointCloudAnalysisIcon,
  RulerIcon,
  SheetSaturationToggleIcon,
  cyan,
  neutral,
  orange,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import { Badge, Link, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useIgnoreMapOpacity } from "../common/transparency-sliders/transparency-sliders";
import { BetaBadge } from "./beta-badge";
import { ClipSceneIcon, ClipSceneSubmenu } from "./clip-scene-tool-ui";
import { useNewFeaturePopup } from "./new-feature/new-feature-context";
import { ToolGroup, ToolStoreField, Toolbar } from "./toolbar";
import {
  disableScaleAreaMessage,
  useDisableCaptureTreeAlignment,
} from "./tree/cad-model-tree/use-disable-capture-tree-alignment";

/**
 * Custom component to show if the area tool is disabled and the user is not logged in.
 *
 * @returns a text with a link to start the login process for the user.
 */
function LogInForAreaToolTooltip(): JSX.Element {
  const { requestLogin } = useAuthContext();

  return (
    <Typography>
      Please{" "}
      <Link
        onClick={requestLogin}
        sx={{ color: "blue350", ":hover": { cursor: "default" } }}
      >
        log in
      </Link>{" "}
      to use the area tool
    </Typography>
  );
}

/**
 * @returns A toolbar providing buttons to activate tools to use within the canvas
 *  Visibility of the tools is controlled through the ui store slice
 */
export function CanvasToolbar(): JSX.Element | null {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUser);
  const currentMode = useAppSelector(selectModeName);
  const [analysisButtonRef, setAnalysisButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const activeTool = useAppSelector(selectActiveTool);

  const startCreateAreaMode = useCallback(() => {
    Analytics.track(EventType.startCreateAreaTool);

    dispatch(changeMode("clippingbox"));
  }, [dispatch]);

  const onMeasurementToolClick = useCallback(() => {
    Analytics.track(
      activeTool === ToolName.measurement
        ? EventType.disableMeasurementMode
        : EventType.enableMeasurementMode,
    );
    if (activeTool === ToolName.measurement) {
      dispatch(setActiveMeasurement(undefined));
    }
  }, [dispatch, activeTool]);

  const desaturation =
    useAppSelector(selectActiveTool) === ToolName.desaturateSheet;
  const onDesaturationToolClick = useCallback(() => {
    Analytics.track(
      desaturation
        ? EventType.turnDesaturationOff
        : EventType.turnDesaturationOn,
    );
  }, [desaturation]);

  const isClipSceneActive =
    useAppSelector(selectActiveTool) === ToolName.clipScene;
  const onClipSceneToolClick = useCallback(() => {
    Analytics.track(
      isClipSceneActive ? EventType.hideClippingBox : EventType.showClippingBox,
    );
  }, [isClipSceneActive]);

  const { area } = useCurrentArea();
  const disableScaling = useDisableCaptureTreeAlignment(area);

  const analysisIsNewFeature = useNewFeaturePopup({
    anchorEl: analysisButtonRef,
    localStorage: "betaFeatureColormapAnalysis",
    isVisible: true,
    placement: "left",
    title: "New Point Cloud Analysis!",
    chipLabel: "beta",
    description: (
      <>
        Enhance your data with vibrant color mapping. Try this new feature and
        share your feedback.{" "}
        <Link
          href="https://help.holobuilder.com/en/articles/10260209-run-a-point-cloud-analysis-beta"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: cyan[400] }}
        >
          Click here
        </Link>{" "}
        to learn more.
      </>
    ),
  });

  const tools: ToolGroup[] = [
    {
      key: "tools",
      tools: [
        {
          name: ToolName.measurement,
          icon: <RulerIcon />,
          tooltip: {
            default: "Measure",
            isInteractive: currentMode === "sheet",
            waiting: "Loading additional data for measurement.",
            disabled:
              currentMode === "sheet" ? (
                <>
                  Measurements are possible only on floor images with a scale.
                  {disableScaling ? (
                    <>
                      <br />
                      {disableScaleAreaMessage}
                    </>
                  ) : (
                    <>
                      Define the scale first.
                      <FaroButton
                        variant="ghost"
                        size="s"
                        dark
                        onClick={() => dispatch(changeMode("floorscale"))}
                        sx={{ color: cyan[500] }}
                      >
                        Set scale
                      </FaroButton>
                    </>
                  )}
                </>
              ) : (
                "Measurements are possible on 360° photos containing depth information or on the point cloud"
              ),
          },
          onClick: onMeasurementToolClick,
        },
        {
          name: ToolName.analysis,
          icon: (
            <Badge
              badgeContent={<BetaBadge />}
              max={10000}
              sx={{
                ["& .MuiBadge-badge"]: {
                  border: `1px solid ${neutral[950]}`,
                  height: "16px",
                  width: "34px",
                  right: "-3px",
                  backgroundColor: orange[400],
                  color: neutral[950],
                  fontWeight: FontWeights.SemiBold,
                  borderRadius: "8px",
                },
              }}
            >
              <PointCloudAnalysisIcon />
            </Badge>
          ),
          setRefButton: (el) => setAnalysisButtonRef(el),
          onClick: () => {
            Analytics.track(
              activeTool === ToolName.analysis
                ? EventType.closeColoredAnalysisTool
                : EventType.openColoredAnalysisTool,
            );
          },
          tooltip: analysisIsNewFeature
            ? undefined
            : {
                default: (
                  <Stack direction="column">
                    <Stack direction="row" marginBottom={2}>
                      Point Cloud Analysis!&nbsp;&nbsp;
                      <BetaBadge dark />
                    </Stack>
                    Enhance your data with vibrant color mapping. Try this new
                    feature and share your feedback.
                  </Stack>
                ),
                disabled:
                  "A point cloud must be displayed to perform an analysis",
              },
        },
        {
          name: ToolName.annotation,
          icon: <AnnotationIcon />,
          tooltip: {
            default: "Create annotation",
            isInteractive: currentMode === "sheet",
            disabled: <TooltipForDisabledAnnotation />,
          },
        },
        {
          name: ToolName.clipping,
          icon: <ClipboxExportIcon />,
          tooltip: {
            default: "Area tool",
            isInteractive: !currentUser,
            disabled: <ToolTipForDisabledAreaTool toolName="Area" />,
          },
          onClick: startCreateAreaMode,
        },
        {
          name: ToolName.export,
          icon: <ExportIcon />,
          tooltip: {
            default: "Export",
            isInteractive: !currentUser,
            disabled: <ToolTipForDisabledAreaTool toolName="Export" />,
          },
          onClick: () => Analytics.track(EventType.startExportTool),
        },
        {
          name: ToolName.clipScene,
          icon: <ClipSceneIcon />,
          tooltip: {
            default: "Clip scene",
            disabled:
              "Clip scene tool is not available when 360° Photo is selected",
          },
          subMenu: (
            <ClipSceneSubmenu
              onReset={() => {
                Analytics.track(EventType.resetClippingBox);
              }}
              onAutoClipped={() => {
                Analytics.track(EventType.applyAutoClippingBox);
              }}
            />
          ),
          onClick: onClipSceneToolClick,
        },
        {
          name: ToolName.desaturateSheet,
          icon: <SheetSaturationToggleIcon />,
          tooltip: {
            default: "Toggle desaturation of sheet",
            disabled:
              "Toggling the desaturation state of the sheet is not available at the moment.",
          },
          onClick: onDesaturationToolClick,
        },
      ],
    },
    {
      key: "settings",
      storeField: ToolStoreField.activeSettingsMenu,
      tools: [
        {
          name: ToolName.opacity,
          icon: <OpacityIcon />,
          tooltip: {
            default: "Opacity",
          },
        },
        {
          name: ToolName.heatmap,
          icon: <HeatmapIcon />,
          tooltip: {
            default: "Heatmap comparison",
          },
        },
      ],
    },
  ];
  return <Toolbar toolGroups={tools} allowOverflow />;
}

/**
 * @returns Icon for the opacity toolbar that is showing a badge dot while any opacity is applied to the models
 */
function OpacityIcon(): JSX.Element {
  const { areDefaultOpacities, objectsOpacity } =
    useTransparencySettingsContext((s) => s);

  const ignoreMapOpacity = useIgnoreMapOpacity();

  return (
    <ChangeIndicatorBadge
      invisible={areDefaultOpacities(objectsOpacity, ignoreMapOpacity)}
    >
      <OpacitySettingsIcon />
    </ChangeIndicatorBadge>
  );
}

type ToolTipForDisabledAreaToolProps = {
  toolName: string;
};

/** @returns the an appropriate disabled message tooltip for Clipping box tool */
export function ToolTipForDisabledAreaTool({
  toolName,
}: ToolTipForDisabledAreaToolProps): JSX.Element {
  const currentUser = useAppSelector(selectCurrentUser);

  const hasWritePermission = useAppSelector(selectHasWritePermission);
  if (currentUser) {
    if (!hasWritePermission) {
      return <>{toolName} is only accessible with edit permissions</>;
    }
    return (
      <>
        {toolName} tool is not available. You can only use it with point cloud
        data.
      </>
    );
  }
  return <LogInForAreaToolTooltip />;
}

/** @returns the an appropriate disabled message tooltip for Annotation tool */
function TooltipForDisabledAnnotation(): JSX.Element {
  const dispatch = useAppDispatch();

  const currentMode = useAppSelector(selectModeName);

  const { area } = useCurrentArea();
  const disableScaling = useDisableCaptureTreeAlignment(area);

  const hasWritePermission = useAppSelector(selectHasWritePermission);
  if (!hasWritePermission) {
    return <>Annotation creation is only available for Project Editors</>;
  }

  if (disableScaling) {
    // Will be removed when https://faro01.atlassian.net/browse/SWEB-5087 is resolved
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{disableScaleAreaMessage}</>;
  }

  if (currentMode === "sheet") {
    return (
      <>
        Annotations are possible only on floor images with a scale. Define the
        scale first.
        <FaroButton
          variant="ghost"
          size="s"
          dark
          onClick={() => dispatch(changeMode("floorscale"))}
        >
          Set scale
        </FaroButton>
      </>
    );
  }
  return <>Annotation creation will be available soon!</>;
}
