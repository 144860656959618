import { useOpenAccountAndSecurity } from "@/hooks/use-open-account-and-security";
import { runtimeConfig } from "@/runtime-config";
import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { APP_VERSION } from "@/utils/env-constants";
import { ToolHeaderBar, ToolHeaderBarProps } from "@faro-lotv/flat-ui";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import { selectDashboardUrl } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { LanguageSelector } from "./language-selector";
import { ProgressOverviewMenu } from "./progress-overview/progress-overview-menu";
import { ViewerHelpCenterMenu } from "./viewer-help-center-menu";

type SphereViewerToolHeaderBarProps = Pick<
  ToolHeaderBarProps,
  | "contentRight"
  | "disableNavigation"
  | "toolName"
  | "exitHref"
  | "onExit"
  | "exitText"
>;

/** @returns A header bar for a tool inside the Sphere Viewer. */
export function SphereViewerToolHeaderBar({
  contentRight,
  ...toolHeaderBarProps
}: SphereViewerToolHeaderBarProps): JSX.Element {
  // Only use the backend defined dashboard url so guest users of unlisted project
  // will not get redirected to the old HB dashboard by mistake
  const dashboardUrl = useAppSelector(selectDashboardUrl);
  const menuLinks = useMenuLinks(dashboardUrl);
  const currentUser = useAppSelector(selectCurrentUser);
  const { requestLogin, logout } = useAuthContext();
  const openAccountAndSecurity = useOpenAccountAndSecurity();

  return (
    <ToolHeaderBar
      menuLinks={menuLinks}
      appVersion={APP_VERSION}
      userDisplayInfo={currentUser}
      onLogin={requestLogin}
      onLogout={logout}
      onAccountAndSecurity={openAccountAndSecurity}
      contentRight={
        <>
          <ViewerHelpCenterMenu />
          <ProgressOverviewMenu />
          {contentRight}
        </>
      }
      userMenuContent={<LanguageSelector />}
      {...toolHeaderBarProps}
    />
  );
}

/**
 * @param dashboardUrl The URL to the Sphere XG dashboard.
 * @returns A list of links to show in the user menu.
 */
function useMenuLinks(dashboardUrl?: string): ToolHeaderBarProps["menuLinks"] {
  return useMemo(() => {
    const externalLinks = {
      ...runtimeConfig.externalLinks,
      dashboardUrl,
    };

    const menuLinks: ToolHeaderBarProps["menuLinks"] = [];

    if (externalLinks.termsOfServiceUrl) {
      menuLinks.push({
        href: externalLinks.termsOfServiceUrl,
        text: "Terms",
      });
    }

    if (externalLinks.privacyPolicyUrl) {
      menuLinks.push({
        href: externalLinks.privacyPolicyUrl,
        text: "Privacy",
      });
    }

    if (externalLinks.imprintUrl) {
      menuLinks.push({
        href: externalLinks.imprintUrl,
        text: "Imprint",
      });
    }

    return menuLinks;
  }, [dashboardUrl]);
}
