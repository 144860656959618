import { useActiveCamera } from "@/components/r3f/cameras/active-camera-context";
import { SnapshotRenderer } from "@/components/r3f/renderers/snapshot-renderer";
import {
  isOrthoCameraParameters,
  useCameraParametersIfAvailable,
} from "@/components/r3f/utils/camera-parameters";
import {
  ModeTransitionProps,
  SceneFilterLookAtInitialState,
} from "@/modes/mode";
import { useAppSelector } from "@/store/store-hooks";
import {
  CameraViewType,
  selectBestCameraViewForElementInThreeSpace,
} from "@/utils/cam-view";
import { DEFAULT_PERSPECTIVE_FOV, SupportedCamera } from "@faro-lotv/lotv";
import { selectIElementWorldPosition } from "@faro-lotv/project-source";
import { useEffect, useMemo } from "react";
import { Vector3, Vector3Tuple } from "three";

/**
 * @returns the transition to enter OverviewMode
 */
export function OverviewTransition({
  initialState,
  onCompleted,
  modeCamera,
}: ModeTransitionProps<SceneFilterLookAtInitialState>): JSX.Element {
  const updatedState = useUpdateCameraParameter(initialState);

  const { validCamera, completedCallback } = useActiveCameraFromDeepLink(
    modeCamera,
    onCompleted,
    updatedState,
  );

  useCameraParametersIfAvailable(
    validCamera,
    updatedState?.camera,
    completedCallback,
  );

  useEffect(() => {
    if (!initialState?.camera) {
      onCompleted();
    }
  }, [initialState?.camera, onCompleted]);

  return <SnapshotRenderer />;
}

const DISTANCE = 2.5;
const CAMERA_NEAR = 0.1;
const CAMERA_FAR = 1000;

/**
 * @returns an initial state with the camera parameters adjusted
 * @param initialState The initial state to update
 */
function useUpdateCameraParameter(
  initialState: ModeTransitionProps<SceneFilterLookAtInitialState>["initialState"],
): ModeTransitionProps<SceneFilterLookAtInitialState>["initialState"] {
  const target = useAppSelector(
    selectIElementWorldPosition(initialState?.lookAtId),
  );
  const savedViewForElement = useAppSelector(
    selectBestCameraViewForElementInThreeSpace(initialState?.lookAtId),
  );

  return useMemo(() => {
    // If the lookAtId is not defined or the camera is already defined, do not change anything
    if (!initialState?.lookAtId || initialState.camera) {
      return initialState;
    }

    // If the element to look at has a saved view, use it instead of a heuristic
    if (savedViewForElement) {
      initialState.camera = {
        near: CAMERA_NEAR,
        far: CAMERA_FAR,
        fov: DEFAULT_PERSPECTIVE_FOV,
        pos: savedViewForElement.position.toArray(),
        target,
      };

      return initialState;
    }

    // Move the camera a little up so we can see the scene from an angle
    const dir = new Vector3(target[0], 0, target[2])
      .normalize()
      .multiplyScalar(DISTANCE);

    const pos: Vector3Tuple = [
      target[0] - dir.x,
      target[1] - dir.y + DISTANCE,
      target[2] - dir.z,
    ];

    // These parameters are always the same in our app in 3D and never change
    initialState.camera = {
      near: CAMERA_NEAR,
      far: CAMERA_FAR,
      pos,
      target,
      fov: DEFAULT_PERSPECTIVE_FOV,
    };

    return initialState;
  }, [initialState, savedViewForElement, target]);
}

type CameraAndCallback = {
  /** The camera that matches the deep link projection, if a deep link is present, or the mode camera, if no deep link is present. */
  validCamera: SupportedCamera;
  /** The callback to complete the transition, or undefined if the transition cannot be completed yet. */
  completedCallback?(): void;
};

/**
 * If a deep link is present, this hook reads the camera projection information from the deep link and ensures that the
 * active camera projection matches the one from the deep link. If no deep link is present, this hook immediately returns
 * the input parameters 'modeCamera' and 'onCompleted'.
 *
 * @param modeCamera The camera that the mode will use if the deep link does not change the projection
 * @param onCompleted The callback to complete the transition to the new mode
 * @param updatedState Deep link information, or undefined if no deep link is present
 * @returns The camera that matches the deep link projection, if a deep link is present, or the mode camera, if no deep link is present.
 */
function useActiveCameraFromDeepLink(
  modeCamera: SupportedCamera,
  onCompleted: () => void,
  updatedState?: SceneFilterLookAtInitialState,
): CameraAndCallback {
  const {
    cameraProjection,
    setCameraProjection,
    defaultOrthoCamera,
    defaultPerspectiveCamera,
  } = useActiveCamera();

  return useMemo(() => {
    if (updatedState?.camera) {
      // If there is a deep link, we check whether the current camera projection matches the camera projection in the deep link
      const deepLinkProjection = isOrthoCameraParameters(updatedState.camera)
        ? CameraViewType.orthographic
        : CameraViewType.perspective;
      if (deepLinkProjection === cameraProjection) {
        // If the projections match, we return the valid camera and complete the transition
        const validCamera =
          deepLinkProjection === CameraViewType.orthographic
            ? defaultOrthoCamera
            : defaultPerspectiveCamera;
        return {
          validCamera,
          completedCallback: onCompleted,
        };
      }
      // If the projections do not match, set the correct camera projection, and this useMemo will be re-run
      setCameraProjection(deepLinkProjection);
      // temporarily return the modeCamera as validCamera, and do not complete the transition
      return {
        validCamera: modeCamera,
        completedCallback: undefined,
      };
    }
    // If there is no deep link, the valid camera is the mode camera and the transition can be completed immediately
    return {
      validCamera: modeCamera,
      completedCallback: onCompleted,
    };
  }, [
    cameraProjection,
    updatedState,
    modeCamera,
    onCompleted,
    setCameraProjection,
    defaultOrthoCamera,
    defaultPerspectiveCamera,
  ]);
}
