import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { updateProject } from "@/components/common/project-provider/update-project";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { changeMode } from "@/store/mode-slice";
import { AlignmentViewLayout } from "@/store/modes/alignment-ui-types";
import {
  selectAlignmentStepForCloudToSheetAlignment,
  selectCrossSectionEnabledForCloudToSheetAlignment,
  selectElevationForCloudToSheetAlignment,
  selectIncrementalTransformForCloudToSheetAlignment,
  selectLayoutForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-selectors";
import {
  CloudToSheetAlignmentStep,
  resetCloudToSheetAlignment,
  setCrossSectionEnabledForCloudToSheetAlignment,
  setLayoutForCloudToSheetAlignment,
} from "@/store/modes/cloud-to-sheet-alignment-mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  selectIElementProjectApiLocalPose,
  selectIElementWorldPosition,
  ViewDiv,
} from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { Vector3Tuple } from "three";
import {
  alignmentTransformToMatrix4,
  IDENTITY,
  matrix4ToAlignmentTransform,
} from "../alignment-modes-commons/alignment-transform";
import { AlignmentViewLayoutToggle } from "../alignment-modes-commons/alignment-view-layout-toggle";
import { CrossSectionToggleButton } from "../alignment-modes-commons/cross-section-toggle-button";
import { createSetPointCloudPoseMutation } from "../alignment-modes-commons/project-alignment-mutations";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { CloudToSheetAlignmentProgressBar } from "./cloud-to-sheet-alignment-progreess-bar";
import { CloudToSheetAlignmentSplitScreen } from "./cloud-to-sheet-alignment-split-screen";
import { useCloudToSheetAlignmentElements } from "./use-cloud-to-sheet-alignment-elements";

/** @returns The overlay for cloud-to-sheet alignment mode */
export function CloudToSheetAlignmentModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const [showSpinner, setShowSpinner] = useState(false);

  const { openToast } = useToast();
  const { handleErrorWithToast } = useErrorHandlers();

  const client = useCurrentProjectApiClient();
  const incrementalTransform = useAppSelector(
    selectIncrementalTransformForCloudToSheetAlignment,
  );

  // Get the sheet elevation in cloud world space, set by user in elevation scene
  const sheetElevation = useAppSelector(
    selectElevationForCloudToSheetAlignment,
  );
  const alignmentElements = useCloudToSheetAlignmentElements();

  const areaWorldPosition = useAppSelector(
    selectIElementWorldPosition(alignmentElements.sectionArea.id),
  );
  const sectionAreaWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(alignmentElements.sectionArea.id),
  );

  // World matrix of data session IElement before applying the new alignment
  const cloudWorldMatrix = useAppSelector(
    selectIElementWorldMatrix4(alignmentElements.cloudDataSession.id),
  );

  const applyAlignment = useCallback(async () => {
    setShowSpinner(true);

    // get the incremental transform matrix
    const incrementalTransformMatrix = alignmentTransformToMatrix4(
      incrementalTransform ?? IDENTITY,
    );

    // calculate new world transform
    const newCloudWorldMatrix =
      incrementalTransformMatrix.multiply(cloudWorldMatrix);

    const newCloudWorldTransform =
      matrix4ToAlignmentTransform(newCloudWorldMatrix);

    // update cloud position
    newCloudWorldTransform.position[1] += areaWorldPosition[1] - sheetElevation;

    // Calculate cloud local transform
    const localCloudMatrix = sectionAreaWorldMatrix
      .invert()
      .multiply(alignmentTransformToMatrix4(newCloudWorldTransform));

    const localTransform = selectIElementProjectApiLocalPose(
      alignmentElements.sectionArea,
      localCloudMatrix,
    )(store.getState());

    const scale: Vector3Tuple = alignmentElements.cloudDataSession.pose?.scale
      ? [
          alignmentElements.cloudDataSession.pose.scale.x,
          alignmentElements.cloudDataSession.pose.scale.y,
          alignmentElements.cloudDataSession.pose.scale.z,
        ]
      : [1, 1, 1];

    try {
      await client.applyMutations([
        createSetPointCloudPoseMutation(alignmentElements.cloudDataSession, {
          position: [
            localTransform.pos.x,
            localTransform.pos.y,
            localTransform.pos.z,
          ],
          quaternion: [
            localTransform.rot.x,
            localTransform.rot.y,
            localTransform.rot.z,
            localTransform.rot.w,
          ],
          scale,
        }),
      ]);
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      setShowSpinner(false);

      return;
    }

    // Fetch the changed cloud DataSession sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    dispatch(
      updateProject({
        projectApi: client,
        // Refresh the cloud node to get new transform
        iElementQuery: {
          ancestorIds: [alignmentElements.cloudDataSession.id],
        },
        invalidateAreaContents: true,
      }),
    );

    // force sheet to be new active area to immediately show results of alignment
    dispatch(setActiveElement(alignmentElements.sectionArea.id));

    setShowSpinner(false);
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("sheet"));

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetCloudToSheetAlignment());
  }, [
    incrementalTransform,
    cloudWorldMatrix,
    areaWorldPosition,
    sheetElevation,
    sectionAreaWorldMatrix,
    alignmentElements.sectionArea,
    alignmentElements.cloudDataSession,
    store,
    dispatch,
    client,
    openToast,
    handleErrorWithToast,
  ]);

  const step = useAppSelector(selectAlignmentStepForCloudToSheetAlignment);
  const alignmentViewLayout = useAppSelector(
    selectLayoutForCloudToSheetAlignment,
  );
  const changeAlignmentViewLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setLayoutForCloudToSheetAlignment(value)),
    [dispatch],
  );

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <CloudToSheetAlignmentProgressBar applyAlignment={applyAlignment} />

        {step === CloudToSheetAlignmentStep.setElevation && (
          <CloudToSheetAlignmentElevation />
        )}
        {step === CloudToSheetAlignmentStep.pairPoints && (
          <>
            {alignmentViewLayout === AlignmentViewLayout.splitScreen && (
              <CloudToSheetAlignmentSplitScreen />
            )}
            {alignmentViewLayout === AlignmentViewLayout.overlay && (
              <CloudToSheetAlignmentOverlayScreen />
            )}
            <AlignmentViewLayoutToggle
              alignmentLayout={alignmentViewLayout}
              changeAlignmentScreenLayout={changeAlignmentViewLayout}
            />
          </>
        )}
      </Stack>
    </>
  );
}

/** @returns The cloud-to-sheet alignment elevation sub-view */
function CloudToSheetAlignmentElevation(): JSX.Element {
  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          border: 3,
          borderColor: neutral[100],
          height: "100%",
        }}
      />
    </ViewDiv>
  );
}

/**
 * @returns cloud-to-sheet alignment overlay screen layout
 */
function CloudToSheetAlignmentOverlayScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForCloudToSheetAlignment,
  );

  const sheetElevation = useAppSelector(
    selectElevationForCloudToSheetAlignment,
  );

  return (
    <ViewDiv
      eventDivRef={singleScreenRef}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <CrossSectionToggleButton
        isEnabled={isCrossSectionEnabled}
        elevation={sheetElevation}
        onToggleCrossSections={() => {
          dispatch(
            setCrossSectionEnabledForCloudToSheetAlignment(
              !isCrossSectionEnabled,
            ),
          );
        }}
      />
    </ViewDiv>
  );
}
