import { EventType } from "@/analytics/analytics-events";
import { EventBarrier } from "@/components/common/event-barrier";
import { matrix4ToAlignmentTransform } from "@/modes/alignment-modes-commons/alignment-transform";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  parseVector3,
  roundedCalculatePosition,
  useControlsLock,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { neutral } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";
import { Box, BoxProps } from "@mui/material";
import {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Camera, Material, Matrix4, Object3D, Vector2, Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { AnnotationObject } from "./annotation-object";
import {
  CanvasSize,
  moveToAnchorPoint,
  moveToCorner,
} from "./annotation-utils";
import { AlignAt } from "./annotations-types";

/** Number of pixel the mouse need to move between click and release to consider the interaction a drag */
const MOUSE_DRAG_PIXEL_THRESHOLD = 5;

interface AnnotationWrapperProps {
  /** The annotation / markup iElement to render */
  iElement: IElement;

  /** The world offset applied to the pano associated to this annotation */
  worldTransform?: Matrix4;

  /**
   * Flag to decide where the annotation's UI should be anchored to
   *
   * @default center
   */
  alignAt?: AlignAt;

  /**
   * If true the popOver will always be shown, otherwise it will be shown onHover
   *
   * @default true
   */
  popOverAlwaysVisible?: boolean;

  /** A custom anchor point (world coordinates) to position the label from */
  anchorPointWorld?: Vector3;

  /**
   * Meters required for the camera to move, before the zIndexRange is updated.
   * If the zIndexRange must be updated at every render, this can be set to a negative number.
   */
  eps?: number;

  /** zIndex range to assign to the Annotation's UI */
  zIndexRange?: [number, number];

  /** Additional styles of PopOver wrapper for the annotations / markup */
  style?: CSSProperties;

  /** Callback executed when the annotation / markup is clicked */
  onClick?(): void;

  /** Method to call when the haver state changes (isHovered is true if the user hovers the annotation) */
  onHoverChange?(isHovered: boolean): void;

  /** Whether a collapsed variant of the AnnotationObject should be shown */
  isCollapsed?: boolean;

  /** Whether depth testing should be used to render the annotation */
  depthTest?: Material["depthTest"];

  /** The render order to use for the annotation */
  renderOrder?: Object3D["renderOrder"];
}

/**
 * @returns A wrapper for annotation and markup components that displays the popOver when the mouse is over the annotation / markup
 */
export function AnnotationWrapper({
  iElement,
  worldTransform,
  popOverAlwaysVisible = false,
  alignAt = AlignAt.center,
  isCollapsed,
  depthTest,
  renderOrder,
  anchorPointWorld,
  eps,
  zIndexRange,
  style,
  onClick,
  onHoverChange,
  children,
}: PropsWithChildren<AnnotationWrapperProps>): JSX.Element {
  const annotationObjectRef = useRef<Object3D | null>(null);

  // Make the annotation pop-over visible
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);

  // Keep the pop-over visible if the mouse is on the pop-over
  const [isMouseOnPopOver, setIsMouseOnPopOver] = useState(false);

  // The HTML element on which we will attach the annotation pop-overs
  const viewOverlay = useThreeEventTarget();
  assert(
    // Return of useThreeEventTarget is not correctly typed (see https://faro01.atlassian.net/browse/SWEB-3461)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    viewOverlay !== null,
    "Cannot create a AnnotationRenderer component because R3F canvas has no parent HTML element.",
  );

  const popOverContainer = useRef(viewOverlay);

  const annotationWorldTransform = useAppSelector(
    selectIElementWorldMatrix4(iElement.id),
  );
  const pose = useMemo(
    () =>
      matrix4ToAlignmentTransform(
        worldTransform
          ? worldTransform.clone().multiply(annotationWorldTransform)
          : annotationWorldTransform,
      ),
    [annotationWorldTransform, worldTransform],
  );

  useControlsLock(!popOverAlwaysVisible && isMouseOnPopOver);

  /**
   * Toggles the state of the mouse being on the pop-over
   */
  const toggleIsMouseOnPopOverAndControls = useCallback(
    (isHovered: boolean) => {
      if (isHovered) {
        Analytics.track(EventType.hoverAnnotationTitle);
      }
      setIsMouseOnPopOver(isHovered);
      onHoverChange?.(isHovered);
    },
    [setIsMouseOnPopOver, onHoverChange],
  );

  const startPos = useRef<Vector2>();

  const calculatePosition = useCallback(
    (el: Object3D, camera: Camera, canvasSize: CanvasSize) => {
      switch (alignAt) {
        case AlignAt.center:
          return roundedCalculatePosition(el, camera, canvasSize);
        case AlignAt.anchorPoint:
          return moveToAnchorPoint({
            camera,
            canvasSize,
            anchorPointWorld: anchorPointWorld ?? parseVector3(pose.position),
          });
        case AlignAt.boundingBoxTopLeft:
        case AlignAt.boundingBoxTopRight:
          // Using default while things are loading
          if (!annotationObjectRef.current) {
            return roundedCalculatePosition(el, camera, canvasSize);
          }

          return moveToCorner({
            el,
            annotationObject: annotationObjectRef.current,
            camera,
            canvasSize,
            alignAt,
          });
      }
    },
    [alignAt, anchorPointWorld, pose.position],
  );

  let content: React.ReactNode;

  if (
    alignAt === AlignAt.anchorPoint ||
    alignAt === AlignAt.boundingBoxTopLeft ||
    alignAt === AlignAt.boundingBoxTopRight
  ) {
    content = (
      <AppAwareHtml
        calculatePosition={calculatePosition}
        style={{ ...style, width: "max-content" }}
        eps={eps}
        zIndexRange={zIndexRange}
        portal={popOverContainer}
      >
        <EventBarrier>
          <Box
            onPointerEnter={() => toggleIsMouseOnPopOverAndControls(true)}
            onPointerLeave={() => toggleIsMouseOnPopOverAndControls(false)}
            component="div"
          >
            {children}
          </Box>
        </EventBarrier>
      </AppAwareHtml>
    );
  } else {
    content = (
      <AppAwareHtml portal={popOverContainer} style={{ width: "max-content" }}>
        <AnnotationPopOverWrapper
          onPointerEnter={() => toggleIsMouseOnPopOverAndControls(true)}
          onPointerLeave={() => toggleIsMouseOnPopOverAndControls(false)}
          style={style}
        >
          {children}
        </AnnotationPopOverWrapper>
      </AppAwareHtml>
    );
  }

  return (
    <group
      {...pose}
      onPointerEnter={() => setIsPopOverVisible(true)}
      onPointerLeave={() => setIsPopOverVisible(false)}
      onPointerDown={(ev) =>
        (startPos.current = new Vector2(ev.clientX, ev.clientY))
      }
      onClick={(ev) => {
        const distanceFromPointerDown =
          startPos.current?.distanceTo(new Vector2(ev.clientX, ev.clientY)) ??
          0;
        if (distanceFromPointerDown < MOUSE_DRAG_PIXEL_THRESHOLD) {
          onClick?.();
          // To support touch devices, the pop over needs to be shown on click
          setIsPopOverVisible(true);
        }
      }}
      // The property exists and can be checked here https://docs.pmnd.rs/react-three-fiber/api/events
      // eslint-disable-next-line react/no-unknown-property
      onPointerMissed={() => setIsPopOverVisible(false)}
      // Setting the render order on a group, also makes it apply to all children
      renderOrder={renderOrder}
    >
      <AnnotationObject
        ref={annotationObjectRef}
        annotation={iElement}
        isCollapsed={isCollapsed}
        depthTest={depthTest}
      />

      {(popOverAlwaysVisible || isPopOverVisible || isMouseOnPopOver) &&
        content}
    </group>
  );
}

type AnnotationPopOverWrapperProps = Omit<BoxProps, "component">;

/**
 * @returns A wrapper for the pop-over of an annotation inline with the style specifications of the design
 */
function AnnotationPopOverWrapper({
  children,
  sx,
  ...rest
}: PropsWithChildren<AnnotationPopOverWrapperProps>): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        py: 0.75,
        px: 1.5,
        backgroundColor: neutral[999],
        outline: `1px solid ${neutral[0]}1A`,
        borderRadius: 1.5,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
