import {
  PointCloudStreamIcon,
  PointCloudToggleMenu,
} from "@/components/common/point-cloud-toggle";
import { ElementIcon, useElementIcon } from "@/components/ui/icons";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  AnchorIcon,
  ArrowDownIcon,
  FaroIconButton,
  FaroTooltip,
  neutral,
  selectChildrenDepthFirst,
  selectIElement,
  TruncatedFaroText,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  IElement,
  IElementGenericPointCloudStream,
  isIElementGenericDataSession,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import { Grid, Stack, SxProps } from "@mui/material";
import { isEqual } from "es-toolkit";
import { useRef, useState } from "react";
import { selectIsPointCloudViewable } from "../mode-selectors";

/** All supported type of labels supported by alignment workflows */
export enum LabelType {
  /** Element which will be moved during alignment */
  alignedElement = "alignedElement",

  /** Element which will not move during alignment */
  referenceElement = "referenceElement",
}

type AlignmentElementLabelProps = {
  /** Element for which label is rendered */
  element: IElement;

  /** Type of icon to render */
  labelType: LabelType;

  /** optional parameter required to provide adjustment in horizontal split screen mode */
  sx?: SxProps;

  /** Optional parameter to show a dropdown menu when current element is a cloud with multiple scans */
  showMultiScansDropdown?: boolean;

  /** Id of the selected scan in the optional dropdown */
  currentScanId?: GUID;

  /** Callback for when the active scan is changed */
  onActiveScanChanged?(id: GUID): void;
};

/**
 * @returns control to display name of element in split-screen views for all alignments
 */
export function AlignmentElementLabel({
  element,
  labelType,
  showMultiScansDropdown,
  currentScanId,
  onActiveScanChanged,
  sx,
}: AlignmentElementLabelProps): JSX.Element {
  const directParent = useAppSelector(selectIElement(element.parentId));
  const elementIcon = useElementIcon(element, directParent);

  const pointCloudStreams = useAppSelector(
    selectPointCloudStreamsInDataSession(element),
    isEqual,
  );
  const enableScansDropdown =
    pointCloudStreams.length >= 2 && showMultiScansDropdown;

  const [isPointCloudMenuOpen, setIsPointCloudMenuOpen] = useState(false);

  const pointCloudMenuAnchor = useRef(null);

  const currentScanElement = useAppSelector(selectIElement(currentScanId));

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        sx={{
          paddingX: "3px",
          borderRadius: "30px",
          border: "2px",
          borderColor: neutral[200],
          borderStyle: "solid",
          px: 1,
          position: "absolute",
          height: "auto",
          width: "250px",
          background: neutral[0],
          opacity: 0.8,
          ...sx,
        }}
        ref={pointCloudMenuAnchor}
      >
        <Grid item xs={2}>
          {currentScanElement &&
          isIElementGenericPointCloudStream(currentScanElement) ? (
            <PointCloudStreamIcon
              pointCloudStream={currentScanElement}
              sx={{ mt: "5px" }}
            />
          ) : (
            <ElementIcon icon={elementIcon} sx={{ mt: "5px" }} />
          )}
        </Grid>
        <Grid item xs={labelType === LabelType.referenceElement ? 8 : 10}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TruncatedFaroText variant="heading14" align="left">
              {element.name}
            </TruncatedFaroText>
            {enableScansDropdown && (
              <FaroIconButton
                onClick={() => {
                  setIsPointCloudMenuOpen(true);
                }}
              >
                <ArrowDownIcon
                  sx={{
                    transform: isPointCloudMenuOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.1s linear",
                  }}
                />
              </FaroIconButton>
            )}
          </Stack>
        </Grid>
        {labelType === LabelType.referenceElement && (
          <Grid item xs={2} sx={{ mt: "5px" }}>
            <FaroTooltip title="The reference element does not move during the alignment">
              <AnchorIcon htmlColor={neutral[400]} />
            </FaroTooltip>
          </Grid>
        )}
      </Grid>
      {enableScansDropdown && (
        <PointCloudToggleMenu
          dark={false}
          currentMainElement={currentScanElement}
          pointCloudStreams={pointCloudStreams}
          onActiveElementChanged={(id) => {
            setIsPointCloudMenuOpen(false);
            if (onActiveScanChanged) {
              onActiveScanChanged(id);
            }
          }}
          anchorEl={pointCloudMenuAnchor.current}
          open={isPointCloudMenuOpen}
          onClose={() => setIsPointCloudMenuOpen(false)}
        />
      )}
    </>
  );
}

/**
 * This selector returns all viewable point-clouds belonging to a data session element.
 * This excludes, e.g. single scan point clouds.
 *
 * @param element The data session element to find point cloud streams in
 * @returns All point cloud streams in the data session element
 */
function selectPointCloudStreamsInDataSession(element: IElement) {
  return (state: RootState): IElementGenericPointCloudStream[] => {
    if (!isIElementGenericDataSession(element)) {
      return [];
    }

    return selectChildrenDepthFirst(
      element,
      isIElementGenericPointCloudStream,
    )(state).filter((pc) => selectIsPointCloudViewable(pc)(state));
  };
}
