import { useWaypointDateRange } from "@/modes/mode-data-context";
import { useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import { ToolGroup, Toolbar } from "@faro-lotv/flat-ui";
import { useState } from "react";
import { MultiLayerTool } from "./multi-layer-tool";
import { useNewFeaturePopup } from "./new-feature/new-feature-context";
import { TagsFilterTool } from "./tags-filter-tool";
import { ViewOptionsTool } from "./view-options-tool";

/** @returns a toolbar that contains the tools to manage view settings */
export function ViewSettingsToolbar(): JSX.Element | null {
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);
  const [settingsButtonRef, setSettingsButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [layerButtonRef, setLayerButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [isMultiLayerMenuOpen, setIsMultiLayerMenuOpen] = useState(false);
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const [isViewOptionsOpen, setIsViewOptionsOpen] = useState(false);
  const waypointsDateRange = useWaypointDateRange();

  useNewFeaturePopup({
    anchorEl: layerButtonRef,
    title: "Import new layers",
    description: "Add new sheets to the active area",
    localStorage: "newFeatureAddLayerToArea",
    placement: "left",
    isVisible: !isMultiLayerMenuOpen && hasWritePermission,
  });

  useNewFeaturePopup({
    anchorEl: settingsButtonRef,
    title: "Waypoints color by capture date",
    description:
      "Waypoints can now be colored based on their capture date, in addition to their elevation.",
    localStorage: "colorizeWaypoints",
    placement: "left",
    isVisible: !isViewOptionsOpen && !!waypointsDateRange,
  });

  return (
    <Toolbar
      ref={setToolbarRef}
      sx={{ alignSelf: "flex-end", overflow: "visible" }}
    >
      <ToolGroup>
        <ViewOptionsTool
          anchorEl={toolbarRef}
          setButtonRef={setSettingsButtonRef}
          setIsMenuOpen={setIsViewOptionsOpen}
        />
        <TagsFilterTool anchorEl={toolbarRef} />
        <MultiLayerTool
          anchorEl={toolbarRef}
          setButtonRef={setLayerButtonRef}
          setMultiLayerToolVisible={setIsMultiLayerMenuOpen}
        />
      </ToolGroup>
    </Toolbar>
  );
}
