import { neutral } from "@faro-lotv/flat-ui";
import { Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { PropsWithChildren } from "react";
import { ViewOptionSwitch } from "./view-options-switch";

type ViewOptionSectionProps = {
  /** The label of the switch */
  label: string;
  /** True if the option is enabled */
  isChecked: boolean;
  /** Callback executed when the user toggles the value */
  onToggled(visible: boolean): void;
} & PropsWithChildren;

/** @returns A switch wrapping a whole section for the view options menu */
export function ViewOptionSection({
  label,
  isChecked,
  onToggled,
  children,
}: ViewOptionSectionProps): JSX.Element {
  return (
    <Stack gap="4px">
      <ViewOptionSwitch
        label={label}
        variant="heading14"
        isChecked={isChecked}
        onToggled={onToggled}
      />
      {children}
      <Divider sx={{ borderColor: neutral[800] }} />
    </Stack>
  );
}
