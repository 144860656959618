import { updateProject } from "@/components/common/project-provider/update-project";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { Tag } from "@/store/tags/tags-slice";
import { GUID } from "@faro-lotv/foundation";
import { isValid } from "@faro-lotv/ielement-types";
import { selectIElements } from "@faro-lotv/project-source";
import {
  createMutationAddLabel,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useMemo } from "react";

type AddScansTagsCallback = () => Promise<void>;

/**
 * @returns An asynchronous callback to add new tags to a list of iElements
 * @param selectedTags The list of tags to add to all the iElements
 * @param elementsIds The list of ids of the iElements
 */
export function useAddTagsToIElements(
  selectedTags: Tag[],
  elementsIds: GUID[],
): AddScansTagsCallback {
  const { projectApiClient } = useApiClientContext();
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorHandlers();

  const iElements = useAppSelector((state) =>
    selectIElements(state, elementsIds),
  );
  const validIElements = useMemo(() => iElements.filter(isValid), [iElements]);

  return useCallback(async () => {
    const mutations = validIElements
      .map(({ labels, id: iElementId }) => {
        const tagsToAdd = labels
          ? selectedTags.filter(
              (tag) => !labels.find((label) => label.id === tag.id),
            )
          : selectedTags;
        return tagsToAdd.map((t) => createMutationAddLabel(iElementId, t.id));
      })
      .flat();

    // Update the project
    try {
      await projectApiClient.applyMutations(mutations);

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        updateProject({
          projectApi: projectApiClient,
          iElementQuery: {
            ancestorIds: validIElements.map((e) => e.id),
          },
        }),
      );
    } catch (error) {
      handleErrorWithToast({ title: "Could not edit Annotation", error });
    }
  }, [
    dispatch,
    handleErrorWithToast,
    validIElements,
    projectApiClient,
    selectedTags,
  ]);
}
