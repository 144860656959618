import { AlignmentTransform } from "@/modes/alignment-modes-commons/alignment-transform";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/modes/alignment-modes-commons/compute-split-screen-alignment";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "@/store/modes/alignment-ui-types";
import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** This defines all the steps to align sheet to a point cloud */
export enum SheetToCloudAlignmentStep {
  setElevation = "setElevation",
  alignIn2d = "alignIn2d",
}

/** State managing data exchange during sheet-to-cloud alignment session */
type SheetToCloudAlignmentToolState = {
  /** reference to sheet ID used during current alignment session */
  sheetIdToAlignWithCloud?: GUID;

  /** reference to cloud ID used during current alignment session */
  referenceCloudId?: GUID;

  /** Alignment step */
  step: SheetToCloudAlignmentStep;

  /** sheet elevation in point cloud coordinate system in meters */
  sheetElevation: number;

  /** if true clipping box in setElevation step is enabled */
  isClippingBoxEnabled: boolean;

  /** if true, extract cross section at elevation defined on first step for cloud tomographic view */
  isCrossSectionEnabled: boolean;

  /**
   * Incremental transform of the sheet to cloud. Translations are in meters.
   * At the end of each alignment cycle it must be reset to undefined to prevent reusing it in next session of alignment
   */
  incrementalTransform?: AlignmentTransform;

  /** anchor positions defined in split screen alignment */
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions;

  /** sheet to cloud alignment screen layout */
  alignmentLayout: AlignmentViewLayout;

  /** the alignment screen split direction */
  splitDirection: SplitDirection;
};

const initialState: SheetToCloudAlignmentToolState = {
  step: SheetToCloudAlignmentStep.setElevation,
  alignmentAnchorPositions: {},
  isCrossSectionEnabled: true,
  isClippingBoxEnabled: false,
  alignmentLayout: AlignmentViewLayout.splitScreen,
  splitDirection: SplitDirection.horizontalSplit,
  sheetElevation: 0,
};

const sheetToCloudAlignmentModeSlice = createSlice({
  initialState,
  name: "sheetToCloudAlignmentMode",
  reducers: {
    setStepForSheetToCloudAlignment(
      state,
      action: PayloadAction<SheetToCloudAlignmentStep>,
    ) {
      state.step = action.payload;
    },

    setCrossSectionEnabled(state, action: PayloadAction<boolean>) {
      state.isCrossSectionEnabled = action.payload;
    },

    setClippingBoxEnabled(state, action: PayloadAction<boolean>) {
      state.isClippingBoxEnabled = action.payload;
    },

    setSheetIdForAlignment(state, action: PayloadAction<string>) {
      state.sheetIdToAlignWithCloud = action.payload;
    },

    setReferenceCloudForAlignment(state, action: PayloadAction<GUID>) {
      state.referenceCloudId = action.payload;
    },

    setSheetElevationForSheetToCloudAlignment(
      state,
      action: PayloadAction<number>,
    ) {
      state.sheetElevation = action.payload;
    },

    setSheetToCloudIncrementalTransform(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.incrementalTransform = action.payload;
    },

    setMovingElementAnchor1ForAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor1 = action.payload;
    },

    setMovingElementAnchor2ForAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor2 = action.payload;
    },

    setReferenceElementAnchor1ForAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor1 = action.payload;
    },

    setReferenceElementAnchor2ForAlignment(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor2 = action.payload;
    },

    resetSheetToCloudAlignment(state) {
      state.step = SheetToCloudAlignmentStep.setElevation;
      state.referenceCloudId = undefined;
      state.sheetIdToAlignWithCloud = undefined;
      state.sheetElevation = 0;
      state.isClippingBoxEnabled = false;
      state.incrementalTransform = undefined;
      state.alignmentAnchorPositions = {};
      state.alignmentLayout = AlignmentViewLayout.splitScreen;
      state.splitDirection = SplitDirection.horizontalSplit;
    },

    setSheetToCloudAlignmentLayout(
      state,
      action: PayloadAction<AlignmentViewLayout>,
    ) {
      state.alignmentLayout = action.payload;
    },

    setSheetToCloudAlignmentSplitDirection(
      state,
      action: PayloadAction<SplitDirection>,
    ) {
      state.splitDirection = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const {
  resetSheetToCloudAlignment,
  setStepForSheetToCloudAlignment,
  setSheetIdForAlignment,
  setReferenceCloudForAlignment,
  setSheetToCloudIncrementalTransform,
  setSheetElevationForSheetToCloudAlignment,
  setClippingBoxEnabled,
  setCrossSectionEnabled,
  setSheetToCloudAlignmentLayout,
  setMovingElementAnchor1ForAlignment,
  setMovingElementAnchor2ForAlignment,
  setReferenceElementAnchor1ForAlignment,
  setReferenceElementAnchor2ForAlignment,
  setSheetToCloudAlignmentSplitDirection,
} = sheetToCloudAlignmentModeSlice.actions;

export const sheetToCloudAlignmentModeReducer =
  sheetToCloudAlignmentModeSlice.reducer;
