import { selectProjectUsers } from "@/store/project-selector";
import { useAppSelector } from "@/store/store-hooks";
import {
  FaroText,
  FilterMenuOptionType,
  NO_TRANSLATE_CLASS,
  NoTranslate,
  getUserInitials,
  neutral,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/ielement-types";
import { GenericUserInfo } from "@faro-lotv/service-wires";
import { Avatar } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { ChipFilterWithSearch } from "./chip-filter-with-search";

export const UNASSIGNED_ID = "unassigned";

export type AssigneeFilterProps = {
  /** Callback executed when the selection changes */
  onSelectedAssigneesChanged(ids: GUID[]): void;
};

/** @returns A chip with a popup that can be used to select a list of users */
export function AssigneeFilter({
  onSelectedAssigneesChanged,
}: AssigneeFilterProps): JSX.Element {
  const assignees = useAppSelector(selectProjectUsers);

  const [selectedAssignees, setSelectedAssignees] = useState<
    FilterMenuOptionType[]
  >([]);
  useEffect(() => {
    onSelectedAssigneesChanged(selectedAssignees.map((a) => a.key));
  }, [onSelectedAssigneesChanged, selectedAssignees]);

  const options = useMemo<FilterMenuOptionType[]>(
    () => [
      {
        key: UNASSIGNED_ID,
        value: UNASSIGNED_ID,
        label: <FaroText variant="bodyS">Unassigned</FaroText>,
        tooltip: (
          <FaroText variant="bodyS" dark>
            Unassigned
          </FaroText>
        ),
      },
      ...assignees.map((a) => ({
        key: a.id ?? "",
        value: a.name ?? "",
        label: <AssigneeOption user={a} />,
        tooltip: <AssigneeOption user={a} dark />,
      })),
    ],
    [assignees],
  );

  return (
    <ChipFilterWithSearch
      label="User"
      selectedOptions={selectedAssignees}
      onFilterChange={setSelectedAssignees}
      options={options}
      headingLabel="Select members"
    />
  );
}

type AssigneeOptionProps = {
  /** The user info */
  user: GenericUserInfo;
  /** True if the option should be rendered using the dark style */
  dark?: boolean;
};

/** @returns a menu item with the user icon and name */
function AssigneeOption({ user, dark }: AssigneeOptionProps): JSX.Element {
  const { profileImageUrl, name } = user;

  const initials = useMemo(() => getUserInitials(user), [user]);

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Avatar
        sx={{
          bgcolor: neutral[400],
          width: "24px",
          height: "24px",
        }}
        src={profileImageUrl}
        alt={initials}
        component="span"
      >
        <NoTranslate>{initials}</NoTranslate>
      </Avatar>
      <FaroText className={NO_TRANSLATE_CLASS} variant="bodyS" dark={dark}>
        {name}
      </FaroText>
    </Stack>
  );
}
