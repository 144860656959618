import { useCached3DObject } from "@/object-cache";
import {
  selectClippingBoxEnabledForCadAlignment,
  selectSheetElevation,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { setSheetElevation } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectAncestor,
  selectIElementWorldPosition,
  View,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementModel3dStream,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback } from "react";
import { Box3 } from "three";
import { useNewPerspectiveCamera } from "../alignment-modes-commons/align-to-cad-utils";
import { ModelElevationScene } from "../alignment-modes-commons/model-elevation-scene";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { useOverlayElements } from "../overlay-elements-context";

type SetHeightSceneProps = {
  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;
};

/** @returns the scene for set the cad height */
export function SetHeightScene({
  activeCad,
  cadBox,
}: SetHeightSceneProps): JSX.Element {
  const cadModelObject = useCached3DObject(activeCad);
  const dispatch = useAppDispatch();

  const { singleScreen } = useOverlayElements();
  const background = useThree((s) => s.scene.background);

  const camera = useNewPerspectiveCamera();

  const sheet = useSheetSelectedForAlignment("sheetToCad");
  const sectionArea = useAppSelector(
    selectAncestor(sheet, isIElementAreaSection),
  );

  const sheetWorldPosition = useAppSelector(
    selectIElementWorldPosition(sheet.id),
  );

  const isClippingBoxEnabled = useAppSelector(
    selectClippingBoxEnabledForCadAlignment,
  );

  const sheetElevation = useAppSelector(selectSheetElevation);

  const updateElevation = useCallback(
    (elevation: number) => {
      dispatch(setSheetElevation(elevation));
    },
    [dispatch],
  );

  return (
    <View
      camera={camera}
      trackingElement={singleScreen}
      background={background}
      hasSeparateScene
    >
      <ModelElevationScene
        cadModelObject={cadModelObject}
        cadBox={cadBox}
        elevation={
          sheetElevation ??
          (sheet.pose ?? sectionArea?.pose ? sheetWorldPosition[1] : undefined)
        }
        updateElevation={updateElevation}
        isClippingBoxEnabled={isClippingBoxEnabled}
        camera={camera}
      />
    </View>
  );
}
