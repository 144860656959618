import { AlignmentTransform } from "@/modes/alignment-modes-commons/alignment-transform";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/modes/alignment-modes-commons/compute-split-screen-alignment";
import { RootState } from "@/store/store";

/**
 * @param state  Current state
 * @returns incremental transform computed using Control Points alignment
 */
export function selectControlPointsAlignmentTransform(
  state: RootState,
): AlignmentTransform | undefined {
  return state.controlPointsAlignmentMode.incrementalTransform;
}

/**
 * @param state  Current state
 * @returns  Elevation of sheet plane in W.C.S., in meters, or undefined if elevation was not modified
 */
export function selectControlPointsSheetElevation(
  state: RootState,
): number | undefined {
  return state.controlPointsAlignmentMode.sheetElevation;
}

/**
 * @param state  Current state
 * @returns  id of layer or area used in  current session of Control Points alignment
 */
export function selectElementToAlignWithControlPointsAlignment(
  state: RootState,
): string | undefined {
  return state.controlPointsAlignmentMode.layerOrAreaId;
}

/**
 * @param state  Current state
 * @returns  anchor positions in Control Points alignment
 */
export function selectControlPointsAlignmentAnchorPositions(
  state: RootState,
): TwoPointsPairsAlignmentAnchorPositions {
  return state.controlPointsAlignmentMode.alignmentAnchorPositions;
}
