import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { PointCloudSimpleSubscene } from "@/components/r3f/effects/point-cloud-simple-subscene";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { centerOrthoCamera } from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useObjectVisibility } from "@/hooks/use-object-visibility";
import { useCached3DObject } from "@/object-cache";
import {
  selectAnchorPositionsForCloudAlignment,
  selectCloudAndCadAlignReference,
  selectCloudCrossSectionEnabled,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentModelElevation,
  selectCloudToCadAlignmentStep,
  selectModelCrossSectionEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  AlignmentReference,
  setCloudToCadIncrementalTransform,
  setMovingElementAnchor1ForCloudAlignment,
  setMovingElementAnchor2ForCloudAlignment,
  setReferenceElementAnchor1ForCloudAlignment,
  setReferenceElementAnchor2ForCloudAlignment,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  AnchorPairPlacement,
  blue,
  ClearPass,
  CopyToScreenPass,
  EffectPipeline,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  TomographicModelPass,
  useNonExhaustiveEffect,
  View,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericPointCloudStream,
  IElementModel3dStream,
} from "@faro-lotv/ielement-types";
import { assert } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useState } from "react";
import { Box3, Color, Plane, Vector3Tuple } from "three";
import {
  useCadTomographicMaterial,
  useCenterCameraOnBoundingBoxIn2D,
  useCenterCameraOnCadModel,
  useClippingPlanesAtCloudElevation,
  useClippingPlanesAtModelElevation,
  useCloudForAlignmentView,
  useNewOrthographicCamera,
} from "../alignment-modes-commons/align-to-cad-utils";
import { computeSplitScreenAlignment } from "../alignment-modes-commons/compute-split-screen-alignment";
import { RenderDispatch } from "../overview-mode/render-dispatch";

type AlignCloudToCadSplitScreenProps = {
  /** The active sheet to be aligned to the CAD model */
  activeCloud: IElementGenericPointCloudStream;

  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;

  /** First screen in split screen (left or top screen) */
  firstScreen: HTMLDivElement;

  /** Second  screen in split screen (right or bottom screen) */
  secondScreen: HTMLDivElement;
};

/** @returns the split-screen scene for align the cloud to cad in 2d */
export function AlignCloudToCadSplitScreen({
  activeCad,
  activeCloud,
  cadBox,
  firstScreen,
  secondScreen,
}: AlignCloudToCadSplitScreenProps): JSX.Element {
  const dispatch = useAppDispatch();
  const background = useThree((s) => s.scene.background);

  const reference = useAppSelector(selectCloudAndCadAlignReference);

  const cloudCamera = useNewOrthographicCamera();
  const modelCamera = useNewOrthographicCamera();

  const cadModel = useCached3DObject(activeCad);
  const cloudToAlign = useCached3DObject(activeCloud);

  const cloudBox = useObjectBoundingBox(cloudToAlign, activeCloud.id);
  assert(cloudBox, "unable to compute bounding box for cloud");

  const cadElevation = useAppSelector(selectCloudToCadAlignmentModelElevation);
  const cloudElevation = useAppSelector(
    selectCloudToCadAlignmentCloudElevation,
  );

  useObjectVisibility(cadModel, true);
  const cadCenteringData = useCenterCameraOnCadModel(
    cadModel,
    cadBox,
    reference === AlignmentReference.bimModel ? secondScreen : firstScreen,
  );

  const cloudCameraCenter = useCenterCameraOnBoundingBoxIn2D(
    cloudBox,
    reference === AlignmentReference.bimModel
      ? firstScreen.clientWidth / firstScreen.clientHeight
      : secondScreen.clientWidth / secondScreen.clientHeight,
  );

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(cloudCamera, cloudCameraCenter);
    centerOrthoCamera(modelCamera, cadCenteringData);
  }, []);

  useCadTomographicMaterial(cadModel);

  const isModelCrossSectionEnabled = useAppSelector(
    selectModelCrossSectionEnabled,
  );
  const clippingPlanes = useClippingPlanesAtModelElevation(
    cadModel,
    cadElevation,
    isModelCrossSectionEnabled,
  );

  const isCloudCrossSectionEnabled = useAppSelector(
    selectCloudCrossSectionEnabled,
  );
  const computedCloudPlanes = useClippingPlanesAtCloudElevation(
    cloudElevation,
    isCloudCrossSectionEnabled,
  );

  const alignmentStep = useAppSelector(selectCloudToCadAlignmentStep);
  const [cloudClippingPlanes, setCloudClippingPlanes] = useState<Plane[]>([]);
  useEffect(() => {
    setCloudClippingPlanes(computedCloudPlanes);
  }, [alignmentStep, computedCloudPlanes]);

  const [controlsEnabled, setControlsEnabled] = useState(true);

  const alignmentAnchorPositions = useAppSelector(
    selectAnchorPositionsForCloudAlignment,
  );

  const changeModelAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(
        reference === AlignmentReference.bimModel
          ? setReferenceElementAnchor1ForCloudAlignment(position)
          : setMovingElementAnchor1ForCloudAlignment(position),
      );
    },
    [dispatch, reference],
  );
  const changeModelAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(
        reference === AlignmentReference.bimModel
          ? setReferenceElementAnchor2ForCloudAlignment(position)
          : setMovingElementAnchor2ForCloudAlignment(position),
      );
    },
    [dispatch, reference],
  );
  const changeCloudAnchor1Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(
        reference === AlignmentReference.bimModel
          ? setMovingElementAnchor1ForCloudAlignment(position)
          : setReferenceElementAnchor1ForCloudAlignment(position),
      );
    },
    [dispatch, reference],
  );
  const changeCloudAnchor2Position = useCallback(
    (position: Vector3Tuple) => {
      dispatch(
        reference === AlignmentReference.bimModel
          ? setMovingElementAnchor2ForCloudAlignment(position)
          : setReferenceElementAnchor2ForCloudAlignment(position),
      );
    },
    [dispatch, reference],
  );

  // compute split screen alignment when there is change in anchor positions
  useEffect(() => {
    const alignmentTransform = computeSplitScreenAlignment(
      alignmentAnchorPositions,
      false,
    );
    if (alignmentTransform) {
      dispatch(setCloudToCadIncrementalTransform(alignmentTransform));
    }
  }, [alignmentAnchorPositions, dispatch]);

  useCloudForAlignmentView(cloudToAlign, cloudClippingPlanes);

  return (
    <>
      <View
        camera={cloudCamera}
        trackingElement={
          reference === AlignmentReference.bimModel ? firstScreen : secondScreen
        }
        background={background}
        hasSeparateScene
      >
        <ambientLight intensity={1.0} />
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={
            reference === AlignmentReference.bimModel
              ? alignmentAnchorPositions.movingElementAnchor1
              : alignmentAnchorPositions.referenceElementAnchor1
          }
          anchor2Position={
            reference === AlignmentReference.bimModel
              ? alignmentAnchorPositions.movingElementAnchor2
              : alignmentAnchorPositions.referenceElementAnchor2
          }
          changeAnchor1Position={changeCloudAnchor1Position}
          changeAnchor2Position={changeCloudAnchor2Position}
        >
          <PointCloudRenderer pointCloud={cloudToAlign} />
        </AnchorPairPlacement>
        <EffectPipelineWithSubScenes>
          <PointCloudSimpleSubscene pointCloud={cloudToAlign} />
          <FilteredRenderPass
            filter={(o) => !isObjPointCloudPoint(o)}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipelineWithSubScenes>

        <SheetModeControls
          camera={cloudCamera}
          referencePlaneHeight={cloudElevation}
          enabled={controlsEnabled}
        />
      </View>
      <View
        camera={modelCamera}
        trackingElement={
          reference === AlignmentReference.bimModel ? secondScreen : firstScreen
        }
        background={background}
        hasSeparateScene
      >
        {/** The ambient light is needed for the CAD in tomographic mode */}
        <ambientLight intensity={1.0} />
        <AnchorPairPlacement
          onPointerDown={() => setControlsEnabled(false)}
          onPointerUp={() => setControlsEnabled(true)}
          anchor1Position={
            reference === AlignmentReference.bimModel
              ? alignmentAnchorPositions.referenceElementAnchor1
              : alignmentAnchorPositions.movingElementAnchor1
          }
          anchor2Position={
            reference === AlignmentReference.bimModel
              ? alignmentAnchorPositions.referenceElementAnchor2
              : alignmentAnchorPositions.movingElementAnchor2
          }
          changeAnchor1Position={changeModelAnchor1Position}
          changeAnchor2Position={changeModelAnchor2Position}
        >
          <CadRenderer
            cadModel={cadModel}
            clippingPlanes={clippingPlanes}
            clippingInLocalTransform
          />
        </AnchorPairPlacement>

        <EffectPipeline>
          <ClearPass
            clearColor={background instanceof Color ? background : undefined}
          />
          <TomographicModelPass
            camera={modelCamera}
            bias={0.05}
            tomographicColor={blue[600]}
          />
          <FilteredRenderPass
            filter={(o) => o.userData.type !== RenderDispatch.CadModel}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipeline>
        <SheetModeControls
          camera={modelCamera}
          referencePlaneHeight={cadElevation}
          enabled={controlsEnabled}
        />
      </View>
    </>
  );
}
