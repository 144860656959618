import { areConnectionsEqual } from "@/data-preparation-tool/utils/edge-utils";
import { colorForIndex } from "@/registration-tools/common/rendering/use-point-cloud-materials";
import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { selectCurrentUser } from "@/store/user-selectors";
import { ColorString, dataComparisonColorsSorted } from "@faro-lotv/flat-ui";
import { EMPTY_ARRAY, GUID } from "@faro-lotv/foundation";
import {
  IElementGenericPointCloudStream,
  isIElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import { selectLoadedIElements } from "@faro-lotv/project-source";
import {
  RegistrationEdgeRevision,
  RegistrationEdgeType,
  RevisionScanEntity,
  RevisionStatus,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import {
  getAncestorIds,
  getDescendantIds,
  getPointCloudStreamForScanEntity,
  selectCandidateEdges,
  selectEntityTransformOverrides,
  selectIsEntityVisibleRecursive,
  selectPointCloudStreamForScanEntity,
  selectRegistrationEdgeType,
  selectRevisionEntities,
  selectRevisionEntity,
  selectRevisionEntityAllDescendants,
  selectRevisionEntityChildrenMap,
  selectRevisionEntityMap,
  selectRevisionRegistrationEdges,
} from "../revision-selectors";
import {
  RegistrationEditMode,
  UserEdit,
  UserEditAddConnection,
} from "./data-preparation-ui-slice";

/**
 * @returns the selected entities' id in the scan tree
 * @param state current app state
 */
export function selectSelectedEntityIds(state: RootState): GUID[] {
  return state.dataPreparationUi.selectedEntityIds;
}

/**
 * @returns all point cloud streams for and beneath the given entity
 * @param id the entity to get the point cloud streams for
 */
export function selectPointCloudStreamsForEntity(id?: GUID) {
  return (state: RootState): IElementGenericPointCloudStream[] => {
    if (!id) {
      return EMPTY_ARRAY;
    }

    const entity = selectRevisionEntity(id)(state);

    if (!entity) {
      return EMPTY_ARRAY;
    }

    const children = selectRevisionEntityAllDescendants(entity.id)(state);

    return [entity, ...children]
      .filter(isRevisionScanEntity)
      .map((e) => selectPointCloudStreamForScanEntity(e)(state))
      .filter(isValid);
  };
}

/**
 * @returns all point cloud stream ids for and beneath the given entity
 * @param id the entity to get the point cloud stream ids for
 */
function selectPointCloudStreamIdsForEntity(id?: GUID) {
  return (state: RootState): GUID[] =>
    selectPointCloudStreamsForEntity(id)(state).map((pcs) => pcs.id);
}

/**
 * @param state current application state
 * @returns the entities that user edits should be applied to.
 *  In particular, this excludes clusters if some of their children are selected individually.
 */
export const selectEntitiesToEdit = createSelector(
  [selectSelectedEntityIds, selectRevisionEntityChildrenMap],
  (selectedEntityIds, childrenMap) =>
    // If individual entities within a cluster are selected, only edit those entities instead of the whole cluster
    selectedEntityIds.filter(
      (id) =>
        !getDescendantIds(childrenMap, id).some(
          (descendantId) =>
            descendantId !== id && selectedEntityIds.includes(descendantId),
        ),
    ),
);

/**
 * @param state current app state
 * @returns The id of selected connection and its corresponding scans.
 */
export function selectSelectedConnectionId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.selectedConnectionId;
}

/**
 * @returns The registration revision of the hovered connection.
 */
export const selectSelectedConnectionDetails = createSelector(
  [selectSelectedConnectionId, selectRevisionRegistrationEdges],
  (selectedConnectionId, edges): RegistrationEdgeRevision | undefined =>
    edges.find((edge) => edge.id === selectedConnectionId),
);

/**
 * @param state current app state
 * @param id The ID of the connection to check whether it is selected.
 * @returns true if given id is same as selected connection id
 */
export const selectIsConnectionSelected = createSelector(
  [selectSelectedConnectionId, (state, id: GUID) => id],
  (connectionId, id): boolean => (connectionId ? connectionId === id : false),
);

/**
 * @param state current app state
 * @param id The ID of the scan entity to check whether it is a part of selected connection.
 * @returns true if given id is part of selected connection
 */
export const selectIsScanInSelectedConnection = createSelector(
  [selectSelectedConnectionDetails, (state, id: GUID) => id],
  (connectionDetails, id): boolean => {
    if (connectionDetails) {
      return (
        connectionDetails.sourceId === id || connectionDetails.targetId === id
      );
    }
    return false;
  },
);

/**
 * @param state current app state
 * @param entityIds entity ids for which point cloud stream ids are to be fetched
 * @returns  point cloud stream ids
 */
function selectPointCloudStreamIdsForEntities(entityIds: GUID[]) {
  return (state: RootState): GUID[] | undefined => {
    if (entityIds.length) {
      const pointCloudStreamIds = entityIds.flatMap((entityId) =>
        selectPointCloudStreamIdsForEntity(entityId)(state),
      );
      return [...new Set(pointCloudStreamIds)];
    }
  };
}

/**
 * @returns all point cloud streams for the selected entities.
 * @param state current app state
 */
export function selectPointCloudStreamIdsForSelectedEntities(
  state: RootState,
): GUID[] | undefined {
  const selectedEntityIds = selectSelectedEntityIds(state);
  const selectedConnectionDetails = selectSelectedConnectionDetails(state);
  if (!selectedEntityIds.length && !selectedConnectionDetails) {
    return;
  }
  const allSelectedEntityIds: GUID[] = [];
  if (selectedEntityIds.length) {
    allSelectedEntityIds.push(...selectedEntityIds);
  }
  if (selectedConnectionDetails) {
    allSelectedEntityIds.push(selectedConnectionDetails.sourceId);
    allSelectedEntityIds.push(selectedConnectionDetails.targetId);
  }
  const pcIds = selectPointCloudStreamIdsForEntities([
    ...new Set(allSelectedEntityIds),
  ])(state);
  return pcIds;
}

/**
 * @returns all scans that are selected directly or indirectly (through selected clusters).
 *  This will be exactly the scans which are also manipulated when editing.
 */
export const selectSelectedScansRecursive = createSelector(
  [
    selectEntitiesToEdit,
    selectRevisionEntityChildrenMap,
    selectRevisionEntityMap,
  ],
  (entitiesToEdit, childrenMap, entityMap): RevisionScanEntity[] =>
    entitiesToEdit
      .flatMap((id) => getDescendantIds(childrenMap, id))
      .map((id) => entityMap[id])
      .filter((entity) => !!entity && isRevisionScanEntity(entity)),
);

/**
 * @description is a memoized selector that derives the ids of scans returned by selectSelectedScansRecursive selector
 * @returns all scan ids that are selected directly or indirectly (through selected clusters).
 */
export const selectSelectedScanIdsRecursive = createSelector(
  [selectSelectedScansRecursive],
  (selectedScans): GUID[] => selectedScans.map((scan) => scan.id),
);

/**
 * @returns true if the given entity is selected (either directly, or through cluster or a connection)
 * @param state current app state
 * @param id the id of the scan to check
 */
export const selectIsScanSelected = curryAppSelector(
  createSelector(
    [
      selectSelectedScanIdsRecursive,
      (state: RootState, id: GUID) =>
        selectIsScanInSelectedConnection(state, id),
      (state, id: GUID) => id,
    ],
    (selectedEntityIds, isScanInSelectedConnection, scanId): boolean => {
      if (!selectedEntityIds.length && !isScanInSelectedConnection) {
        return false;
      }
      return selectedEntityIds.includes(scanId) || isScanInSelectedConnection;
    },
  ),
);

/**
 * @param state current app state
 * @returns The ID of the entity that is currently being hovered or `undefined` otherwise.
 */
export function selectHoveredEntityId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.hoveredEntityId;
}

/**
 * @param state current app state
 * @returns The id of hovered connection.
 */
export function selectHoveredConnectionId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.hoveredConnectionId;
}

/**
 * @returns The registration revision of the hovered connection.
 */
export const selectHoveredConnectionDetails = createSelector(
  [selectHoveredConnectionId, selectRevisionRegistrationEdges],
  (hoveredConnectionId, edges): RegistrationEdgeRevision | undefined =>
    edges.find((edge) => edge.id === hoveredConnectionId),
);

/**
 * @param state current app state
 * @param id The ID of the connection to check whether it is hovered.
 * @returns true if given id is same as hovered connection id
 */
export const selectIsConnectionHovered = createSelector(
  [selectHoveredConnectionId, (state, id: GUID) => id],
  (connectionId, id): boolean => (connectionId ? connectionId === id : false),
);

/**
 * @param state current app state
 * @param id The ID of the scan entity to check whether it is a part of hovered connection.
 * @returns true if given id is part of hovered connection
 */
export const selectIsScanInHoveredConnection = createSelector(
  [selectHoveredConnectionDetails, (state, id: GUID) => id],
  (connectionDetails, id): boolean => {
    if (connectionDetails) {
      return (
        connectionDetails.sourceId === id || connectionDetails.targetId === id
      );
    }
    return false;
  },
);

/**
 * @returns true if the given entity is being hovered
 * @param state current app state
 * @param entityId the entity to check
 */
export const selectIsEntityDirectlyHovered = curryAppSelector(
  createSelector(
    [selectHoveredEntityId, (state, entityId?: GUID) => entityId],
    (hoveredEntityId, entityId): boolean =>
      !!entityId && hoveredEntityId === entityId,
  ),
);

/**
 * @returns all entity ids that are indirectly hovered (e.g when we hover on a cluster).
 */
export const selectHoveredEntityIdsRecursive = createSelector(
  [selectHoveredEntityId, selectRevisionEntityChildrenMap],
  (entityId, childrenMap): GUID[] | undefined => {
    if (entityId !== undefined) {
      return getDescendantIds(childrenMap, entityId);
    }
  },
);

/**
 * @returns true if the given entity is being indirectly hovered (e.g. entity is a part of hovering cluster)
 * @param state current app state
 * @param entityId the entity to check
 */
export const selectIsEntityIndirectlyHovered = curryAppSelector(
  createSelector(
    [selectHoveredEntityIdsRecursive, (state, entityId?: GUID) => entityId],
    (hoveredEntityIds, entityId): boolean => {
      if (!!entityId && !!hoveredEntityIds) {
        return hoveredEntityIds.includes(entityId);
      }
      return false;
    },
  ),
);

/**
 * @returns true if the given scan is hovered (either directly or through a cluster or connection)
 * @param state current app state
 * @param id the id of scan to check
 */
export const selectIsScanHovered = curryAppSelector(
  createSelector(
    [
      (state: RootState, id: GUID) =>
        selectIsEntityIndirectlyHovered(id)(state),
      (state: RootState, id: GUID) =>
        selectIsScanInHoveredConnection(state, id),
    ],
    (isScanHovered, isScanInHoveredConnection): boolean =>
      isScanHovered || isScanInHoveredConnection,
  ),
);

/**
 * @returns true if the given scan should be highlighted/colored (either selected or hovered)
 * @param state current app state
 * @param id the id of scan to check
 */
export const selectIsScanHighlighted = curryAppSelector(
  createSelector(
    [
      selectHoveredEntityId,
      selectHoveredConnectionId,
      selectSelectedScanIdsRecursive,
      (state, id: GUID) => selectIsScanSelected(id)(state),
      (state, id: GUID) => selectIsScanHovered(id)(state),
    ],
    (
      hoveredEntityId,
      hoveredConnectionId,
      selectedScanIds,
      isScanSelected,
      isScanHovered,
    ): boolean => {
      const noScansSelectedOrHovered =
        !selectedScanIds.length &&
        hoveredEntityId === undefined &&
        hoveredConnectionId === undefined;

      if (noScansSelectedOrHovered || isScanSelected || isScanHovered) {
        return true;
      }
      return false;
    },
  ),
);

/**
 * @returns true if the given connection should be highlighted/colored when quality color coding is enabled
 * @param state current app state
 * @param id the id of connection to check
 */
export const selectIsConnectionHighlighted = curryAppSelector(
  createSelector(
    [
      selectHoveredConnectionId,
      selectSelectedConnectionId,
      (state, id: GUID) => selectIsConnectionHovered(state, id),
      (state, id: GUID) => selectIsConnectionSelected(state, id),
    ],
    (
      hoveredConnectionId,
      selectedConnectionId,
      isConnectionHovered,
      isConnectionSelected,
    ): boolean => {
      const noConnectionHovered = hoveredConnectionId === undefined;

      const noConnectionSelected = selectedConnectionId === undefined;

      if (
        (noConnectionHovered && noConnectionSelected) ||
        isConnectionHovered ||
        isConnectionSelected
      ) {
        return true;
      }
      return false;
    },
  ),
);

/**
 * @returns all point cloud streams for the hovered entities.
 * @param state current app state
 */
export function selectPointCloudStreamIdsForHoveredEntities(
  state: RootState,
): GUID[] | undefined {
  const hoveredEntityId = selectHoveredEntityId(state);
  const hoveredConnectionDetails = selectHoveredConnectionDetails(state);
  if (!hoveredEntityId && !hoveredConnectionDetails) {
    return;
  }
  const allHoveredEntityIds: GUID[] = [];
  if (hoveredEntityId) {
    allHoveredEntityIds.push(hoveredEntityId);
  }
  if (hoveredConnectionDetails) {
    allHoveredEntityIds.push(hoveredConnectionDetails.sourceId);
    allHoveredEntityIds.push(hoveredConnectionDetails.targetId);
  }
  return selectPointCloudStreamIdsForEntities([
    ...new Set(allHoveredEntityIds),
  ])(state);
}

/**
 * @param state The current application state
 * @returns Whether the user can edit the registration results.
 */
export function selectIsAnyEditModeEnabled(state: RootState): boolean {
  return state.dataPreparationUi.editMode !== undefined;
}

/**
 * @param state The current application state
 * @returns The edit mode which is currently enabled, or `undefined` if editing is disabled.
 */
export function selectEditMode(
  state: RootState,
): RegistrationEditMode | undefined {
  return state.dataPreparationUi.editMode;
}

/**
 * @param state The current application state
 * @returns Whether the user can edit scans, i.e. move and rotate them.
 */
export function selectIsEditingScans(state: RootState): boolean {
  return state.dataPreparationUi.editMode === RegistrationEditMode.editScans;
}

/**
 * @param state The current application state
 * @returns Whether the user can add new connections to the registration.
 */
export function selectIsAddingConnections(state: RootState): boolean {
  return (
    state.dataPreparationUi.editMode === RegistrationEditMode.addConnection
  );
}

/**
 * @param state The current application state
 * @returns Whether the user can delete existing registration edges.
 */
export function selectIsDeletingConnections(state: RootState): boolean {
  return (
    state.dataPreparationUi.editMode === RegistrationEditMode.deleteConnection
  );
}

/**
 * @param state The current application state
 * @returns All edits performed by the user
 */
function selectUserEdits(state: RootState): UserEdit[] {
  return state.dataPreparationUi.userEdits;
}

/**
 * @param state The current application state
 * @returns All edits performed by the user in reversed order.
 *  Useful to show the most recent edits first.
 */
const selectUserEditsReversed = createSelector([selectUserEdits], (userEdits) =>
  [...userEdits].reverse(),
);

/**
 * @param state The current application state
 * @returns The most recent edits for each connection, in the order they were performed.
 *  For each connection, only the most recent edit is included.
 */
const selectMostRecentConnectionEdits = createSelector(
  [selectUserEditsReversed],
  (userEditsReversed) => {
    const connectionEdits: UserEdit[] = [];

    for (const edit of userEditsReversed) {
      // Only take the most recent edit for each connection
      if (!connectionEdits.some((c) => areConnectionsEqual(c, edit))) {
        connectionEdits.push(edit);
      }
    }

    return connectionEdits.reverse();
  },
);

/**
 * @param state The current application state
 * @returns All connections that the user added manually, in the order they were added.
 */
export const selectConnectionsAddedByUser = createSelector(
  [selectMostRecentConnectionEdits],
  (connectionEdits) =>
    connectionEdits.filter((edit) => edit.type === "addConnection"),
);

/**
 * @param state The current application state
 * @returns All connections that the user deleted manually, in the order they were deleted.
 */
export const selectConnectionsDeletedByUser = createSelector(
  [selectMostRecentConnectionEdits],
  (connectionEdits) =>
    connectionEdits.filter((edit) => edit.type === "deleteConnection"),
);

/**
 * @returns The map of entity IDs to colors.
 * @param revisionEntities The list of revision entities.
 */
export const selectEntityIdToColorMap = createSelector(
  [selectRevisionEntities],
  (revisionEntities) => {
    const entityIdToColorMap: Record<GUID, ColorString> = {};
    revisionEntities.forEach((entity, index) => {
      entityIdToColorMap[entity.id] = colorForIndex(
        index,
        dataComparisonColorsSorted,
      );
    });
    return entityIdToColorMap;
  },
);

/**
 * @returns The map of IElement IDs to colors.
 * @param entityIdToColorMap The map of entity IDs to colors.
 * @param state The current application state.
 */
export const selectIElementIdToColorMap = createSelector(
  [selectEntityIdToColorMap, selectLoadedIElements],
  (entityIdToColorMap, iElements) => {
    const iElementIdToColorMap: Record<GUID, ColorString> = {};
    const scanIds: GUID[] = Object.keys(entityIdToColorMap);

    scanIds.forEach((scanId) => {
      const pointCloudStream = getPointCloudStreamForScanEntity(
        iElements.filter(isIElementGenericPointCloudStream),
        scanId,
      );
      if (pointCloudStream?.id) {
        iElementIdToColorMap[pointCloudStream.id] = entityIdToColorMap[scanId];
      }
    });

    return iElementIdToColorMap;
  },
);

/**
 * @param state The current application state.
 * @returns Candidate edges which were newly added by the user.
 */
export const selectNewCandidateEdges = createSelector(
  [selectConnectionsAddedByUser, selectCandidateEdges, selectCurrentUser],
  (
    addedConnections,
    oldCandidates,
    currentUser,
  ): Array<
    RegistrationEdgeRevision & { type: RegistrationEdgeType.candidate }
  > => {
    if (!currentUser) {
      return EMPTY_ARRAY;
    }

    /**
     * @param added The connection to check for reuse
     * @returns The ID to use for the added edge.
     *  To avoid API errors, the ID of an old edge has to be reused if it exists.
     */
    function reuseOldIdIfPossible(added: UserEditAddConnection): GUID {
      const oldEdge = oldCandidates.find((old) =>
        areConnectionsEqual(old, added),
      );
      if (oldEdge) return oldEdge.id;
      return added.id;
    }

    return addedConnections.map((added) => ({
      ...added,
      id: reuseOldIdIfPossible(added),
      type: RegistrationEdgeType.candidate,
      status: RevisionStatus.added,
      createdBy: currentUser.id,
      lastPatchedAt: added.createdAt,
      lastPatchedBy: currentUser.id,
      data: undefined,
    }));
  },
);

/**
 * @param state The current application state.
 * @returns All available candidate edges, both the ones on the API and the ones added by the user.
 */
const selectAllCandidateEdges = createSelector(
  [selectCandidateEdges, selectNewCandidateEdges],
  (
    oldEdges,
    newEdges,
  ): Array<
    RegistrationEdgeRevision & { type: RegistrationEdgeType.candidate }
  > => {
    const oldEdgesNotOverridden = oldEdges.filter(
      (oldEdge) =>
        !newEdges.some((newEdge) => areConnectionsEqual(newEdge, oldEdge)),
    );
    return [...oldEdgesNotOverridden, ...newEdges];
  },
);

/**
 * @param state The current application state.
 * @param edge The registration edge to check for deletion.
 * @returns Whether the user has deleted the given edge.
 */
const selectIsEdgeDeletedByUser = curryAppSelector(
  createSelector(
    [
      selectConnectionsDeletedByUser,
      (state: RootState, edge: RegistrationEdgeRevision) => edge,
    ],
    (deletedConnections, edge) =>
      deletedConnections.some((deleted) => areConnectionsEqual(edge, deleted)),
  ),
);

/**
 * @param state The current application state.
 * @param edge The registration edge to check for validity.
 * @returns Whether the registration edge is valid, i.e. no related scans have been modified since the registration
 *  and the edge is not deleted by the user.
 */
export const selectIsRegistrationEdgeValid = curryAppSelector(
  createSelector(
    [
      selectRevisionEntityMap,
      selectEntityTransformOverrides,
      (state, edge: RegistrationEdgeRevision) =>
        selectIsEdgeDeletedByUser(edge)(state),
      (state: RootState, edge: RegistrationEdgeRevision) => edge,
    ],
    (entityMap, transformOverrides, isDeleted, edge) => {
      if (isDeleted) return false;
      // Candidate edges are not invalidated by moving scans, as they are manually added by the user
      if (edge.type === RegistrationEdgeType.candidate) return true;

      const sourceAncestorIds = getAncestorIds(entityMap, edge.sourceId);
      const targetAncestorIds = getAncestorIds(entityMap, edge.targetId);

      const sourceScanMoved = sourceAncestorIds.some(
        (id) => !targetAncestorIds.includes(id) && transformOverrides[id],
      );
      const targetScanMoved = targetAncestorIds.some(
        (id) => !sourceAncestorIds.includes(id) && transformOverrides[id],
      );

      return !sourceScanMoved && !targetScanMoved;
    },
  ),
);

/**
 * @returns All loaded registration edges in the revision.
 */
export const selectActiveRegistrationEdges = createSelector(
  [
    selectRevisionRegistrationEdges,
    selectConnectionsDeletedByUser,
    selectAllCandidateEdges,
    selectRegistrationEdgeType,
  ],
  (
    edges,
    connectionsDeletedByUser,
    candidateEdges,
    edgeType,
  ): RegistrationEdgeRevision[] => {
    // The currently selected edge type
    const activeEdges = edges.filter((edge) => edge.type === edgeType);
    // Candidate edges are always included
    activeEdges.push(...candidateEdges);

    // Filter out deleted edges
    return activeEdges.filter(
      (edge: RegistrationEdgeRevision) =>
        !connectionsDeletedByUser.some((deleted) =>
          areConnectionsEqual(edge, deleted),
        ),
    );
  },
);

/**
 * Candidate edges should only be displayed if no other edge connecting the same scans exists.
 *
 * @param edge The candidate edge to check for duplication.
 * @returns `true` if another valid connection exists between the two scans, `false` otherwise.
 */
function selectIsCandidateEdgeDuplicated(edge: RegistrationEdgeRevision) {
  return (state: RootState) =>
    edge.type === RegistrationEdgeType.candidate &&
    selectActiveRegistrationEdges(state).some(
      (activeEdge) =>
        activeEdge.id !== edge.id &&
        selectIsRegistrationEdgeValid(activeEdge)(state) &&
        areConnectionsEqual(activeEdge, edge),
    );
}

/**
 * @param edge The registration edge to check for visibility.
 * @returns Whether the edge should be visible to the user.
 *  Will be hidden if any related scan is hidden or the edge is invalid.
 */
export function selectIsRegistrationEdgeVisible(
  edge: RegistrationEdgeRevision,
) {
  return (state: RootState) =>
    selectIsRegistrationEdgeValid(edge)(state) &&
    selectIsEntityVisibleRecursive(edge.sourceId)(state) &&
    selectIsEntityVisibleRecursive(edge.targetId)(state) &&
    !selectIsCandidateEdgeDuplicated(edge)(state);
}
