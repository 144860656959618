import { EventType } from "@/analytics/analytics-events";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import { setVisibilityDistance } from "@/store/view-options/view-options-slice";
import { Analytics } from "@faro-lotv/foreign-observers";
import { ViewOptionsSlider } from "./view-options-slider";

/** @returns a radio button group to select the visibility distance. */
export function VisibilityDistanceSlider(): JSX.Element {
  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  const dispatch = useAppDispatch();

  return (
    <ViewOptionsSlider
      label="Object visibility"
      title="Object Visibility"
      description="Configure the distance at which objects become visible in the current 3D scene"
      onClick={() => {
        Analytics.track(EventType.openObjectVisibilityHelp);
      }}
      step={0.1}
      min={0}
      max={11}
      value={Math.log2(visibilityDistance)}
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          return;
        }
        dispatch(setVisibilityDistance(Math.pow(2, value)));
      }}
    />
  );
}
