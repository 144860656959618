import { SupportedCamera } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EventDispatcher } from "three";

export type ViewRuntimeContextData = {
  /** The list of cameras for the current view */
  cameras: SupportedCamera[];

  /** The controls attached to each camera */
  controls?: EventDispatcher[];

  /** The setter for the current cameras */
  setCameraData(cameras: SupportedCamera[], controls?: EventDispatcher[]): void;
};

export const ViewRuntimeContext = createContext<
  ViewRuntimeContextData | undefined
>(undefined);

/**
 * @returns a context useful to set and read the current runtime data of the scene
 */
export function useViewRuntimeContext(): ViewRuntimeContextData {
  const context = useContext(ViewRuntimeContext);
  if (!context) {
    throw new Error("ViewRuntimeContext is not initialized.");
  }
  return context;
}

/**
 * @returns a component to keep the view runtime data synced with the view
 */
export function ViewRuntimeContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [cameras, setCameras] = useState<SupportedCamera[]>([]);
  const [controls, setControls] = useState<EventDispatcher[]>([]);

  const setCameraData = useCallback<ViewRuntimeContextData["setCameraData"]>(
    (cameras, controls) => {
      setCameras(cameras);
      setControls(controls ?? []);
    },
    [],
  );

  const value = useMemo<ViewRuntimeContextData>(
    () => ({
      cameras,
      controls,
      setCameraData,
    }),
    [cameras, controls, setCameraData],
  );

  return (
    <ViewRuntimeContext.Provider value={value}>
      {children}
    </ViewRuntimeContext.Provider>
  );
}

/**
 * Stores the new active cameras to the context, restores the previous ones on unmounting
 *
 * @param activeCameras The new active cameras
 */
export function useStoreActiveCameras(activeCameras: SupportedCamera[]): void {
  const { cameras, controls, setCameraData } = useViewRuntimeContext();

  const [initialState] = useState({ cameras, controls });

  useEffect(() => {
    setCameraData(activeCameras);
    return () => {
      setCameraData(initialState.cameras, initialState.controls);
    };
  }, [activeCameras, initialState, setCameraData]);
}

/**
 * Store in the runtime context the current camera and the custom controls
 */
export function useStoreCameraWithCustomControls(): void {
  const camera = useThree((s) => s.camera);
  const customControls = useThree((s) => s.controls);

  const { cameras, controls, setCameraData } = useViewRuntimeContext();
  const [initialState] = useState({ cameras, controls });

  useEffect(() => {
    setCameraData([camera], customControls ? [customControls] : []);
    return () => {
      setCameraData(initialState.cameras, initialState.controls);
    };
  }, [
    camera,
    customControls,
    initialState.cameras,
    initialState.controls,
    setCameraData,
  ]);
}
