import { PointCloudObject } from "@/object-cache";
import { useCallback, useEffect, useRef } from "react";
import { Box3, Matrix4, Vector3 } from "three";
import { computePointCloudBoundingBox } from "../../utils/camera-views";
import {
  SinglePinControls,
  SinglePinControlsImperativeApi,
} from "./single-pin-controls";

type PointCloudPinControlsProps = {
  /**
   * The point cloud object that can be manipulated by the user.
   * If `undefined`, the controls are disabled.
   */
  manipulatedPointCloud?: PointCloudObject;
  /** The callback to execute when the object is manipulated by the user. */
  onTransform(transformChange: Matrix4): void;
};

/**
 * @returns A set of controls for the user to manipulate the transform of a point cloud.
 *
 * The user can left-click on a point cloud to place a pin.
 * - When dragging *on* the pin, the point cloud is translated
 * - When dragging *around* the pin, the point cloud is rotated.
 * - When right-clicking on the pin, the pin is removed
 */
export function PointCloudPinControls({
  manipulatedPointCloud,
  onTransform,
}: PointCloudPinControlsProps): JSX.Element {
  const isManipulationEnabled = !!manipulatedPointCloud;
  const pointCloudId = manipulatedPointCloud?.iElement.id;

  // Accessor for the center of the point cloud, to improve pin positioning
  const bboxCache = useRef(new Box3());
  const centerCache = useRef(new Vector3());
  const getPointCloudCenter = useCallback(() => {
    if (!manipulatedPointCloud) return centerCache.current;

    computePointCloudBoundingBox(manipulatedPointCloud, bboxCache.current);
    return bboxCache.current.getCenter(centerCache.current);
  }, [manipulatedPointCloud]);

  const pinInteractionRef = useRef<SinglePinControlsImperativeApi>(null);

  // Reset the pin when the point cloud changes
  useEffect(() => {
    if (pointCloudId) {
      pinInteractionRef.current?.recenterPin();
    }
  }, [pointCloudId]);

  return (
    <SinglePinControls
      ref={pinInteractionRef}
      isManipulationEnabled={isManipulationEnabled}
      getObjectCenter={getPointCloudCenter}
      onTransform={onTransform}
    />
  );
}
