import { SnackbarKey, useToast } from "@faro-lotv/flat-ui";
import { useCallback, useRef } from "react";

const MAX_TIME_BEFORE_LONG_PROCESSING_TOAST = 3000;

type LongTask = {
  /** Start a timeout to show a toast after MAX_TIME_BEFORE_LONG_PROCESSING_TOAST, containing the `message` text */
  startLongTask(message: string): void;
  /** Stop the timeout */
  stopLongTask(): void;
};

/**
 * @returns The callbacks to start and stop a timeout for showing a toast when a long task is running
 * @param onTaskStateChanged Callback executed when the task state changes
 */
export function useLongTask(
  onTaskStateChanged: (isRunning: boolean) => void,
): LongTask {
  const { openToast, closeToast } = useToast();

  const toastKey = useRef<SnackbarKey | null>(null);
  const toastTimeout = useRef<NodeJS.Timeout | null>(null);

  const startLongTask = useCallback(
    (message: string) => {
      onTaskStateChanged(true);
      // If the addition of a new tag takes more than MAX_TIME_BEFORE_LONG_PROCESSING_TOAST,
      // a toast is shown to inform the user that the operation is still in progress.
      toastTimeout.current = setTimeout(() => {
        toastKey.current = openToast({
          title: message,
          variant: "info",
        });
      }, MAX_TIME_BEFORE_LONG_PROCESSING_TOAST);
    },
    [onTaskStateChanged, openToast],
  );

  const stopLongTask = useCallback(() => {
    onTaskStateChanged(false);
    if (toastTimeout.current) {
      clearTimeout(toastTimeout.current);
    }
    if (toastKey.current) {
      closeToast(toastKey.current);
    }
  }, [closeToast, onTaskStateChanged]);

  return { startLongTask, stopLongTask };
}
