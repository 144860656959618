import { getFullDeepLinkURL } from "@/components/common/deep-link/deep-link-encoding";
import { addFeatureFlagToUrl } from "@/components/common/deep-link/deep-link-utils";
import { ElementIconType } from "@/components/ui/icons";
import { Features } from "@/store/features/features";
import { RootState } from "@/store/store";
import { selectIsAllowedToBuyTokens } from "@/store/user-selectors";
import { createDashboardSubscriptionUrl } from "@/utils/redirects";
import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericPointCloud,
  isIElementGenericPointCloud,
} from "@faro-lotv/ielement-types";
import {
  PointCloudType,
  selectChildDepthFirst,
  selectCompanyId,
  selectDashboardUrl,
  selectIElement,
  selectPointCloudType,
  selectProjectAccessLevel,
  selectRootId,
} from "@faro-lotv/project-source";
import {
  FloorplanGenerationMeasurementUnit,
  MutationGenerateFloorplansForPointCloud,
  ProjectAccessLevel,
  ProjectApi,
  createMutationAddEmptyLayerSection,
  createMutationGenerateFloorplansForPointCloud,
  isElementAllowedForFloorplanGeneration,
} from "@faro-lotv/service-wires";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";
import {
  FloorPlanGenerationStartedDialog,
  GenerateFloorPlanDialog,
  RequestMoreTokensDialog,
} from "./generate-floor-plan-dialogs";

/**
 * Trigger the generation of floorplan(s) for the selected model
 */
export const GENERATE_FLOORPLAN: ContextMenuAction = {
  type: ContextMenuActionType.generateFloorplan,
  label: "Generate floor plan",
  icon: ElementIconType.SheetNewIcon,
  handler: async ({
    elementID,
    state,
    apiClients: { projectApiClient, coreApiClient },
    unitOfMeasure,
    errorHandlers: { handleErrorWithToast },
    createDialog,
  }) => {
    try {
      let measurementUnit =
        unitOfMeasure === "metric"
          ? FloorplanGenerationMeasurementUnit.metric
          : FloorplanGenerationMeasurementUnit.imperial;

      const [availableTokens, requiredTokens] = await Promise.all([
        coreApiClient
          .getTokenBalance(projectApiClient.projectId)
          .then((response) => response.data.balance),
        projectApiClient.calculateTokensForFloorPlanGeneration({
          datasetId: elementID,
        }),
      ]);

      if (availableTokens < requiredTokens) {
        const dashboardUrl = selectDashboardUrl(state);
        const companyId = selectCompanyId(state);
        assert(dashboardUrl && companyId, "Expected user to be in a company");

        createDialog({
          title: "Floor plan generation",
          content: (
            <RequestMoreTokensDialog
              availableTokens={availableTokens}
              requiredTokens={requiredTokens}
              isAllowedToBuyTokens={selectIsAllowedToBuyTokens(state)}
              subscriptionsUrl={createDashboardSubscriptionUrl(
                dashboardUrl,
                companyId,
              )}
            />
          ),
          showXButton: true,
          showCancelButton: false,
          showConfirmButton: false,
        });
        return;
      }

      const hasConfirmed = await createDialog({
        title: "Floor plan generation",
        content: (
          <GenerateFloorPlanDialog
            availableTokens={availableTokens}
            requiredTokens={requiredTokens}
            initialMeasurementUnit={measurementUnit}
            onMeasurementUnitChanged={(newUnit) => {
              measurementUnit = newUnit;
            }}
          />
        ),
        confirmText: "Generate",
        showXButton: true,
        allowToCloseWithoutCancelling: true,
        async onConfirm() {
          try {
            const rootId = selectRootId(state);
            assert(rootId, "Can only create empty layer with root ID.");

            const element = selectIElement(elementID)(state);

            const datasetSection = selectChildDepthFirst(
              element,
              isElementAllowedForFloorplanGeneration,
            )(state);
            assert(datasetSection, "Can't find suitable dataset section.");

            /** Pointcloud to use to generate the floor plan */
            const pcElement = selectPointCloudForFloorplan(element)(state);
            assert(pcElement, "Unable to find suitable point cloud.");

            const projectAccessLevel = selectProjectAccessLevel(state);

            const layerId = generateGUID();

            // Generating a link that will open the Viewer looking at the point cloud the floor plan is generated for
            const projectShareLink = addFeatureFlagToUrl(
              getFullDeepLinkURL({
                id: datasetSection.id,
              }),
              // Allow the third part supplier to access ortho photo exports, to help improve the accuracy of the scale
              Features.AllowOrthoPhotoExport,
            ).toString();

            await startFloorplanGeneration({
              projectApiClient,
              layerId,
              rootId,
              sectionId: datasetSection.id,
              pointcloudElement: pcElement,
              isPrivate: projectAccessLevel === ProjectAccessLevel.private,
              projectShareLink,
              measurementUnit,
            });
            return true;
          } catch (error) {
            handleErrorWithToast({
              title: "Unable to generate floor plan",
              error,
            });
            return false;
          }
        },
      });

      if (!hasConfirmed) return;

      await createDialog({
        title: null,
        content: (
          <FloorPlanGenerationStartedDialog
            remainingTokens={availableTokens - requiredTokens}
          />
        ),
        confirmText: "Ok",
        showCancelButton: false,
      });
    } catch (error) {
      handleErrorWithToast({ title: "Unable to generate floor plan", error });
    }
  },
};

type StartFloorplanGenerationArgs = Pick<
  MutationGenerateFloorplansForPointCloud,
  "isPrivate" | "projectShareLink" | "measurementUnit"
> & {
  /** Client to use */
  projectApiClient: ProjectApi;

  /** ID of the layer section to create the point cloud in */
  layerId: GUID;

  /** ID of the root element */
  rootId: GUID;

  /** ID of the section to append the empty layer to */
  sectionId: GUID;

  /** Point cloud to generate the floor plan(s) */
  pointcloudElement: IElementGenericPointCloud;
};

async function startFloorplanGeneration({
  projectApiClient,
  layerId,
  rootId,
  sectionId,
  pointcloudElement,
  isPrivate,
  projectShareLink,
  measurementUnit,
}: StartFloorplanGenerationArgs): Promise<void> {
  const addEmptyLayerMutation = createMutationAddEmptyLayerSection({
    layerId,
    rootId,
    sectionId,
    name: `Empty layer for section ${sectionId}`,
  });
  const floorplanGenerationMutation =
    createMutationGenerateFloorplansForPointCloud({
      rootId,
      name: `Floor plan generation element for layer ${layerId}`,
      uri: pointcloudElement.uri,
      layerId,
      md5Hash: pointcloudElement.md5Hash,
      fileName: pointcloudElement.fileName,
      fileSize: pointcloudElement.fileSize,
      isPrivate,
      projectShareLink,
      measurementUnit,
    });

  await projectApiClient.applyMutations([
    addEmptyLayerMutation,
    floorplanGenerationMutation,
  ]);
}

/**
 * Select the point cloud to use for the floor plan generation, giving priority to the project point cloud.
 *
 * @param referenceElement The element to use as reference to find the children pointcloud
 * @returns The point cloud to use for the floor plan generation
 */
function selectPointCloudForFloorplan(referenceElement: IElement | undefined) {
  return (state: RootState): IElementGenericPointCloud | undefined => {
    const projectPointCloud = selectChildDepthFirst(
      referenceElement,
      (el: IElement): el is IElementGenericPointCloud =>
        isIElementGenericPointCloud(el) &&
        selectPointCloudType(el)(state) === PointCloudType.project,
    )(state);

    const pcElement =
      projectPointCloud ??
      selectChildDepthFirst(
        referenceElement,
        isIElementGenericPointCloud,
      )(state);

    return pcElement;
  };
}
