import {
  CloseColoredAnalysisLoopEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useUnitOfMeasureContext } from "@/components/common/unit-of-measure-context";
import { MultiPointRenderer } from "@/components/r3f/renderers/measurements/multi-point-measure-renderer";
import { TwoPointMeasureSegment } from "@/components/r3f/renderers/measurements/two-point-segment-renderer";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudObject } from "@/object-cache";
import {
  addAnalysis,
  setActiveAnalysisId,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { deactivateTool, ToolName } from "@/store/ui/ui-slice";
import {
  setObjectVisibility,
  ViewObjectTypes,
} from "@/store/view-options/view-options-slice";
import {
  adjustPointsToBeCoplanar,
  createNewAnalysis,
} from "@/utils/colormap-analysis-utils";
import { useUpdateVisibilityDistance } from "@/utils/use-update-visibility-distance";
import { useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { MAX_NUMBER_OF_POLYGON_POINTS } from "@faro-lotv/lotv";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { forwardRef, useCallback, useRef, useState } from "react";
import { MOUSE, Vector3 } from "three";
import {
  MultiPointMeasureControls,
  MultiPointMeasureControlsActions,
} from "./multi-point-measures/multi-point-measures-controls";
import { ProjectionType } from "./multi-point-measures/multi-point-measures-controls-logic";
import { ToolControlsRef } from "./tool-controls-interface";

type PointCloudAnalysisToolProps = {
  /** The active point cloud object */
  activePointCloud: PointCloudObject;
};

export const PointCloudAnalysisTool = forwardRef<
  ToolControlsRef,
  PointCloudAnalysisToolProps
>(function PointCloudAnalysisTool(
  { activePointCloud }: PointCloudAnalysisToolProps,
  ref,
): JSX.Element {
  const dispatch = useAppDispatch();
  const { openToast } = useToast();

  const [currentPoint, setCurrentPoint] = useState<Vector3>();
  const onCurrentPointChanged = useCallback((point: Vector3 | undefined) => {
    setCurrentPoint(point ? point.clone() : undefined);
  }, []);

  const [polygonPoints, setPolygonPoints] = useState<Vector3[]>();
  const onPointsChanged = useCallback(
    async (points: Vector3[] | undefined) => {
      if (points && points.length >= MAX_NUMBER_OF_POLYGON_POINTS) {
        openToast({
          title: "Point limit reached",
          message: `You've reached the ${MAX_NUMBER_OF_POLYGON_POINTS}-point limit for defining the analysis region. Close the polygon or use backspace to adjust points.`,
          variant: "warning",
        });
        return;
      }

      setPolygonPoints(
        await adjustPointsToBeCoplanar(points, activePointCloud),
      );
      // Reset the current point to trigger a redraw of all segments
      setCurrentPoint(currentPoint?.clone());
    },
    [activePointCloud, currentPoint, openToast],
  );

  const { camera } = useThree();
  const updateVisibilityDistance = useUpdateVisibilityDistance();

  const onAnalysisCompleted = useCallback(
    async (
      isClosed: boolean,
      iElementId: string,
      isCompletedByClickFirstPoint?: boolean,
    ) => {
      if (!polygonPoints) return;
      if (activePointCloud.iElement.id !== iElementId) return;

      if (!isClosed) {
        // polygon is not closed, do not create the analysis
        setPolygonPoints(undefined);
        return;
      }

      Analytics.track<CloseColoredAnalysisLoopEventProperties>(
        EventType.closeColoredAnalysisLoop,
        {
          isCompletedByClickFirstPoint: isCompletedByClickFirstPoint ?? false,
          numberOfPoints: polygonPoints.length,
        },
      );

      const analysis = await createNewAnalysis(
        activePointCloud,
        polygonPoints,
        camera,
      );

      // Ensure the new analysis is visible
      updateVisibilityDistance(camera, polygonPoints[0]);
      dispatch(
        setObjectVisibility({
          type: ViewObjectTypes.analyses,
          visibility: true,
        }),
      );

      dispatch(
        addAnalysis({
          pointCloudID: iElementId,
          analysis,
        }),
      );

      setPolygonPoints(undefined);
      // set the new analysis as active
      dispatch(setActiveAnalysisId(analysis.id));
      // Deactivate the tool after the analysis is completed.
      dispatch(deactivateTool());
    },
    [
      activePointCloud,
      camera,
      dispatch,
      polygonPoints,
      updateVisibilityDistance,
    ],
  );

  const { unitOfMeasure } = useUnitOfMeasureContext();
  const controlActions = useRef<MultiPointMeasureControlsActions>(null);
  const onHandlerClicked = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      if (!polygonPoints) return;
      if (index < 0 || index >= polygonPoints.length) return;
      if (polygonPoints.length < 3) return;
      if (ev.button !== MOUSE.LEFT) return;

      if (index === 0 || index === polygonPoints.length - 1) {
        controlActions.current?.completeMeasurement(true, index === 0);
        ev.stopPropagation();
      }
    },
    [polygonPoints],
  );

  const labelContainer = useViewOverlayRef();

  const analysisActive = useAppSelector(selectActiveTool) === ToolName.analysis;

  return (
    <>
      {polygonPoints && polygonPoints.length >= 1 && currentPoint && (
        <TwoPointMeasureSegment
          visible
          start={currentPoint}
          end={polygonPoints[0]}
          labelPosition={currentPoint}
          length={currentPoint.distanceTo(polygonPoints[0])}
          index={0}
          main
          live={false}
          isMeasurementActive={undefined}
          isLabelActive
          labelContainer={labelContainer}
          unitOfMeasure={unitOfMeasure}
          onClick={() => {}}
          dashed
          labelsPointerEvents="none"
          isLabelVisible={false}
        />
      )}
      {polygonPoints && polygonPoints.length >= 2 && currentPoint && (
        <TwoPointMeasureSegment
          visible
          start={currentPoint}
          end={polygonPoints[polygonPoints.length - 1]}
          labelPosition={currentPoint}
          length={currentPoint.distanceTo(
            polygonPoints[polygonPoints.length - 1],
          )}
          index={0}
          main
          live={false}
          isMeasurementActive={undefined}
          isLabelActive
          labelContainer={labelContainer}
          unitOfMeasure={unitOfMeasure}
          onClick={() => {}}
          dashed
          labelsPointerEvents="none"
          isLabelVisible={false}
        />
      )}
      {polygonPoints && (
        <MultiPointRenderer
          points={polygonPoints}
          live
          isClosed={false}
          isLabelActive={false}
          visible
          unitOfMeasure={unitOfMeasure}
          onToggleUnitOfMeasure={() => {}}
          onHandlerClicked={onHandlerClicked}
          showActionBar={false}
          isMeasurementActive={undefined}
          labelContainer={labelContainer}
          labelsPointerEvents={undefined}
          isLabelVisible={false}
        />
      )}
      <MultiPointMeasureControls
        onPointsChanged={onPointsChanged}
        onCurrentPointChanged={onCurrentPointChanged}
        onMeasurementCompleted={onAnalysisCompleted}
        onDeleteActiveMeasurement={() => {}}
        ref={ref}
        actions={controlActions}
        active={analysisActive}
        projectOnShiftKey={ProjectionType.DoNotProject}
        maxPoints={MAX_NUMBER_OF_POLYGON_POINTS}
      />
    </>
  );
});
