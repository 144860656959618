import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { useAppDispatch } from "@/store/store-hooks";
import { IElement } from "@faro-lotv/ielement-types";
import { useCallback } from "react";
import { ProjectTreeActionButton } from "./project-tree-action-button";

type AlignPcToAreaButtonProps = {
  /** DataSession/DataSet element for the point cloud to align */
  pointCloud: IElement;

  /** true if the button should be disabled (visible but user cannot click on it) */
  disabled?: boolean;
};

/**
 * @returns A component that renders a button in the project tree item,
 * redirecting to alignment tool with the point cloud selected
 * if the conditions to display that button are met.
 */
export function AlignCloudButton({
  pointCloud,
  disabled,
}: AlignPcToAreaButtonProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const startAlignmentWizard = useCallback(() => {
    openAlignmentWizard({
      elementIdToAlign: pointCloud.id,
      dispatch,
    });
  }, [dispatch, pointCloud.id]);

  return (
    <ProjectTreeActionButton
      name="Align"
      onClick={startAlignmentWizard}
      disabled={disabled}
    />
  );
}
