import { RootState } from "@/store/store";
import { UserSubscriptionRole } from "@faro-lotv/service-wires";

/**
 * @returns true if the subscriptions of the current users are available
 * @param state current app state
 */
export function selectAreSubscriptionsAvailable(state: RootState): boolean {
  return state.subscriptions.subscriptions !== undefined;
}

/**
 * @returns true if the current company have the proper bundle to upload a point cloud
 * @param state current app state
 */
export function selectCanUploadPointCloud(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.globalPointcloudWrite,
  );
}

/**
 * @returns true if the current company have the proper bundle to read a point cloud
 * @param state current app state
 */
export function selectCanReadPointCloud(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.globalPointcloudRead,
  );
}

/**
 * @returns true if the current company have the proper bundle to upload and process orbis files
 * @param state current app state
 */
export function selectCanUploadOrbisFiles(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.globalOrbisProcessing,
  );
}

/**
 * @returns true if the current company have the proper bundle to upload CAD
 * @param state current app state
 */
export function selectCanUploadCad(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.cadImport,
  );
}

/**
 * @returns true if the current company have the proper bundle to display the CAD's mesh and its metadata
 * @param state current app state
 */
export function selectCanReadCAD(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.cadDisplay,
  );
}

/**
 * @returns true if the user is allowed to generate floorplans
 * @param state current app state
 */
export function selectCanGenerateFloorplans(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.floorplanGenerator,
  );
}

/**
 * @returns true if all members of the company are allowed to export point clouds
 * @param state current app state
 */
export function selectCanAllMembersExportPointCloud(state: RootState): boolean {
  return !!state.subscriptions.subscriptions?.includes(
    UserSubscriptionRole.pcExportAllMembers,
  );
}
