import { EventType } from "@/analytics/analytics-events";
import { ToolName } from "@/store/ui/ui-slice";
import {
  ToolButton,
  ToolGroup,
  Toolbar,
  ZoomAllIcon,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { DataPreparationToolsToolbar } from "../data-praparation-tools-toolbar";
import { DataPreparationViewSettingsTool } from "./data-preparation-view-settings-tool";

interface DataPreparationViewSettingsToolbarProps {
  /** Callback executed when the view is reset */
  onRecenterView(): void;
  /** The currently active tool */
  activeTool: ToolName | undefined;
  /** Callback to toggle the active tool */
  toggleTool(tool: ToolName): void;
}

/** @returns a toolbar that contains the tools to manage view settings in the data preparation tool */
export function DataPreparationViewSettingsToolbar({
  onRecenterView,
  activeTool,
  toggleTool,
}: DataPreparationViewSettingsToolbarProps): JSX.Element | null {
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);

  return (
    <Toolbar
      ref={setToolbarRef}
      sx={{ alignSelf: "flex-end", overflow: "visible" }}
    >
      <ToolGroup>
        <DataPreparationViewSettingsTool anchorEl={toolbarRef} />

        <RecenterViewTool onRecenterView={onRecenterView} />

        <DataPreparationToolsToolbar
          activeTool={activeTool}
          onToggleTool={toggleTool}
        />
      </ToolGroup>
    </Toolbar>
  );
}

type RecenterViewToolProps = Pick<
  DataPreparationViewSettingsToolbarProps,
  "onRecenterView"
>;

/** @returns tool button to trigger a re centering of the view */
function RecenterViewTool({
  onRecenterView,
}: RecenterViewToolProps): JSX.Element {
  return (
    <Tooltip title="Recenter view" placement="left">
      <ToolButton
        aria-label="recenter view"
        onClick={(e) => {
          Analytics.track(EventType.recenterRegistrationView);
          e.nativeEvent.stopImmediatePropagation();
          onRecenterView();
        }}
      >
        <ZoomAllIcon />
      </ToolButton>
    </Tooltip>
  );
}
