import {
  ActivateLayerProperties,
  EventType,
  IsolateLayerProperties,
} from "@/analytics/analytics-events";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import {
  selectIsSheetVisible,
  selectListVisibleSheets,
} from "@/store/selections-selectors";
import {
  setSheetTheOnlyVisible,
  setSheetVisibility,
} from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  convertToDateString,
  cyan,
  ExclamationMarkCircleFillIcon,
  FaroButton,
  FaroIconButton,
  FaroTooltip,
  IconBadgeData,
  LayoutSheetSmallIcon,
  LocationMapIcon,
  NoTranslate,
  SingleSelectItem,
  SingleSelectItemProps,
  TranslateVar,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElementAreaSection,
  IElementGenericDataSession,
  IElementGenericDataset,
  IElementGenericImgSheet,
  isIElementAreaSection,
  isIElementGenericDataSession,
  isIElementGenericDataset,
  isIElementOverviewImage,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { Stack, SvgIconProps } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { ElementIcon, ElementIconType } from "../icons";
import { ContextMenu } from "../tree/tree-context-menu/tree-context-menu";

type MultiLayerMenuItemProps = Pick<SingleSelectItemProps, "selectedValue"> & {
  /** The sheet of this layer */
  sheet: IElementGenericImgSheet;

  /** Number of visible layers in multi-layer menu for selected area  */
  visibleLayersInArea: number;

  /** Total number of layers in multi-layer menu for selected area  */
  layersInArea: number;
};

/** @returns the select item for each available layer */
export function MultiLayerMenuItem({
  sheet,
  layersInArea,
  visibleLayersInArea,
  ...rest
}: MultiLayerMenuItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const reference = useAppSelector((state) =>
    selectLayerReference(state, sheet),
  );

  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const listVisibleSheets = useAppSelector(selectListVisibleSheets);

  const isVisible = useAppSelector((state) =>
    selectIsSheetVisible(state, sheet.id),
  );

  const isLayerAligned = useMemo(
    () => !!sheet.pose && !!sheet.pose.pos && !!sheet.pose.scale,
    [sheet.pose],
  );

  const startAlignmentWizard = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // we do not want the SingleSelectItem's onClick to react to this click
      event.stopPropagation();

      Analytics.track(EventType.pressMultilayerItemAlignButton);

      openAlignmentWizard({
        elementIdToAlign: sheet.id,
        dispatch,
      });
    },
    [dispatch, sheet.id],
  );

  const [displayTooltip, setDisplayTooltip] = useState(false);

  const unalignedIconBadgeData: IconBadgeData = useMemo(
    () => ({
      badgeContent: (
        <ExclamationMarkCircleFillIcon
          sx={{ fontSize: "1em", color: "warning.main" }}
        />
      ),
      badgeBackgroundColor: "default",
    }),
    [],
  );

  return (
    <SingleSelectItem
      {...rest}
      selected={false}
      selectedValue={undefined}
      disabled={false}
      onClick={() => {
        Analytics.track<IsolateLayerProperties>(EventType.isolateLayer, {
          layerType: isIElementOverviewImage(sheet)
            ? "overviewMap"
            : "floorPlan",
          previousVisibleLayers: listVisibleSheets.length,
        });

        dispatch(setSheetTheOnlyVisible(sheet.id));
      }}
      Icon={getLayerIconComponent(reference)}
      iconBadgeData={isLayerAligned ? undefined : unalignedIconBadgeData}
      label={
        <TruncatedFaroText variant="bodyM" color="inherit" dark>
          <NoTranslate component="span">{sheet.name}</NoTranslate>
        </TruncatedFaroText>
      }
      extraAction={
        <Stack direction="row" alignItems="center">
          {!isLayerAligned && (
            <FaroButton
              aria-label="align-layer-button"
              variant="ghost"
              size="xs"
              dark
              onClick={startAlignmentWizard}
              sx={{
                minWidth: 0,
              }}
            >
              Align
            </FaroButton>
          )}
          <FaroTooltip
            title="At least one layer must remain visible"
            open={displayTooltip}
            onClose={() => setDisplayTooltip(false)}
          >
            <EyeButton
              isVisible={isVisible}
              onClick={(event) => {
                // we do not want the SingleSelectItem's onClick to react to this click
                event.stopPropagation();

                if (visibleLayersInArea > 1 || !isVisible) {
                  Analytics.track<ActivateLayerProperties>(
                    isVisible
                      ? EventType.deactivateLayer
                      : EventType.activateLayer,
                    {
                      layerType: isIElementOverviewImage(sheet)
                        ? "overviewMap"
                        : "floorPlan",
                      previousVisibleLayers: visibleLayersInArea,
                      totalLayers: layersInArea,
                    },
                  );

                  dispatch(
                    setSheetVisibility({
                      sheetId: sheet.id,
                      isVisible: !isVisible,
                    }),
                  );
                  setDisplayTooltip(false);
                } else {
                  setDisplayTooltip(true);
                }
              }}
            />
          </FaroTooltip>
          {hasWritePermission && (
            <ContextMenu
              id={sheet.id}
              isNodeDisabled={false}
              dark
              iconHoverColor={hoverColorForIcons}
            />
          )}
        </Stack>
      }
      key={sheet.id}
      value={sheet.id}
      secondaryText={
        reference ? (
          <MultiLayerMenuItemSecondaryText reference={reference} />
        ) : undefined
      }
      dark
    />
  );
}

type MultiLayerMenuItemSecondaryTextProps = {
  /** The reference element the current layer is part of */
  reference:
    | IElementGenericDataSession
    | IElementGenericDataset
    | IElementAreaSection;
};

/** @returns the secondary text for a layer menu select item */
function MultiLayerMenuItemSecondaryText({
  reference,
}: MultiLayerMenuItemSecondaryTextProps): JSX.Element | null {
  return useMemo(() => {
    if (isIElementAreaSection(reference)) {
      return null;
    }
    return (
      <Stack alignItems="center" direction="row" gap={1}>
        <TranslateVar name="layerName">
          {convertToDateString(reference.createdAt)} {reference.name}
        </TranslateVar>
      </Stack>
    );
  }, [reference]);
}

/**
 * @returns the icon component for a layer
 * @param reference the reference element for a layer (area or dataset)
 */
function getLayerIconComponent(
  reference?:
    | IElementGenericDataSession
    | IElementGenericDataset
    | IElementAreaSection,
): ((props: SvgIconProps) => JSX.Element) | undefined {
  if (!reference) return;

  if (isIElementAreaSection(reference)) {
    return LayoutSheetSmallIcon;
  } else if (
    isIElementGenericDataSession(reference) ||
    isIElementGenericDataset(reference)
  ) {
    return LocationMapIcon;
  }
}

/**
 * @param state the app state
 * @param sheet the sheet to compute the reference for
 * @returns the reference element for a layer (data session or data set)
 */
const selectLayerReference = createSelector(
  [
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementAreaSection)(state),
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementGenericDataSession)(state),
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementGenericDataset)(state),
  ],
  (area, dataSession, dataSet) => dataSession ?? dataSet ?? area,
);

type EyeButtonProps = {
  /** Method to call when an item is selected */
  onClick(event: MouseEvent<HTMLButtonElement>): void;

  /** Flag used to show correct eye icon in menu: Visible/NonVisible  */
  isVisible: boolean;
};

/** @returns render eye button */
function EyeButton({ isVisible, onClick }: EyeButtonProps): JSX.Element {
  return (
    <FaroIconButton
      onClick={onClick}
      margin={0.5}
      size="s"
      aria-label="toggle layer visibility"
      // Get both the image color and its hover color from the container
      color="inherit"
      hoverColor={hoverColorForIcons}
      sx={{
        color: "inherit",
      }}
    >
      <ElementIcon
        icon={isVisible ? ElementIconType.Visible : ElementIconType.NonVisible}
      />
    </FaroIconButton>
  );
}

// Color for mouse hover on the icon buttons
// Should be the same as FaroButton's FaroButtonVariants.ghost text color
const hoverColorForIcons = cyan[400];
