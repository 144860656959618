import { Box, SxProps, Theme } from "@mui/material";
import { useEffect, useReducer } from "react";

export type DelayedProps = {
  /** The components to render */
  children: React.ReactNode;

  /** How many ms to wait before rendering them */
  delay: number;

  // Material UI styling object
  sx?: SxProps<Theme>;
};

/**
 * @returns A component to render another group of components after a configured delay
 */
export function Delayed({
  children,
  delay,
  sx,
}: DelayedProps): JSX.Element | null {
  const [isVisible, show] = useReducer(() => true, false);

  useEffect(() => {
    const timeout = setTimeout(show, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  // When you need to just forward a ReactNode fragment is required to return a JSX.Element
  return isVisible ? (
    <Box component="div" sx={sx}>
      {children}
    </Box>
  ) : null;
}
