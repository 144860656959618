import { selectRevisionEntityScanCenter } from "@/data-preparation-tool/store/revision-selectors";
import { useAppStore } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import { Map2DControls as Map2DControlsImpl } from "@faro-lotv/lotv";
import { Camera } from "@react-three/fiber";
import { Ref, useCallback, useEffect, useRef } from "react";
import { Matrix4, Vector3 } from "three";
import {
  SinglePinControls,
  SinglePinControlsImperativeApi,
} from "./single-pin-controls";

type EntityPinControlsProps = {
  /**
   * The revision entities that can be manipulated by the user.
   * If empty, the controls are disabled.
   */
  manipulatedEntityIds: GUID[];

  /** The callback to execute when the object is manipulated by the user. */
  onTransform(worldTransform: Matrix4): void;

  /** The camera used for the controls */
  camera?: Camera;

  /** A ref for the map controls used with the pin controls */
  mapControlsRef: Ref<Map2DControlsImpl>;
};

/**
 * @returns A set of controls for the user to manipulate the transform of an entity.
 *
 * The user can left-click on any point cloud to place a pin.
 * - When dragging *on* the pin, the entity is translated
 * - When dragging *around* the pin, the entity is rotated.
 * - When right-clicking on the pin, the pin is removed
 */
export function EntityPinControls({
  manipulatedEntityIds,
  mapControlsRef,
  camera,
  onTransform,
}: EntityPinControlsProps): JSX.Element {
  const { getState } = useAppStore();

  const isManipulationEnabled = manipulatedEntityIds.length > 0;

  // Accessor for the center of the entity, to improve pin positioning
  const getPointCloudsCenter = useCallback(() => {
    const entityCount = manipulatedEntityIds.length;
    const center = new Vector3();

    for (const entityId of manipulatedEntityIds) {
      center.add(selectRevisionEntityScanCenter(entityId)(getState()));
    }

    return entityCount > 0 ? center.divideScalar(entityCount) : center;
  }, [manipulatedEntityIds, getState]);

  const pinInteractionRef = useRef<SinglePinControlsImperativeApi>(null);

  // Reset the pin when the entity changes
  useEffect(() => {
    if (manipulatedEntityIds.length > 0) {
      pinInteractionRef.current?.recenterPin();
    }
  }, [manipulatedEntityIds]);

  return (
    <SinglePinControls
      ref={pinInteractionRef}
      isManipulationEnabled={isManipulationEnabled}
      getObjectCenter={getPointCloudsCenter}
      onTransform={onTransform}
      camera={camera}
      mapControlsRef={mapControlsRef}
    />
  );
}
