import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import { ToolName } from "@/store/ui/ui-slice";
import { CameraViewType } from "@/utils/cam-view";
import { TypedEvent } from "@faro-lotv/foundation";
import { createContext, useContext, useState } from "react";
import { Projection } from "./ui/projection-switch";

/** All the data preparation overlay elements we need to react from the registration scenes */
interface DataPreparationOverlayElements {
  /** The views for visualizing pointclouds in 2D projection*/
  perspective: Perspective;
  /** Change the visualization view in 2D projection - top/front/side views */
  setPerspective(perspective: Perspective): void;
  /** The two dimensional or three dimensional projection of point clouds */
  projection: Projection;
  /** Change the projection - 2D/3D */
  setProjection(projection: Projection): void;
  /** camera options in 3D projection */
  cameraOptions3D: CameraViewType;
  /** Change the camera options - perspective/orthographic */
  setCameraOptions3D(cameraViewType: CameraViewType): void;
  /** Center the active camera */
  centerCameraEvent: TypedEvent<Perspective>;
  /** The tool in UI which can be made active based on user action */
  activeTool: ToolName | undefined;
  /** Enable or disable a tool */
  setActiveTool(toolName?: ToolName): void;
}

/** A context to pass to R3F the overlay elements we need in our scenes */
export const DataPreparationOverlayElementsContext = createContext<
  DataPreparationOverlayElements | undefined
>(undefined);

/**
 * @returns The registered overlay elements to be used in mode scenes
 */
export function useDataPreparationOverlayElements(): DataPreparationOverlayElements {
  const ctx = useContext(DataPreparationOverlayElementsContext);
  if (!ctx) {
    throw Error("useOverlayElements called outside an OverlayElementContext");
  }
  return ctx;
}

/**
 * @returns The initial state for the DataPreparationOverlayElementsContext
 */
export function useDataPreparationOverlayElementsInitialState(): DataPreparationOverlayElements {
  const [perspective, setPerspective] = useState(Perspective.topView);
  const [projection, setProjection] = useState(Projection.twoDimensional);
  const [centerCameraEvent] = useState(new TypedEvent<Perspective>());
  const [cameraOptions3D, setCameraOptions3D] = useState<CameraViewType>(
    CameraViewType.perspective,
  );
  const [activeTool, setActiveTool] = useState<ToolName>();

  return {
    perspective,
    setPerspective,
    projection,
    setProjection,
    cameraOptions3D,
    setCameraOptions3D,
    centerCameraEvent,
    activeTool,
    setActiveTool,
  };
}
