import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { DesaturationSheetsPipeline } from "@/components/r3f/renderers/desaturation-pipeline";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { PlaceholderPreview } from "@/components/r3f/utils/placeholder-preview";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
  useOrthoCameraRotationFromSheet,
} from "@/hooks/use-center-camera-on-placeholders";
import { useIElementClippingBoxObb } from "@/hooks/use-object-bounding-box";
import { useCached3DObjectsIfExists } from "@/object-cache";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  DEFAULT_WAYPOINT_TEXTURE_SIZE,
  MapWaypointsRendererRef,
  useSvg,
  WaypointFlashScanSelected,
  WaypointSelected,
} from "@faro-lotv/app-component-toolbox";
import { assert, GUID } from "@faro-lotv/foundation";
import {
  IElementImg360,
  IElementSection,
  isIElementAreaSection,
  isIElementImg360,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  FloorPlanBackgroundTransparency,
  PlaceholderStateFlags,
} from "@faro-lotv/lotv";
import {
  selectChildDepthFirst,
  selectIElement,
  selectMainAreaVolume,
} from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "es-toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OrthographicCamera } from "three";
import { selectCurrentAreaData } from "../mode-selectors";
import { SheetWaypoints } from "../sheet-mode/sheet-waypoints";
import { useTagsManagementContext } from "./tags-management-context";
import {
  selectTagsManagementScene,
  TagsManagementScene as TagsManagementCurrentScene,
} from "./tags-management-selector";

type IndexMap = Record<GUID, number>;

/** @returns The 3D scene of the tags management mode */
export function TagsManagementScene(): JSX.Element | null {
  const {
    activeAreaId,
    hoveredIds,
    hoverId,
    clearHovering,
    selectedIds,
    toggleSelectedId,
    selectId,
  } = useTagsManagementContext();
  const activeArea = useAppSelector(selectIElement(activeAreaId));
  assert(
    activeArea && isIElementAreaSection(activeArea),
    "There should always be an area active",
  );

  const areaData = useAppSelector(selectCurrentAreaData(activeArea), isEqual);
  const currentScene = useAppSelector(
    selectTagsManagementScene(areaData),
    isEqual,
  );

  const areaVolume = useAppSelector(selectMainAreaVolume(activeArea));
  const areaBox = useIElementClippingBoxObb(areaVolume);

  const panos = useAppSelector(
    (state) => selectScenePanos(state, currentScene),
    isEqual,
  );

  const scans = useMemo(
    () => [...currentScene.rooms, ...Object.values(currentScene.scans).flat()],
    [currentScene],
  );

  const { panoToIndex, scanToIndex } = useMemo(() => {
    const panoToIndex: IndexMap = {};
    const scanToIndex: IndexMap = {};
    for (let index = 0; index < panos.length; ++index) {
      panoToIndex[panos[index].id] = index;
      scanToIndex[scans[index].id] = index;
    }
    return { panoToIndex, scanToIndex };
  }, [panos, scans]);

  const cameraRotation = useOrthoCameraRotationFromSheet(
    currentScene.sheets[0].id,
  );
  const cameraData = useCenterCameraOnPlaceholders({
    areaVolume: areaBox,
    sheetElements: currentScene.sheets,
    cameraRotation,
    placeholders: panos,
  });

  const camera = useThree((s) => s.camera);
  useEffect(() => {
    if (camera instanceof OrthographicCamera) {
      centerOrthoCamera(camera, cameraData);
    }
  }, [camera, cameraData]);

  const [mapPlaceholders, setMapPlaceholders] =
    useState<MapWaypointsRendererRef | null>(null);
  useTagsManagementWaypointsSelected(mapPlaceholders, selectedIds, scanToIndex);
  useTagsManagementWaypointsHovered(mapPlaceholders, hoveredIds, scanToIndex);

  const onPlaceholderClick = useCallback(
    (pano: IElementImg360, event: MouseEvent) => {
      const scanIndex = panoToIndex[pano.id];
      if (scanIndex < 0) return;

      const scan = scans[scanIndex];
      if (event.ctrlKey || event.shiftKey || event.metaKey) {
        toggleSelectedId(scan.id);
      } else {
        selectId(scan.id);
      }
    },
    [scans, panoToIndex, toggleSelectedId, selectId],
  );

  const [previewElement, setPreviewElement] = useState<IElementImg360>();
  const onPlaceholderHovered = useCallback(
    (pano?: IElementImg360) => {
      if (!pano) {
        clearHovering();
        setPreviewElement(undefined);
        return;
      }
      const scanIndex = panoToIndex[pano.id];
      if (scanIndex < 0) return;

      const scan = scans[scanIndex];
      hoverId(scan.id);
      setPreviewElement(pano);
    },
    [panoToIndex, scans, hoverId, clearHovering],
  );

  const selectedTexture = useSvg(
    WaypointSelected,
    DEFAULT_WAYPOINT_TEXTURE_SIZE,
    DEFAULT_WAYPOINT_TEXTURE_SIZE,
  );

  const selectedFlashScanTexture = useSvg(
    WaypointFlashScanSelected,
    DEFAULT_WAYPOINT_TEXTURE_SIZE,
    DEFAULT_WAYPOINT_TEXTURE_SIZE,
  );

  const sheetObjects = useCached3DObjectsIfExists(currentScene.sheets);
  return (
    <>
      {sheetObjects.map((sheet, index) => (
        <SheetRenderer
          sheet={sheet}
          backgroundTransparent={FloorPlanBackgroundTransparency.Alpha}
          key={sheet.iElement.id}
          index={index}
        />
      ))}
      <SheetModeControls camera={camera} referencePlaneHeight={0} />
      <SheetWaypoints
        paths={[]}
        panos={panos}
        ref={setMapPlaceholders}
        onPlaceholderClicked={onPlaceholderClick}
        onPlaceholderHovered={onPlaceholderHovered}
        textures={{ selectedTexture }}
        flashTextures={{ selectedTexture: selectedFlashScanTexture }}
      />
      <DesaturationSheetsPipeline enabled={false} transparentBackground />
      <PlaceholderPreview placeholder={previewElement} />
    </>
  );
}

/* eslint-disable jsdoc/require-param */
/**
 * @returns The collection of iElementImg360 children of the current scene
 * @param currentScene The current scene of the tags management mode
 */
const selectScenePanos = createSelector(
  [
    (state: RootState) => state,
    (state: RootState, currentScene: TagsManagementCurrentScene) =>
      currentScene.rooms,
    (state: RootState, currentScene: TagsManagementCurrentScene) =>
      currentScene.scans,
  ],
  (
    state: RootState,
    rooms: IElementSection[],
    scans: Record<string, IElementSection[]>,
  ) =>
    [...rooms, ...Object.values(scans).flat()]
      .map((e) => selectChildDepthFirst(e, isIElementImg360)(state))
      .filter(isValid),
);

/**
 * Manually update the state of the placeholders to support multi-selection.
 * TODO: add support to multi-selection directly in MapPlaceholders https://faro01.atlassian.net/browse/SWEB-6565
 *
 * @param mapPlaceholders The renderer of the waypoints
 * @param selectedIds The list of ids selected by the user
 * @param sections The pano sections in the current scene
 */
function useTagsManagementWaypointsSelected(
  mapPlaceholders: MapWaypointsRendererRef | null | undefined,
  selectedIds: GUID[],
  panoToSectionIndex: Record<GUID, number>,
): void {
  useEffect(() => {
    if (!mapPlaceholders) return;
    const selectedScansIndices: number[] = [];
    for (const id of selectedIds) {
      const panoIndex = panoToSectionIndex[id];
      if (panoIndex >= 0) {
        selectedScansIndices.push(panoIndex);
      }
    }

    // This is an  implementation detail that only the lotv library should know.
    // TODO: Remove these once the multi-selection will be supported by MapPlaceholders https://faro01.atlassian.net/browse/SWEB-6565
    for (let i = 0; i < mapPlaceholders.count; ++i) {
      const state = mapPlaceholders.geometry.attributes.state.getX(i);
      mapPlaceholders.geometry.attributes.state.set(
        [state & ~PlaceholderStateFlags.SELECTED],
        i,
      );
    }

    for (const index of selectedScansIndices) {
      const state = mapPlaceholders.geometry.attributes.state.getX(index);
      mapPlaceholders.geometry.attributes.state.set(
        [state | PlaceholderStateFlags.SELECTED],
        index,
      );
    }
  }, [mapPlaceholders, panoToSectionIndex, selectedIds]);
}

/**
 * Manually update the state of the placeholders to support multi-hovering.
 * TODO: add support to multi-hovering directly in MapPlaceholders https://faro01.atlassian.net/browse/SWEB-6565
 *
 * @param mapPlaceholders The renderer of the waypoints
 * @param hoveredIds The list of ids selected by the user
 * @param sections The pano sections in the current scene
 */
function useTagsManagementWaypointsHovered(
  mapPlaceholders: MapWaypointsRendererRef | null | undefined,
  hoveredIds: GUID[],
  panoToSectionIndex: Record<GUID, number>,
): void {
  useEffect(() => {
    if (!mapPlaceholders) return;
    const hoveredScansIndices: number[] = [];
    for (const id of hoveredIds) {
      const panoIndex = panoToSectionIndex[id];
      if (panoIndex >= 0) {
        hoveredScansIndices.push(panoIndex);
      }
    }

    // This is an  implementation detail that only the lotv library should know.
    // TODO: Remove these once the multi-selection will be supported by MapPlaceholders https://faro01.atlassian.net/browse/SWEB-6565
    for (let i = 0; i < mapPlaceholders.count; ++i) {
      const state = mapPlaceholders.geometry.attributes.state.getX(i);
      mapPlaceholders.geometry.attributes.state.set(
        [state & ~PlaceholderStateFlags.HOVERED],
        i,
      );
    }

    for (const index of hoveredScansIndices) {
      const state = mapPlaceholders.geometry.attributes.state.getX(index);
      mapPlaceholders.geometry.attributes.state.set(
        [state | PlaceholderStateFlags.HOVERED],
        index,
      );
    }
  }, [mapPlaceholders, panoToSectionIndex, hoveredIds]);
}
