import { ScanConnection } from "@/data-preparation-tool/utils/edge-utils";
import { generateGUID, GUID } from "@faro-lotv/foundation";
import { ISOTimeString } from "@faro-lotv/ielement-types";
import { clearStore } from "@faro-lotv/project-source";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearUserEdits } from "../data-preparation-actions";

/** The edit mode, defining which actions the user can take while editing. */
export enum RegistrationEditMode {
  /** Moving and rotating scans. */
  editScans = "editScans",
  /** Manually adding registration edges, which will _always_ be included in the cloud registration. */
  addConnection = "addConnection",
  /** Manually delete registration edges, which will _never_ be included in the cloud registration. */
  deleteConnection = "deleteConnection",
}

/** A connection added manually be the user. */
export type UserEditAddConnection = {
  type: "addConnection";
  /** ID of the edit. Can later be used to persist the connection. */
  id: GUID;
  /** The ID of the source scan of the connection. */
  sourceId: GUID;
  /** The ID of the target scan of the connection. */
  targetId: GUID;
  /** The ISO timestamp when the edit was performed. */
  createdAt: ISOTimeString;
};

/** A connection deleted manually by the user. */
export type UserEditDeleteConnection = {
  type: "deleteConnection";
  /** ID of the edit. */
  id: GUID;
  /** The ID of the source scan of the connection. */
  sourceId: GUID;
  /** The ID of the target scan of the connection. */
  targetId: GUID;
  /** The ISO timestamp when the edit was performed. */
  createdAt: ISOTimeString;
};

/** An edit performed by the user. */
export type UserEdit = UserEditDeleteConnection | UserEditAddConnection;

type DataPreparationUiState = {
  /**
   * The IDs of the entities currently selected by the user,
   * e.g. via the scan tree or directly in the 3D view.
   */
  selectedEntityIds: GUID[];

  /** The hovered entity id in the scan-tree */
  hoveredEntityId?: GUID;

  /** The id of hovered connection and its corresponding scans in the registration scene */
  hoveredConnectionId?: GUID;

  /** The id of selected connection and its corresponding scans in the registration scene */
  selectedConnectionId?: GUID;

  /** The currently active edit mode. */
  editMode?: RegistrationEditMode;

  /** The unsaved edits performed by the user. */
  userEdits: UserEdit[];
};

export const DATA_PREPARATION_UI_INITIAL_STATE: Readonly<DataPreparationUiState> =
  Object.freeze({
    selectedEntityIds: [],
    userEdits: [],
  });

const dataPreparationUiSlice = createSlice({
  initialState: DATA_PREPARATION_UI_INITIAL_STATE,
  name: "data-preparation-ui",

  reducers: {
    /**
     * @param state The current application state
     * @param action The IDs of the entities to select.
     *  Clears the previous selection and replaces it with the new one.
     */
    setSelectedEntityIds(state, action: PayloadAction<GUID[]>) {
      state.selectedEntityIds = action.payload;
    },

    /**
     * Remove the entity from the selection.
     * If the entity is not selected, nothing happens.
     *
     * @param state The current application state
     * @param action The ID of the entity to deselect.
     */
    deselectEntity(state, action: PayloadAction<GUID>) {
      state.selectedEntityIds = state.selectedEntityIds.filter(
        (id) => id !== action.payload,
      );
    },

    /**
     * Select/deselect the entity.
     *
     * - If _only_ this entity is selected, deselect it.
     * - Otherwise, select only this entity.
     *
     * @param state The current application state
     * @param action The ID of the entity to select.
     */
    toggleSingleSelection(state, action: PayloadAction<GUID>) {
      if (
        state.selectedEntityIds.length === 1 &&
        state.selectedEntityIds[0] === action.payload
      ) {
        state.selectedEntityIds = [];
      } else {
        state.selectedEntityIds = [action.payload];
      }
    },

    /**
     * Add/remove the entity from the selection.
     *
     * - If the entity is not selected yet, add it to the selection.
     * - If the entity is already selected, remove it from the selection.
     *
     * @param state The current application state
     * @param action The ID of the entity to select.
     */
    toggleMultiSelection(state, action: PayloadAction<GUID>) {
      const index = state.selectedEntityIds.indexOf(action.payload);

      if (index === -1) {
        // Not included in the selection yet, add it
        state.selectedEntityIds.push(action.payload);
      } else {
        // Remove the entity from the selection
        state.selectedEntityIds.splice(index, 1);
      }
    },

    /**
     * @param state The current application state
     * @param action The entity id that is currently hovered
     */
    setHoveredEntityId(state, action: PayloadAction<GUID | undefined>) {
      state.hoveredEntityId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The entity id that should be unset if it is currently hovered
     */
    unsetHoveredEntityId(state, action: PayloadAction<GUID | undefined>) {
      if (state.hoveredEntityId === action.payload) {
        state.hoveredEntityId = undefined;
      }
    },

    /**
     * @param state The current application state
     * @param action The entity id of the hovered connection
     */
    setHoveredConnectionId(state, action: PayloadAction<GUID | undefined>) {
      state.hoveredConnectionId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The hovered connection id
     */
    unsetHoveredConnectionId(state, action: PayloadAction<GUID | undefined>) {
      if (state.hoveredConnectionId === action.payload) {
        state.hoveredConnectionId = undefined;
      }
    },

    /**
     * @param state The current application state
     * @param action The entity id of the selected connection
     */
    setSelectedConnectionId(state, action: PayloadAction<GUID | undefined>) {
      state.selectedConnectionId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The selected connection id
     */
    unsetSelectedConnectionId(state, action: PayloadAction<GUID | undefined>) {
      if (state.selectedConnectionId === action.payload) {
        state.selectedConnectionId = undefined;
      }
    },
    /**
     * Enable editing and switch to the default editing mode.
     *
     * @param state The current application state
     */
    enableDefaultEditMode(state) {
      state.editMode = RegistrationEditMode.editScans;
    },

    /**
     * Disable any active edit mode, making the view read-only again.
     *
     * @param state The current application state
     */
    disableEditMode(state) {
      state.editMode = undefined;
    },

    /**
     * @param state The current application state
     * @param action The new active edit mode
     */
    setEditMode(state, action: PayloadAction<RegistrationEditMode>) {
      state.editMode = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The connection the user added manually
     */
    addUserEditAddConnection(state, action: PayloadAction<ScanConnection>) {
      const addConnection: UserEditAddConnection = {
        type: "addConnection",
        id: generateGUID(),
        createdAt: new Date().toISOString(),
        sourceId: action.payload.sourceId,
        targetId: action.payload.targetId,
      };
      state.userEdits.push(addConnection);
    },

    /**
     * @param state The current application state
     * @param action The connection the user deleted manually
     */
    addUserEditDeleteConnection(state, action: PayloadAction<ScanConnection>) {
      const deleteConnection: UserEditDeleteConnection = {
        type: "deleteConnection",
        id: generateGUID(),
        createdAt: new Date().toISOString(),
        sourceId: action.payload.sourceId,
        targetId: action.payload.targetId,
      };
      state.userEdits.push(deleteConnection);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => DATA_PREPARATION_UI_INITIAL_STATE);

    builder.addCase(clearUserEdits, (state) => {
      state.userEdits = [];
      state.selectedEntityIds = [];
    });
  },
});

export const {
  setSelectedEntityIds,
  deselectEntity,
  toggleSingleSelection,
  toggleMultiSelection,
  setHoveredEntityId,
  unsetHoveredEntityId,
  setHoveredConnectionId,
  unsetHoveredConnectionId,
  setSelectedConnectionId,
  unsetSelectedConnectionId,
  enableDefaultEditMode,
  disableEditMode,
  setEditMode,
  addUserEditAddConnection,
  addUserEditDeleteConnection,
} = dataPreparationUiSlice.actions;

export const dataPreparationUiReducer = dataPreparationUiSlice.reducer;
